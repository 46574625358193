import Base from './base';
import PropsInit from '../../mixins/reports/controller-props-initialization';
/* global Ember */

export default Base.extend(PropsInit, {
    reportTitle: 'ALERTS',
    sortProperties: ['createdAt:asc'],
    completedRecords: [
    Ember.Object.create({
      "id": "1",
      "groupID": "1",
      "companyID": "1",
      "type": "alert",
      "status": "created",
      "persistent": true,
      "details": {
          descr: "Description ..."
      },
      "createdAt": new Date("2017-04-13T08:45:42.538Z").getTime(),
      "updatedAt": new Date("2017-04-13T08:45:42.538Z").getTime()
    }),	
    Ember.Object.create({
      "id": "2",
      "groupID": "1",
      "companyID": "1",
      "type": "alert",
      "status": "created",
      "persistent": true,
      "details": {
          descr: "Description ..."
      },
      "createdAt": new Date("2017-04-13T09:05:42.538Z").getTime(),
      "updatedAt": new Date("2017-04-13T09:05:42.538Z").getTime()
    }),
    Ember.Object.create({
      "id": "3",
      "groupID": "1",
      "companyID": "1",
      "type": "alert",
      "status": "created",
      "persistent": true,
      "details": {
          descr: "Contrary to popular belief"
      },
      "createdAt": new Date("2017-04-12T08:45:42.538Z").getTime(),
      "updatedAt": new Date("2017-04-12T08:45:42.538Z").getTime()
    }),
	Ember.Object.create({
      "id": "4",
      "groupID": "1",
      "companyID": "1",
      "type": "alert",
      "status": "created",
      "persistent": true,
      "details": {
          descr: "Contrary to popular belief"
      },
      "createdAt": new Date("2017-04-12T10:45:42.538Z").getTime(),
      "updatedAt": new Date("2017-04-12T10:45:42.538Z").getTime()
    }),
	Ember.Object.create({
      "id": "4",
      "groupID": "1",
      "companyID": "1",
      "type": "alert",
      "status": "created",
      "persistent": true,
      "details": {
          descr: "Contrary to popular belief"
      },
      "createdAt": new Date("2017-04-11T03:45:42.538Z").getTime(),
      "updatedAt": new Date("2017-04-11T03:45:42.538Z").getTime()
    })
	]
});