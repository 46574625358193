import Ember from 'ember'
import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin'
import ENV from '../config/environment'

export default Ember.Route.extend(UnauthenticatedRouteMixin, {
	session: Ember.inject.service(),
	i18n: Ember.inject.service(),
	
	onActivate: Ember.on('activate', function() {
		let invitationToken = this.get("session.invitationToken")
		Ember.Logger.debug("Invitation token:", invitationToken)
		if (invitationToken == null) {
			return this.transitionTo("login")
		}
		var lockOptions = {
			autoclose: true,
			closable: true,
			allowLogin: false,
			allowSignUp: true,
			initialScreen: 'signUp',
			auth: {
				redirect: false,
				params:{
					scope: 'openid profile'
				}
			},
			languageDictionary: {
				signUpTerms: this.get('i18n').t('signup.terms').string
			},
			mustAcceptTerms: true,
			configurationBaseUrl: 'https://cdn.eu.auth0.com'
		};
		this.get('session')
		.authenticate('authenticator:tracknamic', lockOptions)
		.then(() => {
			return this.get('session').invalidate()
		})
		.catch(error => {
			Ember.Logger.debug("Signup failed !", error)
			window.location.replace(ENV['ember-simple-auth'].baseURL)
		})
	})
});