export default {
	management: 'Management',
	documents: 'Documente',
	moreDetails: 'Adauga mai multe detalii',
	docStartDate: 'Din',
	docEndDate: 'Pana in',
	price: 'Pret RON',
	load: 'Incarca',
	download: 'Descarca',
	accountInformation: 'Informatie cont',
	document: {
		deleteTittle: "Stergere document",
		sureToDelete: "Documentul {{fileName}} ({{type}}) va fi sters",
		notifications: {
			defaultText: 'Notificari',
			day: 'O zi',
			week: 'O saptamana',
			month: 'O luna',
		}
	},
	headerFields: {
		licensePlate: "Numar inmatriculare",
		driver: "Operator",
		car: "Masina",
		type: "Tip",
		division: 'Divizie',
		status: 'Stare',
		name: 'Nume',
		email: 'Mail',
		phoneNumber: 'Telefon',
		role: 'Tip',
		width: 'Lățime',
	},
	implements: {
		types: {
			plow: "Plug",
			cultivator: "Semanatoare",
			"disc-harrow": "Disc",
			combinator: "Combinator",
			"chisel-plow": "Scarificator",
			"seed-drill-weeding": "Semanatoare plante prasitoare",
			"seed-drill-cereal": "Semanatoare plante paioase",
			weeder: "Prasitoare",
			drill: "Freza",
			sprayer: "Masina Erbicidat",
			fertilizer: "Masina Fertilizat",
			leveler: "Lama de nivelat",
			planter: "Plantator",
			"rotating-rake": "Grebla rotativa",
			baler: "Presa de balotat",
			mower: "Masina de cosit",
			harvesters: "Seceratoare",
			harrow: "Grapa",
			roller: "Tavalug",
			trailer: "Remorca",
			vidange: "Vidanja",
			chopper: "Tocator",
			"front-loader": "Incarcator frontal",

			"harvester-header-cereal": "Header plante paioase",
			"harvester-header-weeding": "Header plante prasitoare"
		},
		newTitle: 'Adauga implement',
		noImplement: 'Fara implement',
		name: 'Denumire',
		type: "Tip",
		width: 'Lățime (cm)',
		sureToDelete: 'Esti sigur ca vrei sa stergi acest implement',
	},
	timetables: {
		withoutTimetable: 'Fară orar',
		sureToDeleteTimetable: 'Esti sigur ca doresti sa stergi acest orar',
		setCompanyDefaultTimetable: 'Seteaza ca implicit pentru companie',
		willBeChanged: 'Orarul va fi schimbat',
		defaultCompanyWillBeChanged: 'Orarul companiei va fi schimbat de la',
		detachDefaultCompanyTimetable: 'Compania ramane fara niciun orar',
		defaultCompanyTimetableWillBeDeleted: 'Compania va ramane fara niciun orar implicit!',
		to: 'la',
		name: 'Nume Orar',
		isDefaultCompanyTimetable: 'Compania va ramane fara un niciun orar ca default daca il stergeti.',
		browse: 'Alege fișier...',
		addCsv: 'CSV',
		status: {
			defaultText: 'Stare',
			disabled: 'Oprit',
			custom: 'Personalizat',
			fullDay: 'Toată ziua',
		},
		new: {
			title: 'Adauga Orar',
			buttonText: 'SALVEAZA',
		},
		import: {
			title: "Importă orar",
			buttonText: "Importă",
			name: "Nume orar",
			licensePlate: "Numar de inmatriculare",

			affectedVehicles: "Utilaje cărora li se va modifica orarul",
			timetablesForRemoval: "Orare care vor fi șterse",

			mondayStart: "Luni start",
			mondayStop: "Luni stop",

			tuesdayStart: "Marti start",
			tuesdayStop: "Marti stop",

			wednesdayStart: "Miercuri start",
			wednesdayStop: "Miercuri stop",

			thursdayStart: "Joi start",
			thursdayStop: "Joi stop",

			fridayStart: "Vineri start",
			fridayStop: "Vineri stop",

			saturdayStart: "Sambata start",
			saturdayStop: "Sambata stop",

			sundayStart: "Duminica start",
			sundayStop: "Duminica stop"
		},
		edit: {
			title: 'Editeaza Orar',
			buttonText: 'MODIFICA',
		},
		view: {
			title: 'Informatie Orar',
			buttonText: 'OK',
		}
	},
	vehicles: {
		// small modal
		division: 'Divizie',
		driverIsAttached: 'Operatorul selectat este asignat la alt utilaj !',
		isAssignedToVehicle: 'este asignat la utilajul',
		willBeAssigned: 'se va asigna la utilajul actual',
		sureToDeleteVehicle: 'Esti sigur ca vrei sa stergi acest utilaj',
		vehicleHasDeviceAssigned: 'Utilajul are asignat dispozitivul {{deviceID}}',
		selectDevice: 'Selecteaza dispozitiv',
		relayContact: {
			blockedContact: 'Contact blocat',
			unblockedContact: 'Contact deblocat',
			areYouSureToBlockThisContact: 'Ești sigur că dorești să blochezi acest contact ?',
			areYouSureToUnblockThisContact: 'Ești sigur că dorești să deblochezi acest contact ?',
			blockContact: 'Blocare contact',
			unblockContact: 'Deblocare contact',
		},
		speedLimit: {
			max: 'Prag alerta implicita',
			maxTooltip: 'peste aceasta viteza trimitem alerta din oficiu indiferent de limitele rutiere (ex 90 km /h pt autoutilitare)',
			min: 'Prag minim activare alerta',
			minTooltip: 'pana la aceasta viteza nu verificam posibilele incalcari',
			offset: 'Încălcare admisă',
			offsetTooltip: 'marja de incalcare admisa. ex 10km/h pentru a fi alertat la \n50 + 10 km/h in localitate, \n90 + 10km/h pe drum national, \n130 +10 km pe autostrada',
			errorMessage: 'Verificați câmpurile marcate cu roșu.'
		},
		doorAlert: {
			noAlert: "Fără alertare",
			alertOpenDoor: "Alertare ușă deschisă",
			doorOpenOutsidePOI: "Alertare ușă deschisă înafară POI",
			doorOpenOutsidePOIInTimetable: "Alertare ușă deschisă înafară POI în orar",
		},
		removeDevice: 'Inlatura dispozitiv',
		cannotChangeDeviceUntilRelayContactDisabled: 'Nu se poate schimba dispozitivul dacă contactul este blocat.',
		cannotChangeRelayContactUntilDeviceIsSaved: 'Nu se poate bloca contactul până nu se salvează device-ul.',
		deviceChange: 'Schimbare dispozitiv',
		noDevices: 'Nu sunt dispozitive libere',
		vehicleDetails: 'Detalii utilaj',
		vehicleAlerts: 'Alerte utilaj',
		moreDetails: 'Mai multe detalii',
		autoPark: 'Parc auto',
		licensePlate: 'Numar Inmatriculare',
		vin: 'Serie sasiu',
		make: 'Marca',
		model: 'Model',
		vehicle: 'Utilaj',
		fuel: {
			typeText: 'Tip combustibil',
			type: {
				petrol: 'Benzina',
				hybridPetrol: 'Hibrid-benzina',
				diesel: 'Motorina',
				hybridDiesel: 'Hibrid-motorina',
				lpg: 'GPL',
				electric: 'Electric',
			},
			capacity: 'Capacitate rezervor',
			consumption: {
				distanceWise: 'Consum normal L/100KM',
				timeWise: 'Consum normal L/h'
			},
		},
		category: 'Categoria',
		type: {
			utility: 'Autoutilitara',
			car: 'Autoturism',
			minibus: 'Microbuz',
			bus: 'Autocar',
			truck: 'Camion',
			tractor: 'Tractor',
			harvester: 'Combina',
			construction: 'Utilaj',
			trailer: 'Remorca',
			'semi-trailer': 'Semiremorcă'
		},
		seats: 'Numar locuri',
		selectDivision: 'Selecteaza divizie',
		selectDriver: 'Selecteaza Operator',
		removeDriver: 'Inlatura Operator',
		fabricationYear: 'An fabricare',
		registrationDate: 'Data inmatriculare',
		engine: {
			power: 'Putere (CP)',
			capacity: 'Capacitate cilindrica'
		},
		engineLoadLimit: 'Limita Sarcină Motor (%)',
		engineLoadLimitText: 'Limita Sarcină Motor(%) pentru calcularea suprafetelor lucrate.\nAdmise doar numere intregi intre 0 si 100',
		payloadCapacity: 'Sarcina utila',
		tireSize: 'Dimensiuni anvelope',
		color: 'Culoare',
		new: {
			title: 'Adauga utilaj',
		},
		edit: {
			title: 'Editeaza utilaj',
		},
		view: {
			title: 'Informatie utilaj',
		}
	},
	users: {
		userDetails: 'Detalii utilizator',
		// small modal
		vehicleHasDriver: 'Utilajul selectat are deja un operator !',
		userHasVehicle: 'Acest utilizator este atasat la utilajul',
		ifDeleteDetatch: 'Daca stergeti acest utlizator, el va fi detasat de la utilajul respectiv',
		haveDriver: 'are ca operator pe',
		wichWillBeReplaced: 'care va fi inlocuit cu',
		sureToDeleteThisUser: 'Esti sigur ca doresti sa stergi acest utilizator',
		userName: 'Nume utilizator',
		firstName: 'Prenume',
		iButton: 'iButton',
		lastName: 'Nume',
		email: 'Mail',
		photoUpload: 'Incarca poza',
		division: 'Divizie',
		phoneNumber: 'Telefon',
		drivingLicense: 'Categorie permis',
		assignedVehicle: 'Utilaj asignat',
		detachVehicle: 'Inlatura Utilaj',
		role: 'Rol',
		driver: 'Operator',
		admin: 'Administrator',
		docStartDate: 'Din',
		docEndDate: 'Pana in',
		docPrice: 'Pret RON',
		load: 'Incarca',
		new: {
			title: 'Adauga utilizator',
			buttonText: 'SALVEAZA',
			headerText: 'Completeaza formularul de mai jos cu datele utilizatorului. Pentru detalii apasati butonul \'Adauga mai multe detalii\'.'
		},
		edit: {
			title: 'Editeaza utilizator',
			buttonText: 'MODIFICA',
			headerText: 'Completeaza formularul de mai jos cu datele utilizatorului. Pentru detalii apasati butonul \'Adauga mai multe detalii\'.'
		},
		view: {
			title: 'Informatie utilizator',
			buttonText: 'OK',
			headerText: 'Informatii despre utilizatorul selectat. Pentru detalii apasati butonul \'Adauga mai multe detalii\'.'
		}
	},
	groups: {
		deleteGroup: 'Esti sigur ca vrei sa stergi acesta divizie',
		group: 'Divizia',
		addGroup: 'Adaugare Divizie',
		timetable: 'Orar',
		editGroup: 'Editare Divizie',
		groupName: 'Nume Divizie',
		addSubGroup: 'Adauga subdivizie',
		editSubGroup: 'Editeaza subdivizie',
		deleteSubGroup: 'Sterge subdivizie',
		share: "Parteneri",
		add: "Adaugă"
	},
	geofences: {
		name: 'Nume field',
		radio: 'Raza',
		sureToDeleteGeofence: 'Esti sigur ca vrei sa stergi fieldul',
		alertStationaryTimeHoverText: 'Minute de stationare (5-60).',
		showAll: 'Arata toate terenurile',
		selectAll: 'Toate fieldurile',
		selectNone: 'Fara fielduri',
		type: {
			geofence: 'Field',
			poi: 'Ferma'
		},
		shape: {
			type: {
				Polygon: 'Field',
				Point: 'Ferma'
			}
		},
		new: {
			title: 'Adauga field',
			buttonText: 'SALVEAZA',
		},
		import: {
			title: 'Import fielduri',
			buttonText: 'IMPORTA FIELDURI',
			importedGeofencesNumber: 'Au fost importate {{value}} fielduri',
			updatedGeofencesNumber: 'Au fost modificate {{value}} fielduri',
			notImportedGeofencesNumber: 'Nu au fost importate {{value}} fielduri',
			allGeofencesImported: 'Toate fieldurile au fost importate ({{value}})',
			pleaseWait: 'Vă rugăm să așteptați',
			processingGeofences: 'Se procesează datele din fișier',
			downloadTemplate: "Descarcă template",
			downloadExamples: "Descarcă exemple"
		},
		simulate: {
			importedGeofencesNumber: 'Vor fi importate {{value}} fielduri.',
			updatedGeofencesNumber: 'Vor fi modificate {{value}} fielduri.',
			notImportedGeofencesNumber: 'Nu vor fi importate {{value}} fielduri.',
			allGeofencesImported: 'Toate fieldurile vor fi importate ({{value}})',
			validateFile: "Verificare CSV"
		},
		edit: {
			title: 'Editeaza field',
			buttonText: 'MODIFICA',
		},
		info: {
			title: 'Informatie field',
			buttonText: 'OK',
		}
	},
	settings: {
		speedLimit: "Limita viteza",
		insertEmailAddress: 'Introduceti adresele de email (max 3):',
		email: 'Email',
		insertPhoneNumber: 'Introduceti numerele de telefon (max 3) Exemplu: 0787 709 154',
		phoneNumber: 'Numar Telefon',
		alerts: {
			limit: 'Limita',
			'geofence-stationary': 'Staționare ferma',
			'document-expiry': 'Expirare document',
			'fuel-consumption-limit': 'Consum maxim combustibil/ora',
			'rpm-limit': 'RPM > 1900 in 2 minute',
		},
		emails: {
			'poi-stationary': 'Staționare ferma',
			'high-rpm': 'RPM > 2000 la 5 puncte consecutive',
			'stationary-fuel-consumption': 'Scădere combustibil în staționare',
			'document-expiry': 'Expirare document',

			// not titan 
			'poi-enter': 'Intrare ferma',
			'poi-exit': 'Ieșire ferma',
			'geofence-enter': 'Intrare field',
			'geofence-exit': 'Ieșire field',
			'geofence-stationary': 'Staționare field',
			'speeding': 'Incălcare limită viteză',
			'door-open-outside-poi': 'Deschidere ușă în afara field',
			insertEmailsSeparatedByComma: 'Introduceti adresa de email si selectati alertele',
			notifications: 'Notificari',
			email: 'Email',
		}
	}
}
