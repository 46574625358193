import Ember from 'ember';

export default Ember.Mixin.create({

    currentUser: Ember.inject.service('current-user'),

    afterModel() {
        var 
            interval,
            _this = this;

        function isUserLoaded() {
            return (_this.get('currentUser.user') !== null);
        }

        function isAdmin() {
            return _this.get('currentUser.user.isAdmin');
        }

        if (!isUserLoaded()) {
            interval = setInterval(function () {
                if (isUserLoaded()) {
                    if (!isAdmin()) {
                        _this.transitionTo('index');
                    } else {
                        clearInterval(interval);
                    }
                }     
            }, 200);
        } else {
            if (!isAdmin()) {
                this.transitionTo('index');
            }
        }     
    }
});
