import Ember from 'ember'

export default Ember.Controller.extend({
	formula: null,
	actions: {
		manageFormula(formula) {
			this.set('formula', formula)
		}
	}
})
