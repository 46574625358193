import Ember from 'ember';
import moment from 'moment';

// http://stackoverflow.com/questions/43520233/why-moment-weekdays-returns-days-starting-with-sunday-when-i-specified-that-fi

export function weekDay(params/*, hash*/) {
  var weekDays = moment.weekdays(true);
  return weekDays[params].capitalize();
}

export default Ember.Helper.helper(weekDay);
