import Ember from 'ember';

const shapeType = 'point';

export default Ember.Service.extend({
    /**
     *
     * @param {array of geofence} geofences
     * @return {array of geofence} contains only polygons
     */
    _filterPOIs(geofences) {
        return geofences.toArray().filter((geofence) => geofence.get('shape.type').toLowerCase() === shapeType);
    },

    /**
     *
     * @param {geofence} geofences
     * @return {array}  polygons coordinates
     */
    pois(geofences) {
        if (!geofences) { return null; }
        let pois = this._filterPOIs(geofences);
        return pois.map(p => this.inverseCoordinates(p));
    },

    /**
     *
     * @param {geofence} poiGeofence
     * @return {object} object similar to geofence, but reverse coordinates
     */
    inverseCoordinates(poiGeofence) {
        let coords = Ember.copy(poiGeofence.get('shape.coordinates'));

        return Ember.Object.create({
            name: poiGeofence.get('name'),
            boundary: poiGeofence.get('boundary'),
			color: poiGeofence.get('color'),
            shape: {
                type: poiGeofence.get('shape.type'),
                coordinates: coords.reverse()
            },
            type: poiGeofence.get('type'),
        });
    },
});
