import Ember from 'ember';

export default Ember.Route.extend({
    // i18n: Ember.inject.service(),
    model() {
        return this.store.createRecord('timetable', {
            name: "",
            timezone: "Europe/Bucharest",
            schedule: [
                [8, 0, 17, 0],
                [8, 0, 17, 0],
                [8, 0, 17, 0],
                [8, 0, 17, 0],
                [8, 0, 17, 0],
                [0, 0, 0, 0],
                [0, 0, 0, 0]
            ],
        });
    },
    actions: {
        willTransition() {
            Ember.$('.managementModal').modal("hide");
            this.controller.rollBackAndTransition();
        },
    }
});
