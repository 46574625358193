import Ember from 'ember';

export default Ember.Component.extend({
    modalApproved: false,

    init() {
        this._super(...arguments);
        Ember.$('.ui.dimmer.modals').remove();
    },

    didInsertElement() {
        var _this = this;
        Ember.$('#division-edit')
            .modal({
                onApprove: function () {
                    _this.set("modalApproved", true);
                },
                onHide: function () {
                    if (!_this.get("modalApproved")) {
                        _this.get('cancel')();
                    }
                },
            })
            .modal('show');
    },
    actions: {
        save() {
            this.sendAction('save');
        }
    }
});
