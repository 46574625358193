import Ember from 'ember';
import moment from 'moment';

export default Ember.Helper.helper(function (params) {
	var date 	= (params[0] != null) ? (new Date(params[0])).getTime() : null;
	var format 	= (params[1] != null) ? params[1]: "d-m-y";
	
	if (date) {
		return moment(date).format(format);
	} else {
		return null;
	}
});
