import DS from 'ember-data';
import MF from 'ember-data-model-fragments';

export default DS.Model.extend({
    groupID:    DS.attr("string"),
    enabled:    DS.attr("boolean"),
    options:    MF.fragmentArray("integration-option-fragment"),
    template:   DS.attr("string"),
    name:       DS.attr("string"),
    logoUrl:    DS.attr("string"),
    homepage:   DS.attr("string"),
    createdAt:  DS.attr("date")
});