import Base from './base';

export default Base.extend({
    // _save(user, vehicleID) {
    //     var _this = this;
    //     if (!user) { throw { error: 'User not exist' }; }

    //     return user
    //         .save()
    //         .then(function (/*data*/) {
    //             if (vehicleID) {
    //                 var vehicle = _this.store.peekRecord('vehicle', user.get("assignedVehicle"));
    //                 vehicle.set("user", user);
    //                 vehicle.save()
    //                     .then();
    //             }
    //             Ember.Logger.debug("User Added");
    //             _this.transitionToRoute(_this.get('parentRoute'));
    //         })
    //         .catch(function (err) {
    //             user.deleteRecord();
    //             Ember.Logger.debug("errorOnSave");
    //             Ember.Logger.debug(err);
    //         });
    // },
    // actions: {
    //     addUser() {
    //         var _this = this;
    //         var user = this.get('model');

    //         if ($('#aclGroups').val() != null ) {
    //             if ($('#aclGroups').val() === "" ) {
    //                 user.set("acl.groups", null);
    //             }
    //             else {
    //                 user.set("acl.groups", $('#aclGroups').val().split(","));
    //             }
    //         }
    //         if ($('#aclVehicles').val() != null) {
    //             if ($('#aclVehicles').val() === "" ) {
    //                 user.set("acl.vehicles", null);
    //             }
    //             else {
    //                 user.set("acl.vehicles", $('#aclVehicles').val().split(","));
    //             }
    //         }
    //         if (user.get("details.drivingLicense.categories")) {
    //             user.set("details.drivingLicense.categories", user.get("details.drivingLicense.categories").split(","));
    //         }

    //         if (user.get("assignedVehicle")) {
    //             _this._save(user, user.get("assignedVehicle"));
    //         }
    //         else {
    //             _this._save(user);
    //         }

    //     },
    // }
});