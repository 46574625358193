import Ember from 'ember';
const { service } = Ember.inject

export default Ember.Component.extend({
	resolution: service(),
	actions: {
		switchTableContent() {
			this.get("switchTableContent")();
		},
	}
});
