import ENV from 'tracknamic-smb/config/environment';

export function initialize(application) {
	application.register('env:main', ENV, { singleton: true, instantiate: false });
	application.inject('controller', 'env', 'env:main');
}

export default {
  name: 'init-environment',
  initialize
};
