export default {
	title: {
		alerts: 'Alerty',
		consumption: 'Zużcie paliwa',
		faz: 'Arkusz dziennych aktywności',
		'fuel-level': 'Poziom paliwa',
		notifications: 'Powiadomienia',
		route: 'Trasy',
		stationary: 'Postoje',
		poi: 'Aktywność POI',
		temperature: 'Temperatura',
		'temperature-range': 'TK Godziny',
		'poi-temperature': 'TK POI'
	},
	ro: 'Raporty',
	menu: {
		alerts: 'Alerty',
		faz: 'Arkusz dziennych akrywności',
		'fuel-level': 'Poziom paliwa',
		'detailed-stops': 'Szczegołowe Postoje ++',
		notifications: 'Powiadomienia',
		route: 'Trasy',
		stationary: 'Postoje',
		poi: 'Aktywność POI',
		temperature: 'Temperatura',
		'temperature-range': 'TK Godziny',
		'poi-temperature': 'TK POI'
	},
	downloads: 'Pobierz',
	settings: {
		specific: {
			route: {
				duration: 'Czas trwania',
				schedule: 'Trasy w harmonogramie',
				noschedule: 'Trasy 0-24'
			}
		},
		shipping: {
			schedule: 'Dostawy wg harmonogramu',
			noschedule: 'Dostawy 0-24'
		}
	},
	subscribe: {
		title: 'Subskrybuj',
		fields: {
			label: {
				email: 'Podaj adresy email oddzielone przecinkiem.',
				frequency: 'Wybierz czestotliwość powiadomień:',
				weekDays: 'Wybierz dzień raportowania:'
			},
			placeholder: {
				email: 'Adres email listy:',
				frequency: 'Wybierz czestotliwość:',
				report: 'Wybierz raport:',
				dayOfWeek: 'Wybierz dzień tygodnia:'
			}
		},
		everyDay: 'Raz na dzień',
		everyWeek: 'Raz na tydzień',
		everyMonth: 'Raz na miesiąc',
		notifyDate: 'Wybierz dzień raportowania:'
	},
	headerFields: {
		acc: 'Przyspieszenie',
		areaPerHour: 'Area/hour (ha)', // TODO: translate
		areaTotal: 'Area (ha)', // TODO translate
		areaTotalLoad50: 'Area with engine load > 50% (ha)', // TODO translate
		averageSpeed: 'Średnia Prędkość (km/h)',
		consumptionCompose: 'Zużycie Paliwa (l)',
		canDistance: 'Dystans (CAN)',
		canFuelConsumption: 'Zużycie Paliwa CAN (l)',
		canFuelConsumptionDistance: 'Zużycie Paliwa / 100 (CAN)',
		canFuelLevelEndOfDay: 'Poziom Paliwa CAN (l)',
		canOdometer: 'Przebieg Końcowy',
		consume: 'Zużycie Paliwa (l)',
		createdAt: 'Utworzone',
		date: 'Data',
		desacc: 'Zwalnianie',
		description: 'Opis',
		distance: 'Dystans (km)',
		distanceCompose: 'Dystans (km)',
		driver: 'Kierowca',
		duration: 'Czas Trwania',
		endLocation: 'Położenie Końcowe',
		endTime: 'Czas Zakończenia',
		freezerOnDuration: 'Czas pracy jednostki chłodzącej',
		fuelConsumption: 'Obliczone Zużycie Paliwa (l)',
		onlyFuelConsumption: 'Consumption (liters)', // TODO translate
		fuelConsumptionPerHa: 'Consumption/ha (liters)', // TODO translate
		fuelConsumptionDistance: 'Zużycie Paliwa / 100',
		fuelLevelDiff: 'Ilość (litry)',
		fuelLevelDiffManual: 'Podana ilość (litry)',
		fuelLevelDiffLiters: 'Ilość (litry)',
		fuelSensor: 'Bak',
		fuelEvent: 'Typ',
		generatedAt: 'Wygenerowane',
		invoiceNumber: 'Numer faktury',
		licensePlate: 'Numery Rejestracyjny',
		location: 'Adres',
		map: 'Mapa',
		maxSpeed: 'Predkość max(km/h)',
		persistent: 'Trwały',
		poiCount: 'Liczba POI',
		poiStationaryDoorsOpenedDuration: 'Postój w POI z otwartymi drzwiami',
		poiStationaryDuration: 'Czas postoju w POI',
		price: 'Cena',
		printDetails: 'Szczegóły',
		sensorFuelConsumption: 'Zużycie paliwa wg sondy (litry)',
		speed: 'Prędkość',
		startLocation: 'Położenie Początkowe',
		startTime: 'Czas Startu',
		stationaries: 'Przystanki z załączonym silnikiem',
		status: 'Status',
		temperature1: 'poniżej -4°C',
		temperature2: 'między -4°C and 0°C',
		temperature3: 'między 0°C and 4°C',
		temperature4: 'powyżej 4°C',
		updatedAt: 'Uaktualniono',
		virage: 'Zakręty',
		specific: {
			route: {
				fuelConsumption: 'Obliczone Zużycie Paliwa',
				purpose: 'Cel',
				selectPurpose: 'Wybierz'
			},
			station: {
				startTime: 'Czas Startu',
				endTime: 'Czas Zakończenia',
				duration: 'Czas Trwania'
			},
			stationary: {
				startTime: 'Czas Startu',
				endTime: 'Czas Zakończenia',
				duration: 'Czas Trwania'
			},
			poi: {
				geofences: 'POI'
			}
		}
	},
	fuelLevel: {
		eventType: {
			refuel: 'Tankowanie',
			consumption: 'Zużycie'
		},
		import: {
			title: 'Importuj tankowania',
			buttonText: 'IMPORTUJ TANKOWANIA',
			imported: '{{value}} tankowania zostały importowane',
			notImported: "{{value}} tankowań nie mogło zostać importowanych",
			allImported: 'Wszystkie tankowania zostały importowane ({{value}})',
		},
		uploadFile: "Prześlij plik"
	},
	table: {
		expand: "Rozwiń tabele",
		collapse: "Zwiń tabele"
	},
	temperature: {
		temperatureTable: "Tabela temperatury"
	}
}
