export default {
	'document-expiry': '{{type}} dla {{driver}} {{vehicle}} wygaśnie {{until}}',
	'document-expired': '{{type}} dla {{driver}} {{vehicle}} wygasło {{until}}',

	'poi-enter': '{{licensePlate}} wjechał do POI {{geofenceName}}',
	'poi-exit': '{{licensePlate}} opuścił POI {{geofenceName}}',
	'poi-stationary': '{{licensePlate}} zatrzymał się w POI {{geofenceName}}',

	'geofence-enter': '{{licensePlate}} wjechał do obszaru {{geofenceName}}',
	'geofence-exit': '{{licensePlate}} opuścił obszar {{geofenceName}}',
	'geofence-stationary': '{{licensePlate}} zatrzymał się w obszarze {{geofenceName}}',

	'door-open-outside-poi': 'Drzwi otwarte poza POI w {{licensePlate}}',
}
