import Ember from 'ember'
import config from '../../config/environment'

const { service } = Ember.inject

export default Ember.Controller.extend({
	needs: 			"reports",
	
	ReportsFields: 	service('reports/report-fields'),
	reportsInfo: 	service('reports/reports-info'),
	api: 			service(),
	i18n: 			service(),
	utilsVehicles: 	service('utils/vehicles'),

	excluded: ["driverID", "vehicleID"],
	reportsController: Ember.inject.controller('reports'),
	empty: true,
	loading: false,
	isFaz: false,

	columns: Ember.computed('model.tHeaders', function () {
		return this.get('model.tHeaders').map((column, idx) => {
			column.position = idx
			return column
		})
	}),

	records: null,

	doNotDoRequest: false,

	apiParams() {
		let apFields = this.get('ReportsFields.fields')
		let params = {}
		for (let i in apFields) {
			if (i !== 'drivers') {
				params[i] = apFields[i]
			}
		}

		return params
	},

	prepareFetchedReportData(rawData) {
		if (!rawData) { return [] }

		return rawData.toArray().map(function (item) {
			return Ember.Object.create(item)
		})
	},

	reloadModel(doNotDoRequest) {
		var _this = this,
			report = this.get('reportsInfo').getReportDataByTitle(this.get('reportsController.reportTitle'))

		if (!report) { return false }

		const api = this.get('api')

        /**
         * If driver changed do not do another request
         * we will just filter the data we have so far.
         */
		if (doNotDoRequest) {
			this.get('records').setObjects(this.get('records'))
			this.set('doNotDoRequest', true)
			return true
		} else {
			this.set('doNotDoRequest', false)
		}

        /**
         * If the user did not select any car do not do a request,
         * just return an empty array.
         */
		if (Ember.isEmpty(this.get('ReportsFields').fields.vehicles)) {
			this.get('records').setObjects([])
			return true
		}

		this.set('loading', true)

		api
			.post('reports/' + report.type, {
				contentType: "application/json; charset=utf-8",
				dataType: "json",
				data: JSON.stringify({ $filter: _this.apiParams() }),
			})
			.then(data => {
				if (Ember.isArray(data)) {
					var processed = _this.prepareFetchedReportData(data)
					_this.get('records').setObjects(processed.toArray())
				} else {
					this.get('records').setObjects(data)
				}
				_this.set('loading', false)

				_this.set('empty', (this.get('records').length === 0 ? true : false))

			})
			.catch(function (err) {
				Ember.Logger.error(err)
			})
	},

	getGroup(groupID, groups) {
		if (!groupID || !groups) { return null }

		return groups.find(function (item) {
			return (item.get('id').toString() === groupID.toString())
		})
	},

	getDriverName(driverID, drivers) {
		if (!driverID || !drivers) { return null }

		return drivers.find(function (item) {
			return item.get('id').toString() === driverID.toString()
		})
	},

	getVehicle(vehicleID, vehicles) {
		if (!vehicleID || !vehicles) { return null }

		return vehicles.find(function (item) {
			return item.get('id').toString() === vehicleID.toString()
		})
	},

	addGoToMapLink(item) {
		if (!item) { return null }

		let start, end

		if (this.get('isFaz')) {
			let d = new Date(item.get('date'))
			start = item.get('date')
			d.setHours(23, 59, 59, 999)
			end = d.getTime()
		} else {
			start = item.get('startTime')
			end = item.get('endTime')
		}

		return '<a href=' + config.rootURL + 'map/routes/' + item.get('vehicleID') + '?end=' + end + '&start=' + start + ' target="_blank"><i class="map outline icon"></i></a>'
	},

    /**
     * Add driver name and licensePlate to model.tData. We do this in order to avoid 'double rendering' in components.
     */
	completedRecords: Ember.computed('records.[]', function () {
		var _this = this
		var report = this.get('reportsInfo').getReportDataByTitle(this.get('reportTitle'))
        /**
         * This field will cause some reports to show an additional column
         * containing a link to map.
         */
		var goToMap = (this.get('model.tHeaders').findBy('key', 'goToMap') ? true : false)

		this.get('records').forEach(function (item) {
			var vehicle, driver

			if (report.graph) { return false }

			item.hasCan = false
			item.hasProbe = false

			if (item.get('vehicleID')) {
				vehicle = _this.get('getVehicle').call(_this, item.get('vehicleID'), _this.get('reportsController.model.vehicles'))
				// item = Ember.$.extend(item, { vehicleID: vehicle.get('licensePlate') })
				if (vehicle) {
					item.set('licensePlate', vehicle.get('licensePlate'))
				} else {
					item.set('licensePlate', '')
				}
			}

			if (item.get('driverID') !== undefined && item.get('driverID') !== null) {
				driver = _this.get('getDriverName').call(_this, item.get('driverID'), _this.get('reportsController.model.drivers'))
				// item = Ember.$.extend(item, { driverID: driver.firstName + ' ' + driver.lastName })
				if (driver) {
					item.set('driver', driver.get('firstName') + ' ' + driver.get('lastName'))
				} else {
					item.set('driver', '')
				}
			} else {
				item.set('driver', '')
			}

			/**
			 * Only for poi report
			 */
			if (item.get('geofences') && !_this.get('doNotDoRequest')) {
				let geofences = ''

				item.get('geofences').forEach(geofence => {
					let geofenceModel = _this.store.peekRecord('geofence', geofence)
					if (geofenceModel) {
						geofences += geofenceModel.get('name') + ', '
					}
				})

				item.set('geofences', geofences.substr(0, geofences.length - 2))
			}

			item.consumptionCompose = 0
			item.distanceCompose = 0

			if (report.get('checkForCanData')) {
				item.hasCan = vehicle ? vehicle.get('hasValidCan') : false
				item.hasProbe = vehicle ? vehicle.get('hasProbe') : false
				/**
				 * Exceptions
				 */
				if (item.hasProbe && item.get('sensorFuelConsumption')) {
					item.consumptionCompose = item.get('sensorFuelConsumption')
				} else if (item.hasCan && item.get('canFuelConsumption')) {
					item.consumptionCompose = item.get('canFuelConsumption')
				} else if (item.get('fuelConsumption') > 0) {
					item.consumptionCompose = item.get('fuelConsumption')
				}

				if (item.hasCan && item.get('canDistance')) {
					item.distanceCompose = item.get('canDistance')
				} else {
					item.distanceCompose = item.get('distance')
				}
			}

			if (goToMap) {
				item.set('goToMap', _this.addGoToMapLink(item))
			}
		})

		return this.get('records').filter(function (item) {

			if (item && typeof item.get('driverIDs') !== 'undefined') {
				let drivers = item.get('driverIDs')

				for (let i = 0; i < drivers.length; i++) {
					return (_this.get('ReportsFields.fields').drivers.indexOf(drivers[i]) > -1)
				}
			} else if (item && typeof item.get('driverID') !== 'undefined') {
				return (_this.get('ReportsFields.fields').drivers.indexOf(item.get('driverID')) > -1)
			}
			return true
		})
	}),

	actions: {
		getRoutesInTimeInterval() {
			return true
		}
	}
})
