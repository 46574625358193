import Ember from 'ember'

const { service } = Ember.inject
/**
 * https://emberobserver.com/addons/ember-toastr
 */

export default Ember.Component.extend({

	tagName: '',

	api: service(),
	i18n: service(),
	ReportRow: service('reports/report-row'),

	processedRow: {},

	processedChilds: [],
	showChilds: false,

	vehicleID: null,

	driverID: null,

	selected_purpose: null,

	didReceiveAttrs() {

		var
			columns = this.get('columns'),
			excluded = this.get('excluded'),
			ignoreFirstColumn = this.get('ignoreFirstColumn') || false,
			row = this.get('row')

		this.set('processedChilds', [])

		this.set('prepareRow', {})
		if (row.get('vehicleID')) {
			this.set('vehicleID', row.vehicleID)
		}

		if (row.get('driverID')) {
			this.set('driverID', row.driverID)
		}

		this.set('selected_purpose', row.get('purpose'))

		let vehicle = this.get('vehicle')
		this.set('processedRow', this.get('ReportRow').prepareRow(row, vehicle, columns, excluded, ignoreFirstColumn, this.get('isTotalRow')))

		// if (row.get("childs.length") > 1) {
		//     row.get("childs").forEach(function (child) {
		//         let processedChild = _this.get('ReportRow').prepareRow(child, columns, excluded, ignoreFirstColumn, _this.get('isTotalRow'))
		//         _this.get("processedChilds").pushObject(processedChild)
		//     })
		// }
	},


	actions: {
		removePurposeEditor() {
			this.toggleProperty('editPurpose')
		},

		savePurposeForRoute(value) {
			let api = this.get('api')
			let i18n = this.get('i18n')

			if (value === this.get('selected_purpose')) { return false }

			api
				.request('routes/' + this.get('row.routeID'), {
					dataType: 'json',
					method: 'PUT',
					data: {
						route: {
							details: {
								purpose: value
							}
						}
					},
				})
				.then(() => {
					if (this) {
						this.set('selected_purpose', value)
						this.toast.success(i18n.t('messages.operationWithSuccess'))
					}
				})
				.catch(err => {
					Ember.Logger.error(err)
					if (this) {
						this.toast.error(i18n.t('messages.operationWithError'))
					}
				})
		},


		toggleShowChilds(row, event) {
			let $el = Ember.$(event.target)
			/**
			 * This is because when clicking on dropdown tr click also triggers.
			 */
			let clickIsFromPurposesDropdown = $el.parents('.purpose-drowpdown').length
			let rowHasChilds = $el.parents('tr').hasClass('hasChilds')
			if (!clickIsFromPurposesDropdown && rowHasChilds) {
				row.toggleProperty('showChilds')
			}
		}
	}
})
