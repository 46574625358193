import Ember from 'ember';
import Base from './base';

export default Base.extend({
    sessionService: Ember.inject.service("session"),
    i18n: Ember.inject.service(),

    model() {
        return Ember.RSVP.hash({
            tHeaders: [
                { key: 'status', value: this.get('i18n').t('reports.headerFields.status'), align: 'left' },
                { key: 'persistent', value:  this.get('i18n').t('reports.headerFields.persistent'), align: 'left' },
                { key: 'description', value:  this.get('i18n').t('reports.headerFields.description'), align: 'left' },
                { key: 'createdAt', value: this.get('i18n').t('reports.headerFields.createdAt'), align: 'left' },
                { key: 'updatedAt', value: this.get('i18n').t('reports.headerFields.updatedAt'), align: 'left' },
            ],
            tData: []
        });
    }
});