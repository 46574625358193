import Ember from 'ember'

const { inject } = Ember
const { service } = Ember.inject
const { Controller } = Ember

export default Controller.extend({
	ReportsFields: service('reports/report-fields'),
	reportsInfo: service('reports/reports-info'),
	api: service(),

	excluded: ["driverID", "vehicleID"],
	reportsController: inject.controller('reports'),
	loading: false,
	isFaz: false,

	columns: Ember.computed('model.tHeaders', function () {
		return this.get('model.tHeaders').map((column, idx) => {
			column.position = idx
			return column
		})
	}),

	apiParams() {
		let apFields = this.get('ReportsFields.fields')
		let params = {}
		for (let i in apFields) {
			if (i !== 'drivers') {
				params[i] = apFields[i]
			}
		}

		return params
	},

	reloadModel() {
		this.fetch()
	}
})
