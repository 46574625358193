import Ember from 'ember'

const { service } = Ember.inject

export default Ember.Controller.extend({
	resolution: service(),

	actions: {
		delete(id) {
			this.store.findRecord('notification', id, { backgroundReload: false })
				.then(notification => {
					notification.deleteRecord();
					notification.save();
				})
				.catch((err) => {
					Ember.Logger.error(err)
				});
		}
	}
})
