import Ember from 'ember';

export default Ember.Component.extend({
    validHex: null,
    curString: null,

    completeHexString(str) {
        let hexString = str
        if (!str || str.length === 0) {
            return null
        }

        let str2
        if (str.length < 16) {
            str2 = new Array(17 - str.length).join("0");
            hexString = str.concat(str2)
        }
        return hexString
    },

    init() {
        this._super(...arguments);
        if (this.get("butID")) {
            this.set("validHex", this.get("butID"))
        }
    },

    isHex(str) {
        let str2
        if (str.length < 16) {
            str2 = new Array(17 - str.length).join("0");
        }
        let hexString = str.concat(str2)
        if (hexString === "0000000000000000" && str.length === 16) {
            return false
        }

        var pattern = /[0-9A-Fa-f]{16}/i;
        return (pattern.test(hexString))
    },
    actions: {
        keyPressed(value, /*evt*/) {
            if (value.length === 0) {
                this.set("curString", null)
                this.set("validHex", null)
                return false
            }

            let curString
            if (this.get("curString")) {
                curString = this.get("curString")
            }
            else {
                curString = ""
            }
            // let keyPressed = String.fromCharCode(evt.keyCode)
            if (this.isHex(value) && value.length <= 16 && value != "0000000000000000") {
                this.set("curString", value.toUpperCase())
                this.set("validHex", value.toUpperCase())
                this.set("butID", this.completeHexString(value.toUpperCase()))
            }
            else {
                this.set("validHex", curString)
            }
        }
    }
});