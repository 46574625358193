import Ember from 'ember';
import moment from 'moment';
import csvtojson from 'npm:csvtojson';

export default Ember.Component.extend({
    overlayLayer:   Ember.inject.service( 'overlay-layer' ),
    currentUser:    Ember.inject.service( 'current-user' ),
    store:          Ember.inject.service( ),
    i18n:           Ember.inject.service( ),

    timetables:     { },

    fileName:       null,

    timetablesForRemoval:   [ ],
    affectedVehicles:       [ ],
    showChanges:            false,
    
    init ( ) {
        this._super( ...arguments );
        
        this.set( "timezones", moment.tz.names() );
        this.set( "timezone", "Europe/Bucharest" );
    },

    actions: {
        selectFile ( event ) {
            var _this   = this;
            
            var file    = event.target.files[ 0 ];
            var reader  = new FileReader();
            var i18n    = this.get("i18n");

            var keys = {
                scheduleName:   i18n.t("management.timetables.import.name").string,
                licensePlate:   i18n.t("management.timetables.import.licensePlate").string,
                days: [
                    "monday",
                    "tuesday",
                    "wednesday",
                    "thursday",
                    "friday",
                    "saturday",
                    "sunday"
                ],
                hours: ["Start", "Stop"]
            };

            var timetables = [];
            

            if ( file ) {
                reader.onload = function ( event ) {
                    var currentTimetable = {};
                    _this.set( "fileName", file.name );
                    csvtojson( { checkType: false } ).fromString( event.srcElement.result ).on( 'json', function ( data ) {
                        Ember.Logger.log( data );
                        if ( data[ keys.scheduleName ].length ) {
                            if ( currentTimetable.name != null ) {
                                timetables.push( Object.assign( { }, currentTimetable ) );
                            }

                            currentTimetable = {
                                name:       data[ keys.scheduleName ],
                                timezone:   _this.get("timezone"),
                                default:    false,
                                schedule:   [],
                                vehicles:   [],
                                companyID:  _this.get( "currentUser.user.companyID" ),
                                groupID:    _this.get( "currentUser.user.groupID")
                            };

                            keys.days.forEach( function ( day ) {
                                var time = [];
                                keys.hours.forEach( function ( hour ) {
                                    var field = i18n.t("management.timetables.import." + day + hour ).string;
                                    if (data[ field ].length) {
                                        var value = data[ field ].split(":");
                                        time.push( parseInt( value[ 0 ] ) );
                                        time.push( parseInt( value[ 1 ] ) );
                                    } else {
                                        time.push( 0 );
                                        time.push( 0 );
                                    }
                                });

                                currentTimetable.schedule.push( time );
                            });

                            Ember.Logger.log( "Current timetable:", currentTimetable );
                        }

                        if ( data[ keys.licensePlate ].length ) {
                            var licensePlate = data[ keys.licensePlate ].toLowerCase().replace(/-|\s/g,"");
                            currentTimetable.vehicles.push( licensePlate );
                        }
                    }).on("done", function () {
                        // Add the last timetable because the json event only adds when a new timetable name is set, which will not happen for the last one
                        timetables.push( Object.assign( { }, currentTimetable ) );

                        _this.set("timetables", timetables);
                        Ember.Logger.log( "[ TIMETABLES ]", timetables );
                        
                        _this.sendAction( "setFile", timetables, function ( data ) {
                            if ( data.timetablesForRemoval.length ) {
                                _this.set( "timetablesForRemoval",  data.timetablesForRemoval );
                            } else {
                                _this.set( "timetablesForRemoval",  null );
                            }

                            if ( data.affectedVehicles.length ) {
                                _this.set( "affectedVehicles",  data.affectedVehicles );
                            } else {
                                _this.set( "affectedVehicles",  null );
                            }

                            _this.set( "showChanges",  data.affectedVehicles.length || data.timetablesForRemoval.length)
                           
                            Ember.Logger.log( "[ CALLBACK ] file data set for simulation", data );
                        });
                    });
                };

                reader.readAsText( file );
            } else {
                this.set( "fileName", null );
                this.set( "affectedVehicles",  null );
                this.set( "timetablesForRemoval",  null );
                this.set( "timetables", null );
                this.set( "showChanges", false );
            }
        },
        
        save() {
            // Ember.Logger.log("save from FORM");
            this.sendAction( 'save', this.get( "timetables" ) );
        },

        cancel() {
            // Ember.Logger.log("cancel from FORM");
            this.sendAction( 'cancel' );
        }
    }
});
