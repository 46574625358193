import DS from 'ember-data'
import Ember from 'ember'

const { service } = Ember.inject

export default DS.RESTSerializer.extend({

	vehiclesUtils: service('utils/vehicles'),

	normalizeArrayResponse(store, primaryModelClass, payload) {

		let groups = payload.group

		groups.forEach(group => {
			this._addDefaultSettings(group)
		})

		return this._super(...arguments)
	},

	normalizeSingleResponse(store, primaryModelClass, payload) {
		let group = payload.group
		if (group) {
			this._addDefaultSettings(group)
		}
		return this._super(...arguments)
	},

	_addDefaultSettings(group) {
		if (!group.settings || !group.settings.speedLimit) {
			group.settings = { speedLimit: {} }
		}

		this.get('vehiclesUtils').getTypes().forEach(type => {
			if (!group.settings.speedLimit[type.name]) {
				group.settings.speedLimit[type.name] = {}
			}

			group.settings.speedLimit[type.name].max = group.settings.speedLimit[type.name].max === undefined ? null : group.settings.speedLimit[type.name].max
			group.settings.speedLimit[type.name].min = group.settings.speedLimit[type.name].min === undefined ? null : group.settings.speedLimit[type.name].min
			group.settings.speedLimit[type.name].offset = group.settings.speedLimit[type.name].offset === undefined ? null : group.settings.speedLimit[type.name].offset
		})
	}
})
