import Ember from 'ember';

export default Ember.Component.extend({
    actions: {
        deleteDoc(doc) {
            Ember.$(".confirmationModal").modal('show')
            this.set("docToDelete", doc)
        },
        confirmDelete(response) {
            let doc = this.get("docToDelete")
            if (response && doc) {
                this.get("deleteDocument")(doc)
            }
        },
        edit() {
            this.sendAction('disableReadonly');
        },
        save() {
            this.sendAction('save');
        },
        createDocument() {
            this.sendAction('createDocument');
        },
        cancel() {
            this.get('cancel')();
        },
    }
});
