import Ember from 'ember';
export default Ember.Service.extend({
    aranged: [],
	maxLevel: 7,
	currentLevel: 0,
	toArange: [],
	setCurrentLevel: function () {
		var _this = this;
		this.toArange.forEach(function (item) {
			var localLevel = item.id.split('-').length;
			_this.currentLevel = localLevel > _this.currentLevel ? localLevel : _this.currentLevel;
		});
	},
	checkCurrentLevel: function () {
		return this.currentLevel <= this.maxLevel;
	},
	getChilds: function (parent) {
		var _this = this,
			childs = [];
		
		this.toArange.forEach(function (n) {
			var idSplited = n.id.split('-');
			idSplited.pop();
			var parentId = idSplited.join('-');
			if (parentId === parent.id) {
				n.childs = _this.getChilds(n);
				childs.push(n);
			}
		});
		return childs;
	},
	arange: function (toArange) {
		var _this = this;

		if (!toArange) { return false; }

		this.toArange = toArange;
		this.aranged = [];
		this.setCurrentLevel();
		if (!this.checkCurrentLevel()) { return false; }
		this.toArange.forEach(function (item) {
			if (item.id.split('-').length === 1) {
				item.childs = _this.getChilds(item);
				_this.aranged.push(item);
			}
		});
		return this;
	}
});