export default {
	"poi-stationary":"{{licensePlate}} Stop auf Farm {{geofenceName}}",
	"geofence-stationary":"{{licensePlate}} Stop auf Feld {{geofenceName}}",
	"rpm-limit":"Motorüberlastung für {{licensePlate}}",
	"fuel-consumption-limit":"Kraftstoffstandabfall für {{licensePlate}}",
	"document-expiry":"{{type}} für{{driver}} {{vehicle}} wird auslaufen {{until}}",
	"document-expired":"{{type}} für {{driver}} {{vehicle}} ausgelaufen am {{until}}",
	"poi-enter":"{{licensePlate}} hat die Farm betreten {{geofenceName}}",
	"poi-exit":"{{licensePlate}} hat die Farm verlassen {{geofenceName}}",
	"geofence-enter":"{{licensePlate}} hat das Feld betreten {{geofenceName}}",
	"geofence-exit":"{{licensePlate}} hat das Feld verlassen {{geofenceName}}",
	"door-open-outside-poi":"Tür außerhalb des Feldes offen für {{licensePlate}}"
}
