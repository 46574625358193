import Ember from 'ember';
import moment from 'moment';

export default Ember.Component.extend({

    overlayLayer: Ember.inject.service('overlay-layer'),
    currentUser: Ember.inject.service('current-user'),
    store: Ember.inject.service(),
    timezones: null,

    // setDefaultCompanyTimetable: false,
    firstTimeTable: false,  //disable checkBox
    isDefault: false,       //initial value of checkBox

    init() {
        this._super(...arguments);
        var companyID = this.get('currentUser.user.companyID');
        var timetableID = this.get('store').peekRecord('group', companyID).get("timetableID");
        var curTimetableID = this.get('timetable.id');
        this.set("timezones", moment.tz.names());
        //first Company TimeTable
        if (!timetableID) {
            this.set('firstTimeTable', true);
            this.set("isDefault", true);
            this.set("timetable.default", true);
        }
        // default company timetable
        else if (timetableID === curTimetableID) {
            this.set("isDefault", true);
            // this.set('firstTimeTable', true);
        }
    },

    actions: {
        edit() {
            this.sendAction('disableReadonly');
        },
        dayStatus(id, value) {
            var start = moment().set({ 'hour': 0, 'minute': 0, 'second': 0 }).toDate();
            var end = moment().set({ 'hour': 23, 'minute': 59, 'second': 0 }).toDate();
            var weekDay = this.get("timetable.schedule").objectAt(id);

            var stDp = Ember.$("#" + id + "st").datepicker().data('datepicker');
            var etDp = Ember.$("#" + id + "et").datepicker().data('datepicker');

            if (value === "custom") {
                weekDay.setProperties({ 0: 0, 1: 0, 2: 23, 3: 59 });
                stDp.update({ maxDate: end })
                stDp.selectDate(start);
                etDp.update({ minDate: start })
                etDp.selectDate(end);
                Ember.$("." + id + "dayFields").removeClass("disabled");
            }
            else if (value === "fullday") {
                weekDay.setProperties({ 0: 0, 1: 0, 2: 24, 3: 0 });
                stDp.update({ maxDate: end })
                stDp.selectDate(start);
                etDp.update({ minDate: start })
                etDp.selectDate(end);
                Ember.$("." + id + "dayFields").addClass("disabled");
            }
            else {
                weekDay.setProperties({ 0: 0, 1: 0, 2: 0, 3: 0 });
                stDp.update({ maxDate: start })
                stDp.selectDate(start);
                etDp.update({ minDate: start })
                etDp.selectDate(start);
                Ember.$("." + id + "dayFields").addClass("disabled");
            }

        },
        setDefault(checked) {
            var _this = this;
            var companyID = this.get('currentUser.user.companyID');
            var companyTimetableID = this.get('store').peekRecord('group', companyID).get("timetableID");
            if (checked) {
                if (companyTimetableID && companyTimetableID != _this.get("timetable.id")) {
                    var oldTimetableName = this.get('store').peekRecord('timetable', companyTimetableID).get("name");
                    Ember.$(".lblOldCompanyTimetable").html(oldTimetableName);
                    Ember.$('.confirmCompanyDefaultTimetable')
                        .modal({
                            allowMultiple: true,
                            onApprove: function () {
                                _this.set("timetable.default", true);
                            },
                            onDeny: function () {
                                _this.set("timetable.default", false);
                                Ember.$('.defaultCompanyTimetable').checkbox('uncheck');
                            }
                        })
                        .modal('show');
                }
                else if (companyTimetableID && companyTimetableID === _this.get("timetable.id")) {
                    _this.set("timetable.default", false);
                }
            }
            else {
                if (companyTimetableID && companyTimetableID === _this.get("timetable.id")) {
                    //modal to ask if want to delete this timetable from company group

                    // set company to null

                    Ember.$('.deleteCompanyDefaultTimetable')
                        .modal({
                            allowMultiple: true,
                            onApprove: function () {
                                _this.set("timetable.default", true);
                            },
                            onDeny: function () {
                                _this.set("timetable.default", false);
                                Ember.$('.defaultCompanyTimetable').checkbox('check');
                            }
                        })
                        .modal('show');
                }
                else {
                    _this.set("timetable.default", false);
                }
            }
        },
        save() {
            this.sendAction('save');
        },
        cancel() {
            this.sendAction('cancel');
        }

    }
});
