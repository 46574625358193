import Ember from 'ember';
/* global Muuri */

export default Ember.Component.extend({
    dashboard: Ember.inject.service('dashboard'),

    defaultSelectTxt: "Toate",
    /*itemTitles: ["Toate", "Harta", "Rapoarte" , "Management"],*/
    dragCounter: 0,

    updateIndices: function () {
        this.get('mGrid').get().forEach(function (item, i) {
            Ember.$(item._element).attr('data-id', i + 1).find('.card-id').text(i + 1);
        });
    },

    changeLayout: function () {
        var mGrid = this.get('mGrid');
        var layoutVal = this.$('.layout-field > input[type=hidden]').val();

        mGrid._settings.layout = ['firstFit', {
            horizontal: false,
            alignRight: layoutVal.indexOf('right') > -1,
            alignBottom: layoutVal.indexOf('bottom') > -1,
            fillGaps: layoutVal.indexOf('fillgaps') > -1
        }];
        mGrid.layout();
    },

    didInsertElement() {
        Ember.run.scheduleOnce('afterRender', this, this.afterRenderEvent);
    },

    afterRenderEvent: function () {
        var mGrid = new Muuri('#dashboardGrid',
            {
                items: [].slice.call(document.getElementsByClassName('mItem')),
                positionDuration: 5,
                positionEasing: [500, 20],
                layout: 'firstFit',
                dragEnabled: false,
                dragContainer: document.getElementById('dashboardContentRow'),
                dragReleaseDuration: 125,
                dragReleaseEasing: [500, 20], //'ease-in'
                dragPredicate: function (e, item, resolve) {
                    var isRemoveAction = Ember.$(e.target).closest('.card-remove').length;
                    if (!isRemoveAction && !this.isResolved) {
                        this.isResolved = true;
                        resolve(e);
                    }
                },
                layoutOnResize: 500
            });

        this.set('mGrid', mGrid);
    },

    init() {
        this._super(...arguments);
        if (this.get('dashboard.fullHeatMap')) {
            var mGrid = this.get('mGrid');

            if (mGrid !== undefined) {
                mGrid.refreshItems(4);
                mGrid.layout();
                this.get('dashboard').partialHeatMap();
            }
        }
    },

    didReceiveAttrs() {
        if (this.get('dashboard.fullHeatMap')) {
            var mGrid = this.get('mGrid');

            if (mGrid !== undefined) {
                mGrid.refreshItems(4);
                mGrid.layout();
                this.get('dashboard').partialHeatMap();
            }
        }
    },

    actions: {
        dropdowned(element) {
            Ember.$(this).siblings('input:hidden:first').val(element);
        }
    }

});
