import Ember from 'ember';
const { service } = Ember.inject;

export default Ember.Service.extend({
    session: service("session"),
    locale: 'ro',
    map: 'google',
    mapType: 'roadmap',
    mapSettings: Ember.computed("session.session.map", "session.session.googleMapLayerType", function () {
        var settings =  {
            mapClient: this.get("session.session.map"),
            mapType: this.get("session.session.googleMapLayerType"),
        };

        if ( ! settings.mapClient ) {
            settings.mapClient = "google";
        }

        if ( ! settings.mapType ) {
            settings.mapType = "roadmap";
        }

        return settings;
    })
});
