import Ember from 'ember';

const { inject: { service } } = Ember;

export default Ember.Component.extend({

    overlayLayer: service('overlay-layer'),
    userValidationSchema: service('user-validation-schema'),
    formValidate: service('form-validate'),

    moreDetails: false,
    preselected: true,
    requestSent: false,

    didReceiveAttrs() {
        if (this.get('requestCompleted')) {
            this.set('requestSent', false)
        }

		if (this.get("listOfErrors").length > 0) {
			this.get("listOfErrors").forEach(error => {
				this.get('toast').error(error.message, '', { timeOut: 0, extendedTimeOut: 0 })
			})
		}
	},

    sortedGroups: Ember.computed('groups', function () {
        return this.get('groups').sortBy('name');
    }),

    checkVehicleDriver(vehicleID, userID) {
        var
            vehicles = this.get('vehicles');

        if (!vehicleID) { return null; }

        if (userID) {
            //exclude current user vehicle
            vehicles = vehicles.filter(function (item) {
                return item.get('userID') !== userID;
            });
        }

        return vehicles.findBy('id', vehicleID);
    },

    init() {
        this._super(...arguments);
        Ember.$('.ui.dimmer.modals').remove();

        var _this = this,
            user = this.get('user');
        if (!user) {
            return false;
        }
        if (_this.get("user").get('isNew')) {
            _this.set("preselected", false);
        } else {
            //set user vehicle
            user.set("vehicle", _this.get("vehicles").findBy("user.id", user.get("id")));
        }

        if (this.get('documentId')) {
            this.set('moreDetails', true);
        }
    },
    didInsertElement() {
        Ember.$('form.formUser')
            .form({
                on: 'blur',
                fields: this.get('userValidationSchema').schema.fields,
                onInvalid() {
                    let ele = this
                    // ele.focus();
                    // ele.click();
                    Ember.$('#formContent').animate({
                        scrollTop: ele.offset().top - (Ember.$(window).height() - ele.outerHeight(true)) / 2
                    }, 200);
                    return false;
                }
            });
    },

    // didInsertElement() {
    //     Ember.run.scheduleOnce('afterRender', this, this.afterRenderEvent);
    // },
    // afterRenderEvent: function () {
    //     Ember.$('form.formUser').form(this.get('userValidationSchema').schema);
    // },

    actions: {
        deleteDoc(doc) {
            Ember.$(".confirmationModal").modal('show')
            this.set("docToDelete", doc)
        },
        confirmDelete(response) {
            let doc = this.get("docToDelete")
            if (response && doc) {
                this.get("deleteDocument")(doc)
            }
        },
        edit() {
            this.sendAction('disableReadonly');
        },
        removeDrivingLicense(item) {
            let _this = this;
            let categories = this.get('user.details.drivingLicense.categories');
            if (!categories) { return false; }

            categories.removeObject(item);

            if (categories.length === 0) {
                _this.set('user.details.drivingLicense.categories', null);
            }
        },

        addDrivingLicense(item) {
            let _this = this;
            let categories = this.get('user.details.drivingLicense.categories');

            if (categories) {
                if (categories.indexOf(item) === -1) {
                    categories.pushObject(item);
                }
                else {// if element exist on array do nothing
                    return false
                }
            }
            else {
                let categoriesToPush = [item];
                _this.set('user.details.drivingLicense.categories', categoriesToPush);
            }
        },
        save() {
            if (!Ember.$('form.formUser').form("validate form")) {
                Ember.Logger.debug("Validare");
                Ember.Logger.debug("FormERROR");
                return false;
            }
            this.set('requestSent', true)

            this.get('save')(Ember.$('#aclGroups').val(), Ember.$('#aclVehicles').val());
        },
        createDocument() {
            this.sendAction('createDocument');
        },
        cancel() {
            this.get('cancel')();
        },
        loadAvatar: function () {
            var fileLoader = Ember.$(event.target).closest("div.field").find("input.invisibleLoadPhoto");
            fileLoader.trigger("click");
        },
        loadedAvatar(element) {
            var fileName = Ember.$(element).val();
            var profilePic = Ember.$(element).parent().find("input[name=profilePic]");

            Ember.Logger.debug(element.files);

            Ember.$(profilePic).val(fileName);
        },
        selectGroup(groupID) {
            var user = this.get('user');
            user.set("group", this.get("groups").findBy("id", groupID));
        },
        selectVehicle(vehicleID) {
            var _this = this;
            var user = this.get('user');
            if (!vehicleID) {
                user.set("vehicle", null);
                return true;
            }

            var vehicle = null;
            if (user.get('isNew')) {
                vehicle = _this.checkVehicleDriver(vehicleID, null);
            }
            else {
                vehicle = _this.checkVehicleDriver(vehicleID, user.get('id'));
            }

            var overlayerAttached = null;
            if (_this.get("isModal")) {
                overlayerAttached = ".vehicleModal";
            }
            if (vehicle.get("user.id")) {
                this.get('overlayLayer').add(Ember.$(overlayerAttached));
                Ember.$(".lblVehiclePlate").html(vehicle.get("licensePlate"));
                Ember.$(".lblAssignedDriverName").html(vehicle.get("user.fullName"));
                Ember.$(".lblDriverName").html(user.get("fullName"));

                Ember.$('.confirmDriverModal')
                    .modal({
                        allowMultiple: true,
                        onApprove: function () {
                            user.set("vehicle", _this.get("vehicles").findBy("id", vehicleID));
                            _this.get('overlayLayer').remove(Ember.$(overlayerAttached));
                        },
                        onDeny: function () {
                            Ember.$("#ddVehicleID").dropdown("restore defaults");
                            _this.get('overlayLayer').remove(Ember.$(overlayerAttached));
                        }
                    })
                    .modal('show');
            }
            else {
                user.set("vehicle", _this.get("vehicles").findBy("id", vehicleID));
            }
        },
        addMoreDetails() {
            this.toggleProperty("moreDetails");
        }
    }
});
