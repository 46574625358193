import JSONSerializer from 'ember-data/serializers/json'

export default JSONSerializer.extend({
	serialize () {
		let json = this._super(...arguments)

		if (json.location != null) {
			json.location.reverse()
		}

		return json
	}
})