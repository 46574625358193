import Ember from 'ember';

export default Ember.Component.extend({
    /**
     *  -> PROPERTIES
     * {number}  recordsCount: number of records to paginate
     * {boolean} showAll: removePagination buttons andShow all records
     * {number}  pageIndex: selected page
     * {number}  resultsPerPage: number of results per page
     * 
     *  -> ACTIONS
     * updateResultsPerPage
     * toggleShowAll
     * updatePage
     */
    allowedResultsPerPage: [5, 10, 25, 50],

    lastPage: Ember.computed("recordsCount", "resultsPerPage", function () {
        return Math.ceil(this.get("recordsCount") / this.get("resultsPerPage"));
    }),
    prevAvailable: Ember.computed("pageIndex", function () {
        return this.get("pageIndex") > 1
    }),
    nextAvailable: Ember.computed("pageIndex", "lastPage", function () {
        return this.get("pageIndex") < this.get("lastPage")
    }),

    actions: {
        selectOnFocus(event) {
            event.target.select()
        },
        setResultsPerPage(results) {
            if (!results) { return false }
            this.get("updateResultsPerPage")(results)
        },
        showAll() {
            this.get("toggleShowAll")()
        },
        turnPage(toPage) {
            let pageNumber = 0
            let curPage = this.get("pageIndex")
            if (isNaN(toPage)) {
                if (toPage === "prev") {
                    pageNumber = curPage - 1
                } else if (toPage === "next") {
                    pageNumber = curPage + 1
                }
            } else {
                if (toPage >= 1 && toPage <= this.get("lastPage")) {
                    pageNumber = toPage
                }
                else {
                    pageNumber = 1
                }
            }
            this.get("updatePage")(pageNumber)
        },
    }
});
