import Ember from 'ember'

const { service } = Ember.inject

export default Ember.Component.extend({
    currentUser: service('current-user'),
    classNames: ['alertListContainer'],
    classNameBindings: ["showAll:showAll"],

    limit: 4,
    enableShowAll: false,

    filteredAlerts: Ember.computed.filter('alerts', function (alert) {
        return  alert.get('isPoi') || alert.get('isGeofence') || alert.get('isDoorOpen') ||
                alert.get('isFuelConsumption') || alert.get('isRpmLimit')
    }),

    showAll: Ember.computed("filteredAlerts.@each", "enableShowAll", function () {
        let enableShowAll = this.get("enableShowAll")
        let length = this.get("filteredAlerts").length
        return (length > this.get("limit") && !enableShowAll) ? false : true
    }),

    hasData: Ember.computed("filteredAlerts.@each", function () {
        let length = this.get("filteredAlerts").length
        return (length > 0) ? true : false
    }),

    didInsertElement() {
        var height = parseInt(this.$().parent().height() * 0.88, 10)

        this.$().height(height)
    },

    actions: {
        check(alert) {
            if (!alert.get('checked')) {
                alert.set('checked', true)
            }

            let _this = this
            if (alert.id) {
                this.get('delete')(alert.id)
            }
            Ember.run.later(this, () => {
                _this.get("filteredAlerts").removeObject(alert)
            }, 3000);
        },

        changeHoverProperty(alert) {
            alert.toggleProperty('isHover')
        },

        showAll() {
            this.set("enableShowAll", true)
        }
    }
})
