import Ember from 'ember';

export default Ember.Service.extend({
	/**
	 * Array of objects e.g. { report: 'FAZ', identifier: 'BH 10 TST' }
	 * In the bellow case, table for vehicle BH 10 TST on report faz will show heatmap instead the actual table.
	 */
	states: [],

	add(report, identifier, whatToShow) {
		if (this.exists(report, identifier)) { return false }
		this.get('states').pushObject({ report, identifier, whatToShow })
	},

	clear() {
		this.get('states').setObjects([])
	},

	exists(report, identifier) {
		return this.get('states').find(item => {
			return item.report === report && item.identifier === identifier
		})
	},

	remove(report, identifier) {
		let filtered = this.get('states').filter(item => {
			item.report !== report && item.identifier !== identifier
		})

		this.get('states').setObjects(filtered)
		return this.get('states')
	},

	showSecondaryLayout(report, identifier) {
		return this.exists(report, identifier)
	}

});
