import Ember from 'ember';

export default Ember.Component.extend({
    didInsertElement() {
        Ember.run.scheduleOnce('afterRender', this, this.afterRenderEvent);
    },

    afterRenderEvent: function () {
        var height = parseInt(this.$().parent().height() * 0.88, 10);
        this.$().height(height);
    }
});
