import Ember from 'ember';

const { service } = Ember.inject

export default Ember.Controller.extend({
    parentRoute: 'management.implements',
    readonly: true,
    requestCompleted: false,
    errors: service(),
    i18n: service(),

    listOfErrors: Ember.computed('errors.list.@each', function () {
        return this.get("errors.list")
    }),

    onError(err, code) {
		err.code = code;
		this.get('errors').add(err);
	},

    goToIndex() {
        this.set('readonly', true)
        this.set("requestCompleted", false);
        this.set("relayRequestCompleted", false);
        this.transitionToRoute(this.get('parentRoute'))
    },

    rollBackAndTransition() {
        let implement = this.get('model');
        implement.rollbackAttributes();

        this.goToIndex()
    },

    actions: {
        disableReadonly() {
            this.set("readonly", false);
        },

        save() {
            this.set('requestCompleted', false)
            this.get('errors').clear();

            let implement = this.get('model');

            if (!implement) { throw { error: 'Implement not exist' }; }

            implement.save()
                .then(() => {
                    this.set('requestCompleted', true)
                    this.toast.clear()
                    this.toast.success(this.get('i18n').t('messages.operationWithSuccess'))
                    this.goToIndex()
                })
                .catch((err) => {
                    this.set('requestCompleted', true)
                    this.onError(err, "4");
                })
        },
        cancel() {
            this.rollBackAndTransition();
        }
    }
});