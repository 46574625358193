import DS from 'ember-data'
import MF from 'ember-data-model-fragments'
import Ember from 'ember'
import moment from 'moment'

const sevenMinutes = 420000

export default DS.Model.extend({
	i18n: 			Ember.inject.service(),
    groupID:        DS.attr('string'),
    userID:         DS.attr('string'),
    timetableID:    DS.attr('string'),
    implementID:    DS.attr('string'),
    deviceID:       DS.attr('string'),
    make:           DS.attr('string'),
    model:          DS.attr('string'),
    vin:            DS.attr('string'),
    year:           DS.attr('number'),
    color:          DS.attr('string'),
    seats:          DS.attr('number'),
    type:           DS.attr('string'),
    licensePlate:   DS.attr('string'),
    registrationDate: DS.attr('date'),
	tireSize:       DS.attr('string'),
	hasRelay:		DS.attr('boolean', { defaultValue: false }),
    // The following can be set to fragments if needed.
	fuel:           MF.fragment('fuelFragment'),
	engine:         DS.attr(),
	conversions:    DS.attr(),
	status: 		MF.fragment('fragments/vehicle/status'),
	sensors: 		MF.fragmentArray('fragments/vehicle/sensor'),
	settings: 		MF.fragment('fragments/vehicle/settings'),

	geolocation:	MF.fragment('fragments/vehicle/geolocation'),
	speedLimit:		MF.fragment('fragments/vehicle/speedLimit'),

    group:          DS.belongsTo('group', {allowNull: true, inverse: 'vehicles'}),
    user:           DS.belongsTo('user', {allowNull: true, inverse: 'vehicles'}),
	device:         DS.belongsTo('device', {allowNull: true}),
	
	companyID: Ember.computed('groupID', function(){
		let group = this.get("groupID")
		let isSubgroup = group.indexOf('-') != -1
		if (isSubgroup) {
			return group.substring(0, group.indexOf('-'))
		} else {
			return group
		}
	}),

	hasDoorSensor: Ember.computed('sensors.@each.type', function () {
		if (!this.get("sensors")) { return false }

		let hasSensor = this.get("sensors").any(function (item) {
			return item.get("type").indexOf("door-") >= 0
		})
		return hasSensor
	}),

	hasFreezerSensor: Ember.computed('sensors.@each.type', function () {
		if (!this.get("sensors")) { return false }

		let hasSensor = this.get("sensors").any(function (item) {
			return item.get("type").indexOf("freezer") >= 0
		})
		return hasSensor
	}),

	doorHoverText: Ember.computed('hasDoorSensor', "status.doors.@each", function () {
		if (!this.get("hasDoorSensor")) { return false }
		let _this = this
		let text = ""

		if (this.get("status.doors") && this.get("status.doors.length") >= 1) {
			this.get("status.doors").forEach(function(item){
				text = text.concat(_this.get("i18n").t("openDoor."+ item ) + "\n")
			})

			return text
		}
		else {
			return this.get("i18n").t("openDoor.allClosed")
		}
	}),

	carName: Ember.computed('make', 'model', function () {
		var model = (this.get('model') ? ' ' + this.get('model') : '');
		return this.get('make') + model;
	}),
	currentLocation:Ember.computed('status.location.@each', function () {
		let geofencesCount = this.get('geolocation.geofencesCount')
		let popupText = this.get('geolocation.popupText')
		if (!geofencesCount && popupText) {
			return popupText
		}

		if (geofencesCount > 1) {
			return 'POI multiple (' + geofencesCount + ')'
		}

		if (geofencesCount === 1) {
			return this.get('geolocation.popupText')
		}

		return this.get('status.location.lastObject') + ', ' + this.get('status.location.firstObject')
	}),

	isMoving: 		Ember.computed.or('status.speed', 'status.can.speed'),

	hasCan:			Ember.computed('status.can.fuel.used', function () {
		return this.get('status.can.fuel.used') !== null
	}),

	hasValidCan:	Ember.computed('hasCan', function () {
		let hasCan = this.get('hasCan')
		if (!hasCan) { return false }
		let hasCapacitySet = !Ember.isNone(this.get('fuel.capacity'))
		let hasCanInPercent = !Ember.isNone(this.get('status.percent'))
		if  (hasCanInPercent && !hasCapacitySet) { return false }
		return true
	}),

	hasProbe:		Ember.computed('status.fuelLevel', function () {
		return this.get('status.fuelLevel') !== null
	}),

	diff: Ember.computed('status.updatedAt', function () {
		return moment(moment()).diff(this.get('status.updatedAt'))
	}),

	statusIndicator: Ember.computed('diff', function () {
		let diff = this.get('diff')
		let speed = this.get('status.speed')
		let updated = this.get('status.updatedAt')

		if (diff < sevenMinutes && updated != null) {
			return (parseInt(speed) > 0) ? 'engineOn' : 'engineOff'
		} else {
			return 'unreachable'
		}
	}),

	statusHumanized: Ember.computed('statusIndicator', function () {
		let diff = this.get('diff')
		if (this.get('statusIndicator') === 'unreachable' && diff > 0) {
			return  moment.duration(diff).humanize()
		} else {
			return null
		}
	})
})
