import Ember from 'ember'

const { service } = Ember.inject

export default Ember.Component.extend({
	i18n: service(),

	selected: null,
	monthDays: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
})
