import Ember from 'ember';

export default Ember.Component.extend({
    init() {
        this._super(...arguments);
        Ember.$('.ui.dimmer.modals').remove();
    },

    didInsertElement() {
        this.get("group").rollbackAttributes();
        var group = this.get("group");
        if (group.get("timetableID")) {
            Ember.$("#ddTimetable").dropdown("set selected", group.get("timetableID"));
        }
        else {
            Ember.$("#ddTimetable").dropdown("clear");
        }
    }
});
