export default {
    at: 'Przy',
    from: 'Od',
    to: 'Do',
    duration: 'Czas trwania',

    start: 'Start',
    stop: 'Stop',
    stationary: 'Postój',

    //charts-container
    speed: "Prędkość",
    altitude: "Wysokość",
    fuelCanLevel: "Poziom Paliwa CAN ",
    fuelLevel: "Poziom Czujnika Paliwa",
    acceleration: "Przyspieszenie",
    rpm: "RPM",

    //map index popup
    lastUpdate: "Ostania aktualizacja",
    engineState: "Stan silnika",
    engineLoad: "Engine load", // TODO translate
    engineTemperature: "Engine temperature", // TODO translate
    gpsPosition: "Pozycja GPS",
    gpsSpeed: "Prędkość GPS",
    canSpeed: "Prędkość CAN",
    canAcceleration: "Przyspieszenie CAN",
    canRPM: "RPM CAN",
    canFuelAvailable: "Poziom paliwa (CAN)",
    canFuelUsed: "Fuel used (CAN)",
    probeFuelAvailable: "Poziom paliwa (sonda)",
    distance: "Dystans",
    doors: "Drzwi",
    agregation: "Agregat",
    temperature: "Temperatura",
}
