import Alerts from './alerts'
import Errors from './errors'
import Management from './management'
import Messages from './messages'
import Reports from './reports'
import Markers from './markers'

export default {
	pagination: {
		first: 'Pierwsza strona',
		last: 'Ostatnia strona',
		next: 'Następna strona',
		previous: 'Poprzednia strona',
		of: 'z',
		resultsPerPage: 'Wyników na stronę',
		goToPage: 'Idź do strony',
		pageOfTotal: 'Strona {{page}} z {{total}}',
	},
	pleaseWait: 'Proszę czekać',
	processingFileData: 'Przetwarzanie pliku',
	expand: 'Rozwiń',
	menu: {
		dispatch: {
			index: 'Dyspozycja',
			routes: {
				add: 'Dodaj trasę'
			}
		}
	},
	loading: {
		text: 'Ładowanie'
	},
	acl: 'Kontrola dostępu',
	alerts: {
		messages: Alerts
	},
	openDoorsStatus: "Status drzwi",
	doorsStatus: {
		open: "Otwarte drzwi",
		closed: "Zamknięte drzwi",
	},
	closed: "Zamknięte",
	open: "Otwarte",
	openDoor: {
		'front-left': "Przednie prawe drzwi otwarte",
		'front-right': "Przednie lewe drzwi otwarte",
		'back-left': "Tylne prawe drzwi otwarte",
		'back-right': "Tylne lewe  drzwi otwarte",
		'trunk': "Otwarty bagażnik",
		'allClosed': "Wszystie drzwi zamknięte.",
		'na': 'Brak czujnika',
		generic: 'Otwarte drzwi'
	},
	freezerStatus: "Status jednostki chłodzącej",
	freezer: {
		on: "Jednostka chłodząca włączona",
		off: "Jednostka chłodząca wyłączona",
		na: "Brak jednostki chłodzącej"
	},
	vehicle: {
		engineOn: 'Silnik włączony',
		engineOff: 'Silnik wyłączony',
		unreachable: 'Brak sygnału',
	},
	vehicleStatus: {
		engineOn: 'W ruchu',
		engineOff: 'Postój',
		unreachable: 'Brak sygnału',
		unreachableSince: 'Brak sygnału od {{status}}',
		noLocation: 'Brak przypisanych urządzeń',
		generic: 'Status pojazdu',
	},
	licensePlate: 'Tablice Rejestracyjne',
	markers: Markers,
	previous: 'Poprzedni',
	next: 'Następny',
	noResults: 'Brak wyników',
	fromDate: 'Od',
	until: 'Do',
	stops: 'Zatrzymanie',
	temperatureIntervals: "Wszystkie",
	routeReport: 'Trasy',
	anotherValue: 'Inna wartość',
	liters: 'Litrów',
	consume: 'Zużycie',
	consumption: 'Zużycie',
	speedPerHour: 'Prędkość (km/h)',
	speed: 'Prędkość',
	altitude: 'Wysokość',
	altitudeMeters: 'Wysokość (m)',
	fuelLevelLitres: 'Poziom Paliwa (l)',
	engineLoad: "Engine Load (%)", // TODO translate
	refuel: 'Tankowanie',
	averageSpeed: 'Średnia Prędkość',
	maxSpeed: 'Maksymalna Prędkość',
	distance: 'Dystans',
	fuelLevelForPeriod: 'Poziom paliwa dla przedziału',
	canFuelLevel: 'Poziom Paliwa CAN',
	fuelLevel: 'Poziom Paliwa',
	fuelCanLevel: 'Poziom Paliwa CAN',
	fuelConsumption: 'Zużycie paliwa',
	details: 'Szczegóły',
	noDetails: 'Brak szczegółów',
	event_enter: 'Zdarzenie wjazdu',
	event_exit: 'Zdarzenie wyjazdu',
	event_stationary: 'Zdarzenie postoju',
	alert_in: 'Alert wjazdu',
	alert_out: 'Alert wyjazdu',
	alert_transit: 'Alert Tranzytu',
	back: 'Powrót',
	minValue: 'Minimalna wartość',
	maxValue: 'Maksymalna wartość',
	averageValue: 'Średnia wartość',
	settings: {
		markers: 'Ustawienia marker',
		map: 'Ustawienia mapy',
		_: 'Ustawienia'
	},
	statusFeed: 'Stan floty',
	vehicles: 'Pojazdy',
	vehicless: 'pojazd(y)',
	geofences: 'Obszary',
	pois: 'POI',
	users: 'Użytkownicy',
	default: 'Domyślne',
	divisions: 'Sekcja',
	duration: 'Czas trwania',
	account: 'Konto',
	poi: 'POI',
	timetables: 'Harmonogramy',
	integrations: 'Integracje',
	implements: "Implements", // TODO: translate
	errors: {
		'1': 'Błąd podczas zapisywania pojazdu',
		'2': 'Błąd podczas zapisywania kierowcy',
		'3': 'Błąd podczas blokowania/odblokowywania zapłonu',
		'4': 'Error saving implement', // TODO translate
		'409': 'Użytkownik już istnieje',
		'410': 'Email jest już używany',
		'5010': 'Tylko proste wielokąty są dozwolone.',
		'5020': 'Sekcja jest już udostępniona.',
		messages: Errors,
		accountDisabled: 'Przepraszamy. Konto tej firmy zostało deaktywowane.'
	},
	dayHours: 'Godziny',
	theMinutes: 'Minuty',
	monthDays: 'Dni',
	theWeekDays: 'Dni',
	messages: Messages,
	day: 'Dzień',
	week: 'Tydzień',
	month: 'Miesiąc',
	every: 'Wszystko',

	map: 'Mapa',
	type: 'Typ',
	search: 'Szukaj',
	ALL: 'WSZYSTKO',
	ok: 'OK',
	add: 'DODAJ',
	save: 'ZAPISZ',
	saveSuccess: 'Dane zapisane.',
	edit: 'EDYTUJ',
	cancel: 'ANULUJ',
	send: 'WYSLIJ',
	timezone: 'Strefa czasowa',
	delete: 'USUŃ',
	addFueling: 'Dodaj tankowanie',
	aclGroup: 'Grupa',
	aclGroups: 'Grupy',
	aclVehicle: 'Pojazd',
	aclVehicles: 'Pojazdy',
	allVehicles: 'Wszystkie Pojazdy',
	selected: 'wybrane',
	subscribe: 'Subskrybuj',
	acceleration: 'Przyspieszenie',
	rpm: 'RPM',
	temperature: 'Temperatura',
	celsiusTemperature: 'Temperatura (°C)',
	sensors: 'Czujniki',
	accelerationG: 'Przyspieszenie',
	alert: 'Alert',
	notification: 'Powiadomienie',
	disabled: 'Wyłączone',
	driver: 'Kierowca',
	drivers: 'Kierowcy',
	driverss: 'Kierowcy',
	noDriver: 'Brak kierowcy',
	selectedDrivers: 'wybrani kierowcy',
	selectFile: 'Wybierz Plik',
	selectVehicles: 'Wybierz pojazdy',
	selectFilters: 'Wybierz filtry',
	selectedFilters: 'wybrane filtry',
	unknownLocation: 'Nieznane położenie',
	time: {
		period: 'Okres',
		today: 'Dziś',
		currentWeek: 'Obecny tydzień',
		currentMonth: 'Obecny miesiąc',
		yesterday: 'Wczoraj',
		lastWeek: 'Poprzedni tydzień',
		lastMonth: 'Poprzedni miesiąc'
	},
	weekDays: {
		sunday: 'Niedziela',
		monday: 'Poniedziałek',
		tuesday: 'Wtorek',
		wednesday: 'Środa',
		thursday: 'Czwartek',
		friday: 'Piątek',
		saturday: 'Sobota'
	},
	dashboard: {
		heatmapTitle: 'Aktywność w ostatnich 24h',
		distance: 'Dystans',
		area: 'Area', // TODO translate
		heatmapShowMore: 'Pokaż więcej',
		heatmapShowLess: 'Pokaż mniej',
		dailyKm: 'Łącznie km w ostatnich 24h',
		dailyHa: 'Total ha in the last 24h',// TODO translate
		dailyConsumption: 'Zużycie w ostatnich 24h',
		monthlyKm: 'Km w tym miesiącu',
		monthlyHa: 'Ha in the current month', // TODO translate
		monthlyConsumption: 'Całkowite zużycie w tym miesiącu',
		cars: 'samochody',
		alerts: 'Alerty',
		notifications: 'Powiadomienia',
		showAlerts: 'Pokaż Alerty',
		showNotifications: 'Pokaż Powiadomienia',
		showAllVehicles: 'Pokaż wszystkie pojazdy',
		stoped: 'Postój',
		moving: 'W ruchu',
		contact: 'Masz sugestię lub skargę?',
		contactLink: 'Skontaktuj się z nami',
		contactTitle: 'Kontakt',
		contactSendBtn: 'Wyślij',
		contactMsgHint: 'Dodaj wiadomość',
		errorOnSendingMessage: 'Błąd podczas wysyłania wiadomości.',
		plaseTryAgainLater: 'Proszę spróbować później',
		emptyMessage: 'Brak wiadomości',
		pleaseInputMessage: 'Proszę wpisać wiadomość',
		noAlertData: 'Brak alertów',
		noNotificationData: 'Brak powiadomień',
		comingSoon: 'Wkrótce',
		noData: 'Brak Danych',
		showDriver: 'Kierowca',
		showLicensePlate: 'Tablica Rejestracyjna',
		liveStatus: {
			vehicle: 'Pojazd',
			address: 'Adres / POI',
			lastPOI: 'Ostatni POI',
			status: 'Status',
			speed: 'Prędkość',
			fuelLevel: 'Poziom paliwa',
			temperature: 'Agregat',
			doors: 'Drzwi',
			implements: "Implements", // TODO translate
		}
	},

	management: Management,
	reports: Reports,
	routes: {
		reports: 'Raporty',
		'reports.stationary': 'Postoje'
	},
	signup: {
		terms: 'I agree to the <a href="/tos" target="_new">terms of service</a>.', // TODO translate
		invitation: {
			expired: 'Zaproszenie przedawniło się. Proszę skontaktować się z administratorem.'
		},
		termsText: 'PL balbalbla', // TODO translate
	},

	dispatch: {
		route: {
			title: 'Trasa',
			distance: 'Dystans (km)',
			name: 'Nazwa',
			vehicles: 'Pojazdy',
			duration: 'Czas Trwania',
			waypoints: {
				title: 'Punkty',
				type: 'Typ',
				types: {
					load: 'Załadunek',
					unload: 'Rozładunek',
					stop: 'Postój'
				},
				location: 'Lokalizacja',
				duration: 'Czas trwania (minuty)'
			},
			actions: {
				route: 'Wygeneruj trasę',
				optimize: 'Optymalizuj trasę',
				next: 'Następny',
				save: 'Zapisz',
				add: 'Dodaj',
				search: 'Znajdź trasę...'
			},
			state: {
				generating: 'Generowanie trasy...'
			},
			optimized: {
				title: 'Zoptymalizowana trasa',
				use: 'Użyj zoptymalizowanej trasy',
				message: 'Trasa nie można zoptymalizować trasy',
				diff: 'Zoptymalizowana trasa jest krótsza o <b>{{distance}}</b> i szybsza o <b>{{time}}</b>.'
			},
			schedule: {
				title: 'Harmonogramy'
			},
			details: {
				title: 'Szczegóły'
			}
		}
	}
};
