import Base from './base'
import Ember from 'ember'

const { service } = Ember.inject

export default Base.extend({
	arrays: service('utils/arrays'),

	groupedRecords: Ember.computed('completedRecords', function () {
		let groupByKey = this.get('groupByKey')
		let collection = this.get('completedRecords')

		let groupedCollection = this.get('arrays').group(collection, groupByKey)

		/**
		 * Exceptions
		 */
		if (this.get('groupByKey') === 'vehicleID') {
			groupedCollection.forEach((item) => {
				this.addVehicleData(item)
				this.addDriversData(item)
			})
		}

		let filteredGroupedCollectionByCanData = groupedCollection.map(collection => {
			collection.set('groupIdentifier', collection.get(groupByKey))

			// let hasCan = collection.get('contents.firstObject.hasCan')

			// if (!hasCan) { return collection }

			// if (collection.get('contents').isEvery('consumptionCompose', 0)) {
			// 	collection.set('contents', this.fallbackConsuption(collection.get('contents')))
			// }
			// if (collection.get('contents').isEvery('consumptionDistance', 0)) {
			// 	collection.set('contents', this.fallbackDistance(collection.get('contents')))
			// }
			return collection
		})

		return filteredGroupedCollectionByCanData
	}),

	addDriversData(item) {
		if (!item.get('contents')) { return null }
		let drivers = item.get('contents').mapBy('driver').uniq()
		if ((drivers && drivers.length == 1 && !drivers[0].length) || !drivers) {
			item.set('drivers', [])
			return
		}
		item.set('drivers', drivers)
	},

	addVehicleData(item) {
		if (!item.get('vehicleID')) { return null }
		let vehicleData = this.getVehicle(item.get('vehicleID'), this.get('reportsController.model.vehicles'))
		if (!vehicleData) { return null }
		item.set('vehicleData', vehicleData)
	}

	// fallbackConsuption(items) {
	// 	if (!items || !items.length) { return items }
	// 	return items.map(item => {
	// 		item.set('consumptionCompose', item.get('fuelConsumption'))
	// 		return item
	// 	})
	// },

	// fallbackDistance(items) {
	// 	if (!items || !items.length) { return items }
	// 	return items.map(item => {
	// 		item.set('distanceCompose', item.get('distance'))
	// 		return item
	// 	})
	// }
})
