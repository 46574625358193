import Ember from 'ember';
import RSVP from 'rsvp';

export default Ember.Route.extend({
    model() {
        let user = this.store.createRecord('user', {
            assignedVehicle: null,
            groupID: null,
            companyID: null,
            email: null,
            phoneNumber: null,
            firstName: null,
            lastName: null,
            role: null,
            avatar: null,
            details: {
                drivingLicense: {
                    categories: null
                },
                iButtonID: null
            },
            acl: {
                groups: null,
                vehicles: null,
                users: null
            }
        });

        let documents = this.store.peekAll('document');

        return RSVP.hash({
            user: user,
            documents: documents
        });
    },

    setupController(controller, model) {
        this._super(controller, model);
        controller.set('users', this.store.peekAll('user'));
        controller.set('vehicles', this.store.peekAll('vehicle'));
        controller.set('groups', this.store.peekAll('group'));
    },
    actions: {
        willTransition() {
            Ember.$('.managementModal').modal("hide");
            this.controller.rollBackAndTransition();
        },
    }
});
