export default {
    at: 'At',
    from: 'From',
    to: 'To',
    duration: 'Duration',

    start: 'Start',
    stop: 'Stop',
    stationary: 'Stationary',

    //charts-container
    speed: "Speed",
    altitude: "Altitude",
    fuelCanLevel: "CAN Fuel Level",
    fuelLevel: "Fuel Sensor Level",
    acceleration: "Acceleration",
    rpm: "RPM",

    //map index popup
    lastUpdate: "Last update",
    engineState: "Engine state",
    engineLoad: "Engine load",
    engineTemperature: "Engine temperature",
    gpsPosition: "GPS position",
    gpsSpeed: "GPS speed",
    canSpeed: "CAN speed",
    canAcceleration: "CAN acceleration",
    canRPM: "CAN RPM",
    canFuelAvailable: "Available fuel level (CAN)",
    canFuelUsed: "Fuel used (CAN)",
    probeFuelAvailable: "Available fuel level (sensor)",
    distance: "Distance",
    doors: "Doors",
    agregation: "Agregation unit",
    temperature: "Temperature",
}
