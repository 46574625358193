import Ember from 'ember'

const { service } = Ember.inject

export default Ember.Component.extend({
	classNames: ['main-menu'],
	currentUser: Ember.inject.service('current-user'),
	sidebar: service(),

	didInsertElement() {
		this.get('sidebar').setTo(this.$().parent());
	}
})
