import Ember from 'ember';

export default Ember.Component.extend({
    didInsertElement() {
        Ember.$(".confirmationModal").modal({ allowMultiple: true }, 'show')
    },
    actions: {
        confirm: function () {
            this.get("confirmationResponse")(true)
        },
        cancel: function () {
            this.get("confirmationResponse")(false)
        }
    }
});