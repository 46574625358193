import DS from 'ember-data'

export default DS.RESTSerializer.extend({
    keyForRelationship(key) {
        switch (key) {
            case 'group': return 'groupID'
            case 'vehicles': return 'vehicleIDs'
        }
        
        return this._super(...arguments)
    }
})
