import DS from 'ember-data';

export default DS.Transform.extend({
  serialize(deserialized) {
    if (deserialized.length === 1) {// Polygon
      let arr = []
      deserialized[0].forEach((coor) => {
        arr.push([coor.get('lng'), coor.get('lat')])
      })
      return arr
    }
    else if (deserialized.length === 2) {// Point
      return [deserialized.get('lng'), deserialized.get('lat')];
    }
  },

  deserialize(serialized) {
    if (serialized.length === 1) {// Polygon
      let arr = []
      serialized[0].forEach((coor) => {
        arr.push(Ember.Object.create({ lat: coor[1], lng: coor[0] }))
      })
      return arr
    }
    else if (serialized.length === 2) {// Point
      return Ember.Object.create({ lat: serialized[1], lng: serialized[0] });
    }
  },
});
