import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import RSVP from 'rsvp';
/* global printHtmlElement */

const { service } = Ember.inject;
const purposes = []

export default Ember.Route.extend(AuthenticatedRouteMixin, {
    i18n: service(),
    parseDivisions: service('parse-divisions'),

    model() {

        let promises = {
            groups: this.get('store').findAll('group'),
            vehicles: this.get('store').findAll('vehicle'),
			users: this.get('store').findAll('user'),
			geofence: this.get('store').findAll('geofence'),
			purposes: purposes
        };

        return RSVP.hash(promises)
            .then((data) => {
                let usersPromises = [];

                data.vehicles.toArray().forEach((vehicle) => {
                    if (vehicle && vehicle.get('user') && vehicle.get('user.id')) {
                        usersPromises.push(this.store.find('user', vehicle.get('user.id')));
                    }
                });

                if (!data.groups.get('length')) {

                    this.noGroups = true;

                    let group = Ember.Object.create({
                        id: '',
                        childs: [],
                        name: 'Fara divizie',
                        vehicles: data.vehicles
                    });

                    return RSVP
                        .all(usersPromises)
                        .then((users) => {
                            data.users = users;
                            data.groups = [group];
                            return data;
                        });
                } else {
                    this.noGroups = false;
                    return data;
                }
            });
    },

    setupController(controller, model) {
        this._super(...arguments);
        var arranged;
        // model.drivers = model.users.filterBy('role', 'driver');
        model.drivers = model.users;
        if (this.noGroups) {
            arranged =model.groups;
        } else {
            this.get('parseDivisions').arange(model.groups.toArray());
            arranged = this.get('parseDivisions').aranged;
        }

        controller.set('arangedGroups', arranged);
        /**
         * Announce controller that model is ready.
         */
        controller.set('modelReady', true);
    },

    actions: {
        willTransition() {
            this.controller.set('reportTitle', null);
            this.controller.set('activeChildController', null);
            this.controller.set('activeRoute', this.get('routeName'));
        },

        print() {
            var _this = this,
                PHE = printHtmlElement;
            PHE.printHtml(Ember.$('.reportContent').html(), {
                styles: 'th {color: #333;}',
                pageTitle: _this.controllerFor('reports').get('reportTitle')
            });
        },

        subscribe(reportTitle) {
            function isValidEmailAddress(emailAddress) {
                var atpos = emailAddress.indexOf("@");
                var dotpos = emailAddress.lastIndexOf(".");
                if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= emailAddress.length) {
                    return false;
                }
                else {
                    return true;
                }
            }
            Ember.$('#reporEmailsList')
                .keyup(function () {
                    var _this = this;
                    var emailTxt = this.value;
                    // emails introduction by commas
                    var commaNumbers = (emailTxt.match(/,/g) || []).length;
                    if (commaNumbers === 1) {
                        var email = emailTxt.substr(0, emailTxt.indexOf(','));
                        if (isValidEmailAddress(email)) {
                            Ember.$('.emailListTag').append(
                                '<div class="ui label"> ' +
                                '<span>' + email + '</span>' +
                                '<i class="delete icon"></i>' +
                                '</div>'
                            );
                            Ember.$(_this).val('');
                            Ember.$('.fieldEmailInput').removeClass("error");
                        }
                        else {
                            Ember.$('.fieldEmailInput').addClass("error");
                        }
                    }
                    else if (commaNumbers > 1) {
                        alert("Invalid email. Type a valid email.");
                        Ember.$(_this).val("");
                        Ember.$('.fieldEmailInput').removeClass("error");
                    }
                });
            Ember.$('.ui.checkbox')
                .checkbox()
                ;
            Ember.$(".monthlyDayPicker td.selectable").on("click", function () {
                Ember.$(".monthlyDayPicker td.selectable").removeClass("active");
                Ember.$(this).addClass("active");
            });
            Ember.$(".frecRadioGroups .ui.radio.checkbox")
                .checkbox({
                    onChange: function () {
                        if (this.value === "weekly" && this.checked) {
                            Ember.$(".weeklyDayCheckGroups").show();        //show weekly checkhbox
                        }
                        else {
                            Ember.$(".weeklyDayCheckGroups").hide();        //hide weekly checkboxs
                        }
                        if (this.value === "monthly" && this.checked) {
                            Ember.$(".monthlyDayPicker").show();        //show monthly checkhbox
                        }
                        else {
                            Ember.$(".monthlyDayPicker").hide();        //hide monthly checkboxs
                        }
                    }
                });
            // var selectVehicles = Ember.$("#multipleSelectVehicles").text();
            // console.log(selectVehicles);
            Ember.$(".monthlyDayPicker").hide();
            Ember.$(".weeklyDayCheckGroups").hide();        //hide weekly checkboxs
            //remove emailTag
            Ember.$('.emailListTag').on("click", ".label i.delete", function () {
                Ember.$(this).closest("div.label").remove();
            });
            // reset modal fields
            function resetFields() {
                Ember.$(".monthlyDayPicker td.selectable").removeClass("active");
                Ember.$("#reporEmailsList").val("");
                Ember.$("#reportName").val("");
                Ember.$(".emailListTag").html("");
                Ember.$(".frecRadioGroups .ui.radio.checkbox").checkbox("set unchecked");
                Ember.$(".weeklyDayCheckGroups .ui.checkbox").checkbox("set unchecked");
            }
            Ember.$('.subscribeModal')
                .modal({
                    onApprove: function () {
                        var driversIDs = Ember.$('#multipleSelectDrivers input').val();
                        var vehicleIDs = Ember.$('#multipleSelectVehicles input').val();
                        var mailsList = [];
                        var dayMonth = Ember.$(".monthlyDayPicker td.active").attr("data-value");
                        var startDate, endDate;
                        if (reportTitle === 'FAZ') {
                            startDate = Ember.$("#rangestartFAZ .input input").val();
                            endDate = Ember.$("#rangeendFAZ .input input").val();
                        } else {
                            startDate = Ember.$("#rangestart .input input").val();
                            endDate = Ember.$("#rangeend .input input").val();
                        }
                        function getEmailList() {
                            Ember.$('.emailListTag .ui.label span').each(function (index, element) {
                                mailsList.push(Ember.$(element).text());
                            });
                            if (isValidEmailAddress(Ember.$('#reporEmailsList').val())) {
                                mailsList.push(Ember.$('#reporEmailsList').val());
                            }
                        }
                        getEmailList();
                        alert("emailList: " + mailsList + "\n" + "driversIDs: " + driversIDs + "\n" + "vehicleIDs: " + vehicleIDs + "\n" + "dayMonth: " + dayMonth + "\n" + "startDate: " + startDate + "\n" + "endDate: " + endDate);
                    },
                    onHide: function () {
                        resetFields();
                    },
                    onDeny: function () {
                        resetFields();
                    }
                })
                .modal('show');
        },
        setTemperatureReadingsInterval ( interval ) {
            this.controllerFor( "reports.temperature" ).set( "readingsInterval", interval );
		},
    }
});
