import Ember from 'ember'

const { service } = Ember.inject

export default Ember.Component.extend({
	api: service(),
	errors: service(),
    email: null,
	partners: new Ember.A(),
	partnersVisible: false,

	/**
	 * Self explanatory
	 * @param {string} email
	 */
	addPartnerOnLayout(email) {
		if (!email) { return false }
		this.get("partners").pushObject({
			email
		})
	},

	removePartnerFromLayout(partner) {
		if (!partner || !partner.email) { return false }
		this.get("partners").removeObject(partner)
	},

	showGenericError() {
		if (!this) { return false }
		this.toast.error(this.get('errors').getGeneric().message)
	},

    actions: {
        addGroup() {
            this.sendAction("addGroup", this.get("group.groupID"))
        },
        editGroup() {
            this.sendAction("editGroup", this.get("group.groupID"))
        },
        removeGroup() {
            this.sendAction("removeGroup", this.get("group.groupID"))
        },

        togglePartners ( groupID ) {
            this.set("partnersVisible", !this.get("partnersVisible" ))
            if (this.get("partnersVisible") && !this.get("partners.length")) {
				this.get("getPartners")(groupID)
				.then(data => {
					if (!this) { return false }
                    this.set( "partners", data )
				})
				.catch(() => {
					this.showGenericError()
				})
            }
        },

		/**
		 * Send request to api to add a partner. If api request is ok we show the new partner.
		 * If request fails a generic error is displayed.
		 */
        addPartner () {
			let email = this.get('email')
			let group = this.get('group')
			let api = this.get('api')
			if (!email || !group || !group.groupID) { return false }
			api
			.raw('groups/' + group.groupID + '/shared/' + email, { dataType: "json", method: 'POST' })
			.then(() => {
				if (!this) { return false }
				this.addPartnerOnLayout(email)
				this.set("email", null)
			})
			.catch((response/*, jqXHR, payload*/) => {
				if (!this) { return false }
				if (response.jqXHR.status === 409) {
					this.get('errors').clear()
					this.get('errors').add({ message: response.payload.message, code: response.jqXHR.status || null })
					this.toast.info(this.get('errors').list[0].message)
				} else {
					this.showGenericError()
				}
			})
		},

		/**
		 * It makes a api request to delete a partner. If request is succesfull then we remove the partner from display.
		 * If request fails we show a generic error.
		 * @param {object} partner
		 */
        removePartner ( partner ) {
			if (!partner || !partner.email) {
				this.showGenericError()
				return false
			}
			let email = partner.email
			let group = this.get('group')
			let api = this.get('api')

			api
			.request('groups/' + group.groupID + '/shared/' + email, { dataType: "json", method: 'DELETE' })
            .then(() => {
				if (!this) { return false }
				this.removePartnerFromLayout(partner)
			})
			.catch(() => {
				this.showGenericError()
			})
        },
    }
})
