import Ember from 'ember'
import moment from 'moment'
import { scaleTime } from 'd3-scale'
import { select, selectAll } from 'd3-selection'
import { axisTop } from 'd3-axis'
import { drag as d3drag, event as d3event } from 'd3-drag'
import { min as d3min } from 'd3-array'

export default Ember.Component.extend({
    dataDomain: [],
    startValue: Ember.computed.min('dataDomain'),
    endValue: Ember.computed.max('dataDomain'),
    sliderHeightCorrection: 20,
    max: {},
    data: [],

    didRender() {
        var _this = this
        var initWidth = Ember.$('.svg-container.slider').width()
        var initHeight = (Ember.$('.svg-container.chart').length * (Ember.$('.svg-container.chart').height() + 15) + 1)
        var startValue = isFinite(this.get('startValue')) ? this.get('startValue') : 0
        var endValue = isFinite(this.get('endValue')) ? this.get('endValue') : 0

        var sliderOffset = Ember.$("#charts-column").offset().left - Ember.$(".charts-container").offset().left + 20

        this.set("sliderOffset", sliderOffset)

        //remove existing svg component
        select(".svg-container.slider svg").remove()

        if (Ember.isEmpty(this.get('dataDomain'))) {
            return
        }

        var svg = select(".svg-container.slider")
            .append("svg")
            .attr("preserveAspectRatio", "xMinYMin meet")
            .attr("viewBox", "0 0 " + initWidth + " " + 40)
            .attr("width", initWidth)
            .attr("height", 40)
            .classed("svg-content", true)

        var margin = {
            right: 53,
            left: 20,
            bottom: 20
        },
            width = +svg.attr("width") - margin.left - margin.right,
            height = +svg.attr("height") - margin.bottom

        var xScale = scaleTime()
            .domain([new Date(startValue), new Date(endValue)])
            .range([0, width])
            .clamp(true)

        var slider = svg.append("g")
            .attr("class", "slider")
            .attr("transform", "translate(" + margin.left + "," + height + ")")

        var divisions = []
        var timeInterval = Math.floor((endValue - startValue) / 10)

        var timeDiv = startValue
        while (timeDiv < endValue) {
            divisions.push(timeDiv)
            timeDiv += timeInterval
        }
        divisions.push(endValue)

        slider.append("g")
            .call(axisTop()
                .scale(xScale)
                .tickValues(divisions)
                .tickPadding(5)
                .tickFormat(function (time) {
                    if (time == startValue || time == endValue) {
                        return moment(time).format("HH:mm")
                    } else {
                        return ""
                    }
                })
            )

        slider.append("line")
            .attr("class", "track")
            .attr("x1", xScale.range()[0])
            .attr("x2", xScale.range()[1])
            .select(function () {
                return this.parentNode.appendChild(this.cloneNode(true))
            })
            .attr("class", "track-inset")
            .select(function () {
                return this.parentNode.appendChild(this.cloneNode(true))
            })
            .attr("class", "track-overlay")
            .call(d3drag()
                .on("start drag", function () {
                    //get current position
                    var h = xScale.invert(d3event.x)
                    var xPos = xScale(h)
                    //set rectangle transform to follow the drag
                    handleLine.attr("transform", function () {
                        return "translate(" + xPos + ",0)"
                    })
                    handleRect.attr("x", xPos - 20)
                    handleText.attr("x", xPos)
                        .text(moment(h).format("HH:mm"))
                    _this.showIntersection(xPos, h.getTime())

                    // send change event with the current value
                    // _this.sliderChanged(h)
                }))



        // add time text for slider
        slider.insert("g", ".track-overlay")
            .attr("class", "ticks")
            .attr("transform", "translate(0," + 18 + ")")
            .selectAll("text")
            .enter().append("text")
            .attr("text-anchor", "middle")
            .text(function (d) {
                return moment(d).format("HH:mm")
            })

        // var sliderHeight = Ember.$('.svg-container.slider').height()
        // var chartHeight = Ember.$('.svg-container.chart').height()
        // Not used
        //var lineHeight = -(sliderHeight / 2 + chartHeight)


        var chartsOverlay = select("#charts")
            .append("svg")
            .attr("preserveAspectRatio", "xMinYMin meet")
            .attr("viewBox", "0 0 " + (sliderOffset + Ember.$(".charts-container").width()) + " " + initHeight)
            .attr("width", (sliderOffset + Ember.$(".charts-container").width()))
            .attr("height", initHeight)
            .classed("svg-content intersection-indicator", true)

        Ember.$(".svg-content.intersection-indicator").offset({ top: Ember.$("#charts-column").offset().top, left: Ember.$(".charts-container").offset().left })

        Ember.$("#charts").height(initHeight)
        //add vertical line
        var handleLine = chartsOverlay.insert("line", ".track-overlay") // attach a line
            .attr("class", "handle-line")
            .style("stroke-dasharray", ("5, 5"))
            .attr("y1", 0) // y position of the first end of the line
            .attr("x1", sliderOffset)
            .attr("y2", initHeight)
            .attr("x2", sliderOffset) // y position of the second end of the line


        // attach a rectangle
        var handleRect = slider.insert("rect", ".track-overlay")
            .attr("class", "handle")
            .attr("x", -20)
            .attr("y", -7)
            .attr("height", 15)
            .attr("width", 40)
            .attr("rx", 7)
            .attr("ry", 7)

        var minXDomainValue = d3min(xScale.domain())

        // attach the text for the rectangle
        var handleText = slider.insert("text", ".track-overlay")
            .attr("class", "handle-text")
            .attr("x", 0)
            .attr("y", 10)
            .attr("dy", -5)
            .attr("text-anchor", "middle")
            .text(function (/*d*/) {
                return moment(minXDomainValue).format("HH:mm")
            })

        var ind = 0
        // add intersection mark
        selectAll('.svg-container.chart').each(function () {
            //add intersection point
            chartsOverlay.insert("circle", ".track-overlay")
                .attr("class", "cross-circle c" + ind)
                .attr("r", 5)

            // add intersection label container
            chartsOverlay.insert("rect", ".track-overlay")
                .attr("class", "cross-rect r" + ind)
                .attr("height", 15)
                .attr("width", 50)
                .attr("rx", 4)
                .attr("ry", 4)

            //add intersection text
            chartsOverlay.insert("text", ".track-overlay")
                .attr("class", "cross-text t" + ind)
                .attr("dy", -5)
                .attr("text-anchor", "middle")

            ind++
        })
        _this.showIntersection(xScale(0), xScale.invert(0).getTime())
    },

    // sliderChanged(value) {

    // },

    showIntersection(positionX, time) {
        var _this = this
        var ind = 0
        var chartHeight = Ember.$('.svg-container.chart').height()
        // var chartsCount = Ember.$('.svg-container.chart').length
        var heightDiff = - 20//this.get('sliderHeightCorrection')
        var sliderOffset = this.get("sliderOffset")
        selectAll('.svg-container.chart .svg-content').each(function () {
            var type = Ember.$(this).parent().data("type")
            var data = _this.get("data")

            var svg = select(this)
            // var chartY = svg.select(".chart-y")
            var line = svg.select('.line-graph')
            var pathLength = line.node().getTotalLength()
            var x = positionX
            var beginning = x,
                end = pathLength,
                target

            var value = null
            var index = 1

            while (!value && index < data.length) {
                if (time > data[index - 1].timestamp && time <= data[index].timestamp) {
                    value = data[index - 1][type]
                }
                index++
            }

            if (Ember.isNone(value)) {
                value = data[0][type]
            }

            let xxx = true
            while (xxx) {
                target = Math.floor((beginning + end) / 2)
                var pos = line.node().getPointAtLength(target)
                if ((target === end || target === beginning) && pos.x !== x) {
                    break
                }
                if (pos.x > x) end = target
                else if (pos.x < x) beginning = target
                else break //position found
            }

            //move circle
            select('.c' + ind)
                .attr("cx", x + sliderOffset)
                .attr("cy", (pos.y + chartHeight * ind - heightDiff))

            //move value container
            select('.r' + ind)
                .attr("x", x + sliderOffset - 25)
                .attr("y", pos.y + chartHeight * ind - heightDiff - 25)

            //move value text
            select('.t' + ind)
                .attr("x", x + sliderOffset)
                .attr("y", pos.y + chartHeight * ind - heightDiff - 8)
                .text(value)

            ind++
        })
    }
})
