import Ember from 'ember';

export default Ember.Component.extend({
    attributeBindings: ['data-id'],
    'data-id': null,

    classNames: ["seven", "fields"],
    
    rdonly: Ember.computed("document.isNew", "readonly", function () {
        if (this.get("readonly") || !this.get("document.isNew")) {
            return true;
        }
        else { return false; }
    }),

    hasFile: Ember.computed("document.isNew", "document.file.hash", function () {
        if (this.get("document.isNew") || !this.get("document.file.hash")) {
            return false;
        }
        else { return true; }
    }),

    init() {
        this._super(...arguments);
        this.set('data-id', this.get('document').get('id'));
    },

    actions: {
        inputClicked(element) {
            element.parentNode.parentNode.firstElementChild.click()
        },
        downloadFile(doc) {
            this.sendAction('downloadFile', doc);
        },
        deleteDocument(doc) {
            this.get("deleteDocument")(doc)
        },
        loadedFile(element) {
            // let textInput = element.parentNode.lastElementChild.firstElementChild
            let file = element.files[0];
            let document = this.get("document");
            if (file) {
                document.set("file.name", file.name);
                document.set("document", file);
            }
            // textInput.setAttribute("value", file.name);
        },
    }
});
