import DS from 'ember-data';
import MF from 'ember-data-model-fragments';
import Ember from 'ember';

function deserialize(serialized) {
    if (serialized.length === 1) {// Polygon
        let arr = []
        serialized[0].forEach((coor) => {
            arr.push(Ember.Object.create({ lat: coor[1], lng: coor[0] }))
        })
        return arr
    }
    else if (serialized.length === 2) {// Point
        return Ember.Object.create({ lat: serialized[1], lng: serialized[0] });
    }
}

export default MF.Fragment.extend({
    type: DS.attr('string'),
    coordinates: DS.attr(),

    transformedCoordinates: Ember.computed("coordinates", function () {
        return deserialize(this.get('coordinates'))
    })
});
