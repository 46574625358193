import Ember from 'ember';
import Base from './base';

export default Base.extend({
	sessionService: Ember.inject.service("session"),
	i18n: Ember.inject.service(),

	model() {
		return Ember.RSVP.hash({
			tHeaders: [
				// { key: 'licensePlate', value: this.get('i18n').t('reports.headerFields.licensePlate'), align: 'left' },
				{ key: 'driver', value:  this.get('i18n').t('reports.headerFields.driver'), align: 'left' },
				{ key: 'duration', value: this.get('i18n').t('reports.headerFields.specific.stationary.duration'), align: 'left' },
				{ key: 'location', value: this.get('i18n').t('reports.headerFields.location'), align: 'left' },
				{ key: 'startTime', value: this.get('i18n').t('reports.headerFields.specific.stationary.startTime'), align: 'left' },
				{ key: 'endTime', value: this.get('i18n').t('reports.headerFields.specific.stationary.endTime'), align: 'left' }
			],
			tData: []
		});
	}
});
