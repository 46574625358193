import Ember from 'ember'

export default Ember.Route.extend({
	model(params) {
		return Ember.RSVP.hash({
			geofences: this.get('store').query('geofence', {
				$where: {
					type: 'poi'
				}
			}),
			groups: this.store.findAll("group"),
			vehicles: this.store.findAll("vehicle"),
			route: params['planned-route_id'] !== 'new' ? this.store.findRecord('planned-route', params['planned-route_id']) : null
		})
	},
	afterModel(model) {
		// New route, prepare defaults
		if (model.route == null) {
			model.route = this.store.createRecord('planned-route', {
				waypoints: [{}, {}]
			})
		}
	},
	cleanup: Ember.on('deactivate', function(){
		let model = this.modelFor(this.routeName)

		if (model.route != null && model.route.get('isNew')) {
			Ember.Logger.debug('Unloading new planned route')
			model.route.unloadRecord()
		} else {
			Ember.Logger.debug('Discarding changes for planned route')
			model.route.rollbackAttributes()
		}
	})
})
