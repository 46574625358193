import Ember from 'ember'
import DS from 'ember-data'
import MF from 'ember-data-model-fragments'
import moment from 'moment'

const poiEnter = 'poi-enter'
const poiExit = 'poi-exit'
const poi = 'poi-'
const geofence = 'geofence-'
const documentExpiry = 'document-expiry'
const fuelConsumption = 'fuel-consumption-limit'
const rpmLimit = 'rpm-limit'
const doorOpenOutsidePOI = 'door-open-outside-poi'
const oneHour = 60 * 60 * 1000
// const fiveMinutes = 5 * 60 * 100

export default DS.Model.extend({
	groupID: DS.attr('string'),
	companyID: DS.attr('string'),
	type: DS.attr('string'),
	status: DS.attr('string'),
	persistent: DS.attr('boolean'),
	details: MF.fragment('not-details', { allowNull: true }),
	createdAt: DS.attr('date'),
	updatedAt: DS.attr('date'),
	timestamp: DS.attr('date'),

	start: Ember.computed('timestamp', function () {
		if (!this.get('timestamp')) { return null }
		return moment(this.get('timestamp').getTime() - oneHour).startOf('day').valueOf()
	}),

	end: Ember.computed('timestamp', function () {
		if (!this.get('timestamp')) { return null }
		return moment(this.get('timestamp').getTime() + oneHour).endOf('day').valueOf()
	}),

	isAlert: Ember.computed('persistent', function () {
		return this.get('persistent')
	}),

	isDocumentExpiry: Ember.computed('type', function () {
		return this.get('type') === documentExpiry
	}),

	isFuelConsumption: Ember.computed('type', function () {
		return this.get('type') === fuelConsumption
	}),

	isRpmLimit: Ember.computed('type', function () {
		return this.get('type') === rpmLimit
	}),

	isPoi: Ember.computed('type', function () {
		return this.get('type').indexOf(poi) > -1
	}),

	isPoiEnter: Ember.computed('type', function () {
		return this.get('type').indexOf(poiEnter) > -1
	}),

	isPoiExit: Ember.computed('type', function () {
		return this.get('type').indexOf(poiExit) > -1
	}),

	isDoorOpen: Ember.computed('type', function () {
		return this.get('type').indexOf(doorOpenOutsidePOI) > -1
	}),

	isGeofence: Ember.computed('type', function () {
		return this.get('type').indexOf(geofence) > -1
	}),
})
