import Ember from 'ember'

const { service } = Ember.inject

export default Ember.Route.extend({
    currentUser: service('current-user'),
    reportsInfo: service('reports/reports-info'),
	tableLayoutState: service('reports/table-layout-state'),

    afterModel() {
        Ember.run.scheduleOnce('afterRender', this, function () {
            this.controllerFor('reports').reload(undefined, true)
        })
    },

	deactivate() {
		this.get('tableLayoutState').clear()
	},

    actions: {
        didTransition() {
            this.controllerFor("reports").setReport(this.get("routeName").split(".")[1].toUpperCase())
            this.controllerFor('reports').setReportRoute(this.get("routeName"))
            this.controllerFor('reports').setActiveChildController(this.controller)
            this.controller.set('empty', true)
        }
    }
})
