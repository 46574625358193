import Ember from 'ember';

const { service } = Ember.inject
const custom = 'custom'

export default Ember.Component.extend({
	i18n: service(),

	edit: false,
	routeID: null,
	selected: null,

	listItems: [
		'Deplasari la clienti',
		'Deplasari la furnizori',
		'Prospectarea pietei',
		'Deplasari pentru instalari',
		'Deplasari pentru service',
		'Deplasari la traininguri',
		'Deplasari la vama',
		'Deplasari la banca',
		'Deplasari la autoritatile fiscale',
		'Deplasare in interes personal'
	],


	extraItem: null,

	disableEdit() {
		Ember.run.later(this, function () {
			this.set('edit', false)
		}, 100)
	},

	enableEdit() {
		Ember.run.later(this, function () {
			this.set('edit', true)
		}, 100)
	},

	// click(e) {
	// 	e.stopPropagation()
	// },

	init() {
		this._super(...arguments)
		if (this.get('selected') && this.get('listItems').indexOf(this.get('selected')) === -1) {
			this.set('extraItem', this.get('selected'))
		}
	},

	actions: {
		append() {
			let extraItemValue = this.get('extraItemValue')
			this.set('extraItem', extraItemValue)
			this.disableEdit()
			this.get('extraAction')(extraItemValue)
		},

		cancel() {
			this.disableEdit()
		},

		select(value) {
			if (value === custom) {
				this.enableEdit()
			} else {
				this.disableEdit()
				this.get('extraAction')(value)
			}
		}
	}

});
