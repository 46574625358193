import Ember from 'ember'

export default Ember.Mixin.create({
	/**
	 * Return an array of labels.
	 * Lines are identified by labels.
	 */
	getLinesLabels(data) {
		if (Ember.isEmpty(data)) { return null }
		let labels = []
		data.forEach(item => {
			if (labels.indexOf(item.label) > -1) { return false }
			labels.push(item.label)
		})
		return labels
	},

	removeConsecutiveSameValues(data, column) {
		if (Ember.isEmpty(data)) { return null }
		let filtered = data.filter((item, pos, arr) => {
			if (arr[pos + 1]) {
				return pos === 0 || (item[column] !== arr[pos-1][column] || item[column] !== arr[pos + 1][column])
			} else {
				return true
			}
		})

		return filtered
	}
})
