import Ember from 'ember'
/* global L */

const { service } = Ember.inject
const maxPositionsInTail = 6
const minimumDistanceBetweenTailPositions = 10
const tailColor = '#3388FF'
const tailCssClass = 'vehicle-tail'
const tailWeight = 5 //10

export default Ember.Service.extend({
	mapMarker: service('map/shapes/marker'),
	mapUtil: service('map/util'),

    vehiclesPositions: Ember.Object.create(),
	/**
	 * This is an interface for distance function from map/util service.
	 */
	distance: function (p1, p2) {
        return this.get('mapUtil').distance(p1.lat, p1.lng, p2.lat, p2.lng)
    },

	/**
	 * Resets vehicle data.
	 */
	resetVehicleData() {
		return Ember.Object.create({
			positions: [],
			polyline: null
		})
	},

	resetAllVehicleData() {
		this.set('vehiclesPositions', Ember.Object.create())
	},

	createLine(positions) {
		return L.polyline(positions, {
					color: tailColor,
					weight: tailWeight,
					class: tailCssClass
				})
	},

	removeFromMap(mapItem, mapInstance) {
		if (!mapItem || !mapInstance || !mapInstance.removeLayer) { return false }
		mapInstance.removeLayer(mapItem)
	},

    pushStatus(vehicle) {
		var vehicleId = vehicle.get("id")
		var vehicleSpeed = vehicle.get('isMoving')
        var location = vehicle.get("status.location")
        var vehicleData = this.vehiclesPositions.get(vehicle.get("id"))
        var positions = null
		var position = null
		var mapInstance = this.get('mapInstance')
		/**
		 * If no location is present do nothing (return from function with false).
		 */
        if (!location) { return false }

		/**
		 * If no data about current car is present initialize data with empty values.
		 */
		if (Ember.isEmpty(vehicleData)) {
			vehicleData = this.resetVehicleData()
			this.vehiclesPositions.set(vehicleId, vehicleData)
		}

		positions = vehicleData.get('positions')
		position = L.latLng(location[1], location[0])

		if ((!positions.length) || (this.distance(positions[positions.length - 1], position) > minimumDistanceBetweenTailPositions)) {

			positions.push(position)

			if (positions.length > maxPositionsInTail) {
				positions.shiftObject()
			}

			/**
			 * Return from function if we do not have a map or we do not have positions.
			 */
			if (!mapInstance || !positions.length) { return false }
			// if (mapInstance && (vehicleData.get("positions.length") > 1)) {

			if (vehicleData.get("polyline")) {
				Ember.Logger.log('%c Remove tail from map', 'color: yellow')
				this.removeFromMap(vehicleData.get('polyline'), mapInstance)
			}

			let line = this.createLine(positions)

			vehicleData.set("polyline", line)

			vehicleData.get("polyline").addTo(mapInstance)

			//line._path.setAttribute("filter", "url(#gausianFilter)")

			let marker = vehicleData.get("directionMarker")
			if (marker) {
				Ember.Logger.log('%c Remove direction marker from map', 'color: yellow')
				this.removeFromMap(marker, mapInstance)
			}
			if (vehicleSpeed) {
				marker = this.get('mapMarker')._makeMarker("direction", {
					location: position,
					angle: vehicle.get("status.bearing")
				})
				
				marker.addTo(mapInstance)
				marker.setZIndexOffset(-1000000);
				vehicleData.set("directionMarker", marker)
			}
		}
    },

	/**
	 * Takes the map object and set it locally if is present.
	 * @param (L.Map)	map
	 * @return null 	map is not present
	 * 			map     map is present
	 */
    setMapInstance(map) {
		if (!map) { return null }
		this.set('mapInstance', map)
		let mapPane = map.getPane('mapPane')
		if (!mapPane) { return map }
		Ember.$(mapPane).prepend('<svg><defs><filter id="gausianFilter" x="-0.5" y="-0.5" width="200%" height="200%"><feGaussianBlur in="SourceGraphic" stdDeviation="3" /></filter></defs></svg>')
		return map
	},
    getMapInstance: function () {
        return this.get('mapInstance')
    },

	getTail() {
		return this.get('vehiclesPositions')
	},

	setMapToNull() {
		this.set('mapInstance', null)
	},

    mapInstance: null
})
