import Ember from 'ember';
import Base from './base';

export default Base.extend({    
    i18n: Ember.inject.service(),

    model() {
        return {
            tHeaders: [
                { key: 'stationaries', value: this.get('i18n').t('reports.headerFields.stationaries'), align: 'left' }, 
                { key: 'acc', value: this.get('i18n').t('reports.headerFields.acc'), align: 'left' },
                { key: 'desacc', value: this.get('i18n').t('reports.headerFields.desacc'), align: 'left' },
                { key: 'virage', value: this.get('i18n').t('reports.headerFields.virage'), align: 'left' },
                { key: 'speed', value: this.get('i18n').t('reports.headerFields.speed'), align: 'left' }
            ],
            tData: []
        };
    }
});
