import Ember from 'ember';
import RSVP from 'rsvp';

export default Ember.Route.extend({
    session: Ember.inject.service(),
    init() {
        this._super(...arguments);
        Ember.$('.ui.dimmer.modals').remove();
    },
    inserVehiclesOnGroups(groups, vehicles) {
        var _mainThis = this;
        /*
            0. order by groupID length to get the level
            1. get parent
            2. insert in parent the parent Vehicles
            3. insert in parent childrens the groupID
        */

        var arrangedGroup = [];

        //new structure of array
        // DONE 
        groups.toArray().forEach(function (item) {
            var allowNew = true;
            var idSplited = item.id.split('-');
            idSplited.pop();
            var parentID = idSplited.join('-');
            var level = item.get('id').split("-").length
            if (level >= 7) {
                allowNew = false;
            }
            arrangedGroup.push({
                "groupID": item.get('id'),
                "level": level,
                "allowNew": allowNew,
                "isEmpty": false,
                "parentID": parentID,
                "groupName": item.get('name'),
                "childrens": [],
                "vehicles": []
            });
        });

        //insert Vehicles on each Group
        vehicles.toArray().forEach(function (veh) {
            arrangedGroup.findBy('groupID', veh.get('groupID')).vehicles.push(veh);
        });

        // sortArray ASC
        arrangedGroup.sort(function (a, b) {
            return a.groupID.length - b.groupID.length;
        });

        var arrSubGroups = [];

        arrangedGroup.toArray().forEach(function (grp) {
            var groupLevel = grp.level;

            //get all childs of parent
            function getChilds(group) {
                var childrens = [];

                arrangedGroup.forEach(function (n) {
                    var idSplited = n.groupID.split('-');
                    idSplited.pop();
                    var partId = idSplited.join('-');
                    if (partId === group.groupID) {
                        n.childrens = getChilds(n);
                        childrens.push(n);
                    }
                });
                return childrens;
            }

            //groupLevel == 2: main childrens of companyID
            if (groupLevel === 2) {// get the parents and push on them
                var splitedGroupID = grp.groupID.split("-");
                splitedGroupID.pop();
                var parentiID = splitedGroupID.join('-');
                //get all childrens for this parent
                //find parentID and push in him
                arrSubGroups.findBy('groupID', parentiID).childrens.push({
                    //this will insert parent on each level
                    "groupID": grp.groupID,
                    "parentID": grp.parentID,
                    "level": grp.level,
                    "allowNew": grp.allowNew,
                    "isEmpty": false,
                    "groupName": grp.groupName,
                    "childrens": getChilds(grp),
                    "vehicles": grp.vehicles
                });

            } else if (groupLevel === 1) {
                // this must be executed first because the arrangedGroup Array is sorted ASC.
                // we have not parent. this is the companyID grp
                arrSubGroups.push({
                    "groupID": grp.groupID,
                    "groupName": grp.groupName,
                    "level": grp.level,
                    "allowNew": grp.allowNew,
                    "isEmpty": false,
                    "parentID": "",
                    "childrens": [],
                    "vehicles": grp.vehicles
                });
                _mainThis.set("companyID", grp.groupID);
            }
        });

        return arrSubGroups;

    },

    model() {
        var groups = this.store.peekAll("group");
        var companyID = this.get("session.session.authenticated.profile.companyID")
        var companyGroups = groups.filterBy("companyID", companyID)
        var partners = groups.rejectBy("companyID", companyID)
        var vehicles = this.store.peekAll("vehicle");
        var companyVehicles = vehicles.filterBy("companyID", companyID)
        var arrangedGroup = this.inserVehiclesOnGroups(companyGroups, companyVehicles);
        var timetables = this.store.peekAll("timetable");

        function getChildrens(array) {
            array.forEach(function (item) {
                if (!item.vehicles.length > 0 && !item.childrens.length > 0) {
                    item.isEmpty = true;
                }
                if (item.childrens.length > 0) {
                    getChildrens(item.childrens);
                }
            });
        }

        getChildrens(arrangedGroup);

        // return arrangedGroup;
        return RSVP.hash({
            timetables: timetables,
            arrangedGroup: arrangedGroup,
            partners: partners,
        });
    },

    actions: {
        addGroup(groupID) {
            var _this = this,
                lastChildrenID = Ember.$('.segment[data-parent="' + groupID + '"]').last().attr('id'),
                nextID = null;

            if (lastChildrenID) {
                nextID = composeNextGroupId(lastChildrenID);
            } else {
                nextID = composeNextGroupId(groupID, true);
            }
            this.set('nextID', nextID);

            function addOneToDivisionID(divisionToAddId) {
                var nextId = Number(divisionToAddId) + 1;

                if (nextId === 100) {
                    throw 100;
                }

                if (nextId < 10) {
                    return ('0' + nextId);
                }

                return nextId;
            }

            function composeNextGroupId(id, newChild) {
                var idParts = id.toString().split('-'),
                    lastIdPart = idParts[idParts.length - 1];

                if (idParts.length === 1 || newChild) {
                    return id + '-01';
                }

                idParts.pop();

                idParts.push(addOneToDivisionID(lastIdPart));

                return idParts.join('-');
            }

            Ember.$('#division-new input#groupName').val("");
            Ember.$("#division-new #ddTimetable").dropdown("clear");

            Ember.$('#division-new')
                .modal({
                    onApprove: function () {
                        var newGroupID = _this.get("nextID");
                        var groupName = Ember.$("#division-new input#groupName").val();
                        var timetableID = Ember.$("#division-new #ddTimetable").dropdown("get value");
                        if (!newGroupID) { return false; }
                        var companyID = newGroupID.split('-')[0];
                        var newGroup = _this.store.createRecord('group', {
                            id: newGroupID,
                            companyID: companyID,
                            timetableID: timetableID,
                            name: groupName
                        });
                        newGroup.save()
                            .then(function () {
                                _this.refresh();
                            }).catch(function (err) {
                                Ember.Logger.error(err);
                            })
                    },
                    onHidden: function () {
                        Ember.$("input#groupName").val("");
                    }
                })
                .modal('show');
        },
        editGroup(groupID) {
            if (groupID) {
                this.transitionTo('management.groups.edit', groupID);
            }
        },
        removeGroup(groupID) {
            var _this = this;
            var groupToRemove = _this.store.peekRecord('group', groupID);
            Ember.$("span#groupNameToDelete").html(groupToRemove.get("name"));
            Ember.$('#division-delete')
                .modal({
                    onApprove: function () {
                        groupToRemove.destroyRecord()
                            .then(function () {
                                _this.refresh();
                            })
                            .catch(function (err) {
                                Ember.Logger.error(err);
                            });
                    }
                })
                .modal('show');
        },
    }
});