import Ember from 'ember';
import config from './config/environment';


const { service } = Ember.inject;

const Router = Ember.Router.extend({
    location: config.locationType,
    rootURL: config.rootURL,
    metrics: service(),
    didTransition() {
        this._super(...arguments);
        this._trackPage();
    },

    _trackPage() {
        Ember.run.scheduleOnce('afterRender', this, () => {
            const page = this.get('url');
            const title = this.getWithDefault('currentRouteName', 'unknown');

            Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
                page, title
            });
        });
    }
});

Router.map(function () {
  this.route('management', function () {
    this.route('vehicles', function () {
      this.route('new');
      this.route('view', { path: '/:id' });
      this.route('edit', { path: '/:id/edit' });
      this.route('docs', { path: '/:id/docs' });
      this.route('test-components');
    });
    this.route('settings')
    this.route('users', function () {
        this.route('new');
        this.route('view', { path: '/:id' });
        this.route('edit', { path: '/:id/edit' });
        this.route('docs', { path: '/:id/docs' });
      });
      this.route('settings')
      this.route('users', function () {
          this.route('new');
          this.route('view', { path: '/:id' });
          this.route('edit', { path: '/:id/edit' });
          this.route('docs', { path: '/:id/docs' });
      });
      this.route('groups', function () {
          // this.route('new');
          this.route('edit', { path: '/:id/edit' });
      });
      this.route('geofences', function () {
          this.route('new');
          this.route('view', { path: '/:id' });
          this.route('edit', { path: '/:id/edit' });
          this.route('import');
      });
      this.route('timetables', function () {
        this.route('new');
        this.route('view', { path: '/:id' });
        this.route('edit', { path: '/:id/edit' });
        this.route('import');
    });
    this.route('timetables', function () {
      this.route('new');
      this.route('view', { path: '/:id' });
      this.route('edit', { path: '/:id/edit' });
      this.route('import');
    });
    this.route('account');
    this.route('integrations');
    this.route('implements', function() {
      this.route('new');
      this.route('view', { path: '/:id' });
      this.route('edit', { path: '/:id/edit' });
    });
  });
  this.route('map', function () {
      this.route('routes', { path: '/routes/:vehicle_id' }, function () { });
  });
  this.route('reports', function () {
    this.route('faz');
    this.route('route');
    this.route('stationary');
    this.route('consumption');
    this.route('base');
    this.route('detailed-stops');
    this.route('alerts');
    this.route('notifications');
    this.route('poi');
    this.route('temperature');
    this.route('fuel-level')
    this.route('temperature-range');
    this.route('poi-temperature');
  });
  this.route('dispatch', function () {
    this.route('routes', function () {
        this.route('edit', { path: '/:planned-route_id' })
    })
  })
  this.route('login');
  this.route('signup');
  this.route('invitation', { path: '/invitation' });
  this.route('not-found', { path: '/*path' });
  this.route('account-disabled');
  this.route('widgets', function() {
    this.route('status-feed');
  });
  this.route('tos');
});

export default Router;
