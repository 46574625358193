import Ember from 'ember';
import Base from './base';

export default Base.extend({
    sessionService: Ember.inject.service("session"),
    i18n: Ember.inject.service(),

    model() {
        return Ember.RSVP.hash({
            tHeaders: [
                // { key: 'licensePlate', value: this.get('i18n').t('reports.headerFields.licensePlate'), align: 'left' },
                // { key: 'driver', value:  this.get('i18n').t('reports.headerFields.driver'), align: 'left' },
                { key: 'day', value: this.get('i18n').t('day'), align: 'left' },
                { key: 'geofences', value: this.get('i18n').t('reports.headerFields.specific.poi.geofences'), align: 'left' },
                { key: 'areaTotal', value: this.get('i18n').t('reports.headerFields.areaTotal'), align: 'left' },
                { key: 'areaTotalLoad50', value: this.get('i18n').t('reports.headerFields.areaTotalLoad50'), hoverText: this.get('i18n').t('reports.headerFields.areaTotalLoad50Popup'), align: 'left' },
                { key: 'fuelConsumptionCompose', value: this.get('i18n').t('reports.headerFields.onlyFuelConsumption'), align: 'left' },
                { key: 'fuelConsumptionPerHa', value: this.get('i18n').t('reports.headerFields.fuelConsumptionPerHa'), align: 'left' },
                { key: 'fuelConsumptionPerHour', value: this.get('i18n').t('reports.headerFields.fuelConsumptionPerHour'), align: 'left' },
                { key: 'canFuelLevelEndOfDay', value: this.get('i18n').t('reports.headerFields.canFuelLevelEndOfDay'), align: 'left' },
            ],
            tData: [],
            geofences: this.store.findAll('geofence')
        });
    }
});
