import DS from 'ember-data';
import Ember from 'ember';

export default DS.Model.extend({
    groupID: DS.attr('string'),
    vehicleID: DS.attr('string'),
    number: DS.attr('string'),
    status: DS.attr('string'),
    createdAt: DS.attr('string'),
    updatedAt: DS.attr('string'),

    isBroken: Ember.computed('status', function () {
        return this.get('status') === 'broken'
    }),
});
