import Ember from 'ember';

export default Ember.Route.extend({
    model(params) {
        return this.store.peekRecord('timetable', params.id);
    },
    actions: {
        willTransition() {
            Ember.$('.managementModal').modal("hide");
            this.controller.rollBackAndTransition();
        },
    }
});
