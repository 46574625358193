import Ember from 'ember';

export default Ember.Service.extend({
	// TODO: translate this
	schema: {
		fields: {
			car_min: {
				rules: [
					{
						type: 'gte[0]',
						prompt: 'Values greater then 20 are accepted only.'
					},
					{
						type: 'lte[300]',
						prompt: 'Values less then 200 are accepted only'
					}
				]
			},
			car_offset: {
				rules: [
					{
						type: 'gte[-50]',
						prompt: "You must enter a value between -50 and 50."
					},
					{
						type: 'lte[50]',
						prompt: "You must enter a value between -50 and 50."
					}
				]
			},
			car_max: {
				rules: [
					{
						type: 'gte[0]',
						prompt: 'Values greater then 20 are accepted only.'
					},
					{
						type: 'lte[300]',
						prompt: 'Values less then 200 are accepted only'
					}
				]
			},

			minibus_min: {
				rules: [
					{
						type: 'gte[0]',
						prompt: 'Values greater then 20 are accepted only.'
					},
					{
						type: 'lte[300]',
						prompt: 'Values less then 200 are accepted only'
					}
				]
			},
			minibus_offset: {
				rules: [
					{
						type: 'gte[-50]',
						prompt: "You must enter a value between -50 and 50."
					},
					{
						type: 'lte[50]',
						prompt: "You must enter a value between -50 and 50."
					}
				]
			},
			minibus_max: {
				rules: [
					{
						type: 'gte[0]',
						prompt: 'Values greater then 20 are accepted only.'
					},
					{
						type: 'lte[300]',
						prompt: 'Values less then 200 are accepted only'
					}
				]
			},


			bus_min: {
				rules: [
					{
						type: 'gte[0]',
						prompt: 'Values greater then 20 are accepted only.'
					},
					{
						type: 'lte[300]',
						prompt: 'Values less then 200 are accepted only'
					}
				]
			},
			bus_offset: {
				rules: [
					{
						type: 'gte[-50]',
						prompt: "You must enter a value between -50 and 50."
					},
					{
						type: 'lte[50]',
						prompt: "You must enter a value between -50 and 50."
					}
				]
			},
			bus_max: {
				rules: [
					{
						type: 'gte[0]',
						prompt: 'Values greater then 20 are accepted only.'
					},
					{
						type: 'lte[300]',
						prompt: 'Values less then 200 are accepted only'
					}
				]
			},


			truck_min: {
				rules: [
					{
						type: 'gte[0]',
						prompt: 'Values greater then 20 are accepted only.'
					},
					{
						type: 'lte[300]',
						prompt: 'Values less then 200 are accepted only'
					}
				]
			},
			truck_offset: {
				rules: [
					{
						type: 'gte[-50]',
						prompt: "You must enter a value between -50 and 50."
					},
					{
						type: 'lte[50]',
						prompt: "You must enter a value between -50 and 50."
					}
				]
			},
			truck_max: {
				rules: [
					{
						type: 'gte[0]',
						prompt: 'Values greater then 20 are accepted only.'
					},
					{
						type: 'lte[300]',
						prompt: 'Values less then 200 are accepted only'
					}
				]
			},


			tractor_min: {
				rules: [
					{
						type: 'gte[0]',
						prompt: 'Values greater then 20 are accepted only.'
					},
					{
						type: 'lte[300]',
						prompt: 'Values less then 200 are accepted only'
					}
				]
			},
			tractor_offset: {
				rules: [
					{
						type: 'gte[-50]',
						prompt: "You must enter a value between -50 and 50."
					},
					{
						type: 'lte[50]',
						prompt: "You must enter a value between -50 and 50."
					}
				]
			},
			tractor_max: {
				rules: [
					{
						type: 'gte[0]',
						prompt: 'Values greater then 20 are accepted only.'
					},
					{
						type: 'lte[300]',
						prompt: 'Values less then 200 are accepted only'
					}
				]
			},

			harvester_min: {
				rules: [
					{
						type: 'gte[0]',
						prompt: 'Values greater then 20 are accepted only.'
					},
					{
						type: 'lte[300]',
						prompt: 'Values less then 200 are accepted only'
					}
				]
			},
			harvester_offset: {
				rules: [
					{
						type: 'gte[-50]',
						prompt: "You must enter a value between -50 and 50."
					},
					{
						type: 'lte[50]',
						prompt: "You must enter a value between -50 and 50."
					}
				]
			},
			harvester_max: {
				rules: [
					{
						type: 'gte[0]',
						prompt: 'Values greater then 20 are accepted only.'
					},
					{
						type: 'lte[300]',
						prompt: 'Values less then 200 are accepted only'
					}
				]
			},

			construction_min: {
				rules: [
					{
						type: 'gte[0]',
						prompt: 'Values greater then 20 are accepted only.'
					},
					{
						type: 'lte[300]',
						prompt: 'Values less then 200 are accepted only'
					}
				]
			},
			construction_offset: {
				rules: [
					{
						type: 'gte[-50]',
						prompt: "You must enter a value between -50 and 50."
					},
					{
						type: 'lte[50]',
						prompt: "You must enter a value between -50 and 50."
					}
				]
			},
			construction_max: {
				rules: [
					{
						type: 'gte[0]',
						prompt: 'Values greater then 20 are accepted only.'
					},
					{
						type: 'lte[300]',
						prompt: 'Values less then 200 are accepted only'
					}
				]
			},


			trailer_min: {
				rules: [
					{
						type: 'gte[0]',
						prompt: 'Values greater then 20 are accepted only.'
					},
					{
						type: 'lte[300]',
						prompt: 'Values less then 200 are accepted only'
					}
				]
			},
			trailer_offset: {
				rules: [
					{
						type: 'gte[-50]',
						prompt: "You must enter a value between -50 and 50."
					},
					{
						type: 'lte[50]',
						prompt: "You must enter a value between -50 and 50."
					}
				]
			},
			trailer_max: {
				rules: [
					{
						type: 'gte[0]',
						prompt: 'Values greater then 20 are accepted only.'
					},
					{
						type: 'lte[300]',
						prompt: 'Values less then 200 are accepted only'
					}
				]
			},



			'semi-trailer_min': {
				rules: [
					{
						type: 'gte[0]',
						prompt: 'Values greater then 20 are accepted only.'
					},
					{
						type: 'lte[300]',
						prompt: 'Values less then 200 are accepted only'
					}
				]
			},
			'semi-trailer_offset': {
				rules: [
					{
						type: 'gte[-50]',
						prompt: "You must enter a value between -50 and 50."
					},
					{
						type: 'lte[50]',
						prompt: "You must enter a value between -50 and 50."
					}
				]
			},
			'semi-trailer_max': {
				rules: [
					{
						type: 'gte[0]',
						prompt: 'Values greater then 20 are accepted only.'
					},
					{
						type: 'lte[300]',
						prompt: 'Values less then 200 are accepted only'
					}
				]
			},

			utility_min: {
				rules: [
					{
						type: 'gte[0]',
						prompt: 'Values greater then 20 are accepted only.'
					},
					{
						type: 'lte[300]',
						prompt: 'Values less then 200 are accepted only'
					}
				]
			},
			utility_offset: {
				rules: [
					{
						type: 'gte[-50]',
						prompt: "You must enter a value between -50 and 50."
					},
					{
						type: 'lte[50]',
						prompt: "You must enter a value between -50 and 50."
					}
				]
			},
			utility_max: {
				rules: [
					{
						type: 'gte[0]',
						prompt: 'Values greater then 20 are accepted only.'
					},
					{
						type: 'lte[300]',
						prompt: 'Values less then 200 are accepted only'
					}
				]
			},


		}
	}
});
