import moment from 'moment';
import Ember from 'ember';

export default Ember.Helper.extend({
	compute(params) {
		this._super(...arguments);

		if (!params || params && params.length > 3) {
			throw new TypeError('moment-duration-format: Invalid Number of arguments, at most 3');
		}

		return moment.duration(params[0], params[1]).format(params[2]);
	}
});
