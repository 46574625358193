import Ember from 'ember';

const { service } = Ember.inject;

export default Ember.Component.extend({

    resolution: service('resolution'),
    sticky: service('sticky-table'),

    resolutionHasChanded: false,

    preparedRecords: Ember.computed('records', function () {
        let records = this.get('records')
        let hasCan = records.get('firstObject.hasCan')
        if (!hasCan) { return records }

        if (records.isEvery('consumptionCompose', 0)) {
            records = this.fallbackConsuption(records)
        }
        if (records.isEvery('consumptionDistance', 0)) {
            records = this.fallbackDistance(records)
        }

        return records
    }),

    fallbackConsuption(items) {
        if (!items || !items.length) { return items }
        return items.map(item => {
            item.set('consumptionCompose', item.get('fuelConsumption'))
            return item
        })
    },

    fallbackDistance(items) {
        if (!items || !items.length) { return items }
        return items.map(item => {
            item.set('distanceCompose', item.get('distance'))
            return item
        })
    },

    /** Sticky params */
    defautlStickyOptions: {
        scrollContainer: function ($table) {
            return $table.parents('.report-tables');
        }
    },

    fixSticky() {
        this.get('sticky').destroy();

        if (!this.get('resolution').isMobileResolutions()) {
            this.$('.content table').css('min-width', this.$('div.content').first().width() + 'px');
            this.$('.content table').css('width', '100%');
        } else {
            this.$('.content table').css({
                'min-width': 'auto',
                width: 'auto'
            });
        }

        this.get('sticky').correctTablesWidth(this.$('.content table'));
        this.get('sticky')
            .sticky(this.$('.content table').first(), this.get('defautlStickyOptions'));

        this.$('.content .header').css('width', this.$('.content table').first().width() + 'px');
    },
    /** End of sticky params */

    didInsertElement() {
        if (this.get('resolution').isMobileResolutions()) {
            this.get('sticky').correctTablesWidth(this.$('.content table'));
        } else {
            this.get('sticky').alignColumns(this.$('.content table').not(':eq(0)'), this.$('.content table').first());
        }

        this.$('.content .header').css('width', this.$('.content table').first().width() + 'px');

        this.get('sticky')
            .sticky(this.$('.content table').first(), this.get('defautlStickyOptions'));
    },

    didRender() {
        this.fixSticky();
    },

    willDestroy() {
        this.get('sticky').destroy();
    }
});
