import Ember from 'ember';

export default Ember.Component.extend({
    init() {
        // orderBy groupID array
        this._super(...arguments);
        this.set( "aGroups", this.get( "aGroups" ).sortBy( "groupID" ) );
    },
    
    actions: {
        colapse(groupID) {
            var item = Ember.$("div#" + groupID);
            item.next('ul').slideToggle('fast', function () {
                var i = item.find('i');
                if (i.hasClass('down')) {
                    i.removeClass('down').addClass('right');
                } else {
                    i.removeClass('right').addClass('down');
                }
            });
        },
        addGroup(groupID) {
            this.sendAction("addGroup", groupID);
        },
        editGroup(groupID) {
            this.sendAction("editGroup", groupID);
        },
        removeGroup(groupID) {
            this.sendAction("removeGroup", groupID);
        },
    }
});
