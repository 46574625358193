import Ember from 'ember';
import RSVP from 'rsvp';

export default Ember.Route.extend({
    model(params) {
        return RSVP.hash({
            vehicles: this.store.peekAll("vehicle"),
            groups: this.store.peekAll("group"),
            geofence: this.store.peekRecord('geofence', params.id)
        });
    }
});
