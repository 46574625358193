import DS from 'ember-data'
import Ember from 'ember'
import polyline from "npm:@mapbox/polyline"
import MF from 'ember-data-model-fragments'

export default DS.Model.extend({
    group: DS.belongsTo('group'),
    startDate: DS.attr('date'),
    name: DS.attr('string'),
    distance: DS.attr('number'),
    duration: DS.attr('number'),
    schedule: DS.attr('string'),
    polyline: DS.attr('string'),
    vehicles: DS.hasMany('vehicle'),
    waypoints: MF.fragmentArray('planned-route-waypoint'),

    distanceInKM: Ember.computed('distance', function() {
        return (this.get('distance') / 1000).toFixed(2)
    }),
    vehicleLicensePlates: Ember.computed('vehicles.@each.licensePlate', function() {
        return this.get('vehicles').mapBy('licensePlate').join(', ')
    }),
    decodedPolyline: Ember.computed('polyline', function() {
        let encoded = this.get('polyline')

        if (encoded == null || !encoded.length) {
            return []
        }

        return polyline.decode(encoded)
    })
})
