import Ember from 'ember';
import moment from 'moment';
/* global printHtmlElement */

const { service } = Ember.inject;
const { isArray } = Ember

export default Ember.Controller.extend({
	reportsFields: service('reports/report-fields'),
	dates: service('dates'),
	reportsInfo: service('reports/reports-info'),
	currentUser: service('current-user'),
	sticky: service('sticky-table'),
	resolution: service(),

	filterOn: false,

	modelReady: false,

	selectedDrivers: Ember.computed('modelReady', function () {
		if (!this.get('modelReady')) { return null; }

		let drivers = this.get('model.drivers').map(function (item) {
			return item.get('id');
		});

		// Add no drivers attached option.
		drivers.push(null);

		return drivers
	}),

	selectedVehicles: Ember.computed('modelReady', function () {
		if (!this.get('modelReady')) { return null; }

		return this.get('model.vehicles').map(function (item) {
			return item.get('id');
		});
	}),

	// We need this for external updates after the route has been loaded because the previous gets overridden by a piece of code furthe down and looses its computed properties
	// selectedVehiclesForDropdown: Ember.computed("selectedVehicles@each", function () {
	// 	Ember.Logger.log("Updating selected vehicles: ", this.get("selectedVehicles"));
	// 	return this.get("selectedVehicles");
	// }),

	menuOptions: Ember.computed('reportsInfo', function () {
		return this.get('reportsInfo').getMenuLinks();
	}),

	activeChildController: null,
	reportTitle: null,
	activeRoute: null,
	requiresStops: false,
	intervals: [
		Ember.Object.create({
			id: '1',
			text: '1 min',
			value: 60000
		}),
		Ember.Object.create({
			id: '2',
			text: '5 min',
			value: 300000
		}),
		Ember.Object.create({
			id: '3',
			text: '10 min',
			value: 600000
		}),
		Ember.Object.create({
			id: '4',
			text: '20 min',
			value: 1200000
		}),
		Ember.Object.create({
			id: '5',
			text: '30 min',
			value: 1800000
		}),
	],

	requiresIntervals: Ember.computed( "reportTitle", function ( ) {
		// Change period select input in the header for temperature reports
		return this.get( "reportTitle" ) === "TEMPERATURE";
	} ),

	readingInterval: null,

	currentReport: Ember.computed('reportTitle', function () {
		return this.get('reportsInfo').getReportDataByTitle(this.get('reportTitle'));
	}),

	startDate: null,
	endDate: null,
	fazStartDate: null,
	fazEndDate: null,

	selectedPeriod: null,

	periods: ['today', 'yesterday', 'currentWeek', 'lastWeek', 'currentMonth', 'lastMonth'],



	stationaryDuration: 10,
	lastStartDate: null,
	lastEndDate: null,

	lastInterval: {
		startDate: null,
		endDate: null,
		reportTitle: null,
		vehicles: null
	},

	calendarText: {
		days: ['Du', 'Lu', 'Ma', 'Mi', 'Jo', 'Vi', 'Sa'],
		//months: ['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie'],
		months: ['Ian', 'Feb', 'Mar', 'Apr', 'Mai', 'Iun', 'Iul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
		monthsShort: ['Ian', 'Feb', 'Mar', 'Apr', 'Mai', 'Iun', 'Iul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
		today: 'Azi',
		now: 'Acum',
		am: 'AM',
		pm: 'PM'
	},

	showSubscribeModal: false,

	isFaz: Ember.computed('reportTitle', function () {
		if (this.get('reportTitle') === 'FAZ') {
			return true;
		} else {
			return false;
		}
	}),

    /**
     * Reload current report route model.
     */
	reload(whatChanged, doReload) {
		var data = {
			vehicles: this.get('selectedVehicles'),
			drivers: this.get('selectedDrivers'),
			startDate: this.get('startDate').getTime(),
			endDate: this.get('endDate').getTime(),
			stationaryDuration: this.get('stationaryDuration')
		};

		if (this.get('reportTitle') === 'FAZ') {
			data.startDate = this.get('fazStartDate').getTime();
			data.endDate = this.get('fazEndDate').getTime();
		}
        /**
         * In some situations we want to check the previous selected dates.
         * i.e. Timepicker 'reloadData' action.
         */
		this.set('lastStartDate', this.get('startDate'));
		this.set('lastEndDate', this.get('endDate'));

		this.get('reportsFields')
			.setFields(Ember.$.extend(this.get('reportsFields').get('fields'), data, {
				reportTitle: this.get('reportTitle')
			}));

		if (doReload && this.get('activeChildController')) {
			this.get('activeChildController').reloadModel((whatChanged === 'drivers' || whatChanged === 'stationaryDuration'));
		}
	},
	setReport(reportTitle) {
		this.set('reportTitle', reportTitle);
	},

	setReportRoute(activeRoute) {
		this.set('activeRoute', activeRoute);
	},

	setActiveChildController(controller) {
		this.set('activeChildController', controller);
		let vehicleID = controller.get('vehicleID')
		let start = controller.get('start')
		let end = controller.get('end')
		let isFaz = controller.get('isFaz')

		if (!vehicleID) { return false }

		this.setSelectedVehicles([controller.get('vehicleID')])

		if (isFaz) {
			this.set('fazStartDate', new Date(Number(start)))
			this.set('fazEndDate', new Date(Number(end)))
		} else {
			this.set('startDate', new Date(Number(start)))
			this.set('endDate', new Date(Number(end)))
		}
	},

	/**
	 * Set selected vehicles.
	 * @param {array} selectedVehicles - array of vehicles ids
	 */
	setSelectedVehicles(selectedVehicles) {
		if (!isArray(selectedVehicles)) { return false }
		this.set('selectedVehicles', selectedVehicles)
	},

	actions: {
		toggleFilter() {
			this.toggleProperty('filterOn')
		},

		selectPeriod(period) {
			if (this.get('isFaz')) { return false; }

			let start = moment().startOf('day');
			let end = moment();

			switch (period) {
				case 'currentWeek':
					start = moment().startOf('week');
					break;
				case 'currentMonth':
					start = moment().startOf('month');
					break;
				case 'yesterday':
					start = moment().add(-1, 'days').startOf('day');
					end = moment().add(-1, 'days').endOf('day');
					break;
				case 'lastWeek':
					start = moment().startOf('week').add(-7, 'days').startOf('day');
					end = moment().startOf('week').add(-1, 'days').endOf('day');
					break;
				case 'lastMonth':
					start = moment().add(-1, 'months').startOf('month');
					end = moment().add(-1, 'months').endOf('month');
					break;
			}

			this.set('startDate', start.toDate());
			this.set('endDate', end.toDate());

			this.actions.reloadData.call(this, { initialization: false });
		},

		stationaryDurationChange(stationaryDuration) {
			this.set('stationaryDuration', Number(stationaryDuration))
			this.reload('stationaryDuration', true)
		},

		updateVehicle(value) {
			this.setSelectedVehicles(value.split(','))
			this.reload('vehicles', true);
		},
		updateDriver(selectedDrivers) {
			this.set('selectedDrivers', selectedDrivers);
			this.reload('drivers', true);
		},

		setFazStartDate(formattedDate, date) {
			let
				lastDayInMonth = this.get('dates').getLastDayInMonth(date); // moment instance
			this.set('fazEndDate', moment(lastDayInMonth.toDate().setHours(23, 59, 59, 999)).toDate());
			this.reload('dates', true);
		},

        /**
         * When we use timepicker, hide event is triggerd twice.
         * In order to avoid duplicate request we do the bellow check.
         */
		reloadData(datepicker) {
			if (datepicker.initialization) { return false; }
			if (this.get('lastStartDate') === this.get('startDate') && this.get('lastEndDate') === this.get('endDate')) {
				return false;
			}
			if (this.get('startDate') > this.get('endDate')) {
				return false;
			}

			this.reload('dates', true);
		},

		print(reportTitle) {
			this.get('sticky').destroy()
			var
				PHE = printHtmlElement,
				tables = Ember.$('.report-tables');

			if (!reportTitle) { return false; }

			PHE.printHtml(tables.html(), {
				styles: 'th {color: #333;}',
				pageTitle: reportTitle
			});

			tables = null;
		},

		toggleModal() {
			this.toggleProperty('showSubscribeModal');
		},
		requiredBreaksChange ( interval ) {
			this.send( 'setTemperatureReadingsInterval', interval );
		},
	},

	init() {
		this._super(...arguments);
		// Set the calendar default dates.
		var today = new Date();
		this.set('startDate', moment().add(-1, 'days').startOf('day').toDate());
		this.set('endDate', today);
		this.set('fazStartDate', moment().startOf('month').toDate());
		this.set('fazEndDate', today);
	}
});
