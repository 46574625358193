/**
 * https://github.com/yapplabs/ember-modal-dialog
 */
import Ember from 'ember';
/* global validator */

export default Ember.Component.extend({
    name: 'inbox',
    classNames: ['small inbox'],
    reportsInfo: Ember.inject.service('reports/reports-info'),
    api: Ember.inject.service('api'),

    selectedEmails: [],
    selectedFrequency: null,
    selectedDayOfMonth: null,
    selectedDayOfWeek: null,
    selectedReport: null,

    error: null,

    showWeekDays: Ember.computed('selectedFrequency', function () {
        if (this.get('selectedFrequency') === 'weekly') { return true; }
        else { return false; }
    }),

    showMonthDays: Ember.computed('selectedFrequency', function () {
        if (this.get('selectedFrequency') === 'monthly') { return true; }
        else { return false; }
    }),

    reports: Ember.computed('reportsInfo', function () {
        return this.get('reportsInfo').getMenuLinks();
    }),

    selected: Ember.computed('selectedFrequency', 'selectedReport', 'selectedDayOfWeek', 'selectedDayOfMonth', 'selectedEmails.[]', function () {
        return {
            emails: this.get('selectedEmails'),
            dayOfMonth: this.get('selectedDayOfMonth'),
            dayOfWeek: this.get('selectedDayOfWeek'),
            frequency: this.get('selectedFrequency'),
            report: this.get('selectedReport')
        };
    }),

    didInsertElement() {
        let _this = this;

        Ember.$('.ui.modal.subscribe').on('click', '.emails-selected .delete', function (e) {
            e.preventDefault();
            Ember.$(this).parents('.ui.label').remove();
            _this.get('selected.emails').removeObject(Ember.$(this).prev().text());
        });
    },

    actions: {
        onApprove: function (selected) {
            Ember.Logger.debug('Subscribe Modal - Approved');

            let _this = this;

            return this.get('api')
                .request('reports/subscribe', {
                    dataType: "json",
                    data: selected
                })
                .then(() => {
                    _this.get('toggleModal')();
                })
                .catch(function (err) {
                    Ember.Logger.error(err);
                    _this.set('error', err.message);
                });

        },

        onDeny: function () {
            Ember.Logger.debug('Deny');
            this.get('toggleModal')();
        },

        removeError() {
            this.set('error', null);
        },

        setDayOfMonth(value) {
            this.set('selectedDayOfMonth', value);
        },

        setEmails(target) {
            let
                email = target.value,
                commas = (email.match(/,/g) || []).length;

            email = email.substr(0, email.indexOf(','));

            Ember.$(target).parents('.field').removeClass('error');

            if (commas !== 1) { return false; }

            if (validator.isEmail(email)) {
                Ember.$(target).next('.field').append(
                    '<div class="ui label" data-email="' + email + '"> ' +
                    '<span>' + email + '</span>' +
                    '<i class="delete icon"></i>' +
                    '</div>'
                );
                Ember.$(target).val('');
                this.get('selectedEmails').pushObject(email);
            } else {
                Ember.$(target).parents('.field').addClass('error');
            }

        }
    }
});
