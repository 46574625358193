import Base from './base-grouped'
import PropsInit from '../../mixins/reports/controller-props-initialization'
/* global Ember */

export default Base.extend(PropsInit, {
    excluded: ["driverID", "vehicleID", "licensePlate", 'driver'],
    reportTitle: 'ROUTE',
    sortProperties: ['startTime:asc'],
    groupByKey: 'vehicleID',

    queryParams: {vehicleID: 'vehicleID', start: 'start', end: 'end'},
    //['vehicleID', 'start', 'end'],
    vehicleID: null,
    start: null,
    end: null,

    purposes: Ember.computed.readOnly('reportsController.model.purposes')
})
