import Base from './base';
import Ember from 'ember';

export default Base.extend({
    loading: false,

    rollBackAndTransition() {
        this.transitionToRoute(this.get('parentRoute'));
    },

    findVehicleByLicensePlate ( licensePlate ) {
        let found = null;

        this.get( "model.vehicles" ).forEach( function ( vehicle ) {
            if ( vehicle.get( "licensePlate" ).toLowerCase( ).replace( /-|\s/g, "" ) === licensePlate ) {
                found = vehicle;
            }
        } );

        return found;
    },

    timetableHasVehicles ( timetableID ) {
        let hasVehicles = false;

        this.get( "model.vehicles" ).forEach( function ( vehicle ) {
            if ( vehicle.get( "timetableID" ) === timetableID ) {
                hasVehicles = true;
            }
        } );

        return hasVehicles;
    },

    updateVehiclesTimetable ( licensePlates, timetableID, promises ) {
        var _this       = this;
        
        licensePlates.forEach( function ( licensePlate ) {
            let vehicle = _this.findVehicleByLicensePlate( licensePlate );
            if ( vehicle ) {
                vehicle.set( "timetableID", timetableID );
                var promise = vehicle.save( ).then( function ( ) {
                    _this.incrementProperty("updatedVehicles");
                }, function ( ) {
                    _this.incrementProperty("updatedVehicles");
                });

                if ( promises != null ) {
                    promises.push( promise );
                }
            } else {
                _this.decrementProperty( "expectedVehicleUpdates" );
            }
        } );
    },

    clearUnassignedTimetables ( onReady ) {
        var _this       = this;
        var promises    = [ ];
        this.get( "model.timetables" ).forEach( function ( timetable ) {
            if ( ! _this.timetableHasVehicles ( timetable.get( "id" ) ) ) {
                promises.push( timetable.destroyRecord( ) );
            }
        } );

        Ember.RSVP.all( promises ).then(function ( ) {
            if ( onReady ) {
                onReady( );
            }
        });
    },

    /**
     *  Use this in order to wait for all the timetable vehicles to complete before starting to clear unassigned timetables.
     */
    expectedVehicleUpdates: 0,

    /**
     * Because the process itself of adding save() promises for vehicles is asynchronous, 
     * we need to make sure that we only call Ember.RSVP.all() when all expected promises are set,
     * so all() will not miss out promises that haven't been set yet
     */
    updatedVehicles: 0,

    actions: {
        /**
         * Simulate a timetable import in order to let the user know what data his new file will affect
         */
        setFile: function ( timetables, onReady ) {
            var _this = this;

            var existingTimetables      = [ ];
            var timetablesForRemoval    = [ ];
            var affectedVehicles        = [ ];

            this.get( "model.timetables" ).forEach( function ( timetable ) {
                var existingTimetable = {
                    id:         timetable.get( "id" ),
                    name:       timetable.get( "name" ),
                    createdAt:  timetable.get( "createdAt" ),
                    vehicles:   [ ]
                };

                _this.get("model.vehicles").forEach( function ( vehicle ) {
                    if ( vehicle.get("timetableID") === existingTimetable.id ) {
                        existingTimetable.vehicles.push( vehicle.get( "licensePlate" ) );
                    }
                } );

                existingTimetables.push( existingTimetable );
            } );

            timetables.forEach( function ( timetable ) {
                timetable.vehicles.forEach( function ( licensePlate ) {
                    var vehicle = _this.findVehicleByLicensePlate ( licensePlate );
                    if ( vehicle ) {
                        existingTimetables.forEach ( function ( existingTimetable ) {
                            var vehicleIndex = existingTimetable.vehicles.indexOf( vehicle.get( "licensePlate" ) );
                            if ( vehicleIndex > -1 ) {
                                existingTimetable.vehicles.splice( vehicleIndex, 1 );
                                affectedVehicles.push ({
                                    id:             vehicle.get("id"),
                                    licensePlate:   vehicle.get("licensePlate"),
                                    name:           vehicle.get("make") + " " + vehicle.get("model") + " " + vehicle.get("year"),
                                    moveFrom:       existingTimetable.name,
                                    moveTo:         timetable.name
                                });
                            }
                        } );
                    }
                } );
            } );

            existingTimetables.forEach( function ( existingTimetable ) {
                if ( ! existingTimetable.vehicles.length ) {
                    timetablesForRemoval.push( { name: existingTimetable.name } );
                }
            } );

            if ( onReady ) {
                onReady( {
                    timetablesForRemoval:   timetablesForRemoval,
                    affectedVehicles:       affectedVehicles
                } );
            }
        },

        /**
         * Confirm import
         */
        confirm: function ( timetables ) {
            var _this = this;
            Ember.Logger.log( "Received timetables data: ", timetables );

            var promises = [ ];
            var expectedPromises = 0;
            
            this.set( "loading", true );

            timetables.forEach( function ( timetable ) {
                expectedPromises += timetable.vehicles.length;
            });

            this.set("expectedVehicleUpdates", expectedPromises);

            timetables.forEach( function ( timetable ) {
                var newTimetable = _this.get( "store" ).createRecord( "timetable", {
                    groupID:    timetable.groupID,
                    companyID:  timetable.companyID,
                    name:       timetable.name,
                    schedule:   timetable.schedule,
                    timezone:   timetable.timezone
                } );

                newTimetable.save( ).then( function ( response ) {
                    _this.updateVehiclesTimetable( timetable.vehicles, response.get( "id" ), promises );
                });
            });
            
            // Wait for all the promises to be set before setting the finally() after all(), otherwise some async promises may be missed out
            var waitForPromises = function ( ) {
                if ( promises.length == expectedPromises ) {
                    Ember.RSVP.all( promises ).finally( function ( ) {
                        _this.clearUnassignedTimetables( function ( ) {
                            _this.set("loading", false);
                            _this.rollBackAndTransition( )
                        } );
                    });
                } else {
                    Ember.run.later( this, waitForPromises, 250);
                }
            };

            Ember.run.later( this, waitForPromises, 250);
        }
    }
});
