import Ember from 'ember';
import Base from './base';
import moment from 'moment'

export default Base.extend({
    i18n: Ember.inject.service(),
    queryParams: {
        start: {
            refreshModel: true
        },
        end: {
            refreshModel: true
        },
        vehicleID: {
            refreshModel: true
        }
    },
    model() {
        return Ember.RSVP.hash({
            tHeaders: [
                // { key: 'driver', value: this.get('i18n').t('reports.headerFields.driver'), align: 'left' },
                { key: 'startTime', value: this.get('i18n').t('reports.headerFields.startTime'), align: 'left' },
                { key: 'startLocation', value: this.get('i18n').t('reports.headerFields.startLocation'), align: 'left' },
                { key: 'endTime', value: this.get('i18n').t('reports.headerFields.endTime'), align: 'left' },
                { key: 'endLocation', value: this.get('i18n').t('reports.headerFields.endLocation'), align: 'left' },
                { key: 'canOdometer', value: this.get('i18n').t('reports.headerFields.canOdometer'), align: 'left' },
                // { key: 'distance', value: this.get('i18n').t('reports.headerFields.distance'), align: 'left' },
				// { key: 'canDistance', value: this.get('i18n').t('reports.headerFields.canDistance'), align: 'left' },
				{ key: 'distanceCompose', value: this.get('i18n').t('reports.headerFields.distanceCompose'), align: 'left' },
                { key: 'duration', value: this.get('i18n').t('reports.headerFields.duration'), align: 'left' },
				{ key: 'consumptionCompose', value: this.get('i18n').t('reports.headerFields.consumptionCompose'), align: 'left' },
                // { key: 'fuelConsumption', value: this.get('i18n').t('reports.headerFields.specific.route.fuelConsumption'), align: 'left' },
				{ key: 'maxSpeed', value: this.get('i18n').t('reports.headerFields.maxSpeed'), align: 'left' },
				{ key: 'implement', value: this.get('i18n').t('reports.headerFields.specific.route.implement'), align: 'left' },
                // { key: 'canFuelConsumption', value: this.get('i18n').t('reports.headerFields.canFuelConsumption'), align: 'left' },
                { key: 'goToMap', value: this.get('i18n').t('reports.headerFields.map') }
                // { key: 'printDetails', value: this.get('i18n').t('reports.headerFields.printDetails'), align: 'left', class: 'printDetails' }
            ],
            tData: []
        });
    },
    
    deactivate () {
        let reportsController   = this.controllerFor('reports');
        let controller          = this.controllerFor('reports.route');

        let start   = moment().subtract( 1, "days" ).startOf( "day" ).toDate();
        let end     = moment().toDate();

        reportsController.set('lastStartDate', null);
        reportsController.set('lastEndDate', null);
        reportsController.set('startDate', start);
        reportsController.set('endDate',   end);

        reportsController.set("selectedVehicles", reportsController.get( 'model.vehicles' ).map( function ( item ) {
			return item.get( 'id' );
        }));

        reportsController.notifyPropertyChange("selectedVehicles");
        reportsController.notifyPropertyChange("selectedVehiclesForDropdown");
        
        controller.set('start', null);
        controller.set('end',   null);

        controller.set('startDate', start);
        controller.set('endDate',   end);
        
        controller.set('vehicleID', null);
    },

    // activate () {
    //     var controller = this.controllerFor('reports.route')
        
    // }
});
