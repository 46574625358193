import Ember from 'ember'
import UiModal from 'semantic-ui-ember/components/ui-modal'
import config from '../../../config/environment';

const { service } = Ember.inject;
export default UiModal.extend({
    session: service(),

    name: 'modalFuelingImport',
    elementId: 'modalFuelingImport',
    classNames: ['modalFuelingImport small'],

    file: null,
    response: null,
    uploadProgress: null,
    completedRequest: false,

    fileName: Ember.computed('file', function () {
        let file = this.get('file')
        if (!file) { return null }
        return file.name
    }),

    created: Ember.computed('response.created', function () {
        return this.get('response.created')
    }),
    parsed: Ember.computed('response.parsed', function () {
        return this.get('response.parsed')
    }),
    notCreated: Ember.computed('created', 'parsed', function () {
        return this.get("parsed") - this.get("created")
    }),

    vehiclesToRefresh: Ember.computed("response.refuels", "filters", function () {
        if (!this.get("response.refuels") || this.get("filters")) { return false }
        let filters = this.get("filters")
        let vehiclesToRefresh = this.get("response.refuels").filter(refuel => {
            if ((refuel.timestamp > filters.startDate) &&
                (refuel.timestamp < filters.endDate) &&
                (filters.vehicles.indexOf(refuel.vehicleID) != -1)) {
                return true
            }
            return false
        })
        return vehiclesToRefresh.mapBy("vehicleID")
    }),

    init() {
        this._super(...arguments)
        Ember.$('.ui.dimmer.modals #modalFuelingImport').remove();
    },

    didInsertElement() {
        Ember.$('#modalFuelingImport')
            .modal({
                duration: 200,
                onHidden: () => {
                    this.refreshVehicles()
                    this.reset();
                },
            })
            .modal('is active');
    },

    reset() {
        this.set('err', null)
        this.set("file", null)
        this.set("response", null)
        this.set("uploadProgress", null)
        this.set("completedRequest", false)
        this.set('ImportWasClicked', false)
        this.set('newFileSelected', false)
        // Ember.$("form#formFuelingImport").form("clear") // TODO: Reset the hidden input type file on reset.
    },

    refreshVehicles() {
        let vehiclesToRefresh = this.get("vehiclesToRefresh")
        if (vehiclesToRefresh.length > 0) {
            this.get("refreshVehiclesTables")(vehiclesToRefresh)
        }
    },

    saveFile() {
        let file = this.get("file")
        if (file) {
            let url = config.api.host + '/' + config.api.namespace + '/refuels/import';
            const idToken = this.get('session.data.authenticated.idToken');

            let fd = new FormData();
            fd.append('refuel_file', file);
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', `Bearer ${idToken}`);
            xhr.upload.onprogress = (pe) => {
                if (pe.lengthComputable) {
                    let percentComplete = parseInt((pe.loaded / pe.total) * 100);
                    this.set("uploadProgress", percentComplete)
                }
            }

            xhr.onloadend = (pe) => {
                try {
                    let response = JSON.parse(pe.target.responseText)
                    if (response.error) {
                        this.set("err", true)
                    }
                    else {
                        this.set("completedRequest", true)
                        this.set("response", response)
                    }
                }
                catch (e) {
                    this.set("err", true)
                }
            }

            xhr.send(fd);
        }
    },

    actions: {
        selectFile() {
            this.set('ImportWasClicked', false)
            //trigger the select file input
            Ember.$("input#fuelFile[type='file']").click()
        },
        loadedFile(event) {
            let file = event.srcElement.files.length ? event.srcElement.files[0] : null;
            if (file) {
                this.set("newFileSelected", true)
                this.set("file", file)
            }
            else {
                this.set("newFileSelected", false)
                this.set("file", null)
            }
        },

        yes() {
            this.set('ImportWasClicked', true)
            this.set('completedRequest', false)
            this.set('newFileSelected', false)

            this.saveFile();
        },
        no() {
            this.execute('hide');
        }
    }
});