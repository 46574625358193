import Ember from 'ember';
/* global L */

export default Ember.Service.extend({
    /**
     * Definition of icon types. The icon types must match the marker types.
     */
    icons: {
        default: function (options) {
            if (Ember.isEmpty(options.color)) {
                options.color = "blue";
            }

            let size = options.color.indexOf("big") ? 40 : 60;
            
            return L.divIcon({
                className: "default-marker " + options.color,
                iconSize: L.point(size, size),
                iconAnchor: [size / 2, size],
                popupAnchor: [0, -40]
            });
        },

        big: function (/*options*/) {
            return L.divIcon({
                className: "default-marker big",
                iconSize: [60, 60],   // size of the icon
                iconAnchor: [30, 60],   // point of the icon which will correspond to marker's location
                popupAnchor: [0, -60]    // point from which the popup should open relative to the iconAnchor
            });
        },

        info: function (options) {
            if (Ember.isEmpty(options.color)) {
                options.color = "default";
            }

            return L.divIcon({
                className: "info-marker " + options.color,
                html: "<span>" + (options.label ? options.label : "") + "</span>",
                iconSize: L.point(180, 50),
                iconAnchor: [90, 48],
            });
        },

        stop: function (options) {
            return L.divIcon({
                className: "stop-marker",
                iconSize: L.point(16, 16),
                iconAnchor: [8, 8],
                allOptins: options
            });
        },

        routeStop: function (options) {
            return L.divIcon({
                className: "info-marker stop",
                html: "<span>" + (options.label ? options.label.substring(0, 20) : "") + "</span>",
                iconSize: L.point(180, 60),
                iconAnchor: [90, 51],
                allOptins: options
            });
        },

        bearing: function (options) {
            return L.divIcon({
                html: "<i style=\"-webkit-transform: rotate(" + options.angle + "deg); -moz-transform:rotate(" + options.angle + "deg);\" class=\"bearing-icon\"></i>",
                className: "bearing-marker " + (options.class ? options.class : ""),
                iconAnchor: [5, 5],
                iconSize: L.point(10, 10)
            });
        },
        direction: function (options) {
            return L.divIcon({
                html: "<i style=\"-webkit-transform: rotate(" + options.angle + "deg); -moz-transform:rotate(" + options.angle + "deg);\" class=\"direction-icon\"></i>",
                className: "direction-marker " + (options.class ? options.class : ""),
                iconAnchor: [20, 20],
                iconSize: L.point(40, 40)
            });
        }
    },

    getIcon: function (type, options) {
        var icon = this.get("icons." + type);
        if (icon != null) {
            return icon(options);
        } else {
            throw new Error("Marker type \"" + type + "\" has no icon definition.");
        }
    },

    _makeMarker: function (type, options) {
        var _this = this;
        var marker = L.marker(options.location, Object.assign({}, options, {
            icon:       _this.getIcon(type, options),
            content:    options.content,
            entityID:   options.entityID,
            owner:      "tracknamic",
            entityType: options.entityType
        }));

        if (!Ember.isEmpty(options.content)) {
            marker.bindPopup(options.content);
        }

        return marker
    },

    makeMarker: function (type, options) {
        if (Ember.isEmpty(options.location)) {
            throw new Error("The location is required.");
        } else {
            options.location = L.latLng(options.location[1], options.location[0]);
            return this._makeMarker(type, options);
        }
    },

});
