export default {
	title: {
		alerts: 'Alerts',
		consumption: 'Consumption',
		faz: 'Daily activity',
		'fuel-level': 'Fuel level',
		notifications: 'Notifications',
		route: 'Routes',
		stationary: 'Stops',
		poi: 'Field Activity',
		temperature: 'Temperature',
		'temperature-range': 'TK Hours',
		'poi-temperature': 'Field TK'
	},
	ro: 'Reports',
	menu: {
		alerts: 'Alerts',
		faz: 'Daily activity',
		'fuel-level': 'Fuel level',
		'detailed-stops': 'Detailed Stops ++',
		notifications: 'Notifications',
		route: 'Routes',
		stationary: 'Stops',
		poi: 'Field Activity',
		temperature: 'Temperature',
		'temperature-range': 'TK Hours',
		'poi-temperature': 'Field TK'
	},
	downloads: 'Download',
	settings: {
		specific: {
			route: {
				duration: 'Duration',
				schedule: 'Routes in schedule',
				noschedule: 'Routes 0-24'
			},
			shipping: {
				schedule: 'Shippings in schedule',
				noschedule: 'Shippings 0-24'
			}
		}
	},
	subscribe: {
		title: 'Subscribe',
		fields: {
			label: {
				email: 'Insert email addresses separated by comma.',
				frequency: 'Choose notification frequency:',
				weekDays: 'Choose report day:'
			},
			placeholder: {
				email: 'Email address list:',
				frequency: 'Select frequency:',
				report: 'Choose report:',
				dayOfWeek: 'Choose weekday:'
			}
		},
		everyDay: 'Once a day',
		everyWeek: 'Once a week',
		everyMonth: 'Once a month',
		notifyDate: 'Choose report day:'
	},
	headerFields: {
		acc: 'Acceleration score',
		areaPerHour: 'Area/hour (ha)',
		areaTotal: 'Area (ha)',
		areaTotalLoad50: 'Area with engine load > 50% (ha)',
		areaTotalLoad50Popup: 'Area with increased engine load',
		averageSpeed: 'Average Speed (km/h)',
		consumptionCompose: 'Consumption (liters)',
		canDistance: 'Distance (CAN)',
		canFuelConsumption: 'CAN Consumption (liters)',
		canFuelConsumptionDistance: 'Consumption / 100 (CAN)',
		canFuelLevelEndOfDay: 'Fuel Level CAN (liters)',
		canOdometer: 'End Odometer',
		consume: 'Consumption (liters)',
		createdAt: 'Created at',
		date: 'Date',
		desacc: 'Decceleration score',
		description: 'Description',
		distance: 'Distance (km)',
		distanceCompose: 'Distance (km)',
		driver: 'Driver',
		duration: 'Duration',
		endLocation: 'End Location',
		endTime: 'End time',
		freezerOnDuration: 'Refrigeration unit run time',
		fuelConsumption: 'Computed Consumption (liters)',
		onlyFuelConsumption: 'Consumption (liters)',
		fuelConsumptionPerHa: 'Consumption/ha (liters)',
		fuelConsumptionPerHour: 'Consumption/hour (liters)',
		fuelConsumptionDistance: 'Consumption / 100',
		fuelLevelDiff: 'Quantity (liters)',
		fuelLevelDiffManual: 'Quantity entered (liters)',
		fuelLevelDiffLiters: 'Quantity (liters)',
		fuelSensor: 'Fuel tank',
		fuelEvent: 'Type',
		generatedAt: 'Generated at',
		invoiceNumber: 'Invoice number',
		licensePlate: 'License Plate',
		location: 'Address',
		map: 'Map',
		maxSpeed: 'Max Speed (km/h)',
		persistent: 'Persistent',
		poiCount: 'Fields count',
		poiStationaryDoorsOpenedDuration: 'Stationary in field with open doors',
		poiStationaryDuration: 'Stationary in field duration',
		price: 'Price',
		printDetails: 'Details',
		sensorFuelConsumption: 'Fuel sensor consumption (liters)',
		speed: 'Speed score',
		startLocation: 'Start Locations',
		startTime: 'Start Time',
		stationaries: 'Stops with engine on',
		status: 'Status',
		temperature1: 'bellow 4°C',
		temperature2: 'between -4°C and 0°C',
		temperature3: 'betweem 0°C and 4°C',
		temperature4: 'above 4°C',
		updatedAt: 'Updated at',
		virage: 'Turn score',
		specific: {
			route: {
				fuelConsumption: 'Computed Consumption',
				purpose: 'Purpose',
				implement: "Implement", // todo translate
				selectPurpose: 'Select'
			},
			station: {
				startTime: 'Start Time',
				endTime: 'End Time',
				duration: 'Duration'
			},
			stationary: {
				startTime: 'Start Time',
				endTime: 'End Time',
				duration: 'Duration'
			},
			poi: {
				geofences: 'Fields'
			}
		}
	},
	fuelLevel: {
		eventType: {
			refuel: 'Refuel',
			consumption: 'Consumption'
		},
		import: {
			title: 'Import refuels',
			buttonText: 'IMPORT REFUELS',
			imported: '{{value}} refuels were imported',
			notImported: "{{value}} refuels could not be imported",
			allImported: 'All refuels have been imported ({{value}})',
		},
		uploadFile: "Upload file"
	},
	table: {
		expand: "Expand table",
		collapse: "Collapse table"
	},
	temperature: {
		temperatureTable: "Temperature table"
	}
}
