/**
 * Handles single data row from reports table.
 * Exclude some columns and prepare data for output.
 */
import Ember from 'ember'
import moment from 'moment'

const { service } = Ember.inject

export default Ember.Service.extend({
	Dates: service('dates'),
	i18n: service(),
	reportsInfo: service('reports/reports-info'),

	removeExcludedColumns(row, excluded) {
		if (!row || (!excluded || !excluded.length)) { return false }

		for (let i in row) {
			if (excluded.indexOf(i) > -1) {
				delete row[i]
			}
		}
	},

	prepareValue(key, value) {
		var
			dateFields = this.get('reportsInfo.dateFields'),
			timestampFields = this.get('reportsInfo.timestampFields'),
			timeDurationFields = this.get('reportsInfo.timeDurationFields'),
			floatFields = this.get('reportsInfo.floatFields'),
			numericFields = this.get('reportsInfo.numericFields')

		// Remainder: This exception should not be here.
		if (!key) { return null }
		// Exceptions
		if (['temperature1', 'temperature2', 'temperature3', 'temperature4'].indexOf(key) > -1 && !value) {
			return ''
		}

		let prepared = value

		if (dateFields.indexOf(key) > -1) {
			prepared = this.get('Dates').timestampToDate(value, 'DD/MM/YYYY')
		} else if (timestampFields.indexOf(key) > -1) {
			prepared = this.get('Dates').timestampToDate(value, 'DD/MM/YYYY HH:mm:ss')
		} else if (timeDurationFields.indexOf(key) > -1) {
			let formattedDur = this.get('Dates').duration(moment("1900-01-01 00:00:00").add(value, 'seconds').valueOf(), moment("1900-01-01 00:00:00").valueOf())
			prepared = formattedDur
		} else if (floatFields.indexOf(key) > -1) {
			prepared = Number(value).toFixed(2)
		} else if (numericFields.indexOf(key) > -1 && !value) {
			prepared = 0
		}

		return prepared
	},

	_totalPopup(key, value, row) {
		let popup = ''
		if (key === 'consumptionCompose') {
			popup += 'Total CAN ' + Number(row.canFuelConsumption).toFixed(2)
			popup += '\nTotal Calculat ' + Number(row.fuelConsumption).toFixed(2)
		} else if (key === 'fuelConsumptionPerHour') {
			let duration = Number(row.duration) ? Number(row.duration) / 3600 : 0
			if (row.hasProbe) {
				popup += 'Total Sonda ' + (Number(row.sensorFuelConsumption) / duration).toFixed(2)
			}
			if (row.hasCan) {
				popup += 'Total CAN ' + (Number(row.canFuelConsumption) / duration).toFixed(2)
			}
			popup += '\nTotal Calculat ' + (Number(row.fuelConsumption) / duration).toFixed(2)
		} else if (key === 'distanceCompose') {
			if (row.canDistance) {
				popup += 'Total CAN ' + Number(row.canDistance).toFixed(2)
				popup += '\nTotal Calculat ' + Number(row.distance).toFixed(2)
			}
		}

		return popup
	},

	// _distancePopup(rowClone, hasProbe = false, hasCan = false) {
	_distancePopup(rowClone) {
		let popup = ''
		let i18n = this.get('i18n')
		popup += i18n.t('reports.headerFields.distance') + ' ' + rowClone.distance + '\n'
		popup += i18n.t('reports.headerFields.canDistance') + ' ' + rowClone.canDistance + '\n'
		return popup
	},

	parseComposed(rowClone, column) {
		let composed = this.get('reportsInfo').isComposeField(column.key)

		if (!composed) { return column }

		let popup = false
		if (column.key === 'distanceCompose') {
			if (rowClone.hasCan) {
				popup = this._distancePopup(rowClone)
			}
		}
		column.popup = popup
		return column
	},

	/**
     * return value and popup based on values and priorities
     * @param {*} sensorVal  (prioty 1) if sensor fail: show can. if can fail show calculated
     * @param {*} canVal (prioty 2) if can fail show calculated
     * @param {*} calculatedVal (prioty 3) if no sensor and no can show calculated
     * @param {*} vehicle 
     */
	fuelValuePopup(sensorVal, canVal, calculatedVal, vehicle) {
		sensorVal = Number(sensorVal)
		canVal = Number(canVal)
		calculatedVal = Number(calculatedVal)
		var finalVal = { value: 0, popup: '' }
		if (!vehicle) { return finalVal }
		let i18n = this.get('i18n')
		if (vehicle.hasProbe) { // priority 1
			let sensorFail = !sensorVal && calculatedVal
			finalVal.popup += i18n.t('reports.headerFields.sensorFuelConsumption') + ' ' + sensorVal.toFixed(2) + '\n'
			if (!sensorFail) {
				finalVal.value = sensorVal
			}
			else { // fail prioirty 1
				if (vehicle.hasCan) { // if has priority 2
					let canFail = !canVal && calculatedVal
					if (!canFail) {
						finalVal.value = canVal
						finalVal.popup += i18n.t('reports.headerFields.canFuelConsumption') + ' ' + canVal.toFixed(2) + '\n'
					}
					else { // fail prioirty 2
						finalVal.value = calculatedVal
						finalVal.popup += i18n.t('reports.headerFields.canFuelConsumption') + ' ' + canVal.toFixed(2) + '\n'
						finalVal.popup += i18n.t('reports.headerFields.fuelConsumption') + ' ' + calculatedVal.toFixed(2) + '\n'
					}
				} else {
					finalVal.value = calculatedVal
					finalVal.popup += i18n.t('reports.headerFields.fuelConsumption') + ' ' + calculatedVal.toFixed(2) + '\n'
				}
			}
		}
		if (vehicle.hasCan) { // if has priority 2
			let canFail = !canVal && calculatedVal
			finalVal.popup += i18n.t('reports.headerFields.canFuelConsumption') + ' ' + canVal.toFixed(2) + '\n'
			if (!canFail) {
				finalVal.value = canVal
			}
			else {// fail prioirty 2
				finalVal.value = calculatedVal
				finalVal.popup += i18n.t('reports.headerFields.fuelConsumption') + ' ' + calculatedVal.toFixed(2) + '\n'
			}
		}
		if (!vehicle.hasProbe && !vehicle.hasCan) { // if noCan and noProbe >> show calculated
			finalVal.value = calculatedVal
			finalVal.popup += i18n.t('reports.headerFields.fuelConsumption') + ' ' + calculatedVal.toFixed(2) + '\n'
		}

		return finalVal
	},

    /**
     * @param   {object}    row
     * @param   {array}     columns array containing the columns
     * @param   {array}     excluded    array with columns we want to exclude
     * @param   {boolean}   ignoreFirstColumn used in row containing totals
     */
	prepareRow(row, vehicle, columns, excluded, ignoreFirstColumn, isTotalRow = false) {
		var
			rowClone,
			preparedRow = {},
			reportsInfo = this.get('reportsInfo')

		if (!row || !columns) { return null }

		rowClone = JSON.parse(JSON.stringify(row))

		this.removeExcludedColumns(rowClone, excluded)

		columns.forEach((item, idx) => {
			item = this.parseComposed(rowClone, item)

			let completeRow
			let value = rowClone[item.key]
			if (item.key === 'consumptionCompose') {
				let sensorFuel = rowClone.sensorFuelConsumption
				let canFuel = rowClone.canFuelConsumption
				let fuel = rowClone.fuelConsumption
				let result = this.fuelValuePopup(sensorFuel, canFuel, fuel, rowClone)
				value = result.value
				item.popup = result.popup
			}
			if (item.key === 'areaPerHour') {
				if (row.get('area') && row.get('duration')) {
					let totalHA = row.get('area') / 10000
					let totalDuration = row.get('duration') / 3600
					value = totalHA / totalDuration
					item.popup = value.toFixed(2)
				}
				else {
					value = 0
					item.popup = '0'
				}
			}
			if (item.key === 'fuelConsumptionPerHour') {
				let duration = rowClone.duration
				if (!duration) {
					value = 0
				}
				else {
					let durationHours = duration / 3600
					let sensorFuel = rowClone.sensorFuelConsumption / durationHours
					let canFuel = rowClone.canFuelConsumption / durationHours
					let fuel = rowClone.fuelConsumption / durationHours

					let result = this.fuelValuePopup(sensorFuel, canFuel, fuel, rowClone)
					if (result.value) {
						value = result.value
					}
					else {
						value = 0
					}
					item.popup = result.popup
				}
			}
			if (item.key === 'area') {
				value = value / 10000
				item.popup = value.toFixed(2)
			}
			if (item.key === 'areaLoad50') {
				value = value / 10000
				item.popup = value.toFixed(2)
			}
			if (item.key === 'canFuelLevelEndOfDay') {
				// if fuel is percent, do not divide by 100
				if (vehicle && vehicle.get('status') && vehicle.get('status.can') && vehicle.get('status.can.fuel') && vehicle.get('status.can.fuel.level') && vehicle.get('status.can.fuel.level.1')) {
				}
				else {
					value = value / 100
				}
				item.popup = value.toFixed(2)
			}
			if (isTotalRow && !reportsInfo.hasTotal(item.key)) {
				value = ''
			} else {
				value = this.prepareValue(item.key, value, row)
				if (isTotalRow) {
					item.popup = this._totalPopup(item.key, value, row)
					if (item.key === 'area') {
						item.popup = (row.area / 10000).toFixed(2)
					}
					if (item.key === 'areaLoad50') {
						item.popup = (row.areaLoad50 / 10000).toFixed(2)
					}
					if (item.key === 'areaPerHour') {
						item.popup = row.areaPerHour.toFixed(2)
					}
				}
			}

			completeRow = {
				natural: rowClone[item.key],
				prepared: value,
				showPopup: (reportsInfo.get('floatFields').indexOf(item.key) > -1),
				popup: item.popup || false,
				align: item.align,
				class: item.class
			}

			if (idx === 0 && ignoreFirstColumn) {
				completeRow.prepared = rowClone[item.key]
			}

			preparedRow[item.key] = completeRow
		})

		return preparedRow
	}
})
