import Ember from 'ember'
import Base from './base-charts'
import PropsInit from '../../mixins/reports/controller-props-initialization'
import config from '../../config/environment';
/**
 * Remainder: Data to process should be sorted first.
 */

const { service } = Ember.inject
const { computed, Logger, RSVP, isArray } = Ember

export default Base.extend(PropsInit, {
	api: service(),
	dates: service(),
	arrays: service('utils/arrays'),
	session: service(),
	i18n: service(),

	labels: ['fuelLevel', 'fuelConsumption', 'refuel'],
	reportTitle: 'FUEL-LEVEL',
	sortProperties: null,

	loading: true,
	empty: false,

	reportData: [],

	limit: 10, // number of tables to show as default
	increment: 1,
	recordsLength: Ember.computed.alias('sortedReportData.length'),

	scrolledRecords: Ember.computed('sortedReportData', 'limit', function () {
		let limit = this.get('limit')
		let length = this.get('recordsLength')
		let sortedReportData = this.get('sortedReportData')
		return length > limit ? sortedReportData.slice(0, limit) : sortedReportData
	}),

	sortedReportData: computed.sort('reportData', 'sortDefinition'),
	sortDefinition: ['fuelEvents.length:desc'],

	vehicles: computed.map('reportsController.model.vehicles', function (vehicle) {
		vehicle.set('show', true)
		return vehicle
	}),

	fetch() {
		this.set('loading', true)

		RSVP.all([
			this.fetchFuelLevels(),
			this.fetchFuelEvents()
		])
			.then(([chartData, tableData]) => {
				this.processData(chartData, tableData)

				this.set('loading', false)
				this.set('empty', !this.get('reportData.length'))
			})
			.catch(err => {
				Logger.log(err)
			})
	},

	fetchFuelLevels() {
		let api = this.get('api')
		let params = this.apiParams()

		return api.post('reports/10', {
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			data: { $filter: params },
		})
	},

	fetchFuelEventsByVehicle(vehicleID) {
		let api = this.get('api')
		let params = this.apiParams()
		params.vehicles = [vehicleID]

		return api.post('reports/11', {
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			data: { $filter: params },
		})
	},

	fetchFuelEvents() {
		let api = this.get('api')
		let params = this.apiParams()

		return api.post('reports/11', {
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			data: { $filter: params },
		})
	},
	/**
	 * function to get sequence between 1 and the passed number
	 * @param {*} number 
	 */
	getSequenceUntil(number) {
		if (!number || isNaN(number)) { return false }
		let text = ""
		for (let i = 1; i <= number; i++) {
			text += i === number ? i : i + ", ";
		}
		return text
	},

	/**
	 *  {
	 *    "vehicleID": "de141c62-7906-40cf-9750-e9638017df32",
	 *    "date": 1505632438000,
	 *    "duration": 410,
	 *    "diffFuelLevel": 34,
	 *    "diffFuelLevels": [20,14],
	 *    "diffFuelLevelCAN": 44
	 *  }
	 * @param {array} vehicleFuelEvents vehicle events
	 */
	processFuelEvents(vehicleFuelEvents, vehicle) {
		if (!vehicleFuelEvents || !vehicleFuelEvents.length) { return null }
		let processed = [];

		vehicleFuelEvents.forEach(item => {
			let fuelSensorsNumber = item.diffFuelLevels.length;
			item.volumeTooltip = "";

			let level = item.diffFuelLevel || item.diffFuelLevelCAN || item.diffFuelLevelManual
			let isPercentFuel = vehicle && vehicle.get('status') && vehicle.get('status.can') && vehicle.get('status.can.fuel') && vehicle.get('status.can.fuel.level') && vehicle.get('status.can.fuel.level.1')
			item.diffFuelLevelCAN = isPercentFuel ? item.diffFuelLevelCAN : item.diffFuelLevelCAN / 100

			//total & tank columns
			if (fuelSensorsNumber) {
				item.total = item.diffFuelLevel;
				item.tank = this.getSequenceUntil(fuelSensorsNumber); //sonde fuel tank is 0
			}
			else if (item.diffFuelLevelCAN != null) {
				item.total = item.diffFuelLevelCAN;
				item.tank = 1; //can fuel tank is 1
			} else {
				item.tank = 1;
			}

			if (item.total != null) {
				item.total = item.total.toFixed(1) + ' L'
			}
			item.type = level > 0 ? 'refuel' : 'consumption'

			//volume column
			if (fuelSensorsNumber) {
				item.diffFuelLevels.forEach((val, index) => {
					item.volumeTooltip += "\nVolum rezervor " + (index + 1) + ": " + val.toFixed(1) + " L";
				})
			}
			if (item.diffFuelLevelCAN != null) {
				item.volumeTooltip += "\nVolum CAN: " + item.diffFuelLevelCAN.toFixed(1) + " L";
			}

			if (item.diffFuelLevelManual != null) {
				item.diffManual = item.diffFuelLevelManual.toFixed(1) + " L"
			}

			//duration column
			item.durations = item.duration > 0 ? this.get("dates").asDHMS(item.duration * 1000) : '';

			processed.pushObject(item);
		});

		return processed
	},
	getFuelEventsForVehicle(events, vehicleID) {
		let fuelEvents = []
		events.forEach(event => {
			if (event.vehicleID === vehicleID) {
				fuelEvents.push(event)
			}
		})
		return fuelEvents
	},

	processData(chartData, tableData) {
		let reportData = []

		let vehicles = this.get('vehicles')
		vehicles.forEach(vehicle => {
			let vehicleID = vehicle.get('id')
			let data = {
				vehicle: vehicle,
				fuelLevels: this.processFuelLevels(chartData[vehicleID], vehicle),
				fuelEvents: this.processFuelEvents(this.getFuelEventsForVehicle(tableData, vehicleID), vehicle)
			}

			// if (data.fuelLevels.length > 0 || data.fuelEvents != null) {
			reportData.push(data)
			// }
		})

		this.set('reportData', reportData)
	},
	processFuelLevels(fuelLevels, vehicle) {
		let i18n = this.get('i18n')
		if (fuelLevels == null || !isArray(fuelLevels)) {
			return []
		}

		let charts = {}

		let isPercentFuel = vehicle && vehicle.get('status') && vehicle.get('status.can') && vehicle.get('status.can.fuel') && vehicle.get('status.can.fuel.level') && vehicle.get('status.can.fuel.level.1')
		fuelLevels.forEach(point => {
			if (point.canFuelLevel != null) {
				if (charts.can == null) {
					charts.can = []
				}

				charts.can.push({
					timestamp: point.timestamp,
					time: new Date(point.timestamp),
					label: i18n.t('canFuelLevel').string,
					value: isPercentFuel ? point.canFuelLevel : point.canFuelLevel / 100 || 0
				})
			}
			if (point.fuelLevels != null && isArray(point.fuelLevels) && point.fuelLevels.length > 0) {
				point.fuelLevels.forEach((fuelLevel, index) => {
					const chartName = `tank${index}`
					if (charts[chartName] == null) {
						charts[chartName] = []
					}

					charts[chartName].push({
						timestamp: point.timestamp,
						time: new Date(point.timestamp),
						label: `${i18n.t('fuelLevel').string} ${index + 1}`,
						value: fuelLevel || 0
					})
				})
			}
		})

		let result = Object.keys(charts)
			.map(function (chart) {
				return charts[chart]
			})

		return result
	},

	file: null,

	fileName: Ember.computed('file', function () {
		let file = this.get('file')
		if (!file) { return null }
		return file.name
	}),

	response: null,
	uploadProgress: null,
	completedRequest: false,

	saveFile(file) {
		let _this = this
		if (file) {
			let url = config.api.host + '/' + config.api.namespace + '/refuels/import';
			const idToken = this.get('session.data.authenticated.idToken');

			let fd = new FormData();
			fd.append('refuel_file', file);
			let xhr = new XMLHttpRequest();
			xhr.open('POST', url, true);
			xhr.setRequestHeader('Authorization', `Bearer ${idToken}`);
			xhr.upload.onprogress = function (pe) {
				if (pe.lengthComputable) {
					let percentComplete = parseInt((pe.loaded / pe.total) * 100);
					_this.set("uploadProgress", percentComplete)
				}
			}

			xhr.onloadend = function (pe) {
				let response = JSON.parse(pe.target.responseText);
				_this.set("completedRequest", true);
				_this.set("response", response);
				_this.set("file", null);
				_this.set("uploadProgress", null);
				_this.fetch();
			}

			xhr.send(fd);
		}
	},

	refreshVehicleTable(vehicleID) {
		if (!vehicleID) { return false }
		let report = this.get("reportData").findBy("vehicle.id", vehicleID)
		this.fetchFuelEventsByVehicle(vehicleID)
			.then(fuelEvents => {
				Ember.set(report, 'fuelEvents', this.processFuelEvents(fuelEvents), report.vehicle)
			})
			.catch(err => {
				Logger.log(err)
			})
	},

	actions: {
		incrementRecords() {
			let limit = this.get('limit')
			let length = this.get('recordsLength')
			let increment = this.get('increment')
			if (length <= limit) { return false }

			this.incrementProperty('limit', increment)
		},
		addFueling(vehicleID) {
			this.set("insertFuelVehicleID", vehicleID)
			Ember.$("#modalAddFueling").modal('show')
		},
		refreshVehicleTable() {
			let vehicleID = this.get("insertFuelVehicleID")
			this.refreshVehicleTable(vehicleID)
		},
		refreshVehiclesTables(vehicleIDs) {
			vehicleIDs.forEach(vehicleID => {
				this.refreshVehicleTable(vehicleID)
			})
		},
	}
})
