import Ember from 'ember';

export default Ember.Component.extend({
    ImportWasClicked: false,
    file: null,
    
    fileName: Ember.computed('file', function ( ) {
        let file = this.get('file')
        if (!file) { return null }
        return file.name
    }),

    newFileSelected: false,

    created: Ember.computed( 'response.count.created', function ( ) {
        return this.get( 'response.count.created' )
    }),
    parsed: Ember.computed( 'response.count.parsed', function ( ) {
        return this.get( 'response.count.parsed' )
    }),
    updated: Ember.computed( "response.count.updated", function ( ) {
        return this.get( 'response.count.updated' )
    }),
    notCreated: Ember.computed( 'created', 'parsed', 'updated', function ( ) {
        return this.get( "parsed" ) - ( this.get( "created" ) + this.get( "updated" ) )
    }),
    hasErrors: Ember.computed( "response.failed", function ( ) {
        return this.get( "response.failed.length" )
    }),

    errors: Ember.computed( "response.failed", function ( ) {
        
    } ),

    showConfirmationButton: false,

    actions: {
        simulate ( ) {
            this.set( 'ImportWasClicked', true )
            this.set( 'completedRequest', false )
            this.set( 'newFileSelected', false )
            let file = this.get( 'file' )
            let _this = this

            this.sendAction( 'simulation', file, function ( response ) {
                _this.set( 'newFileSelected', true )
                _this.set( 'completedRequest', true )
                
                if ( response.count.parsed ) {
                    _this.set( "showConfirmationButton", true )
                } else {
                    _this.set( "showConfirmationButton", false )
                }
            } );
        },

        importGeofence( ) {
            this.set( 'ImportWasClicked', true )
            this.set( 'completedRequest', false )
            this.set( 'newFileSelected', false )
            let file = this.get( 'file' )

            this.sendAction( 'upload', file );

            this.set( "showConfirmationButton", false )
        },

        loadedFile ( element ) {
            let file = element.target.files[ 0 ]

            if ( file ) {
                this.set( "newFileSelected", true )
                this.set( "showConfirmationButton", false )
                this.set( "file", file )
            } else {
                this.set( "newFileSelected", false )
                this.set( "file", null )
            }
        },

        selectFile ( ) {
            this.set( 'ImportWasClicked', false )
            //trigger the select file input
            Ember.$( "input[type='file']" ).click( );
        },

        cancel ( ) {
            this.sendAction( 'cancel' );
        },
    }
});
