export default {
    at: 'La',
    from: 'De la',
    to: 'Până la',
    duration: 'Durată',

    start: 'Pornire',
    stop: 'Oprire',
    stationary: 'Staționare',

    //charts-container
    speed: "Viteză",
    altitude: "Altitudine",
    fuelCanLevel: "Nivel Combustibil Can",
    fuelLevel: "Nivel Combustibil Sondă",
    acceleration: "Accelerație",
    rpm: "RPM",

    //map index popup
    lastUpdate: "Ultima actualizare",
    engineState: "Stare motor",
    engineLoad: "Sarcină motor",
    engineTemperature: "Temperatură motor",
    gpsPosition: "Poziție GPS",
    gpsSpeed: "Viteză GPS",
    canSpeed: "Viteză CAN",
    canAcceleration: "Accelerație CAN",
    canRPM: "RPM CAN",
    canFuelAvailable: "Combustibil disponibil (CAN)",
    canFuelUsed: "Combustibil utilizat (CAN)",
    probeFuelAvailable: "Combustibil disponibil (sonda)",
    distance: "Distanță",
    doors: "Uși",
    agregation: "Agregat frigorific",
    temperature: "Temperatură",
}
