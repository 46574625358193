import Ember from 'ember'
import config from '../../../config/environment'
import RSVP from 'rsvp'

const { service } = Ember.inject

export default Ember.Controller.extend({
	api: service(),
	session: service(),
	i18n: service(),
	errors: service(),

	readonly: true,
	parentRoute: 'management.vehicles',
	needs: 'management.vehicles',
	parentController: Ember.computed.alias('controllers.management.vehicles'),

	documentId: null,
	requestCompleted: false,
	relayRequestCompleted: false,
	documents: Ember.computed('model.documents.[]', function () {
		let _this = this
		let vehicle = this.get('model.vehicle')
		if (!vehicle.get('isNew')) {
			return _this.get('model.documents').filterBy('vehicle.id', vehicle.get('id'))
		}
		// if is a new vehicle(no vehicle ID), filter by documents isNew atrribute.
		else {
			return _this.get('model.documents').filterBy('isNew', true)
		}
	}),

	listOfErrors: Ember.computed('errors.list.@each', function () {
		return this.get("errors.list")
	}),

	queryParams: [
		{ documentId: 'document' }
	],

	onError(err, code) {
		err.code = code;
		this.get('errors').add(err);
	},

	goToIndex() {
		this.set('readonly', true)
		this.set("requestCompleted", false);
		this.set("relayRequestCompleted", false);
		this.transitionToRoute(this.get('parentRoute'))
	},

	checkAsignedUsers(selUserID, vehicleID) {
		var vehicles = this.store.peekAll('vehicle').filter(function (item) {
			return item.get('id') !== vehicleID;
		});
		if (selUserID) {
			return vehicles.findBy('user.id', selUserID);
		} else {
			return null;
		}
	},

	rollBackAndTransition() {
		let vehicle = this.get('model.vehicle')
		let documents = this.get('documents')

		if (vehicle.get("hasDirtyAttributes")) {
			vehicle.rollbackAttributes()
		}
		if (documents) {
			documents.forEach((document) => {
				document.rollbackAttributes()
			})
		}

		this.goToIndex()
	},

	saveDevice(device, vehicle) {
        /**
         * if vehicle & not device
         * set device to null
         * 1 - delete device (vehicleID, !deviceID) set device vehicleID to null
         *
         * 2 - set vehicle to device.
         *  2a - vehicle has not previous device
         *  2b - vehicle has previous device
         */
		let vehicleID = vehicle.get('id')
		let deviceID = (device && device.get('id')) ? device.get('id') : null

		if (!vehicleID) { return false }

		let devices = this.store.peekAll('device')

		if (!deviceID && vehicleID) { // delete vehicle from device
			let device = devices.findBy('vehicleID', vehicleID)
			if (device) {
				device.set('vehicleID', null)
				return device.save()
			}
		}
		else if (deviceID && vehicleID) { // set device vehicle
			devices = devices.filter((item) => {
				return item.get('id') !== deviceID // exclude selectedDevice from devices
			})

			let device = this.store.peekAll('device').findBy('id', deviceID)
			if (device) { //device must exist
				device.set('vehicleID', vehicleID)
				let prevDevice = devices.findBy('vehicleID', vehicleID)
				if (prevDevice) { // 2b - vehicle has previous device
					prevDevice.set('vehicleID', null)
					return prevDevice
						.save() // delete vehicle from prevDevice
						.then(() => {
							device.save() // save vehicle to device
						})
				}
				else { // 2a - vehicle has not previous device
					return device.save()
				}
			}
		}
	},

	saveFile(file, docID) {
		if (file && docID) {
			let fd = new FormData()
			fd.append('document', file)
			return this.get("api").request('/documents/' + docID + '/upload', {
				data: fd,
				processData: false,
				contentType: false,
				type: 'POST',
			})
		}

		return window.Promise.resolve(null)
	},

	saveDocuments(vehicle) {
		let documents = this.get('model.documents').filterBy("isNew")
		let promises = []

		// Add documents to vehicle
		documents.forEach((document) => {
			document.set("vehicle", vehicle)

			promises.push(document.save())
		})

		return RSVP.all(promises)
			.then(data => {
				let promises = []

				// Add file to document
				data.forEach(document => {
					let file = document.get("document")
					promises.push(this.saveFile(file, document.get('id')))
				})
				return RSVP.all(promises)
			})
	},

	detachSelectedUserFromPreviousVehicle(vehicle) {
		let vehicleToDetachUser = this.checkAsignedUsers(vehicle.get('user.id'), vehicle.get('id'))
		if (vehicleToDetachUser) {
			vehicleToDetachUser.set('user', null)
			return vehicleToDetachUser.save()
		}
		return window.Promise.resolve(null)
	},

	updateRelay(vehicleID, relayState) {
		this.set('relayRequestCompleted', false)
		this.get('errors').clear();
		if (!vehicleID) { return false }
		let url = '/vehicles/' + vehicleID + '/relay'
		this.get('api').put(
			url, {
				contentType: "application/json; charset=utf-8",
				dataType: "json",
				data: { state: relayState }
			})
			.then(() => {
				this.toast.clear()
				this.set('relayRequestCompleted', true)
			})
			.catch((err) => {
				this.onError(err, "3");
				this.set('relayRequestCompleted', true)
			})
	},

	save() {
		this.set('requestCompleted', false)
		this.get('errors').clear();
		// this.toast.clear();  BUG TOAST moved to last then chain
		let vehicle = this.get('model.vehicle')
		if (!vehicle) { throw { error: 'Vehicle not exist' } }
		let device = this.store.peekAll('device').findBy("id", vehicle.get("device.id"))
		vehicle
			.save()
			.then(savedVehicle => {
				let promises = {
					detachedUser: this.detachSelectedUserFromPreviousVehicle(savedVehicle),
					documentsSaved: this.saveDocuments(savedVehicle),
					deviceSaved: this.saveDevice(device, savedVehicle),
					vehicle: savedVehicle
				}

				return RSVP.hash(promises)
			})
			.then(() => {
				this.set('requestCompleted', true)
				this.toast.clear()
				this.toast.success(this.get('i18n').t('messages.operationWithSuccess'))
				this.goToIndex()
			})
			.catch((err) => {
				// Ember.Logger.error("error:", err)
				this.set('requestCompleted', true)
				this.onError(err, "1");
				// this.toast.error(this.get('i18n').t('messages.operationWithError'))
			})
	},

	actions: {
		updateRelay(vehicleID, relayState) {
			this.updateRelay(vehicleID, relayState);
		},
		cancel() {
			this.rollBackAndTransition()
		},
		createDocument() {
			let startDate = new Date();
			let endDate = new Date();
			let vehicle = this.get('model.vehicle');
			endDate.setDate(endDate.getDate() + 1);

			this.store.createRecord('document', {
				groupID: null,
				companyID: null,
				type: "RCA",
				category: null,
				number: null,
				vehicle: vehicle,
				valid: {
					from: startDate,
					until: endDate,
				},
				price: {
					value: null,
					currency: null
				},
				file: {
					name: null,
					type: null,
					size: null,
					hash: null
				},
				details: {
					alertInterval: null
				}
			});
		},
		deleteDocument(doc) {
			if (doc) {
				doc.get("isNew") ? doc.rollbackAttributes() : doc.destroyRecord()
			}
		},
		disableReadonly() {
			this.set("readonly", false);
		},
		downloadFile(doc) {
			let url = config.api.host + '/' + config.api.namespace + '/documents/' + doc.get("id") + '/download';
			const idToken = this.get('session.data.authenticated.idToken');

			if (doc.get("id") && doc.get("downloading") === false) {
				doc.set("downloading", true); // download started
				let req = new XMLHttpRequest();
				req.open('GET', url, true);
				req.setRequestHeader('Authorization', `Bearer ${idToken}`);
				req.responseType = 'blob';

				req.onload = function (/*event*/) {
					doc.set("downloading", false); // download finished
					let blob = req.response;
					let link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);
					link.download = doc.get("file.name");
					link.click();
				}
				req.error = () => {
					doc.set("downloading", false); // download finished
				}
				req.send();
			}
		},
		save() {
			this.save()
		},
	}
})
