export default {
	"title": {
		"alerts": "Warnungen",
		"consumption": "Verbrauch",
		"faz": "Tägliche Aktivitäten",
		"fuel-level": "Tankfüllstand",
		"notifications": "Benachrichtigungen",
		"route": "Routen",
		"stationary": "Stopps",
		"poi": "Feldaktivität",
		"temperature": "Temperatur",
		"temperature-range": "TK Stunden",
		"poi-temperature": "Feld TK"
	},
	"ro": "Berichte",
	"menu": {
		"alerts": "Warnungen",
		"faz": "Tägliche Aktivitäten",
		"fuel-level": "Tankfüllstand",
		"detailed-stops": "Detaillierte Stopps ++",
		"notifications": "Benachrichtigungen",
		"route": "Routen",
		"stationary": "Stopps",
		"poi": "Feldaktivität",
		"temperature": "Temperatur",
		"temperature-range": "TK Stunden",
		"poi-temperature": "Feld TK"
	},
	"downloads": "Download",
	"settings": {
		"specific": {
			"route": {
				"duration": "Dauer",
				"schedule": "Routen im Zeitplan",
				"noschedule": "Routen 0-24"
			},
			"shipping": {
				"schedule": "Versand im Zeitplan",
				"noschedule": "Sendungen 0-24"
			}
		}
	},
	"subscribe": {
		"title": "Abonnieren",
		"fields": {
			"label": {
				"email": "Fügen Sie durch Komma getrennte E-Mail-Adressen ein.",
				"frequency": "Wählen Sie die Benachrichtigungshäufigkeit:",
				"weekDays": "Wählen Sie den Berichtstag:"
			},
			"placeholder": {
				"email": "E-Mail-Adressliste:",
				"frequency": "Frequenz auswählen:",
				"report": "Bericht auswählen:",
				"dayOfWeek": "Wochentag auswählen:"
			}
		},
		"everyDay": "Täglich",
		"everyWeek": "Wöchentlich",
		"everyMonth": "Monatlich",
		"notifyDate": "Tag auswählen:"
	},
	"headerFields": {
		"acc": "Beschleunigungswert",
		"areaPerHour": "Fläche/Stunde (ha)",
		"areaTotal": "Fläche (ha)",
		"areaTotalLoad50": "Bereich mit Motorlast > 50% (ha)",
		"areaTotalLoad50Popup": "Bereich mit erhöhter Motorlast",
		"averageSpeed": "Durchschnittsgeschwindigkeit (km/h)",
		"consumptionCompose": "Verbrauch (Liter)",
		"canDistance": "Entfernung (CAN)",
		"canFuelConsumption": "CAN Verbrauch (Liter)",
		"canFuelConsumptionDistance": "Verbrauch  / 100 (CAN)",
		"canFuelLevelEndOfDay": "Tankfüllstand CAN (Liter)",
		"canOdometer": "Kilometerzähler Ende",
		"consume": "Verbrauch (Liter)",
		"createdAt": "Erstellt am",
		"date": "Datum",
		"desacc": "Verzögerungswert",
		"description": "Beschreibung",
		"distance": "Entfernung (km)",
		"distanceCompose": "Entfernung (km)",
		"driver": "Fahrer",
		"duration": "Dauer",
		"endLocation": "Endstandort",
		"endTime": "Endzeit",
		"freezerOnDuration": "Laufzeit der Kühleinheit",
		"fuelConsumption": "Berechneter Verbrauch (Liter)",
		"onlyFuelConsumption": "Verbrauch (Liter)",
		"fuelConsumptionPerHa": "Verbrauch/ha (Liter)",
		"fuelConsumptionPerHour": "Verbrauch/Stunde (Liter)",
		"fuelConsumptionDistance": "Verbrauch / 100",
		"fuelLevelDiff": "Quantität (Liter)",
		"fuelLevelDiffManual": "Quantität eingegeben (Liter)",
		"fuelLevelDiffLiters": "Quantität (Liter)",
		"fuelSensor": "Treibstofftank",
		"fuelEvent": "Typ",
		"generatedAt": "Erstellt bei",
		"invoiceNumber": "Rechnungsnummer",
		"licensePlate": "Nummernschild",
		"location": "Adresse",
		"map": "Karte",
		"maxSpeed": "Max Geschwindigkeit (km/h)",
		"persistent": "Hartnäckig",
		"poiCount": "Felder zählen",
		"poiStationaryDoorsOpenedDuration": "Leerlauf im Feld mit offenen Türen",
		"poiStationaryDuration": "Leerlaufin Felddauer",
		"price": "Preis",
		"printDetails": "Details",
		"sensorFuelConsumption": "Kraftstoffsensorverbrauch (Liter)",
		"speed": "Geschwindigkeitsbewertung",
		"startLocation": "Start Standort",
		"startTime": "Startzeitpunkt",
		"stationaries": "Stopps bei eingeschaltetem Motor",
		"status": "Status",
		"temperature1": "unter 4°C",
		"temperature2": "zwischen -4°C und 0°C",
		"temperature3": "zwischen 0°C und 4°C",
		"temperature4": "über 4°C",
		"updatedAt": "Aktualisert am",
		"virage": "Punktzahl Drehungen",
		"specific": {
			"route": {
				"fuelConsumption": "Berechneter Verbrauch",
				"purpose": "Zweck",
				"implement": "Anbaugeräht",
				"selectPurpose": "Auswählen"
			},
			"station": {
				"startTime": "Startzeit",
				"endTime": "Endzeit",
				"duration": "Dauer"
			},
			"stationary": {
				"startTime": "Startzeit",
				"endTime": "Endzeit",
				"duration": "Dauer"
			},
			"poi": {
				"geofences": "Felder"
			}
		}
	},
	"fuelLevel": {
		"eventType": {
			"refuel": "Tanken",
			"consumption": "Verbrauch"
		},
		"import": {
			"title": "Kraftstoffe importieren",
			"buttonText": "IMPORTIERE Auffüllungen",
			"imported": "{{value}} Auffüllungen wurden importiert",
			"notImported": "{{value}} Auffüllungen konnten nicht importiert wurden",
			"allImported": "Alle Auffüllungen wurden importiert ({{value}})"
		},
		"uploadFile": "Datei Hochladen"
	},
	"table": {
		"expand": "Tabelle erweitern",
		"collapse": "Tabelle reduzieren"
	},
	"temperature": {
		"temperatureTable": "Temperatur Tabelle"
	}
}
