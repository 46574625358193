import Ember from 'ember'

const types = [
	Ember.Object.create({ name: 'car', translationKey: 'management.vehicles.type.car' }),
	Ember.Object.create({ name: 'minibus', translationKey: 'management.vehicles.type.minibus' }),
	Ember.Object.create({ name: 'bus', translationKey: 'management.vehicles.type.bus' }),
	Ember.Object.create({ name: 'truck', translationKey: 'management.vehicles.type.truck' }),
	Ember.Object.create({ name: 'tractor', translationKey: 'management.vehicles.type.tractor' }),
	Ember.Object.create({ name: 'harvester', translationKey: 'management.vehicles.type.harvester' }),
	Ember.Object.create({ name: 'construction', translationKey: 'management.vehicles.type.construction' }),
	Ember.Object.create({ name: 'trailer', translationKey: 'management.vehicles.type.trailer' }),
	Ember.Object.create({ name: 'semi-trailer', translationKey: 'management.vehicles.type.semi-trailer' }),
	Ember.Object.create({ name: 'utility', translationKey: 'management.vehicles.type.utility' })
]

export default Ember.Service.extend({
	getTypes() {
		return types
	}
})
