import Ember from 'ember'
import Base from './base'
import PropsInit from '../../mixins/reports/controller-props-initialization'
import moment from 'moment'
/*global d3*/

const { service } = Ember.inject

export default Base.extend(PropsInit, {
	resolution: service('resolution'),
	reportTitle: 'TEMPERATURE',
	sortProperties: null,

	queryParams: ['vehicleID', 'start', 'end'],
	vehicleID: null,
	start: null,
	end: null,
	selectedSeedColor: '',
	formater: null,

	columns: ['licensePlate', 'minValue', 'averageValue', 'maxValue', 'details'],

	dates: service(),

	startDate: Ember.computed('ReportsFields.fields', function () {
		return this.get('ReportsFields.fields.startDate')
	}),
	endDate: Ember.computed('ReportsFields.fields', function () {
		return this.get('ReportsFields.fields.endDate')
	}),

	startDateVisible: Ember.computed('startDate', function () {
		return moment(this.get('startDate')).format('DD-MM-YYYY')
	}),

	endDateVisible: Ember.computed('endDate', function () {
		return moment(this.get('endDate')).format('DD-MM-YYYY')
	}),

	selectedInterval: Ember.computed('startDate', 'endDate', function () {
		let startDate = new Date(this.get('startDate'))
		let endDate = new Date(this.get('endDate'))
		let dates = this.get('dates')

		if (dates.areSameYear(startDate, endDate) && dates.areSameMonth(startDate, endDate) && dates.areSameDay(startDate, endDate)) {
			return 'H'
		} else if (dates.areSameYear(startDate, endDate) && dates.areSameMonth(startDate, endDate) && !dates.areSameDay(startDate, endDate)) {
			return 'D'
		}

		return 'W'

	}),

	data: Ember.computed('records.[]', function () {

		let vehicles = this.get('reportsController.model.vehicles')
		let records = this.get('records')[0]

		let empty = true
		let parsedRecords = {}

		for (let i in records) {
			let vehicle = this.getVehicle(i, vehicles)
			let data = []
			if (vehicle && records[i].length) {
				empty = false
				data = records[i].map(item => ({
					time: new Date(item.timestamp),
					label: vehicle.get('licensePlate'),
					value: item.temperature
				}))

				parsedRecords[vehicle.get('licensePlate')] = this.removeConsecutiveSameValues(data.sortBy('time'), 'value')
			}
		}

		if (empty) { return false }
		return parsedRecords
	}),

	tableData: Ember.computed('data', function () {
		if (!this.get('data')) { return false }
		let records = this.get('data')
		let parsedRecords = {}

		for (let i in records) {
			if (records[i].length) {
				parsedRecords[i] = {
					options: { graphActive: false },
					data: [
						i,
						this.getMin(records[i]),
						this.getAverage(records[i]),
						this.getMax(records[i]),
					]
				}
			}
		}
		return parsedRecords
	}),

	readingsInterval: 0,
	
	temperatureReadings: Ember.computed( "readingsInterval", function () {
		let readings = {};
		let readingsInterval = this.get( "readingsInterval" );
		let vehicles = this.get('reportsController.model.vehicles')
		let records = this.get('records')[0];

		vehicles.forEach( ( vehicle ) => {
			let vehicleID 		= vehicle.get( "id" );
			let licensePlate 	= vehicle.get( "licensePlate" );

			if ( records[ vehicleID ] && records[ vehicleID ].length ) {

				readings[ licensePlate ] = [];
				readings[ licensePlate ].push( records[ vehicleID ][ 0 ] );
				for ( let i = 1; i < records[ vehicleID ].length - 1; i++ ) {
					if ( readingsInterval ) {
						if ( ( readings[ licensePlate ][ readings[ licensePlate ].length - 1 ].timestamp + readingsInterval ) >= records[ vehicleID ][ i ].timestamp ) {
							readings[ licensePlate ].push( records[ vehicleID ][ i ] );
						}
					} else {
						readings[ licensePlate ].push( records[ vehicleID ][ i ] );
					}
				}

				readings[ licensePlate ].push(records[ vehicleID ][ records[ vehicleID ].length - 1 ]);
			}
		} );

		return readings;
	} ),

	getMin(data) {
		if (!data || !data.length) { throw 'Invalid arguments' }

		let firstItemWitNotNullValue = data.find(item => {
			if (item.value === null) { return false }
			return true
		})


		return data.reduce((min, current) => {
			/**
			 * Value can be null in which case we set it to previous min value.
			 */
			if (!current.value) { current.value = min }
			return min > current.value ? current.value : min
		}, firstItemWitNotNullValue.value).toFixed(1)
	},

	getMax(data) {
		if (!data || !data.length) { throw 'Invalid arguments' }

		let firstItemWitNotNullValue = data.find(item => {
			if (item.value === null) { return false }
			return true
		})

		return data.reduce((max, current) => {
			if (!current.value) { current.value = max }

			return max > current.value ? max : current.value
		}, firstItemWitNotNullValue.value).toFixed(1)
	},

	getAverage(data) {
		if (!data || !data.length) { throw 'Invalid arguments' }
		let sumValues = data.reduce((sum, item) => {
			if (!item.value) { item.value = 0 }
			return sum + item.value
		}, 0)
		let length = data.length
		return (sumValues / length).toFixed(1)
	},

	removeConsecutiveSameValues(values, column) {
		let filtered = values.filter((item, pos, arr) => {
			if (arr[pos + 1]) {
				return pos === 0 || (item[column] !== arr[pos - 1][column] || item[column] !== arr[pos + 1][column])
			} else {
				return true
			}
		})

		return filtered
	},

	init() {
		this._super(...arguments)
		this.set('formater', d3.time.format('%Y-%m-%d %H:%M:%S'))
	},
	
	actions: {
		toggleGraph( data ) {
			Ember.set( data.options, 'graphActive', !data.options.graphActive )
		},

		toggleTable ( data ) {
			Ember.set( data.options, 'tableActive', !data.options.tableActive )
		}
	}
})
