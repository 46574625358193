import Ember from 'ember';
import config from '../../../config/environment';

const { service } = Ember.inject;
export default Ember.Controller.extend({
    // api: service(),
    session: service(),
    currentUser: service('current-user'),
    parentRoute: 'management.geofences',

    response: null,
    progressOne: null,
    completedRequest: false,
    err: false,

    saveFile( file, simulation, onComplete ) {
        let _this = this
        if (file) {
            let url = config.api.host + '/' + config.api.namespace + '/geofences/import';
            const idToken = this.get('session.data.authenticated.idToken');

            if ( simulation ) {
                url = config.api.host + '/' + config.api.namespace + '/geofences/simulateImport';
            }

            let fd = new FormData();
            fd.append('geofence', file);
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', `Bearer ${idToken}`);
            xhr.upload.onprogress = function (pe) {
                if (pe.lengthComputable) {
                    let percentComplete = parseInt((pe.loaded / pe.total) * 100);
                    _this.set("progressOne", percentComplete)
                }
            }
            xhr.onloadend = (pe) => {
                try {
                    let response = JSON.parse(pe.target.responseText)

                        this.set("completedRequest", true)
                        this.set("response", response)
                    if ( onComplete ) {
                        onComplete( response )
                    }
                }
                catch (e) {
                    this.set("completedRequest", true)
                    // this.set("err", true)
                }
            }
            xhr.send(fd);
        }
    },
    actions: {
        simulation ( file, onComplete ) {
            if ( file ) {
                this.saveFile( file, true, onComplete )
            }
        },
        upload( file ) {
            if ( file ) {
                this.saveFile( file, false )
            }
        },
        cancel() {
            this.transitionToRoute( this.get( 'parentRoute' ) )
        },
    }
});
