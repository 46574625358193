import Ember from 'ember';

const { service } = Ember.inject;

export default Ember.Component.extend({
    resolution: service(),
    sidebar: service('sidebar'),

    classNames: ['column tracknamic-side-bar'],

    activeOrNot() {
        if (this.get('resolution.isLargeDesktop')) {
            this.$().addClass('active');
        } else {
            this.$().removeClass('active');
        }
    },

    didInsertElement() {
        this.get('sidebar').setTo(this.$());
        this.activeOrNot();
    }
});
