import Ember from 'ember'

const { service } = Ember.inject
// const selfIntersection = 'Self-intersection'   ¿¿??

export default Ember.Controller.extend({
	errors: service(),

	parentRoute: 'management.geofences',

	actions: {
        save() {
            var geofence = this.get('model.geofence')
            if (!geofence) { throw { error: 'geofence not exist' } }

            return geofence.save()

        },
        cancel() {
            var geofence = this.get('model.geofence')
            geofence.rollbackAttributes()
            this.transitionToRoute(this.get('parentRoute'))
		},
		success() {
			this.transitionToRoute(this.get('parentRoute'))
		}
    }

})
