import Ember from 'ember';

export default Ember.Service.extend({
    /**
     * Adds an overlay layer over specified jElement.
     * @param {jQueryObject} jElement 
     */
    add(jElement) { 
        var overlayLayer;
        
        if (!jElement || !jElement.length) { return null; }
        
        overlayLayer = jElement.find('.overlay-layer');

        if (overlayLayer.length) { return false; }

        jElement.addClass('position-relative');
        jElement.append('<div class="overlay-layer"><div>');
    },

    /**
     * Removes overlay layer from inside jElement if there is one.
     * @param {jQueryObject} jElement 
     */
    remove(jElement) {
        var overlayLayer;

        if (!jElement || !jElement.length) { return null; }

        overlayLayer = jElement.find('.overlay-layer');

        if (!overlayLayer.length) { return false; }

        jElement.find('.overlay-layer').remove();
        jElement.removeClass('position-relative');
    }
});
