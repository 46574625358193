import Ember from 'ember';

export default Ember.Component.extend({
    implementsTypes: [
        "plow",
        "cultivator", 
        "disc-harrow", 
        "combinator",
        "chisel-plow",
        "seed-drill-weeding",
        "seed-drill-cereal",
        "weeder",
        "drill",
        "sprayer",
        "fertilizer",
        "leveler",
        "planter",
        "rotating-rake",
        "baler",
        "mower",
        "harvesters",
        "harrow",
        "roller",
        "front-loader",
        "trailer",
        "vidange",
        "chopper",

        "harvester-header-cereal",
        "harvester-header-weeding"
    ],

    requestSent: false,

    didReceiveAttrs() {
        this._super(...arguments)
        if (this.get('requestCompleted')) {
            this.set('requestSent', false)
        }

        if (this.get("listOfErrors").length > 0) {
            // alert("we have errors")
            this.get("listOfErrors").forEach(error => {
                this.get('toast').error(error.message, '', { timeOut: 0, extendedTimeOut: 0 })
            })
        }
    },

    actions: {
        edit() {
            this.get('disableReadonly')()
        },

        save() {
            // if (!Ember.$('form.formVehicle').form("validate form")) {
            //     Ember.Logger.debug("Validare")
            //     Ember.Logger.debug("FormERROR")
            //     return false
            // }
            this.set('requestSent', true)
            this.get('save')()
        },

        cancel() {
            this.get('cancel')()
        },
    }
});
