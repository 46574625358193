import Ember from 'ember'
import moment from 'moment'

export default Ember.Component.extend({
    classNames: ["fields"],
    fullDay: false,

    startDate: null,
    endDate: null,

    status: Ember.computed("endDate", "startDate", function () {
        let _this = this;
        let startDate = moment(this.get("startDate"));
        let endDate = moment(this.get("endDate"));
        // let day = this.get("day")
        let minDif = endDate.diff(startDate, 'minutes')

        if (startDate.get('hour') === 0 && minDif === 0) {
            // disabled
            _this.get("day").setProperties({ 0: 0, 1: 0, 2: 0, 3: 0 });
            _this.set("fullDay", false);
            return "disabled";
        }
        else if (minDif === 1440) {
            // disabled
            _this.get("day").setProperties({ 0: 0, 1: 0, 2: 24, 3: 0 });
            _this.set("fullDay", true);
            return "fullday";
        }
        else {
            // custom
            _this.get("day").setProperties({ 0: startDate.get("hours"), 1: startDate.get("minutes"), 2: endDate.get("hours"), 3: endDate.get("minutes") });
            _this.set("fullDay", false);
            return "custom";
        }
    }),

    init() {
        this._super(...arguments);
        let _this = this;
        let sd = moment(946677600000); // 1/1/2000 00:00:00
        sd.set({ "h": _this.get("day.0"), "m": _this.get("day.1") });
        this.set("startDate", sd.toDate());
        let ed = moment(946677600000); // 1/1/2000 00:00:00
        ed.set({ "h": _this.get("day.2"), "m": _this.get("day.3") });
        this.set("endDate", ed.toDate());
    },
    actions: {
        dayStatus(id, value) {
            let _this = this;
            // let startDate = this.get("startDate");
            // let endDate = this.get("endDate");
            let sd = moment(946677600000); // 1/1/2000 00:00:00
            let ed = moment(946677600000); // 1/1/2000 00:00:00

            if (value === "custom") {
                sd.set({ "h": 0, "m": 0 });
                ed.set({ "h": 23, "m": 59 });
                _this.set("startDate", sd.toDate());
                _this.set("endDate", ed.toDate());
            }
            else if (value === "fullday") {
                sd.set({ "h": 0, "m": 0 });
                ed.set({ "h": 24, "m": 0 });
                _this.set("startDate", sd.toDate());
                _this.set("endDate", ed.toDate());
            }
            else {
                sd.set({ "h": 0, "m": 0 });
                ed.set({ "h": 0, "m": 0 });
                _this.set("startDate", sd.toDate());
                _this.set("endDate", ed.toDate());
            }
        },
    }
});
