import Ember from 'ember';
const { inject: { service } } = Ember;

export default Ember.Component.extend({

    currentUser: service('current-user'),

    init() {
        this._super(...arguments);
        Ember.$('.ui.dimmer.modals').remove();
    },

    didInsertElement() {
        Ember.run.scheduleOnce('afterRender', this, this.afterRenderEvent);
    },

    afterRenderEvent: function () {
        var height = parseInt(this.$().parent().height() * 0.88, 10);
        this.$().height(height);
    },

    actions: {
        openModal: function (name) {
            Ember.$('#' + name).modal('show');
        },
    }
});
