import Ember from 'ember';

export default Ember.Component.extend({
    mapTypes: ['roadmap', 'satellite', 'terrain', 'hybrid'],
    classNames: ['google-map-type-selector'],
    actions: {
        selectMapType ( mapType ) {
            this.sendAction ("select", mapType);
        }
    }
});
