import Ember from 'ember'

const { service } = Ember.inject

export default Ember.Route.extend({
	currentUser: service('current-user'),
	api: service(),

	model() {
		let companyID = this.get('currentUser.user.companyID')
		let api = this.get('api')

		return api.request(`groups/${companyID}`, {
			dataType: "json",
		})
	}
})
