import DS from 'ember-data';
import MF from 'ember-data-model-fragments';
import Ember from 'ember';

const { service } = Ember.inject

export default DS.Model.extend({
    dates: service(),
    
    deviceSN:   DS.attr('string'),
    vehicleID:  DS.attr('string'),
    userID:     DS.attr('string'),
    start:      MF.fragment('checkpoint'),
    end:        MF.fragment('checkpoint'),
    details:    DS.attr(),
    events:     DS.attr(),
    points:     DS.attr(),
	type:		DS.attr('string'),
    startTime:  Ember.computed("start", function(){
        return this.get("start.time");
    }),

    duration:   Ember.computed("start", "end", function(){
        return this.get("dates").diffTwoDates(this.get("start.time"),this.get("end.time"))
    }),
    
    humanizedDuration: Ember.computed("duration", function(){
        return this.get("dates").asDHMS(this.get("duration"))
    })

});
