import Base from './base';

export default Base.extend({
    // _save(user) {
    //     var _this = this;
    //     if (!user) { throw { error: 'User not exist' }; }

    //     return user
    //         .save()
    //         .then(function (/*data*/) {
    //             Ember.Logger.debug("User Edited");
    //             _this.transitionToRoute(_this.get('parentRoute'));
    //         })
    //         .catch(function (err) {
    //             Ember.Logger.debug("error on saving user edit");
    //             Ember.Logger.debug(err);
    //         });
    // },
    // actions: {
    //     editUser(vehicleToDetachUser) {
    //         var vehicle,
    //             _this = this,
    //             user = this.get('model');

    //         if ($('#aclGroups').val() != null) {
    //             if ($('#aclGroups').val() === "") {
    //                 user.set("acl.groups", null);
    //             }
    //             else {
    //                 user.set("acl.groups", $('#aclGroups').val().split(","));
    //             }
    //         }
    //         if ($('#aclVehicles').val() != null) {
    //             if ($('#aclVehicles').val() === "") {
    //                 user.set("acl.vehicles", null);
    //             }
    //             else {
    //                 user.set("acl.vehicles", $('#aclVehicles').val().split(","));
    //             }
    //         }
    //         if (user.get("details.drivingLicense.categories")) {
    //             user.set("details.drivingLicense.categories", user.get("details.drivingLicense.categories").split(","));
    //         }
    //         if (user.get("assignedVehicle")) {
    //             vehicle = this.store.peekRecord('vehicle', user.get("assignedVehicle"));
    //             vehicle.set("user", user);
    //             vehicle.save()
    //                 .then();
    //         } else {
    //             var vehicles = this.store.peekAll('vehicle');
    //             vehicle = vehicles.findBy("user.id", user.get("id"));
    //             if (vehicle) {
    //                 vehicle.set("user", null);
    //                 vehicle
    //                     .save()
    //                     .then();
    //             }
    //         }

    //         if (vehicleToDetachUser) {
    //             vehicleToDetachUser.set('user', null);
    //             vehicleToDetachUser
    //                 .save()
    //                 .then(_this._save(user));
    //         } else {
    //             this._save(user);
    //         }
    //     }
    // }
});
