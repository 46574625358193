import Ember from 'ember'
import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin'

export default Ember.Route.extend(UnauthenticatedRouteMixin, {
    session: Ember.inject.service(),
    
    beforeModel: function(transition) {
        this._super(...arguments)
        if (!this.get('session.isAuthenticated')) {
            this.set("session.invitationToken", decodeURI(transition.queryParams.t))
            this.transitionTo("signup")
        }
    }
})