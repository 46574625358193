import Ember from 'ember'

const succesStyle = 'background: #222 color: #FF0000'
const errorStyle = 'background: #222 color: #bada55'
const expectedStyle = 'background: #fff color: #666'
const actualStyle = 'background: #fff color: #666'

export default Ember.Service.extend({

	assertions: [],

	multipleAssertions: false,

	start() {
		this.set('assertions', [])
		this.set('multipleAssertions', true)
		return this
	},

	equal(expected, actual, description) {
		try {
			Ember.assert(description, expected === actual)
			this.pass(description)
		} catch (e) {
			this.fail(expected, actual, description)
		}

		if (!this.get('multipleAssertions')) {
			this.run()
			return true
		}

		return this
	},

	fail(expected, actual, description) {
		let test = {
			description: {
				log: '%c Test ' + description,
				style: errorStyle
			},
			expected: {
				log: '%c Expected ' + expected,
				style: expectedStyle
			},
			actual: {
				log: '%c Actual ' + actual,
				style: actualStyle
			},
			fail: true
		}

		this.get('assertions').push(test)

		// Ember.Logger.log('%c Test ' + description, errorStyle)
		// Ember.Logger.log('%c Expected ' + expected, expectedStyle)
		// Ember.Logger.log('%c Actual ' + actual, actualStyle)
	},

	pass(description) {
		let test = {
			description: {
				log: '%c Test ' + description,
				style: succesStyle
			},
			fail: false
		}

		this.get('assertions').push(test)
		// Ember.Logger.log('%c Test ' + description, succesStyle)
	},

	run() {
		if (!this.get('assertions').length) { return this }

		this.get('assertions').forEach(assertion => {
			Ember.Logger.log(assertion.description.log, assertion.description.style)
			Ember.Logger.log(assertion)
			if (assertion.fail) {
				Ember.Logger.log(assertion.expected.log, assertion.expected.style)
				Ember.Logger.log(assertion.actual.log, assertion.actual.style)
			}
		})

		this.set('assertions', [])
		this.set('multipleAssertions', false)

		return this
	}
})
