import Ember from 'ember';

export default Ember.Route.extend({
    i18n: Ember.inject.service(),
    model ( ) {
        return Ember.RSVP.hash({
            vehicles:   this.get("store").findAll("vehicle"),
            timetables: this.get("store").findAll("timetable")
        });
    },
    actions: {
        willTransition() {
            Ember.$('.managementModal').modal("hide");
            this.controller.rollBackAndTransition();
        },
    }
});
