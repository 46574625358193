import Ember from 'ember'
import moment from 'moment'

export default Ember.Component.extend({

	minute: [0],
	hour: [0],
	weekDay: null,
	monthDay: null,

	weekDaysCorespondency: null,

	value: null,
	values: null,

	allowMultiple: true,

	sendFormula() {
		let minute = !Ember.isEmpty(this.get('minute')) ? this.get('minute').sort((a, b) => a - b).join(',') : ['*']
		let hour = !Ember.isEmpty(this.get('hour')) ? this.get('hour').sort((a, b) => a - b).join(',') : ['*']
		let weekDay = !Ember.isEmpty(this.get('weekDay')) ? this.get('weekDay').map(day => this.get('weekDaysCorespondency')[day]).sort((a, b) => a - b).join(',') : ['*']
		let monthDay = !Ember.isEmpty(this.get('monthDay')) ? this.get('monthDay').sort((a, b) => a - b).join(',') : ['*']
		let formula = this.makeFormulla(minute + ' ' + hour + ' ' + monthDay + ' * ' + weekDay)
		if (this.get('manageFormula')) {
			this.get('manageFormula')(formula)
		}
		return formula
	},

	makeFormulla(value) {
		let cron = value.replace(/\s+/g, " ").split(" ")
		let frequency = { base: "1" } // default: every minute
		let tempArray = []
		let baseFrequency = {
			minute: 1,
			hour: 2,
			day: 3,
			week: 4,
			month: 5,
			year: 6
		}
		let allowMultiple = this.get('allowMultiple')

		if (cron[0] === "*" && cron[1] === "*" && cron[2] === "*" && cron[3] === "*" && cron[4] === "*") {
			frequency.base = baseFrequency.minute // every minute
		} else if (cron[1] === "*" && cron[2] === "*" && cron[3] === "*" && cron[4] === "*") {
			frequency.base = baseFrequency.hour // every hour
		} else if (cron[2] === "*" && cron[3] === "*" && cron[4] === "*") {
			frequency.base = baseFrequency.day // every day
		} else if (cron[2] === "*" && cron[3] === "*") {
			frequency.base = baseFrequency.week // every week
		} else if (cron[3] === "*" && cron[4] === "*") {
			frequency.base = baseFrequency.month // every month
		} else if (cron[4] === "*") {
			frequency.base = baseFrequency.year // every year
		}

		if (cron[0] !== "*") {
			//preparing to handle multiple minutes
			if (allowMultiple) {
				tempArray = cron[0].split(',')
				for (let i = 0; i < tempArray.length; i++) { tempArray[i] = +tempArray[i] }
				frequency.minuteValues = tempArray
			} else {
				frequency.minuteValues = parseInt(cron[0])
			}
		}
		if (cron[1] !== "*") {
			//preparing to handle multiple hours
			if (allowMultiple) {
				tempArray = cron[1].split(",")
				for (let i = 0; i < tempArray.length; i++) { tempArray[i] = +tempArray[i] }
				frequency.hourValues = tempArray
			} else {
				frequency.hourValues = parseInt(cron[1])
			}
		}
		if (cron[2] !== "*") {
			//preparing to handle multiple days of the month
			if (allowMultiple) {
				tempArray = cron[2].split(",")
				for (let i = 0; i < tempArray.length; i++) { tempArray[i] = +tempArray[i] }
				frequency.dayOfMonthValues = tempArray
			} else {
				frequency.dayOfMonthValues = parseInt(cron[2])
			}
		}
		if (cron[3] !== "*") {
			//preparing to handle multiple months
			if (allowMultiple) {
				tempArray = cron[3].split(",")
				for (let i = 0; i < tempArray.length; i++) { tempArray[i] = +tempArray[i] }
				frequency.monthValues = tempArray
			} else {
				frequency.monthValues = parseInt(cron[3])
			}
		}
		if (cron[4] !== "*") {
			//preparing to handle multiple days of the week
			if (allowMultiple) {
				tempArray = cron[4].split(",")
				for (let i = 0; i < tempArray.length; i++) { tempArray[i] = +tempArray[i] }
				frequency.dayValues = tempArray
			} else {
				frequency.dayValues = parseInt(cron[4])
			}
		}

		let formula = ""
		formula += (frequency.minuteValues ? frequency.minuteValues.join(',') : '*') + ' '
			+ (frequency.hourValues ? frequency.hourValues.join(',') : '*') + ' '
			+ (frequency.dayOfMonthValues ? frequency.dayOfMonthValues.join(',') : '*') + ' '
			+ '* '
			+ (frequency.dayValues ? frequency.dayValues.join(',') : '*')

		return formula
	},

	getWeekDaysCorespondency() {
		let weekDays = moment.weekdays()
		let obj = {}
		weekDays.forEach((item, idx) => {
			obj[item] = idx
		})
		return obj
	},

	getValues() {
		return ['day', 'week', 'month']
	},

	init() {
		this._super(...arguments)
		this.set('weekDaysCorespondency', this.getWeekDaysCorespondency())
		this.set('values', this.getValues())
	},

	actions: {
		setValue(value) {
			this.set('value', value)

			if (value === 'day') {
				this.set('weekDay', null)
				this.set('monthDay', null)
			} else if (value === 'week') {
				this.set('weekDay', ['luni'])
				this.set('monthDay', null)
			} else if (value === 'month') {
				this.set('weekDay', null)
				this.set('monthDay', [1])
			}
			this.sendFormula()
		},
		setHour(hour) {
			this.set('hour', hour)
			this.sendFormula()
		},
		setMinute(minute) {
			this.set('minute', minute)
			this.sendFormula()
		},
		setWeekDay(weekDay) {
			this.set('weekDay', weekDay)
			this.sendFormula()
		},
		setMonthDay(monthDay) {
			this.set('monthDay', monthDay)
			this.sendFormula()
		}
	}
})
