export default {
    emptyName: 'Imię jest wymagane..',
    missingCoordinates: 'Brakujące koordynaty.',
    invalidAlerStationaryTime: 'Niewłaściwy czas postoju. Wprowadz liczbę między 5 a 60.',
    insufficientPolygonPoints: 'Niewystarczająca liczba punktów (minimum 3)',
    errorsExists: 'Błąd.',
    plaseTryAgainLater: 'Proszę spróbować później',
    numberWithoutComma: 'Uzyj "." zeby oddzielić części dziesiętne.',
    errorsExistsCheckData: 'Znaleziono błędy. Proszę sprawdzić dane.',
}
