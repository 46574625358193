import Ember from 'ember'

const { service } = Ember.inject

export default Ember.Component.extend({
    appSettings: service('application-settings'),
    map: Ember.computed.alias('appSettings.map'),
    i18n: service(),
    sidebar: service(),
    resolution: service(),
    classNames: ['vehicle-list'],
    showSettings: false,
    
    showMenuAnyway: false,

    showMenu: Ember.computed("isMobile", "showMenuAnyway", function () {
        return (this.get("isMobile") ? false : true) || ( this.get( "showMenuAnyway" ) ) ;
    }),

    polygons: Ember.computed.filterBy("geofences", "type", "geofence"),
    points: Ember.computed.filterBy("geofences", "type", "poi"),
    hasGeofences: Ember.computed.notEmpty("geofences"),

    init() {
        this._super(...arguments)
        Ember.$.fn.dropdown.settings.message = {
            addResult: 'Add <b>{term}</b>',
            count: '{count} ' + this.get("i18n").t("selectedFilters"),
            maxSelections: 'Max {maxCount} selections',
            noResults: 'No results found.'
        }
    },

    didInsertElement() {
        this.get('sidebar').setTo(this.$().parents('#map-menu-col'))

        /**
         * hack for close the multiple dropdown on click when opened (Semantic UI BUG)
         * https://github.com/Semantic-Org/Semantic-UI/issues/2982
         */
        Ember.$(document).on('click', '.vehiclesFilters', function (e) {
            let dropdown = Ember.$('.vehiclesFilters')

            if (e.target !== this && !Ember.$(e.target).hasClass("excludeThisOnHack")) { // exclude childrens, allow only "excludeThisOnHack" class
                return false
            }
            if (dropdown.hasClass('closeDDHack')) {
                if (dropdown.dropdown('is active')) {
                    dropdown.dropdown('hide')
                }
            }
            else {
                dropdown.addClass("closeDDHack")
            }
        })

        if ( this.get( "isMobile" ) ) {
            this.set( "showMenuAnyway", false );
        }
    },
    
    willDestroyElement() {
        Ember.$(document).off('click', ".vehiclesFilters")
    },

    actions: {
        vehiclesFiltersHide() { // remove the closeDDHack class on dropdown hide
            Ember.$('.vehiclesFilters').removeClass("closeDDHack")
        },
        searchKeyUp() {
            this.set("showMenuAnyway", true)
        },
        showTab(tabNumber) {
            this.get("selectTab")(tabNumber)
        },
        selectGeofence(geofence) {
            this.get("zoomInGeofence")(geofence)
        },
        mouseEnter(vehicleId) {
            this.get('vehicleHover')(vehicleId)
        },
        mouseLeave(/*vehicleId*/) {
            this.get('vehicleHover')(null)
        },
        toggleProperty(propName) {
            this.toggleProperty(propName)
        },
        toggleDetails(selected) {
            this.get("toggleDetails")(selected)
            if (this.get("isMobile")) {
                this.set(" showSettings", false )
            }
        },

        setMapClient ( mapClient ) {
            this.sendAction( "setMapClient", mapClient );
        }
    }
})
