import Base from './base'
import Ember from 'ember'
import moment from 'moment'
import PropsInit from '../../mixins/reports/controller-props-initialization'

let romLatLng = {
    lat: 45.9432,
    lng: 24.9668
}
let romZoom = 230000

const { service } = Ember.inject

export default Base.extend(PropsInit, {
    markerService: service('map/shapes/marker'),
    reportsInfo: service('reports/reports-info'),
    mapUtil: service('map/util'),

    reportTitle: 'DETAILED-STOPS',
    sortProperties: ['startTime:asc'],
    bounds: Ember.computed("markers", function () {
        let markersLatLng = this.get("markers").mapBy("location");
        if (markersLatLng.length > 0) {
            return this.get('mapUtil').calculateBoundsZoom(markersLatLng)
        }
        return this.get('mapUtil').calculateBoundsZoom(romLatLng, romZoom)
    }),

    markers: Ember.computed('completedRecords.[]', function () {
        let markers = []
        this.get('completedRecords').forEach(item => {
            let location = this.get('mapUtil').transformCoordinatesLatLng(item.gps.coordinates)
            let duration = moment("1900-01-01 00:00:00").add(item.duration, 'seconds').format("mm:ss")
            let popupText = ''
            // let lastUpdated = ''

            popupText += item.licensePlate + "<br>"
            popupText += 'Start ' + moment(item.startTime).format("HH:mm DD/MM/YYYY") + '<br>'
            popupText += 'Stop ' + moment(item.endTime).format("HH:mm DD/MM/YYYY") + '<br>'
            popupText += this.get('i18n').t('duration') + ' ' + duration + '<br>'
            if (item.altitude) {
                popupText += this.get('i18n').t('altitude') + ' ' + item.gps.altitude + '<br>'
            }

            if (item.speed) {
                popupText += this.get('i18n').t('speed') + ' ' + item.gps.speed
            }
            //lastUpdated = moment(vehicle.status.updatedAt).format("HH:mm DD/MM/YYYY")
            // popupText += lastUpdated
            markers.push({
                location,
                popupText: Ember.String.htmlSafe(popupText),
            })
        })
        return markers
    })
})
