import DS from 'ember-data';
import MF from 'ember-data-model-fragments';

export default MF.Fragment.extend({
    speed: DS.attr('number'),
    rpm: DS.attr('number'),
    acceleration: DS.attr('number'),
    distance: DS.attr('number'),
    engineLoad: DS.attr('number'),
    engineTemperature: DS.attr('number'),
    fuel: MF.fragment('fragments/vehicle/status/can/fuel'),
});
