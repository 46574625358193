import Ember from 'ember'
import UiModal from 'semantic-ui-ember/components/ui-modal'

export default UiModal.extend({
    name: 'modalAddFueling',
    elementId: 'modalAddFueling',
    classNames: ['modalAddFueling small'],

    reset() {
        Ember.$('form.addFueling').form("clear")
        Ember.$('form.addFueling .ui.error.message').empty()
        if (this.get("refuel.isNew")) {
            this.get("refuel").rollbackAttributes()
        }
        else {
            this.set("refuel", null)
        }
        this.set('err', null);
    },

    init() {
        this._super(...arguments)
        Ember.$('.ui.dimmer.modals #modalAddFueling').remove();
    },

    didUpdateAttrs() {
        if (!this.get("refuel")) { return false }

        if (this.get("vehicleID")) {
            this.set("refuel.vehicleID", this.get("vehicleID"))
        }
        if (this.get("fuelingValues.location")) {
            this.set("refuel.location", this.get("fuelingValues.location"))
        }
        if (this.get("fuelingValues.timestamp")) {
            this.set("refuel.timestamp", this.get("fuelingValues.timestamp"))
        }
    },

    didInsertElement() {
        Ember.$('#modalAddFueling')
            .modal({
                duration: 200,
                onHidden: () => {
                    this.reset();
                },
                onShow: () => {
                    let refuel = this.store.createRecord('refuel', {
                        vehicleID: this.get("vehicleID") ? this.get("vehicleID") : null,
                        timestamp: null,
                        amount: null,
                        location: null,
                        price: null,
                        groupID: null,
                        invoice: null,
                        createdAt: null,
                        updatedAt: null,
                    });
                    this.set("refuel", refuel)
                }
            })
            .modal('is active');
        Ember.$('form.addFueling')  // form validation
            .form({
                on: 'submit',
                fields: {
                    timestamp: {
                        identifier: 'timestamp',
                        rules: [{
                            type: 'empty',
                            prompt: '<b>{name}</b> trebuie sa aiba o valoare.'
                        }]
                    },
                    amount: {
                        identifier: 'amount',
                        rules: [
                            {
                                type: 'empty',
                                prompt: '<b>{name}</b> trebuie sa aiba o valoare.'
                            },
                            {
                                type: 'number',
                                prompt: '<b>{name}</b> trebuie sa fie numar.'
                            }
                        ]
                    },
                },
            })
    },

    addFueling() {
        let refuel = this.get("refuel")
        refuel.save()
            .then(() => {
                if (this.get("refreshVehicleTable")) { // this will be used only from fuel-level reports
                    this.get("refreshVehicleTable")()
                }
                this.execute('hide');
            })
            .catch((err) => {
                this.set('err', err);
            });
    },

    actions: {
        yes() {
            // validate form
            if (!Ember.$('form.addFueling').form("validate form")) {
                return false
            }

            this.addFueling();
        },
        no() {
            this.execute('hide');
        }
    }
});