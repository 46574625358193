import Ember from 'ember'
import moment from 'moment'
import config from '../../config/environment'

const { service } = Ember.inject
const markerIndex = 1000
const selectedMarkerZIndex = 2000
const selectedMarkerColor = 'blue'
const selectedNoDetailsMakerColor = 'big'

export default Ember.Controller.extend({
	i18n: service(),
	session: service(),
	polygon: service('map/shapes/polygon'),
	poi: service('map/shapes/poi'),
	tail: service('map/tail'),
	mapUtil: service('map/util'),
	markerService: service('map/shapes/marker'),
	sidebar: service('sidebar'),
	appSettings: service('application-settings'),
	selectedVehicle: null,
	activeMarker: null,
	isRefresh: false,
	selectedFilters: null,
	searchValue: '',
	selectedTab: 1,
	focusBounds: null,
	detailsType: "no_details",
	mapInstance: null,

    /**
	 * Can't find a place where this property is being used.
	 * fitToMarkers:   Ember.computed.not('isRefresh'),
	 */

	mapInFocus: false,
	//dropdown Filters
	vehicleFilters: Ember.computed("selectedFilters", function () {
		let selectedFilters = this.get("selectedFilters")
		if (!selectedFilters || !selectedFilters.length) { return null }
		let freezer = [] // "on", "off"
		let doors = [] // "open", "closed"
		let statusIndicator = [] // "unreachable", "engineOn", "engineOff"

		selectedFilters.forEach(filter => {
			if (filter.indexOf("freezer-") != -1) {
				let status = filter.split("-")[1]
				freezer.push(status)
			}
			else if (filter.indexOf("doors-") != -1) {
				let status = filter.split("-")[1]
				doors.push(status)
			}
			else if (filter.indexOf("statusIndicator-") != -1) {
				let status = filter.split("-")[1]
				statusIndicator.push(status)
			}
		})
		return {
			freezer,
			doors,
			statusIndicator,
		}
	}),
	searchVehicles: Ember.computed("selectedTab", "searchValue", function () {
		if (this.get("selectedTab") == 1 && this.get("searchValue")) {
			return this.get("searchValue").toLowerCase()
		}
		return ""
	}),
	searchGeofences: Ember.computed("selectedTab", "searchValue", function () {
		if (this.get("selectedTab") == 2 && this.get("searchValue")) {
			return this.get("searchValue").toLowerCase()
		}
		return ""
	}),

	filtredVehicles: Ember.computed("model.vehicles.@each.status", "vehicleFilters", "searchVehicles", "selectedTab", function () {
		let vehicles = this.get("model.vehicles")
		let vehiclesTab = this.get("selectedTab") == 1
		let filters = this.get("vehicleFilters")
		let searchValue = vehiclesTab ? this.get("searchVehicles") : ""
		if (!filters && !searchValue) { return vehicles }

		return vehicles.filter(vehicle => {
			let passedSearch = false
			let passedFreezer = false
			let passedDoors = false
			let passedStatus = false
			// search filter
			if (!searchValue || vehicle.get("group.name").toLowerCase().indexOf(searchValue) != -1 ||
				(vehicle.get("user.fullName") && vehicle.get("user.fullName").toLowerCase().indexOf(searchValue) != -1) ||
				vehicle.get("licensePlate").toLowerCase().indexOf(searchValue) != -1) {
				passedSearch = true
			}

			// dropdown Filters
			if (!filters) {
				passedFreezer = true
				passedDoors = true
				passedStatus = true
			}
			else {
				let freezer = filters.freezer
				let doors = filters.doors
				let statusIndicator = filters.statusIndicator
				if (!freezer.length) {
					passedFreezer = true
				} else if (vehicle.get("hasFreezerSensor")) {
					freezer.forEach(freezerStatus => {
						if (freezerStatus === "on" && vehicle.get("status.freezer")) {
							passedFreezer = true
						}
						else if (freezerStatus === "off" && vehicle.get("status.freezer") === false) {
							passedFreezer = true
						}
					})
				}
				if (!doors.length) {
					passedDoors = true
				} else if (vehicle.get("hasDoorSensor")) {
					doors.forEach(doorsStatus => {
						if (doorsStatus === "open" && vehicle.get("status.doors.length")) {
							passedDoors = true
						}
						else if (doorsStatus === "closed" && vehicle.get("status.doors.length") === 0) {
							passedDoors = true
						}
					})
				}
				if (!statusIndicator.length) {
					passedStatus = true
				} else if (vehicle.get("statusIndicator")) {
					statusIndicator.forEach(state => {
						if (vehicle.get("statusIndicator") === state) {
							passedStatus = true
						}
					})
				}
			}
			if (passedSearch && passedFreezer && passedDoors && passedStatus) {
				return true
			}
			else {
				return false
			}
		})
	}),

	filtersDetails: Ember.computed("filtredVehicles", function () {
		let filtersDetails = {
			"freezerOn": 0,
			"freezerOff": 0,
			"statusIndicatorUnreachable": 0,
			"statusIndicatorEngineOn": 0,
			"statusIndicatorEngineOff": 0,
			"doorsOpen": 0,
			"doorsClosed": 0,
		}
		this.get("filtredVehicles").forEach(vehicle => {
			if (vehicle.get("hasFreezerSensor")) {
				if (vehicle.get("status.freezer")) {
					filtersDetails["freezerOn"] += 1
				}
				else if (vehicle.get("status.freezer") === false) {
					filtersDetails["freezerOff"] += 1
				}
			}
			if (vehicle.get("hasDoorSensor")) {
				if (vehicle.get("status.doors.length")) {
					filtersDetails["doorsOpen"] += 1
				}
				else if (vehicle.get("status.doors.length") === 0) {
					filtersDetails["doorsClosed"] += 1
				}
			}
			if (vehicle.get("statusIndicator")) {
				switch (vehicle.get("statusIndicator")) {
					case "unreachable":
						filtersDetails["statusIndicatorUnreachable"] += 1
						break
					case "engineOn":
						filtersDetails["statusIndicatorEngineOn"] += 1
						break
					case "engineOff":
						filtersDetails["statusIndicatorEngineOff"] += 1
						break
					default:
						break
				}
			}
		})
		return filtersDetails
	}),

	groupedVehicles: Ember.computed("filtredVehicles", function () {
		let groupedVehicles = []

		this.get("filtredVehicles").forEach(vehicle => {
			if (vehicle.get("group") && vehicle.get("group.id") && groupedVehicles.isAny("id", vehicle.get("group.id"))) {
				groupedVehicles.findBy("id", vehicle.get("group.id")).get("vehicles").pushObject(vehicle)
			}
			else if (vehicle.get("group") && vehicle.get("group.id")) {
				let vehGrp = vehicle.get("group")
				let group = Ember.Object.create({
					id: vehGrp.get("id"),
					name: vehGrp.get("name"),
					companyID: vehGrp.get("companyID"),
					timetableID: vehGrp.get("timetableID"),
					details: vehGrp.get("details"),
					vehicles: [vehicle]
				})
				groupedVehicles.pushObject(group)
			}
		})
		return groupedVehicles
	}),

	init: function () {
		this._super(...arguments)
		let detailsType = this.get("session.session.detailsType")
		if (!Ember.isEmpty(detailsType)) {
			this.set("detailsType", detailsType)
		}
	},

	resetValues() { // called from route.willTransition to reset values on route change
		this.set("selectedTab", 1)
		this.set("searchValue", "")
		this.set("focusBounds", null)
		this.set("selectedFilters", null)
	},

	geofences: Ember.computed("model.geofences", "selectedTab", "searchGeofences", function () {
		if (!this.get("searchGeofences") || this.get("selectedTab") != 2) { return this.get("model.geofences") }
		let searchValue = this.get("searchGeofences")
		return this.get("model.geofences").filter((item) => {
			if (item.get("name") && item.get("name").toLowerCase().indexOf(searchValue) > -1) {
				return true
			}
			return false
		})
	}),

	polygons: Ember.computed('geofences', function () {
		return this.get('polygon').polygons(this.get('geofences'))
	}),

	pois: Ember.computed('geofences', function () {
		return this.get('poi').pois(this.get('geofences'))
	}),

	vehicleMarkers: Ember.computed('filtredVehicles.@each.status', 'selectedVehicle', 'detailsType', function () {
		// Ember.Logger.log('%cCONTROLLER VEHICLE MARKERS', 'color: blue')
		let vehicles = this.get('filtredVehicles')
		let markerList = []
		let tails = this.get("tail")
		let detailsType = this.get('detailsType') == "no_details" ? false : this.get('detailsType')
		let markerLabelInfoType = null
		let selectedVehicleId = this.get("selectedVehicle.id")
		let markerStyle = detailsType ? selectedMarkerColor : selectedNoDetailsMakerColor
		if (Ember.isEmpty(vehicles)) {
			return []
		}
		if (!tails.getMapInstance()) {
			tails.setMapInstance(this.get("mapInstance"))
		}

		if (detailsType) {
			markerLabelInfoType = detailsType
		}

		vehicles.forEach(vehicle => {
			let markerClasses = markerStyle;
			if (!vehicle.get('status.location') || !vehicle.get('status.location').length) { return false }
			switch (vehicle.get("statusIndicator")) {
				case "unreachable":
					markerClasses += " red"
					break;
				case "engineOn":
					markerClasses += " green"
					break;
				case "engineOff":
					markerClasses += " neutral"
					break;
			}
			// We need this "lastPolling" property because the statusIndicator won't show the unreachable case because for that case the status is not updated,
			// therefore it does not trigger the computed property callback and we need to trigger that on each polling.
			// vehicle.set("lastPolling", moment())
			if (selectedVehicleId != vehicle.get("id")) {
				let marker = this.makeMarker(vehicle, markerLabelInfoType)
				if (marker) {
					marker.options.zIndexOffset = markerIndex
					markerList.push(marker)
				}
			} else {
				let marker = this.makeMarker(this.get("selectedVehicle"), markerLabelInfoType, markerClasses)
				if (marker) {
					marker.options.zIndexOffset = selectedMarkerZIndex

					markerList.push(marker)
				}
			}
			if (tails.getMapInstance()) {
				tails.pushStatus(vehicle)
			}
		})

		// Ember.Logger.log('TAIL', tails.getTail())
		return markerList
	}),

	makeMarker: function (vehicle, requiredInfo, explicitColor) {
		let i18n = this.get("i18n")
		if (Ember.isEmpty(vehicle)) {
			return null
		}
		var location = vehicle.get("status.location")
		if (Ember.isEmpty(location)) {
			return null
		}
		var lastUpdated = moment(vehicle.get("status.updatedAt")).format("HH:mm DD/MM - YYYY")

		var driverName = ""
		if (!Ember.isEmpty(vehicle.get("user.firstName"))) {
			driverName += vehicle.get("user.firstName")
		}
		if (!Ember.isEmpty(vehicle.get("user.lastName"))) {
			driverName += " " + vehicle.get("user.lastName")
		}

		var markerOptions = {
			id: vehicle.get("id"),
			label: vehicle.get("licensePlate"),
			location: location,
		}
		markerOptions.content = Ember.String.htmlSafe("<table><tbody>"
			+ "<tr><td>" + vehicle.get("licensePlate") + " </td><td>" + (driverName ? ("<br />" + driverName) : "") + "</td></tr>"
			+ "<tr><td>"+ i18n.t("markers.lastUpdate") +": </td><td>" + lastUpdated + "</td></tr>"
			+ "<tr><td>" + i18n.t("markers.engineState") + ": </td><td>" + (vehicle.get("status.engineState") ? i18n.t("vehicle.engineOn") : i18n.t("vehicle.engineOff")) + "</td></tr>"
			+ "<tr><td>" + i18n.t("markers.gpsPosition") + ": </td><td>" + location[1] + " N <br />" + location[0] + " V</td></tr>"
			+ "<tr><td>" + i18n.t("markers.gpsSpeed") + ": </td><td>" + vehicle.get("status.speed") + " km/h</td></tr>"
			+ "<tr><td>" + i18n.t("markers.canSpeed") + ": </td><td>" + (vehicle.get("status.can.speed") ? vehicle.get("status.can.speed") : "0") + " km/h</td></tr>"
			+ "<tr><td>" + i18n.t("markers.engineLoad") + ": </td><td>" + (vehicle.get("status.can.engineLoad") ? vehicle.get("status.can.engineLoad") : "--") + " %</td></tr>"
			+ "<tr><td>" + i18n.t("markers.engineTemperature") + ": </td><td>" + (vehicle.get("status.can.engineTemperature") ?  Number((vehicle.get("status.can.engineTemperature")) / 10).toFixed(1) : "--.-") + " °C</td></tr>"
			+ "<tr><td>" + i18n.t("markers.canAcceleration") + ": </td><td>" + (vehicle.get("status.can.acceleration") ? vehicle.get("status.can.acceleration") : "0") + "</td></tr>"
			+ (vehicle.get("status.can.rpm") ? "<tr><td>" + i18n.t("markers.canRPM") + ": </td><td>" + vehicle.get("status.can.rpm") + "</td></tr>" : "")
			+ (vehicle.get("status.can.fuel.level.firstObject") ?
				(
					"<tr><td>" + i18n.t("markers.canFuelAvailable") + ": </td><td>"
					+ vehicle.get("status.can.fuel.level.firstObject") / 100 + " l"
					+ (vehicle.get("status.can.fuel.level.lastObject") ? (" " + vehicle.get("status.can.fuel.level.lastObject") + " %") : "")
					+ "</td></tr>")
				:
				""
			)
			+ (vehicle.get("status.can.fuel.used") ?
				(
					"<tr><td>" + i18n.t("markers.canFuelUsed") + ": </td><td>"
					+ vehicle.get("status.can.fuel.usedLiters")
					+ " l</td></tr>"
				)
				:
				""
			)
			+ (vehicle.get("status.fuelLevel") ?
				(
					"<tr><td>" + i18n.t("markers.probeFuelAvailable") + ": </td><td>"
					+ vehicle.get("status.fuelLevel")
					+ " l</td></tr>"
				)
				:
				""
			)
			+ (vehicle.get("status.odometer") ? ("<tr><td>" + i18n.t("markers.distance") + ": </td><td>" + vehicle.get("status.odometer") + "km</td></tr>") : "")
			+ (vehicle.get('hasDoorSensor') ? ("<tr><td>" + i18n.t("markers.doors") + ": </td><td>" + vehicle.get("doorHoverText") + "</td></tr>") : "")
			+ (vehicle.get('hasFreezerSensor') ? ("<tr><td>" + i18n.t("markers.agregation") + ": </td><td>" + (vehicle.get("status.freezer") ? 'Pornit' : 'Oprit') + "</td></tr>") : "")
			+ ((vehicle.get('hasFreezerSensor')) ? ("<tr><td>" + i18n.t("markers.temperature") + ": </td><td>" + (vehicle.get("status.freezer") ? (vehicle.get("status.temperature") ? vehicle.get("status.temperature").toFixed(1) : vehicle.get("status.temperature")) : "--") + " °C</td></tr>") : "")
			+ '<tr><td colspan="2"><a href="' + config.rootURL + 'map/routes/' + vehicle.get('id') + '">' + i18n.t('details') + '</a><td></tr>'
			+ "</tbody></table>")

		switch (requiredInfo) {
			case "license_plates":
				markerOptions.type = "info"
				break
			case "driver_names":
				markerOptions.type = "info"
				markerOptions.label = driverName.length ? driverName : vehicle.get("licensePlate")
				break
			default:
				markerOptions.type = "default"
				break
		}

		markerOptions.label = markerOptions.label.substr(0, 20)

		if (vehicle.get('statusIndicator') !== 'unreachable') {
			markerOptions.label += " - " + vehicle.get("status.speed") + " km/h"
		}

		// verde - in miscare, galben - stationare, rosu - offline

		if (explicitColor) {
			markerOptions.color = explicitColor
		} else if (vehicle.get("statusIndicator") === "unreachable") {
			markerOptions.color = "red"
		} else if (vehicle.get("statusIndicator") === "engineOff") {
			markerOptions.color = "neutral"
		} else {
			markerOptions.color = "green"
		}

		return this.get("markerService").makeMarker(markerOptions.type, markerOptions)
	},

	mapType: Ember.computed("session.session.map", function () {
		if ( this.get( "session.session.map" ) ) {
			return this.get( "session.session.map" );
		} else {
			return this.get( "appSettings.map" );
		}
	} ),

	actions: {
		selectTab(tabNumber) {
			this.set("selectedTab", tabNumber)
			this.set("searchValue", "")
			this.set("selectedFilters", null)
			this.set('activeMarker', null)
		},
		vehicleHover(vehicleID) {
			// Ember.Logger.log('%cCONTROLLER vehicleHoverAction', 'color: blue')
			if (this.get("activeMarker")) {
				this.set('activeMarker', null)
			}
			let selectedVehicle = vehicleID ? this.get("filtredVehicles").findBy("id", vehicleID) : null
			this.set('selectedVehicle', selectedVehicle)
		},
		zoomInGeofence(geofence) {
			let latlngCoords = this.get("mapUtil").transformCoordinatesLatLng(geofence.get("shape.coordinates"))
			let zoom = geofence.get("boundary")
			let bounds = this.get('mapUtil').calculateBoundsZoom(latlngCoords, zoom)
			this.set("focusBounds", bounds)
		},
		selectVehicle(vehicleId) {
			// Ember.Logger.log('%cCONTROLLER selectVehicleAction', 'color: blue')
			this.transitionToRoute('/map/routes/' + vehicleId)
		},
		activateClickedMarker(e) {
			// Ember.Logger.log('%cCONTROLLER activateClickedMarkerAction', 'color: blue')
			this.set('activeMarker', e.options.id)
		},
		clearcAtiveMarker() {
			// Ember.Logger.log('%cCONTROLLER clearActiveMarkerAction', 'color: blue')
			this.set('activeMarker', null)
		},
		toggleDetails(detailsType) {
			// Ember.Logger.log('%cCONTROLLER toggleDetailsAction', 'color: blue')
			this.set("session.session.detailsType", detailsType)
			this.set("detailsType", detailsType)
		},
		setMapInstance: function (mapInstance) {
			// Ember.Logger.log('%cCONTROLLER setMapInstanceAction', 'color: blue')
			let tails = this.get('tail')
			this.set("mapInstance", mapInstance)
			tails.setMapInstance(mapInstance)
		},

		setMapClient: function  (mapClient ) {
			this.set( "session.session.map", mapClient );
			this.set( "appSettings.map", mapClient );
		}
	}
})
