import Ember from 'ember'
import DS from 'ember-data'
import MF from 'ember-data-model-fragments'

export default MF.Fragment.extend({
    // vehicle: DS.belongsTo('vehicle', {allowNull: true}),
    vehicle_id: DS.attr('string'),
    vehicle: Ember.computed('vehicle_id', function () {
        let id = this.get('vehicle_id')
        if (!id) { return null }

        let vehicle = this.store.peekRecord('vehicle', id)
        return vehicle ? vehicle : this.store.findRecord('vehicle', id)
    }),
    geofence_id: DS.attr('string'),
    geofence: Ember.computed('geofence_id', function () {
        let id = this.get('geofence_id')
        if (!id) { return null }

        let geofence = this.store.peekRecord('geofence', id)
        return geofence ? geofence : this.store.findRecord('geofence', id)
    }),
    document_id: DS.attr('string'),
    document: Ember.computed('document_id', function () {
        let id = this.get('document_id')
        if (!id) { return null }

        let document = this.store.peekRecord('document', id)
        return document ? document : this.store.findRecord('document', id)
    }),
})
