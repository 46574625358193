import Ember from 'ember';

export default Ember.Service.extend({
	group(collection, by) {

		if (!collection || !collection.length || !by) { return [] }

		let result = []

		collection.forEach(function (item) {
			/**
			 * Make this function work with regular plain objects too.
			 */
			let itemGetBy = item.get ? item.get(by) : item[by]

			let hasType = result.findBy(by, itemGetBy)

			if (!hasType) {
				let obj = Ember.Object.create({
					contents: []
				})
				obj.set(by, itemGetBy)
				result.pushObject(obj)
			}

			result.findBy(by, itemGetBy).get('contents').pushObject(item)
		});
		return result;
	},

	/**
	 * Group array by given key. Each key value will be a property of the new object.
	 * It workes with POJO and Ember objects.
	 * @param {*} collection
	 * @param {*} by grouping key
	 * @return {object}
	 */
	groupToObject(collection, by) {
		if (!collection || !collection.length || !by) { return [] }

		let results = {}

		collection.forEach((item) => {
			/**
			 * Make this function work with POJO too.
			 */
			let itemGetBy = item.get ? item.get(by) : item[by]

			if (!results[itemGetBy]) {
				results[itemGetBy] = []
			}

			results[itemGetBy].push(item)
		})

		return results
	},

	/**
	 * Takes two arrays of simple objects and compare them.
	 * @param {*} firstArray
	 * @param {*} secondArray
	 * @param {string} excludeKey from comparison
	 * @return {boolean}
	 */
	compareTwoArrayOfSimpleObjects(firstArray, secondArray, excludeKey) {
		if (!firstArray || !secondArray) { return false }
		if (firstArray.length !== secondArray.length) { return false }
		let firstObject = firstArray[0]
		let keys = Object.keys(firstObject)
		let equal = true
		firstArray.forEach((object, idx) => {
			keys.forEach(key => {
				if (key === excludeKey) { return false }
				if (object[key] !== secondArray[idx][key]) { equal = false }
			})
		})

		return equal
	},

	/**
	 * Compare two simple arrays. e.g. [1, 2, 3] with [1, 2, 3]
	 * @param {array} arrayOne
	 * @param {array} arrayTwo
	 * @return {boolean}
	 */
	isEqual(arrayOne, arrayTwo) {
		if (!Ember.isArray(arrayOne) || !Ember.isArray(arrayTwo)) { throw 'Invalid arguments' }
		return (arrayOne.length == arrayTwo.length && arrayOne.every((element, index) => element === arrayTwo[index]))
	},

	/**
	 * E.g. testArray = [0, 2, 3, 3, 3, 3, 3, 3, 8, 9, 10, 10, 10, 11, 11, 11, 12, 12]
	 * cleanedArray = [0, 2, 3, 3, 8, 9, 10, 10, 11, 11, 12, 12]
	 * @param {array} arr
	 * @return {array}
	 */
	removeConsecutiveSameValues(arr) {
		let filtered = arr.filter((item, pos, arr) => {
			if (arr[pos + 1]) {
				return pos === 0 || (item !== arr[pos - 1] || item !== arr[pos + 1])
			} else {
				return true
			}
		})

		return filtered
	}
});
