import Ember from 'ember';

export default Ember.Route.extend({
    i18n: Ember.inject.service(),
    currentUser: Ember.inject.service('current-user'),

    model() {
        return this.store.peekAll('timetable');
    },
    
    actions: {
        view(ttID) {
            if (ttID) {
                this.transitionTo( 'management.timetables.view' , ttID );
            }
        },
        new () {
            this.transitionTo( 'management.timetables.new' );
        },
        edit(ttID) {
            if (ttID) {
                this.transitionTo( 'management.timetables.edit', ttID );
            }
        },
        import () {
            this.transitionTo( "management.timetables.import" );
        },
        delete(id) {
            var _this = this;
            var isDefaultCompanyTimetable = false;
            if (!id) { return false }

            var timetable = this.store.peekRecord('timetable', id);

            if (!timetable) { return false }

            var companyID = this.get('currentUser.user.companyID');
            var companyTimetable = this.store.peekRecord('group', companyID);
            if (id === companyTimetable.get("timetableID")) {
                isDefaultCompanyTimetable = true;
                Ember.$("span#isDefaultCompanyTimetable").html('<h3><i class="warning sign icon"></i> ' + _this.get('i18n').t('management.timetables.isDefaultCompanyTimetable') + '</h3>');
            }
            else {
                Ember.$("span#isDefaultCompanyTimetable").html('');
            }
            Ember.$("span#timeTableName").html(timetable.get("name"));


            Ember.$('.modal.deleteTimetable')
                .modal({
                    onApprove: function () {
                        // set company timetableID to null
                        if (isDefaultCompanyTimetable) {
                            companyTimetable.set("timetableID", null);
                            companyTimetable.save()
                                .catch(function (err) {
                                    Ember.Logger.error(err);
                                });
                        }
                        // delete timetable
                        timetable.destroyRecord()
                            .then(function () {
                                _this.refresh();
                            })
                            .catch(function (err) {
                                Ember.Logger.error(err);
                            });
                    },
                })
                .modal('show');
        }
    }


});