import Ember from 'ember';

export default Ember.Controller.extend({
    parentRoute: 'management.timetables',
    currentUser: Ember.inject.service('current-user'),
    readonly: true,

    rollBackAndTransition() {
        var timetable = this.get('model');
        timetable.rollbackAttributes();
        this.set("readonly", true);
        this.transitionToRoute(this.get('parentRoute'));
    },

    actions: {
        disableReadonly() {
            this.set("readonly", false);
        },

        setCompanyTimetable(ttID) {
            var companyID = this.get('currentUser.user.companyID');
            var companyGroup = this.store.peekRecord('group', companyID);
            if (ttID != companyGroup.get("timetableID")) {
                companyGroup.set("timetableID", ttID);
                companyGroup.save();
            }
            else {
                companyGroup.set("timetableID", null);
                companyGroup.save();
            }
        },
        save() {
            var _this = this;
            var timetable = this.get('model');

            if (!timetable) { throw { error: 'Timetable not exist' }; }
            //timetable default will be set only when we want to make changes on companyTimetableID
            if (timetable.get("default")) {
                //1 save timetable
                timetable.set("default", false);
                timetable
                    .save()
                    .then(function (/*data*/) {
                        //2 update company timetableID
                        _this.send("setCompanyTimetable", timetable.get('id'));
                        _this.transitionToRoute(_this.get('parentRoute'));
                        // var companyID = _this.get('currentUser.user.companyID');
                        // var companyGroup = _this.store.peekRecord('group', companyID);
                        // if (timetable.get('id') != companyGroup.get('timetableID')) {
                        //     companyGroup.set("timetableID", timetable.get('id'));
                        //     companyGroup.save();
                        // }
                        // else if (timetable.get('id') === companyGroup.get('timetableID')) {
                        //     companyGroup.set("timetableID", null);
                        //     companyGroup.save();
                        // }
                    }, function (error){
                        _this.get("raven").captureException(error);
                        Ember.Logger.error(error);
                        _this.transitionToRoute(_this.get('parentRoute'));
                    });
            }
            else {
                timetable.save().then(function(){
                    _this.transitionToRoute(_this.get('parentRoute'));
                }, function (error){
                    _this.get("raven").captureException(error);
                    Ember.Logger.error(error);
                    _this.transitionToRoute(_this.get('parentRoute'));
                });
            }

            this.set("readonly", true);
        },
        cancel() {
            this.rollBackAndTransition();
        }
    }
});