export default {
	title: {
		alerts: 'Alerte',
		consumption: 'Consum',
		faz: 'Foaie de activitate zilnica',
		'fuel-level': 'Nivel combustibil',
		notifications: 'Notificari',
		route: 'Fișe de activitate',
		stationary: 'Stationari',
		poi: 'Statistici Camp',
		temperature: 'Temperatură',
		'temperature-range': 'Ore TK',
		'poi-temperature': 'Livrări'
	},
	ro: 'Rapoarte',
	menu: {
		alerts: 'Alerte',
		faz: 'FAZ',
		'fuel-level': 'Alimentari',
		'detailed-stops': 'Stationari ++',
		notifications: 'Notificari',
		route: 'Fișe de activitate',
		stationary: 'Stationari',
		poi: 'Statistici Camp',
		temperature: 'Temperatură',
		'temperature-range': 'Ore TK',
		'poi-temperature': 'Livrări'
	},
	downloads: 'Descarca',
	settings: {
		specific: {
			route: {
				duration: 'Durata',
				schedule: 'Foi parcurs conform orar',
				noschedule: 'Foi parcurs 0-24'
			},
			shipping: {
				schedule: 'Livrări conform orar',
				noschedule: 'Livrări 0-24'
			}
		}
	},
	subscribe: {
		title: 'Subscribe',
		fields: {
			label: {
				email: 'Introduceti adresele de email separate prin virgula.',
				frequency: 'Selectati frecventa notificarilor:',
				weekDays: 'Selectati ziua in care doriti sa primiti raportul:'
			},
			placeholder: {
				email: 'Lista de adrese email:',
				frequency: 'Alegeti frecventa:',
				report: 'Alegeti raportul:',
				dayOfWeek: 'Alegeit ziua din saptamana:'
			}
		},
		everyDay: 'O data pe zi',
		everyWeek: 'O data pe saptamana',
		everyMonth: 'O data pe luna',
		notifyDate: 'Selectati data in care doriti sa primiti raportul:'
	},
	headerFields: {
		acc: 'Scor accelerari',
		areaPerHour: 'Suprafata/ora (ha)',
		areaTotal: 'Suprafata (ha)',
		areaTotalLoad50: 'Suprafata lucrata (ha)',
		areaTotalLoad50Popup: 'Suprafata cu sarcina motor in lucru',
		averageSpeed: 'Viteza Medie (km/h)',
		consumptionCompose: 'Consum (litri)',
		canDistance: 'Distanta (CAN)',
		canFuelConsumption: 'Consum CAN (litri)',
		canFuelConsumptionDistance: 'Consum / 100 (CAN)',
		canFuelLevelEndOfDay: 'Nivel Rezervor CAN (litri)',
		canOdometer: 'Odometru Sosire',
		consume: 'Consum (litri)',
		createdAt: 'Create la',
		date: 'Data',
		desacc: 'Scor decelerari',
		description: 'Description',
		distance: 'Distanta (km)',
		distanceCompose: 'Distanta (km)',
		driver: 'Operator',
		duration: 'Ore Functionare',
		endLocation: 'Adresa Sosire',
		endTime: 'Timp Sosire',
		freezerOnDuration: 'Ore functionare agregat frigorific',
		fuelConsumption: 'Consum Calculat (litri)',
		onlyFuelConsumption: 'Consum (litri)',
		fuelConsumptionPerHa: 'Consum/ha (litri)',
		fuelConsumptionPerHour: 'Consum/ora (litri)',
		fuelConsumptionDistance: 'Consum / 100',
		fuelLevelDiff: 'Volum detectat (litri)',
		fuelLevelDiffManual: 'Volum introdus (litri)',
		fuelLevelDiffLiters: 'Volum (litri)',
		fuelSensor: 'Rezervor',
		fuelEvent: 'Tip',
		generatedAt: 'Generat la',
		invoiceNumber: 'Nr. de factura',
		licensePlate: 'Vehicul',
		location: 'Adresa',
		map: 'Harta',
		maxSpeed: 'Viteza Maximă (km/h)',
		persistent: 'Persistent',
		poiCount: 'Fielduri vizitate',
		poiStationaryDoorsOpenedDuration: 'Durată staționare în field cu ușa deschisă',
		poiStationaryDuration: 'Durată staționare in field',
		price: 'Preț',
		printDetails: 'Detalii',
		sensorFuelConsumption: 'Consum sonda (litri)',
		speed: 'Scor viteza',
		startLocation: 'Adresa Pornire',
		startTime: 'Timp Pornire',
		stationaries: 'Staționări cu motor pornit',
		status: 'Stare',
		temperature1: 'sub -4°C',
		temperature2: 'între -4°C și 0°C',
		temperature3: 'între 0°C și 4°C',
		temperature4: 'peste 4°C',
		updatedAt: 'Modificat la',
		virage: 'Scor viraje',
		specific: {
			route: {
				fuelConsumption: 'Consum Calculat',
				purpose: 'Scopul deplasării',
				implement: "Implement",
				selectPurpose: 'Alege'
			},
			station: {
				startTime: 'Inceput stationare',
				endTime: 'Sfarsit stationare',
				duration: 'Durata'
			},
			stationary: {
				startTime: 'Inceput stationare',
				endTime: 'Sfarsit stationare',
				duration: 'Durata'
			},
			poi: {
				geofences: 'Field'
			}
		}
	},
	fuelLevel: {
		eventType: {
			refuel: 'Alimentare',
			consumption: 'Consum in stationare'
		},
		import: {
			title: 'Import alimentari',
			buttonText: 'IMPORTA ALIMENTARI',
			imported: 'Au fost importate {{value}} alimentari',
			notImported: 'Nu au fost importate {{value}} alimentari',
			allImported: 'Toate alimentarile au fost importate ({{value}})',
		},
		uploadFile: "Încarcă document"
	},
	table: {
		expand: "Afișează tot tabelul",
		collapse: "Micșorează",
	},
	temperature: {
		temperatureTable: "Tabel de temperaturi"
	}
}
