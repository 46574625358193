import Ember from 'ember'
import UiModal from 'semantic-ui-ember/components/ui-modal'

export default UiModal.extend({
    name: 'modalContact',
    elementId: 'modalContact',
    classNames: ['modalContact small modal'],

    api: Ember.inject.service(),
    currentUser: Ember.inject.service('current-user'),

    didInsertElement() {
        let _this = this;
        Ember.$('#modalContact')
            .modal({
                onHidden: function () {
                    _this.set('message', '');
                    _this.reset();
                }
            })
            .modal('is active');
    },

    message: '',
    err: null,

    emptyMessage: Ember.computed('message', function () {
        if (this.get('message')) {
            return false
        }
        else {
            return true
        }
    }),

    reset() {
        this.set('err', null);
    },

    sendMessage(message) {
        this.reset();
        if (!message) {
            return false;
        }
        this.get('api').post('/dashboard/contact', {
            data: {
                message: message
            }
        })
            .then(() => {
                this.execute('hide');
                this.set('message', '');
            })
            .catch((err) => {
                this.set('err', err);
            });
    },

    actions: {
        yes() {
            this.sendMessage(this.get('message'));
        },
        no() {
            this.reset();
            this.execute('hide');
        }
    }
});