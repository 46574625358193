import Ember from 'ember';

export default Ember.Component.extend({
    sortedData: Ember.computed.sort('data', 'sortDefinition'),
    sortDefinition: ['date'],

    pageIndex: 1,
    showAll: false,
    resultsPerPage: 10, // obvious
    recordsCount: Ember.computed("sortedData.length", function () {
        return this.get("sortedData.length")
    }),
    showPagination: Ember.computed("recordsCount", "resultsPerPage", function () {
        return this.get("recordsCount") > this.get("resultsPerPage")
    }),

    visibleRecords: Ember.computed("sortedData", "showAll", "pageIndex", "resultsPerPage", function () {
        let resultsPerPage = this.get("resultsPerPage")
        let page = this.get("pageIndex")

        if (this.get("showAll") || !this.get("showPagination")) {
            return this.get('sortedData');
        } else {
            return this.get('sortedData').slice((page - 1) * resultsPerPage, page * resultsPerPage);
        }
    }),

    actions: {
        setResultsPerPage(results) {
            if (!results) { return false }
            this.set("resultsPerPage", results)
            this.set("pageIndex", 1)
        },
        showAll() {
            this.toggleProperty("showAll")
        },
        turnPage(pageNumber) {
            if (!pageNumber) { return false }
            this.set("pageIndex", pageNumber)
        },
    }
});
