import DS from 'ember-data';

export default DS.RESTSerializer.extend({
    primaryKey: 'sn',
    keyForRelationship(key, relationship) {
        if (relationship === 'belongsTo') {
            return `${key}ID`;
        }
    }
});
