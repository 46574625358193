import DS from 'ember-data';

export default DS.RESTSerializer.extend({
    serialize(/*snapshot, options*/) {
        let json = this._super(...arguments);
        delete json.vehicleID;
        delete json.isAdmin;
        return json;
    },
    keyForRelationship(key, relationship) {
        if (relationship === 'belongsTo') {
            return `${key}ID`;
        }
    }
});
