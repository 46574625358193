import DS from 'ember-data';
import MF from 'ember-data-model-fragments';

export default MF.Fragment.extend({
    location: DS.attr(),
    speed: DS.attr('number'),
    fuelLevel: DS.attr('number'),
    engineState: DS.attr('boolean'),
    relayState: DS.attr('boolean'),
    freezer: DS.attr('boolean'),
    temperature: DS.attr('number'),
    odometer: DS.attr('number'),
    geofences: DS.attr(),
    doors: MF.array('string'),
    can: MF.fragment('fragments/vehicle/status/can'),
    bearing: DS.attr("number"),
    updatedAt: DS.attr()
});
