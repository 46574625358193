import Ember from 'ember';
import uuid from "ember-uuid";

export default Ember.Controller.extend({
    actions: {
        saveIntegration: function (integration, callback) {
            let _this = this;
            if (! integration.get("createdAt")) {
                // If the integration did not exist yet, create an instance, save it and remove the template from the model => from view.
                let newIntegration = this.get("store").createRecord("integration", {
                    groupID: integration.get("groupID"),
                    enabled: integration.get("enabled"),
                    options: integration.get("options").copy(),
                    template: integration.get("template"),
                    name:    integration.get("name"),
                    logoUrl: integration.get("logoUrl"),
                    homepage: integration.get("homepage")
                });
                newIntegration.save().then(function (response) {
                    let disableSaveCallback = _this.get("disableSaveCallback");
                    Ember.Logger.log("New integration saved: ", response);
                    _this.get("model.integrations").removeObject(integration); // Locally remove the old integration card so it disappears from view.

                    if (disableSaveCallback) {
                        disableSaveCallback();
                        _this.set("disableSaveCallback", null);
                    } else {
                        Ember.Logger.log("No callback");
                    }
                }).catch( function (error) {
                    Ember.Logger.log(error);
                    // no need to destroy it if saving failed
                    newIntegration.deleteRecord();
                });
            } else {
                // If the integratoin already exists, then update it with the new info.
                integration.save().then(function(response){
                    if (callback) {
                        callback(response);
                    }
                });
            }
        },
        toggleIntegration: function(integration) {
            if (integration.get("enabled")) {
                if (! integration.get("createdAt")) {
                    integration.get("options").forEach( function (option) {
                        // If the option editing is disabled, generate the values. Using a switch 
                        if (option.get("disabled")) {
                            switch(option.get("name")) {
                                case "user":
                                    option.set("value", "tracknamic-" + integration.get("id") + "-" + integration.get("groupID"));
                                    break;
                                case "password":
                                    option.set("value", uuid.v4());
                                    break;
                            }
                        }
                        Ember.Logger.log("Option: ", option.get("name"), option.get("value"));
                    });
                }
            }
        },
        disableSave: function (callback) {
            this.set("disableSaveCallback", callback);
        }
    },
    disableSaveCallback: null
});
