import Ember from 'ember';

const { service } = Ember.inject;

export default Ember.Component.extend({
    i18n: service(),

    actions: {
        resetErrors() {
            this.get('resetErrors')();
        }
    }
});
