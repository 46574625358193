import Ember from 'ember';
const { service } = Ember.inject;

export default Ember.Component.extend({
    counter: 0,
    text: 'Masina',
    selected: '',
    previousSelection: '',
    i18n: service(),

    setCounter(jItem) {
        var counter = this.get('counter');
        /**
         * Do not count group items.
         */
        if (jItem.hasClass('group')) { return; }
        if (jItem.hasClass('active')) {
            counter++;
            this.set('counter', counter);
        } else {
            counter--;
            this.set('counter', counter);
        }
    },
    counterTo(value) {
        this.set('counter', value);
    },
    setText() {
        this.set('text', '(' + this.get('counter') + ') ' + this.get('i18n').t('vehicless'));
    },
    setSelected(items) {
        var selected = [];
        items.find('.item.active').not('.group').each(function (index, item) {
            selected.push(Ember.$(item).data('id'));
        });
        this.set('selected', selected.join(','));
    },
    selectOneItem(jItem) {
        jItem.addClass('active');
        this.setCounter(jItem);
        this.setText();
    },
    deSelectOneItem(jItem) {
        jItem.removeClass('active');
        this.setCounter(jItem);
        this.setText();
    },
    getChildrensGroups(jGroupItem, jChildsItems) {
        var jNextGroupItems = jGroupItem.nextAll('.group'),
            jGroupItemHasActiveClass = jGroupItem.hasClass('active'),
            _this = this;
        if (!jNextGroupItems.length) { return false; }
        if (!jChildsItems) {
            jChildsItems = [];
        }
        jNextGroupItems.each(function (idx, nextItem) {
            var jNextGroupItemSplitedId,
                jNextGroupItemParentId,
                jNextItem = Ember.$(nextItem);
            /**
             * Determine if is a child of jGroupItem
             */
            jNextGroupItemSplitedId = jNextItem.data('id').toString().split('-');
            jNextGroupItemSplitedId.pop();
            /**
             * Go to next iteration if there is no parent as nextItem is not a child but root item.
             */
            if (!jNextGroupItemSplitedId.length || !jGroupItem.data('id')) { return true; }

            jNextGroupItemParentId = jNextGroupItemSplitedId.join('-');

            if (jGroupItem.data('id').toString() === jNextGroupItemParentId.toString()) {
                jChildsItems.push(jNextItem);
                if (jGroupItemHasActiveClass) {
                    jNextItem.addClass('active');
                } else {
                    jNextItem.removeClass('active');
                }
                _this.getChildrensGroups(jNextItem, jChildsItems);
            }
        });
        return jChildsItems;
    },
    setChildrensState(jGroupItem) {
        var _this = this;
        /**
         * If item is a group then add / remove active class for all it's childrens.
         */
        if (jGroupItem.hasClass('group')) {
            jGroupItem.nextUntil('.group').each(function (idx, sibling) {
                var jSibling = Ember.$(sibling);
                /**
                 * If both the child and the group has class 'active' set or unset then we don nothing.
                 */
                if ((jGroupItem.hasClass('active') && jSibling.hasClass('active')) ||
                    (!jGroupItem.hasClass('active') && !jSibling.hasClass('active'))) {
                    return;
                }
                /**
                 * Set parent state class for child.
                 */
                if (jGroupItem.hasClass('active')) {
                    _this.selectOneItem(jSibling);
                } else {
                    _this.deSelectOneItem(jSibling);
                }

            });
        }
    },

    addedIDs: [],

    addGroups(groups, level) {
        var html = '',
            _this = this,
            i18n = this.get('i18n'),
            addedIDs = this.get("addedIDs");

        if (!groups) { return; }
        if (!level) {
            level = 1;
        }

        groups.forEach(function (item) {
            var itemIDSplited = item.get('id').split('-');
            if (!itemIDSplited || itemIDSplited.length === 1) {
                level = 1;
            }

            if (item.get('childs.length') || item.get('vehicles.length')) {
                let itemId = item.get("id");

                if (itemId && (addedIDs.indexOf(itemId) === -1)) {
                    html += '<div id="element-' + itemId + '" class="item group level' + level + '" data-id="' + itemId + '" data-value="' + itemId + '">' + item.get('name') + ' (' + item.get('vehicles').get('length') + ') ' + i18n.t("vehicles").string.toLowerCase() + '</div>';
                    addedIDs.push(itemId);
                }

                if (item.get('vehicles').length) {
                    let sortedVechiles = item.get('vehicles').sortBy('licensePlate');
                    sortedVechiles.forEach(function (vehicle) {
                        let vehicleId = vehicle.get("id");
                        if (addedIDs.indexOf(vehicleId) === -1) {
                            html += '<div id="element-' + vehicleId + '" class="item level' + (level + 1) + '" data-id="' + vehicleId + '" data-parent="' + itemId + '">' + vehicle.get('licensePlate') + '</div>';
                            addedIDs.push(vehicleId);
                        }
                    });
                }
                if (item.get('childs').length) {
                    level++;
                    html += _this.addGroups(item.get('childs'), level);
                }
            }
        });

        this.set("addedIDs", addedIDs);

        return html;
    },

    addOrphanVehicles() {
        let html = "";
        let _this = this;
        let i18n = this.get("i18n")
        let orphans = 0;
        this.get("vehicles").forEach(function (vehicle) {
            let vehicleId = vehicle.get("id");
            if (_this.get("addedIDs").indexOf(vehicleId) === -1) {
                orphans++;
                _this.get("selectedVehicles").push(vehicleId);
                html += '<div id="element-' + vehicleId + '" class="item level2" data-id="' + vehicleId + '" data-parent="foreign">' + vehicle.get('licensePlate') + '</div>';
            }
        });
        if (orphans) {
            html = '<div id="element-foreign" class="item group level1" data-id="foreign" data-value="foreign">' + this.get("i18n").t("management.groups.share").string + ' (' + orphans + ') ' + i18n.t("vehicles").string.toLowerCase() + '</div>'
                + html;
        }

        Ember.Logger.log(_this.get("selectedVehicles"));

        return html;
    },
    deselectParents(jItem) {
        // Check if item is not a level1.
        var previous = jItem.prevUntil('.menu'),
            topParent = jItem.hasClass('group') ? jItem.data('id').toString().split('-')[0] : jItem.data('parent').toString().split('-')[0];
        previous.each(function (idx, item) {
            if (Ember.$(item).hasClass('group') && Ember.$(item).data('id').toString().indexOf(topParent) === 0) {
                Ember.$(item).removeClass('active');
            }
        });
    },
    selectParents(jItem) {
        // Get parents.
        var parentGroupSplitedId;
        if (!jItem.hasClass('active')) { return false; }
        if (jItem.hasClass('group')) {
            parentGroupSplitedId = jItem.data('id').toString().split('-');
            if (Ember.$('.group[data-id^=' + jItem.data('id') + ']').last().length) {
                parentGroupSplitedId = Ember.$('.group[data-id^=' + jItem.data('id') + ']').last().data('id').toString().split('-');
            }
        } else {
            parentGroupSplitedId = jItem.data('parent').toString().split('-');
            if (Ember.$('.group[data-id^=' + jItem.data('parent') + ']').last().length) {
                parentGroupSplitedId = Ember.$('.group[data-id^=' + jItem.data('parent') + ']').last().data('id').toString().split('-');
            }
        }
        //if (parentGroupSplitedId.length === 1) { return false; }
        while (parentGroupSplitedId.length !== 0) {
            var selectedChildrens = [];
            var childrens = Ember.$('.item[data-parent="' + parentGroupSplitedId.join('-') + '"]');
            childrens.each(function (cIdx, cItem) {
                if (Ember.$(cItem).hasClass('active')) {
                    selectedChildrens.push(cItem);
                }
            });
            if (childrens.length === selectedChildrens.length) {
                Ember.$('.group[data-id="' + parentGroupSplitedId.join('-') + '"]').addClass('active');
            } else {
                break;
            }
            parentGroupSplitedId.pop();
        }
    },

    selectAll() {
        var _this = this;

        this.$('.item').removeClass('active');
        this.set('selected', '');

        this.set('selected', this.get('selectedVehicles').join(','));
        this.get('selectedVehicles').forEach(function (item) {
            _this.$('.item').not('.group').each(function (idx, el) {
                if (Ember.$(el).data('id') === item) {
                    _this.clickOnItem(Ember.$(el), _this.$().find('.text'));
                }
            });
        });

        this.set('previousSelection', this.get('selected'));
    },

    clickOnItem(jItem, jText) {
        // Set the state for clicked item.
        jItem.toggleClass('active');
        this.setCounter(jItem);
        this.setText();

        // If is a group item set the same state for it's childrens too.
        this.setChildrensState(jItem);
        // Get childrens groups.
        let jGroupItemChildrens = this.getChildrensGroups(jItem);
        // Set the same state for chidrens group childrens.
        if (jGroupItemChildrens.length) {
            jGroupItemChildrens.forEach((groupChild) => {
                this.setChildrensState(groupChild);
            });
        }

        // If item was deselected also deselect all it's parents.
        if (jItem.data('parent')) {
            this.deselectParents(jItem);
            this.selectParents(jItem);
        }

        if (this.get('counter')) {
            jText.removeClass('default');
        } else {
            jText.addClass('default');
        }
        // Set selected as comma separated ids and set them to input hidden value.
        this.get('setSelected').call(this, this.$().find('.menu'));
    },

    loaded: false,

    selectVehicle(vehicleID) {
        let jqVehicle = this.$("#element-" + vehicleID);
        if (!jqVehicle.hasClass("active") && jqVehicle.data("parent")) {
            jqVehicle.addClass("active");
            let groupParts = jqVehicle.data("parent").split("-");
            let currentGroupSelector = "#element-" + groupParts[0];

            for (var subgroupIndex = 0; subgroupIndex < groupParts.length; subgroupIndex++) {
                if (subgroupIndex) {
                    currentGroupSelector += "-" + groupParts[subgroupIndex];
                }
                if (!this.$(currentGroupSelector).hasClass("active")) {
                    this.$(currentGroupSelector).addClass("active")
                }
            }
        }
    },

    internalUpdate: true,

    didRender() {
        Ember.Logger.log("DID RENDER");
        if (this.get("loaded")) {
            if (this.get("internalUpdate")) {
                let dropdownValues = this.$('.ui.special.dropdown').dropdown("get value");
                let dropdownSelected = dropdownValues.split(",");
                let selectedVehicles = this.get("selectedVehicles");
                let _this = this;

                if (dropdownValues.length && (dropdownSelected.length !== selectedVehicles.length) || (dropdownSelected[0] !== selectedVehicles[0])) {
                    this.$(".item.active").removeClass("active");
                    selectedVehicles.forEach(function (vehicle) {
                        _this.selectVehicle(vehicle);
                    });
                    this.counterTo(selectedVehicles.length);
                    this.set('selected', selectedVehicles.join(','));
                    this.$('.ui.special.dropdown').dropdown("set selected", selectedVehicles);

                    this.setText();
                }
            }
        } else {
            this.set("loaded", true);
        }
    },

    didInsertElement() {
        var _this = this;

        this.set("addedIDs", []);

        var menuHtml = this.addGroups(this.get('groups'));

        menuHtml += this.addOrphanVehicles();

        this.$().find('.menu').html(menuHtml);

        this.$('.ui.special.dropdown')
            .dropdown({
                action: 'nothing',
                onHide: function () {
                    let prev = _this.get('previousSelection');
                    let current = _this.get('selected');
                    if (prev === current) { return true; }
                    _this.set('previousSelection', current);
                    _this.get('updateVehicle')(_this.get('selected'));
                },
                useLabels: false,
            }).on('click', '.item', function (e) {
                e.preventDefault();
                _this.set("internalUpdate", false);
                var jItem = _this.$(e.target),
                    jText = _this.$().find('.text');

                _this.clickOnItem(jItem, jText);
            });

        this.selectAll(this.get('selectedVehicles'));
        Ember.Logger.log("INSERT ELEMENT");
    },

});
