import Ember from 'ember'
import Base from './base'

export default Base.extend({
	queryParams: ['lat', 'lng'],
	lat: null,
	lng: null,

	geofenceCoords: Ember.computed('lat', 'lng', function () {
		let lat = this.get('lat')
		let lng = this.get('lng')
		if (!lat || !lng) { return null }
		return { lat: Number(this.get('lat')), lng: Number(this.get('lng')) }
	})
})
