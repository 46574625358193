export default {
	management: 'Management',
	documents: 'Documents',
	moreDetails: 'Add more',
	docStartDate: 'From',
	docEndDate: 'Until',
	price: 'Price',
	load: 'Load',
	download: 'Download',
	accountInformation: 'Account informations',
	document: {
		deleteTittle: "Delete document",
		sureToDelete: "The document {{fileName}} ({{type}}) will be deleted",
		notifications: {
			defaultText: 'Notifications',
			day: 'One day',
			week: 'One week',
			month: 'One month',
		}
	},
	headerFields: {
		licensePlate: "License Plate",
		driver: "Driver",
		car: "Car",
		type: "Type",
		division: 'Division',
		status: 'Status',
		name: 'Name',
		email: 'Email',
		phoneNumber: 'Phone',
		role: 'Type',
		width: 'Width',
	},
	implements: {
		types: {
			plow: "Plow",
			cultivator: "Cultivator",
			"disc-harrow": "Disc harrow",
			combinator: "Combinator",
			"chisel-plow": "Chisel plow",
			"seed-drill-weeding": "Seed drill weeding",
			"seed-drill-cereal": "Seed drill cereal",
			weeder: "Weeder",
			drill: "Drill",
			sprayer: "Sprayer",
			fertilizer: "Fertilizer",
			leveler: "Leveler",
			planter: "Planter",
			"rotating-rake": "Rotating rake",
			baler: "Baler",
			mower: "Mower",
			harvesters: "Harvester",
			harrow: "Harrow",
			roller: "Roller",
			trailer: "Trailer",
			vidange: "Vidange",
			chopper: "Chopper",
			"front-loader": "Front loader",

			"harvester-header-cereal": "Harvester header cereal",
			"harvester-header-weeding": "Harvester header weeding"
		},
		newTitle: 'Add implement',
		noImplement: 'No implement',
		name: 'Name',
		type: "Type",
		width: 'Width (cm)',
		sureToDelete: 'Are you sure that you want to delete this implement',
	},
	timetables: {
		withoutTimetable: 'No timetable',
		sureToDeleteTimetable: 'Are you sure that you want to delete this schedule',
		setCompanyDefaultTimetable: 'Set company default',
		willBeChanged: 'The schedule will be changed',
		defaultCompanyWillBeChanged: 'Company schedule will be changed from',
		detachDefaultCompanyTimetable: 'The company will not have any schedules',
		defaultCompanyTimetableWillBeDeleted: 'The company will not have a default schedule!',
		to: 'to',
		name: 'Schedule name',
		isDefaultCompanyTimetable: 'If this schedule is deleted the company will not have a default schedule',
		browse: 'Browse...',
		addCsv: 'Upload CSV',
		status: {
			defaultText: 'Status',
			disabled: 'Disabled',
			custom: 'Custom',
			fullDay: 'All day',
		},
		new: {
			title: 'Add Schedule',
			buttonText: 'SAVE',
		},
		import: {
			title: "Import timetable",
			buttonText: "IMPORT",
			name: "Timetable Name",
			licensePlate: "License Plate",

			affectedVehicles: "Vehicles shifting from existing timetables",
			timetablesForRemoval: "Timetables for removal",

			mondayStart: "Monday Start",
			mondayStop: "Monday Stop",

			tuesdayStart: "Tuesday Start",
			tuesdayStop: "Tuesday Stop",

			wednesdayStart: "Wednesday Start",
			wednesdayStop: "Wednesday Stop",

			thursdayStart: "Thursday Start",
			thursdayStop: "Thursday Stop",

			fridayStart: "Friday Start",
			fridayStop: "Friday Stop",

			saturdayStart: "Saturday Start",
			saturdayStop: "Saturday Stop",

			sundayStart: "Sunday Start",
			sundayStop: "Sunday Stop"
		},
		edit: {
			title: 'Edit Schedule',
			buttonText: 'EDIT',
		},
		view: {
			title: 'Schedule details',
			buttonText: 'OK',
		}
	},
	vehicles: {
		// small modal
		division: 'Division',
		driverIsAttached: 'The selected driver will be assigned to another vehicle!',
		isAssignedToVehicle: 'is assigned to the vehicle',
		willBeAssigned: 'will be assigned to the vehicle',
		sureToDeleteVehicle: 'Are you sure that you want to delete the vehicle',
		vehicleHasDeviceAssigned: 'Device {{deviceID}} is assigned to the vehicle',
		selectDevice: 'Select device',
		relayContact: {
			blockedContact: 'Ignition blocked',
			unblockedContact: 'Unblock ignition',
			areYouSureToBlockThisContact: 'Are you sure that you want to block the ignition?',
			areYouSureToUnblockThisContact: 'Are you sure that you want to unblock the ignition?',
			blockContact: 'Block ignition',
			unblockContact: 'Unblock ignition',
		},
		speedLimit: {
			max: 'Default alert threshold',
			maxTooltip: 'alerts will be sent if threshold is exceeded regardless of the road limit (ie 90 km/h for trucks)',
			min: 'Alert threshold',
			minTooltip: 'speed alerts will not be sent if under this threshold',
			offset: 'Allowed threshold',
			offsetTooltip: '10 km/h to be alerted at\n50 + 10 km/h in localities, \n90 + 10 km/h on national roads, \n130 + 10 km/h on highways',
			errorMessage: 'Check the fields marked with red'
		},
		doorAlert: {
			noAlert: "No alert",
			alertOpenDoor: "Alert open door",
			doorOpenOutsidePOI: "Alert open door outside of POI",
			doorOpenOutsidePOIInTimetable: "Alert open door outside of POI in timetable",
		},
		removeDevice: 'Remove device',
		cannotChangeDeviceUntilRelayContactDisabled: 'If the ignition is blocked the devices cannot be changed',
		cannotChangeRelayContactUntilDeviceIsSaved: 'The ignition cannot be blocked until the device is saved',
		deviceChange: 'Change device',
		noDevices: 'There are no devices available',
		vehicleDetails: 'Vehicle Details',
		vehicleAlerts: 'Vehicle Alerts',
		moreDetails: 'More Details',
		autoPark: 'Auto Park',
		licensePlate: 'License Plate',
		vin: 'VIN',
		make: 'Make',
		model: 'Model',
		vehicle: 'Vehicle',
		fuel: {
			typeText: 'Fuel Type',
			type: {
				petrol: 'Petrol',
				hybridPetrol: 'Hybrid-petrol',
				diesel: 'Diesel',
				hybridDiesel: 'Hybrid-diesel',
				lpg: 'GPL',
				electric: 'Electric',
			},
			capacity: 'Tank capacity',
			consumption: {
				distanceWise: 'Average fuel consumption L/100KM',
				timeWise: 'Average fuel consumption L/h'
			},
		},
		category: 'Category',
		type: {
			utility: 'Utility',
			car: 'Car',
			minibus: 'Minibus',
			bus: 'Bus',
			truck: 'Truck',
			tractor: 'Tractor',
			harvester: 'Harvester',
			construction: 'Construction',
			trailer: 'Trailer',
			'semi-trailer': 'Semi-trailer'
		},
		seats: 'Number of seats',
		selectDivision: 'Select division',
		selectDriver: 'Select driver',
		removeDriver: 'Remove driver',
		fabricationYear: 'Fabrication year',
		registrationDate: 'Registration date',
		engine: {
			power: 'Horsepower (HP)',
			capacity: 'Engine capacity'
		},
		engineLoadLimit: 'Engine load limit (%)',
		engineLoadLimitText: 'Engine load limit(%) for calculate the worked areas.\nOnly allowed integer numbers between 0 and 100',
		payloadCapacity: 'Payload capacity',
		tireSize: 'Tire size',
		color: 'Color',
		new: {
			title: 'Add Vehicle',
		},
		edit: {
			title: 'Edit Vehicle',
		},
		view: {
			title: 'Vehicle details',
		}
	},
	users: {
		userDetails: 'User details',
		// small modal
		vehicleHasDriver: 'The selected vehicle already has a driver!',
		userHasVehicle: 'The user is assigned to the vehicle',
		ifDeleteDetatch: 'If you deleted this user, he will be unassigned from this vehicle',
		haveDriver: 'drived by',
		wichWillBeReplaced: 'which will be replaced with',
		sureToDeleteThisUser: 'Are you sure you want to delete user',
		userName: 'User name',
		firstName: 'First name',
		iButton: 'iButton',
		lastName: 'Last name',
		email: 'Email',
		photoUpload: 'Upload photo',
		division: 'Division',
		phoneNumber: 'Phone',
		drivingLicense: 'Driving License',
		assignedVehicle: 'Asigned vehicle',
		detachVehicle: 'Remove vehicle',
		role: 'Role',
		driver: 'Driver',
		admin: 'Administrator',
		docStartDate: 'From',
		docEndDate: 'Until',
		docPrice: 'Price',
		load: 'Load',
		new: {
			title: 'Add user',
			buttonText: 'SAVE',
			headerText: 'Fill in the form with user data. For details press \'Add more details\'.'
		},
		edit: {
			title: 'Edit user',
			buttonText: 'EDIT',
			headerText: 'Fill in the form with user data. For details press \'Add more details\'.'
		},
		view: {
			title: 'User informations',
			buttonText: 'OK',
			headerText: 'Informations about the selected user. For more detatails press \'More\'.'
		}
	},
	groups: {
		deleteGroup: 'Are you sure you want to delete the division',
		group: 'Division',
		addGroup: 'Add division',
		timetable: 'Schedule',
		editGroup: 'Edit Division',
		groupName: 'Division title',
		addSubGroup: 'Add subdivision',
		editSubGroup: 'Edit subdivision',
		deleteSubGroup: 'Delete subdivision',
		share: "Partners",
		add: "Add"
	},
	geofences: {
		name: 'Field title',
		radio: 'Radius',
		sureToDeleteGeofence: 'Are you sure you want to delete the field',
		alertStationaryTimeHoverText: 'Minutes stationary (5-60)',
		showAll: 'Show all fields',
		selectAll: 'All fields',
		selectNone: 'Without fields',
		type: {
			geofence: 'Field',
			poi: 'Farm'
		},
		shape: {
			type: {
				Polygon: 'Field',
				Point: 'Farm'
			}
		},
		new: {
			title: 'add field',
			buttonText: 'SAVE',
		},
		import: {
			title: 'Import field',
			buttonText: 'IMPORT field',
			importedGeofencesNumber: '{{value}} fields were imported',
			updatedGeofencesNumber: '{{value}} fields have been modified',
			notImportedGeofencesNumber: '{{value}} fields were not imported',
			allGeofencesImported: 'All ({{value}}) fields have been imported',
			pleaseWait: 'Please wait',
			processingGeofences: 'Processing data from file',
			downloadTemplate: "Download template",
			downloadExamples: "Download examples"
		},
		simulate: {
			importedGeofencesNumber: '{{value}} fields will be imported.',
			updatedGeofencesNumber: '{{value}} fields will be modified.',
			notImportedGeofencesNumber: '{{value}} fields will not be imported',
			allGeofencesImported: 'All fields will be imported({{value}})',
			validateFile: "Check CSV"
		},
		edit: {
			title: 'EDIT FIELD',
			buttonText: 'EDIT',
		},
		info: {
			title: 'Field information',
			buttonText: 'OK',
		}
	},
	settings: {
		speedLimit: "Speed Limit",
		insertEmailAddress: 'Insert emails address (max 3):',
		email: 'Email',
		insertPhoneNumber: 'Insert phones numbers (max 3) Example: 0787 709 154',
		phoneNumber: 'Phone Number',
		alerts: {
			limit: 'Limit',
			'geofence-stationary': 'Field stationary',
			'document-expiry': 'Document expiry',
			'fuel-consumption-limit': 'maximum fuel consumption/hour',
			'rpm-limit': 'RPM > 1900 in 2 minutes',
		},
		emails: {
			'poi-stationary': 'Stationary in farm',
			'high-rpm': 'RPM > 2000 at each 5 consecutive points',
			'stationary-fuel-consumption': 'Stationary fuel consumption',
			'document-expiry': 'Document expiry',

			// not titan 
			'poi-enter': 'Enter farm',
			'poi-exit': 'Exit farm',
			'geofence-enter': 'Enter field',
			'geofence-exit': 'Exit field',
			'geofence-stationary': 'Stationary in field',
			'speeding': 'Overspeeding',
			'door-open-outside-poi': 'Door open outside field',
			insertEmailsSeparatedByComma: 'Insert multiple email addresses separated by comma',
			notifications: 'Notifications',
			email: 'Email',
		}
	}
}
