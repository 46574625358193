import Ember from 'ember';

// 1- didReceiveAttrs will hide the modal when requestComplete and noErrors. ifErrors show them in the form
// 2- onModalHidden completed, cancel action(Controller) will make the transition
export default Ember.Component.extend({
    init() {
        this._super(...arguments);
        Ember.$('.ui.dimmer.modals').remove();
    },

    didReceiveAttrs() {
        if (this.get('requestCompleted') && !this.get("listOfErrors").length > 0) {
            this.hideModal()
        }
    },

    didInsertElement() {
        Ember.$('.managementModal')
            .modal({
                duration: 200,
                onHidden: () => {
                    this.get('cancel')();
                },
            })
            .modal('show');
    },

    hideModal() {
        Ember.$('.managementModal').modal('hide');
    },

    actions: {
        save() {
            this.get('save')();
        },
        cancel() {
            this.hideModal();
        },
    }
});