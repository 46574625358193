import Ember from 'ember'
import d3V3Lines from '../d3-v3-lines/component'


const { service } = Ember.inject
const doorStatusTranslationKey = 'openDoorsStatus'
const freezerStatusTranslationKey = 'freezerStatus'

export default d3V3Lines.extend({

	i18n: service(),

	hasDoorStatusData: Ember.computed('labels', function () {
		let i18n = this.get('i18n')
		let labels = this.get('labels')
		return labels.indexOf(i18n.t(doorStatusTranslationKey).string) > -1
	}),

	hasFreezerStatusData: Ember.computed('labels', function () {
		let i18n = this.get('i18n')
		let labels = this.get('labels')
		return labels.indexOf(i18n.t(freezerStatusTranslationKey).string) > -1
	}),

	actions: {
		setHoverdPoint(pointData) {
			if (this.get('getHoveredPointData')) {
				this.get('getHoveredPointData')(pointData)
			}
		}
	}
})
