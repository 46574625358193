import Ember from 'ember'
import moment from 'moment'

const { service } = Ember.inject

export default Ember.Component.extend({
	i18n: service(),

	selected: null,
	weekDays: null,

	getWeekDays(mondayFirst = true) {
		let weekDays = moment.weekdays()
		if (mondayFirst) {
			let sunday = weekDays.shift()
			weekDays.push(sunday)
		}
		return weekDays
	},

	init() {
		this._super(...arguments)
		this.set('weekDays', this.getWeekDays())
	}
})
