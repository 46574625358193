import Base from './base-grouped';
import PropsInit from '../../mixins/reports/controller-props-initialization';

export default Base.extend(PropsInit, {
	excluded: ["licensePlate"],
	reportTitle: 'STATIONARY',
	sortProperties: ['startTime:asc'],
	groupByKey: 'vehicleID',

	actions: {
		getRoutesInTimeInterval(vehicle, startDate, endDate) {
			if (!vehicle || !startDate || !endDate) { return [] }
			return this.store.query("route", {
				$where: {
					vehicle_id: vehicle.get('id'),
					'>=': {
						start_time: startDate
					},
					'<=': {
						start_time: endDate
					}
				}
			})
		},
	}
});
