/**
 * http://t1m0n.name/air-datepicker/docs/
 */
import Ember from 'ember';

export default Ember.Component.extend({
    datepicker: null,
    appSettings: Ember.inject.service('application-settings'),
    appLanguage: Ember.computed.alias("appSettings.locale"),

    dateFormat: "DD/MM/YYYY HH:mm",
    classNames: ["has-air-datepicker"],
    iconType: "calendar",
    iconPosition: "left",
    position: "bottom left",
    autoClose: true,
    pickerType: "datetime",
    inline: false,

    didInsertElement() {
        var
            timepicker = false,
            classes = null;

        switch (this.get("pickerType")) {
            case "datetime":
                timepicker = true;
                break;
            case "time":
                timepicker = true;
                classes = "only-timepicker";
                break;
        }
        this.$('input').datepicker({
            classes: classes,
            timepicker: timepicker,
            autoClose: this.get('autoClose'),
            minDate: this.get('minDate'),
            maxDate: this.get("maxDate"),
            startDate: this.get('startDate'),
            date: this.get("selectedDate"),
            inline: this.get("inline"),
            language: this.get("appLanguage"),
            view: this.get("view"),
            position: this.get("position"),
            minView: this.get("view"),
            onSelect: (formattedDate, dateObject) => {
                this.set("selectedDate", dateObject);
            }
        }).data('datepicker');
    },
});
