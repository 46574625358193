import Ember from 'ember';

export default Ember.Component.extend({
    integration: {},
    classNames: ["column"],
    classNameBindings: ["isHidden:hidden"],
    isHidden: false,
    actions: {
        update: function () {
            let _this = this;
            if (!this.get("integration.createdAt")) {
                this.set("isHidden", true);
            }
            this.sendAction("saveIntegration", this.get("integration"), function () {
                _this.set("toBeToggled", false);
                _this.set("saveDisabled", true);
            });
        },
        copyToClipBoard: function(event) {
            var target = Ember.$(event.target);
            target.focus();
            event.target.setSelectionRange(0, target.val().length);
            var succeed;

            try {
                if (target.val().length) {
                    succeed = document.execCommand("copy");
                }
            } catch(e) {
                succeed = false;
            }

            if (succeed) {
                target.popup({
                    position:   "right center",
                    content:    "Copiat în clipboard.",
                    on:         "click"
                });
                target.popup("show");
            }
        }
    },
    saveDisabled: true,
    didInsertElement: function () {
        // We need to do this because the resolve on save seems to be triggered after the component is processed when saving a new integration,
        // which leads to having the saveDisabled property set to false, because of the observers getting triggered.
        let _this = this;
        let callback = function () {
            _this.set("saveDisabled", true);
            _this.set("toBeToggled", false);
        };
        this.sendAction("disableSave", callback);
    },
    optionsObserver: Ember.observer("integration.options.@each.value", "integration.enabled", function () {
        this.set("saveDisabled", false);
    }),
    toBeToggled: false,
    activationObserver: Ember.observer("integration.enabled", function () {
        this.set("toBeToggled", true);
    }),

    integrationStatus: Ember.observer("integration.enabled", function() {
        this.sendAction("toggleIntegration", this.get("integration"));
    })
});
