import Ember from 'ember';

export default Ember.Service.extend({
    do: function (jForm) {
        if (!jForm) { return false; }
        
        jForm.form('validate form');

        return jForm.form('is valid');
    }
    /*do: function (jForm, formValidationSchema) {
        if (!jForm || !formValidationSchema) { return false; }
       
        jForm.form(formValidationSchema);

        return jForm.form('is valid');
    }*/
});
