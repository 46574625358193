import Ember from 'ember'
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin'

export default Ember.Route.extend(AuthenticatedRouteMixin, {
	model() {
		return Ember.RSVP.hash({
			vehicles: this.store.findAll('vehicle'),
			plannedRoutes: this.store.findAll('planned-route'),
		})
	},

	actions: {
		delete (route) {
			Ember.Logger.debug('Delete planned route', route)

			route.destroyRecord()
			.catch(error => {
				Ember.Logger.debug('Failed to delete planned route !', error, route)
			})
		}
	}
})