import Ember from 'ember'

export default Ember.Controller.extend({
	menu: [
		Ember.Object.create({
			name: 'route.add',
			route: 'dispatch.routes.add',
			translationKey: 'menu.dispatch.routes.add',
		})
	]
})
