import timeSeries from '../time-series/component'
/* global d3 */

/**
 * Get the potin that is closest to mouse x coordinates.
 * @param {array} line
 * @param {date} timeAtMousePosition
 * @returns {object}
 */
function getPoint(line, timeAtMousePosition) {
	let bisectDate = d3.bisector(function (d) { return d.time }).left
	let closestElement = bisectDate(line.values, timeAtMousePosition, 1)
	let t0 = line.values[closestElement - 1]
	let t1 = line.values[closestElement]

	return timeAtMousePosition.getTime() - t0.time.getTime() > t1.time.getTime() - timeAtMousePosition.getTime() ? t1 : t0
}

import Ember from 'ember'

export default timeSeries.extend({

	mouseXPosition: null,

	mouseYPosition: null,

	updateCharts: Ember.computed('mouseXPosition', 'mouseYPosition', function () {
		this.get('updateLineMarkers')()
	}),

	didUpdateAttrs() {
		this.updateLineMarkers()
	},

	didInsertElement() {
		this._super()
		var _this = this

		d3.select(this.$('svg')[0])
			.on('mousemove', function () {
				if (!_this.get('isInteractive')) {
					return
				}
				// # Check if we are within the domain/range of the data
				if (_this._isEventWithinValidRange()) {
					_this.get('setCurrentChart')(_this)
					_this.get('triggerMouseMove')(this, d3.mouse(this))
				}
			})
			.on('mouseleave', function () {
				_this.hideTooltip()
			})
	},

	showTooltip: function (content, event) {
		var $ttid = this._getTooltip()
		$ttid.html(content)
		$ttid.show()
		return this._updateTooltipPosition(event)
	},

	_lineMarkerData: function () {
		var mousePosition = this._mousePosition(true)
		if (Ember.isEmpty(mousePosition)) {
			return []
		}

		var invXScale = this.get('xTimeScale').invert
		let xScale = this.get('xTimeScale')
		let yScale = this.get('yScale')

		var timeAtMousePosition = invXScale(mousePosition[0])

		var markerData = []
		let pointData = null
		this.get('viewport').selectAll('path.line').each(function (d) {
			let point = getPoint(d, timeAtMousePosition)
			if (!point) { return false }

			/**
			 * In this very moment does not matter which line we use to take the point as all have same x coordinates.
			 */
			if (!pointData) {
				pointData = point
			}

			return markerData.push(Object.assign({}, point, {
				x: xScale(point.time),
				y: yScale(point.value),
				group: d.group,
				value: point.value,
				time: point.time,
				path: this
			}))
		})

		let tooltipDetails = { values: [] }
		tooltipDetails.values.push({ time: markerData[0].time })
		markerData.forEach(item => {
			tooltipDetails.values.push({ group: item.label, value: item.value, customValueText: item.customValueText })
		})

		if (this.get('externalAction')) {
			this.get('externalAction')(tooltipDetails)
		}

		return markerData
	},

	_mousePosition: function (hack = false) {
		if (hack) {
			return [this.get('mouseXPosition'), this.get('mouseYPosition')]
		}
		if (!d3.event) {
			return null
		}
		return d3.mouse(this.get('$viewport'))
	},

	showDetails(data, i, element) {

		d3.select(element).classed('hovered', true)

		if (!data || !data[0] || !data[0].time) { return '' }

		let time = data[0].time

		if (!time) {
			return this.showTooltip('', d3.event)
		}

		time = this.adjustTimeForShowDetails(time)
		var content = this.$('<span>')
		content.append(this.$("<span class=\"tip-label\">").text(this.get('formatTime')(time)))
		this.showTooltip(content.html(), d3.event)
		var formatLabelFunction = this.get('formatLabelFunction')

		var addValueLine = (d) => {
			var name = this.$('<span class="name" />').text(d.group + ': ')
			var value = this.$('<span class="value" />').text(d.customValueText ? d.customValueText : formatLabelFunction(d.value))
			content.append(name)
			content.append(value)
			content.append('<br />')
		}

		data.forEach(addValueLine)

		return this.showTooltip(content.html(), d3.event)

	},
})
