import Ember from 'ember';

export default Ember.Service.extend({
    schema: {
        // inline: true,
        on: 'blur',        
        fields: {
            groupID: {
                identifier: "groupID",
                rules: [{
                    type: "empty",
                    prompt: "Not valid groupID."
                }]
            },
            email: {
                identifier: "email",
                rules: [
                    {
                        type: "empty",
                        prompt: "Not valid email."
                    },
                    {
                        type   : 'email',
                        prompt : 'Please enter a valid e-mail'
                    }
                ]
            },
            firstName: {
                identifier: "firstName",
                rules: [{
                    type: "empty",
                    prompt: "Not valid firstName."
                }]
            },
            lastName: {
                identifier: "lastName",
                rules: [{
                    type: "empty",
                    prompt: "Not valid lastName."
                }]
            },
            iButton: {
                identifier: "iButton",
                rules: [{
                    type: "regExp",
                    value: /^$|[^0]+/i,
                    prompt: "Not valid iButton."
                }]
            }
            // companyID: {
            //     identifier: "companyID",
            //     rules: [
            //         {
            //             type: "empty",
            //             prompt: "Not valid companyID."
            //         }
            //     ]
            // },
            // phoneNumber: {
            //     identifier: "phoneNumber",
            //     rules: [
            //         {
            //             type: "empty",
            //             prompt: "Not valid phoneNumber."
            //         }
            //     ]
            // },


            // type: {
            //     identifier: "type",
            //     rules: [
            //         {
            //             type: "empty",
            //             prompt: "Not valid type."
            //         }
            //     ]
            // },
            // avatar: {
            //     identifier: "avatar",
            //     rules: [
            //         {
            //             type: "empty",
            //             prompt: "Not valid avatar."
            //         }
            //     ]
            // }
        }
    }
});
