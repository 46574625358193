export default {
    emptyName: 'Name is required.',
    missingCoordinates: 'Missing coordinates.',
    invalidAlerStationaryTime: 'Invalid stationary time. Insert a number between 5 and 60.',
    insufficientPolygonPoints: 'Insufficient points (minimum 3)',
    errorsExists: 'There are errors.',
    plaseTryAgainLater: 'Please try again later',
    numberWithoutComma: 'Use "." as decimal separator.',
    errorsExistsCheckData: 'Errors found. Please check data.',
}
