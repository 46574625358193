import DS from 'ember-data';
import MF from 'ember-data-model-fragments';

export default DS.Model.extend({
    companyID:      DS.attr('string'),
    name:           DS.attr('string'),
    timetableID:    DS.attr('string'),
    details:        DS.attr(),

    vehicles:       DS.hasMany("vehicle", {inverse: "group", allowNull: true}),
    users:          DS.hasMany("user", {inverse: "group"}),
    settings:		MF.fragment('fragments/group-settings/settings')
});
