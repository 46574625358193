import Ember from 'ember';

export default Ember.Component.extend({
    i18n: Ember.inject.service(),
    totals: Ember.computed('data.contents', function () {
        let totals = {
            areaTotal: 0,
            areaTotalLoad50: 0,
            fuelConsumption: 0,
            canFuelUsed: 0,
            sensorFuelConsumption: 0,
            fuelConsumptionCompose: 0,
            fuelConsumptionPerHa: 0,
            fuelConsumptionPerHour: 0,
            duration: 0,
            fuelConsumptionPerHaPopup: '',
            fuelConsumptionPerHourPopup: '',
            fuelConsumptionComposePopup: '',
        }
        let i18n = this.get('i18n')
        this.get('data.contents').forEach(item => {
            totals.areaTotal = totals.areaTotal + (item.get('areaTotal') ? item.get('areaTotal') / 10000 : 0)
            totals.areaTotalLoad50 = totals.areaTotalLoad50 + (item.get('areaTotalLoad50') ? item.get('areaTotalLoad50') / 10000 : 0)
            totals.fuelConsumption = totals.fuelConsumption + (item.get('fuelConsumption') ? item.get('fuelConsumption') : 0)
            totals.canFuelUsed = totals.canFuelUsed + (item.get('canFuelUsed') ? item.get('canFuelUsed') : 0)
            totals.sensorFuelConsumption = totals.sensorFuelConsumption + (item.get('sensorFuelConsumption') ? item.get('sensorFuelConsumption') : 0)
            totals.duration = totals.duration + (item.get('duration') ? item.get('duration') : 0)
        })

        // sensor fuels
        let sensorFuelConsumptionPerHa = totals.sensorFuelConsumption && totals.areaTotal ? totals.sensorFuelConsumption / totals.areaTotal : 0
        let sensorFuelConsumptionPerHour = totals.sensorFuelConsumption && totals.duration ? totals.sensorFuelConsumption / totals.duration : 0
        let sensorFuelConsumptionCompose = totals.sensorFuelConsumption

        // can fuels
        let canFuelConsumptionPerHa = totals.canFuelUsed && totals.areaTotal ? totals.canFuelUsed / totals.areaTotal : 0
        let canFuelConsumptionPerHour = totals.canFuelUsed && totals.duration ? totals.canFuelUsed / totals.duration : 0
        let canFuelConsumptionCompose = totals.canFuelUsed

        // calculated fuels
        let fuelConsumptionPerHa = totals.fuelConsumption && totals.areaTotal ? totals.fuelConsumption / totals.areaTotal : 0
        let fuelConsumptionPerHour = totals.fuelConsumption && totals.duration ? totals.fuelConsumption / totals.duration : 0
        let fuelConsumptionCompose = totals.fuelConsumption

        let valuesPerHa = this.fuelValuePopup(sensorFuelConsumptionPerHa, canFuelConsumptionPerHa, fuelConsumptionPerHa, this.get('data.vehicleData'))
        totals.fuelConsumptionPerHa = valuesPerHa.value
        totals.fuelConsumptionPerHaPopup = valuesPerHa.popup

        let valuesPerHour = this.fuelValuePopup(sensorFuelConsumptionPerHour, canFuelConsumptionPerHour, fuelConsumptionPerHour, this.get('data.vehicleData'))
        totals.fuelConsumptionPerHour = valuesPerHour.value
        totals.fuelConsumptionPerHourPopup = valuesPerHour.popup

        let valuesCompose = this.fuelValuePopup(sensorFuelConsumptionCompose, canFuelConsumptionCompose, fuelConsumptionCompose, this.get('data.vehicleData'))
        totals.fuelConsumptionCompose = valuesCompose.value
        totals.fuelConsumptionComposePopup = valuesCompose.popup

        return totals
    }),
    /**
     * return value and popup based on values and priorities
     * @param {*} sensorVal  (prioty 1) if sensor fail: show can. if can fail show calculated
     * @param {*} canVal (prioty 2) if can fail show calculated
     * @param {*} calculatedVal (prioty 3) if no sensor and no can show calculated
     * @param {*} vehicle 
     */
    fuelValuePopup(sensorVal, canVal, calculatedVal, vehicle) {
        sensorVal = Number(sensorVal)
        canVal = Number(canVal)
        calculatedVal = Number(calculatedVal)
        var finalVal = { value: 0, popup: '' }
        if (!vehicle) { return finalVal }
        let i18n = this.get('i18n')
        if (vehicle.get('hasProbe')) { // priority 1
            let sensorFail = !sensorVal && calculatedVal
            finalVal.popup += i18n.t('reports.headerFields.sensorFuelConsumption') + ' ' + sensorVal.toFixed(2) + '\n'
            if (!sensorFail) {
                finalVal.value = sensorVal
            }
            else { // fail prioirty 1
                if (vehicle.get('hasCan')) { // if has priority 2
                    let canFail = !canVal && calculatedVal
                    if (!canFail) {
                        finalVal.value = canVal
                        finalVal.popup += i18n.t('reports.headerFields.canFuelConsumption') + ' ' + canVal.toFixed(2) + '\n'
                    }
                    else { // fail prioirty 2
                        finalVal.value = calculatedVal
                        finalVal.popup += i18n.t('reports.headerFields.canFuelConsumption') + ' ' + canVal.toFixed(2) + '\n'
                        finalVal.popup += i18n.t('reports.headerFields.fuelConsumption') + ' ' + calculatedVal.toFixed(2) + '\n'
                    }
                } else {
                    finalVal.value = calculatedVal
                    finalVal.popup += i18n.t('reports.headerFields.fuelConsumption') + ' ' + calculatedVal.toFixed(2) + '\n'
                }
            }
        }
        if (vehicle.get('hasCan')) { // if has priority 2
            let canFail = !canVal && calculatedVal
            finalVal.popup += i18n.t('reports.headerFields.canFuelConsumption') + ' ' + canVal.toFixed(2) + '\n'
            if (!canFail) {
                finalVal.value = canVal
            }
            else {// fail prioirty 2
                finalVal.value = calculatedVal
                finalVal.popup += i18n.t('reports.headerFields.fuelConsumption') + ' ' + calculatedVal.toFixed(2) + '\n'
            }
        }
        if (!vehicle.get('hasProbe') && !vehicle.get('hasCan')) { // if noCan and noProbe >> show calculated
            finalVal.value = calculatedVal
            finalVal.popup += i18n.t('reports.headerFields.fuelConsumption') + ' ' + calculatedVal.toFixed(2) + '\n'
        }

        return finalVal
    },

    groupedRecords: Ember.computed('data', function () {
        let vehicle = this.get('data')
        let geofences = this.get('geofences')
        let i18n = this.get('i18n')
        if (!vehicle || !vehicle.get('contents')) { return [] }
        let newResult = []
        vehicle.get('contents').forEach(element => {
            element.set('startOfDay', moment(element.get('date')).startOf('day').valueOf())
            let elementStart = element.get ? element.get('startOfDay') : element['startOfDay']
            let hasType = newResult.findBy('startOfDay', elementStart)
            let geofence = geofences.findBy('id', element.get('geofenceID'))
            let geofenceName = geofence && geofence.get('name') ? geofence.get('name') : ''
            element.set('geofence', geofenceName)
            // deductible values (not send by API) : fuelConsumptionPerHa && fuelConsumptionPerHour
            let areaTotal = element.get('areaTotal')
            let duration = element.get('duration')

            let sensorFuelConsumption = element.get('sensorFuelConsumption')
            let canFuelUsed = element.get('canFuelUsed')
            let fuelConsumption = element.get('fuelConsumption')

            let sensorFuelConsumptionPerHa = areaTotal ? (sensorFuelConsumption / (areaTotal / 10000)) : 0
            let canFuelConsumptionPerHa = areaTotal ? (canFuelUsed / (areaTotal / 10000)) : 0
            let fuelConsumptionPerHa = areaTotal ? (fuelConsumption / (areaTotal / 10000)) : 0

            let sensorFuelConsumptionPerHour = duration ? (sensorFuelConsumption / (duration / 3600)) : 0
            let canFuelConsumptionPerHour = duration ? (canFuelUsed / (duration / 3600)) : 0
            let fuelConsumptionPerHour = duration ? (fuelConsumption / (duration / 3600)) : 0

            let valuesCompose = this.fuelValuePopup(sensorFuelConsumption, canFuelUsed, fuelConsumption, this.get('data.vehicleData'))
            element.set('fuelConsumptionCompose', valuesCompose.value)
            element.set('fuelConsumptionPopup', valuesCompose.popup)

            let valuesPerHa = this.fuelValuePopup(sensorFuelConsumptionPerHa, canFuelConsumptionPerHa, fuelConsumptionPerHa, this.get('data.vehicleData'))
            element.set('fuelConsumptionPerHa', valuesPerHa.value)
            element.set('fuelConsumptionPerHaPopup', valuesPerHa.popup)

            let valuesPerHour = this.fuelValuePopup(sensorFuelConsumptionPerHour, canFuelConsumptionPerHour, fuelConsumptionPerHour, this.get('data.vehicleData'))
            element.set('fuelConsumptionPerHour', valuesPerHour.value)
            element.set('fuelConsumptionPerHourPopup', valuesPerHour.popup)

            // canFuelLevelEndOfDay: if is percent, do not divide by 100
            let veh = vehicle.get('vehicleData')
            let isPercentFuel = veh && veh.get('status') && veh.get('status.can') && veh.get('status.can.fuel') && veh.get('status.can.fuel.level') && veh.get('status.can.fuel.level.1')
            let endOfDayFuelLevel = isPercentFuel ? element.get('canFuelLevelEndOfDay') : element.get('canFuelLevelEndOfDay') / 100
            element.set('canFuelLevelEndOfDay', endOfDayFuelLevel)

            if (!hasType) {
                let obj = Ember.Object.create({
                    elements: []
                })
                obj.set('startOfDay', elementStart)
                newResult.pushObject(obj)
            }
            newResult.findBy('startOfDay', elementStart).get('elements').pushObject(element)
        })
        newResult.forEach(sod => {
            sod.set('rowspan', sod.elements.length)
        })
        return newResult.sortBy('startOfDay')
    }),

    pageIndex: 1,
    showAll: false,
    resultsPerPage: 5, // obvious
    recordsCount: Ember.computed("groupedRecords.length", function () {
        return this.get("groupedRecords.length")
    }),
    showPagination: Ember.computed("recordsCount", "resultsPerPage", function () {
        return this.get("recordsCount") > this.get("resultsPerPage")
    }),

    visibleRecords: Ember.computed("groupedRecords", "showAll", "pageIndex", "resultsPerPage", function () {
        let resultsPerPage = this.get("resultsPerPage")
        let page = this.get("pageIndex")
        if (this.get("showAll") || !this.get("showPagination")) {
            return this.get('groupedRecords');
        } else {
            return this.get('groupedRecords').slice((page - 1) * resultsPerPage, page * resultsPerPage);
        }
    }),

    actions: {
        setResultsPerPage(results) {
            if (!results) { return false }
            this.set("resultsPerPage", results)
            this.set("pageIndex", 1)
        },
        showAll() {
            this.toggleProperty("showAll")
        },
        turnPage(pageNumber) {
            if (!pageNumber) { return false }
            this.set("pageIndex", pageNumber)
        },
    }
});
