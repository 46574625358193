import Ember from 'ember'
/* global d3 */

const { service } = Ember.inject
const { Component } = Ember

export default Component.extend({
	api: service(),

	data: null,

	height: 200,

	width: 1500,

	showLegend: true,

	showControls: true,

	mouseXPosition: null,
	mouseYPosition: null,

	label: [],

	currentChart: null,

	formatTime: d3.time.format('%Y-%m-%d %H:%M'),

	actions: {
		buildLabel(data) {
			let time = data.values[0].time
			let group = data.values[1].group
			let value = data.values[1].value
			let componentData = this.get('data')

			if (this.get('label').length === componentData.length) {
				this.get('label').setObjects([])
			}

			this.get('label').pushObject({ time, group, value })
		},
		triggerMouseMove(el, mouseCoords) {
			this.set('mouseXPosition', mouseCoords[0])
			this.set('mouseYPosition', mouseCoords[1])
		},

		setCurrentChart(currentChart) {
			currentChart.showDetails(this.get('label'))
		}
	}
})
