import Ember from 'ember';
import Base from './base';

export default Base.extend({
    model() {
        return Ember.RSVP.hash({
            tHeaders: [],
            tData: []
        });
    }
});