import DS from 'ember-data';
import ENV from '../config/environment';
import DataAdapterMixin from 'ember-simple-auth/mixins/data-adapter-mixin';

export default DS.RESTAdapter.extend(DataAdapterMixin, {
	host: ENV.api.host,
	namespace: ENV.api.namespace,
	authorizer: 'authorizer:jwt',

	normalizeErrorResponse(status, headers, payload) {
		if (payload && typeof payload === 'object' && payload.errors) {
			return payload.errors;
		} else {
			return [{
				status: '' + status,
				title: "The backend responded with an error",
				detail: '' + payload.message
			}];
		}
	}
});
