export default {
    "at":"Um",
    "from":"Von",
    "to":"Nach",
    "duration":"Dauer",
    "start":"Start",
    "stop":"Stop",
    "stationary":"Stillstehend",
    "speed":"Geschwindigkeit",
    "altitude":"Höhe",
    "fuelCanLevel":"CAN Tankfüllstand",
    "fuelLevel":"Füllstand des Kraftstoffsensors",
    "acceleration":"Beschleunigung",
    "rpm":"RPM",
    "lastUpdate":"Letztes Update",
    "engineState":"Status Motor",
    "engineLoad":"Motorlast",
    "engineTemperature":"Motortemperatur",
    "gpsPosition":"GPS Position",
    "gpsSpeed":"GPS Geschwindigkeit",
    "canSpeed":"CAN Geschwindigkeit",
    "canAcceleration":"CAN Beschleunigung",
    "canRPM":"CAN RPM",
    "canFuelAvailable":"Verfügbarer Kraftstoffstand (CAN)",
    "canFuelUsed":"Kraftstoff verbraucht (CAN)",
    "probeFuelAvailable":"erfügbarer Kraftstoffstand (sensor)",
    "distance":"Entfernung",
    "doors":"Türen",
    "agregation":"Aggregationseinheit",
    "temperature":"Temperatur"
}