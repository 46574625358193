/**
 * Changes added here influence
 * the two components that extends it's functionality.
 * Be carrefull when adding code here.
 * NOTE: You can always create a new component that extends this one for new and cool functionalities.
 */
import Ember from 'ember'
import ChartMixin from 'tracknamic-smb/mixins/charts/d3-v3/line'
/* global d3 */

export default Ember.Component.extend(ChartMixin, {
	/**
	 * Lines are identified by labels.
	 */
	labels: null,

	selectedLabels: null,
	selectedInterval: 'H',
	selectedSeedColor: '#FFFFFF',
	showLegend: false,
	labelChanged: false,
	controlsAlignment: 'centered',
	showControls: false,

	dataProcessed: null,
	formatTime: d3.time.format( '%Y-%m-%d %H:%M' ),

	filteredData: Ember.computed( 'dataProcessed', 'labelChanged', function () {
		let data = this.get( 'data' )
		let selectedLabels = this.get( 'selectedLabels' )

		if ( !selectedLabels || !selectedLabels.length ) { return [ { time: 0, value: 0 } ] }
		return data.filter((item) => {
			if ( ( item.isTemperature ) && ( item.doorStatusValue !== "Închis" ) ) {
				Ember.Logger.log( "Filtered Data: ", item );
			}
			return selectedLabels.indexOf( item.label ) > -1
		})
	}),

	processData( data ) {
		return this.removeConsecutiveSameValues( data, 'value' )
	},

	init() {
		this._super(...arguments)
		let data = this.get('data')
		if (data && data.length) {
			this.set( 'dataProcessed', this.processData( this.get( 'data' ) ) )
			this.set( 'labels', this.getLinesLabels( this.get( 'data' ) ) )
			this.set( 'selectedLabels', this.get( 'labels' ).map( item => item ) )
		}
	},

	didReceiveAttrs() {
		this.set( 'dataProcessed', this.get( 'data' ) )
	},

	actions: {
		toggleLabel( label, state ) {
			let selectedLabels = this.get( 'selectedLabels' )
			if ( state ) {
				selectedLabels.push( label )
			} else {
				let labelIndex = selectedLabels.indexOf( label )
				if ( labelIndex > -1 ) {
					selectedLabels.splice( labelIndex, 1 )
				}
			}

			this.toggleProperty( 'labelChanged' )
		}
	}
})
