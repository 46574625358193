import Base from './base';

export default Base.extend({
    actions: {
        onlySaveDocuments() {
            this.saveDocuments(this.get("model.vehicle"))
                .then(() => {
                    this.rollBackAndTransition()
                })
        }
    }
});