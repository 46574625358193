import Ember from 'ember';
import config from 'ember-get-config'
import AjaxService from 'ember-ajax/services/ajax';

export default AjaxService.extend({
	namespace: config.api.namespace,
	host: config.api.host,
	session: Ember.inject.service(),
	headers: Ember.computed('session.data.authenticated.idToken', {
		get() {
			let headers = {};
			const idToken = this.get('session.data.authenticated.idToken');
			if (idToken) {
				headers['Authorization'] = `Bearer ${idToken}`;
			}
			return headers;
		}
	})
});