import Ember from 'ember';

const shapeType = 'polygon';

export default Ember.Service.extend({
    /**
     *
     * @param {array of geofence} geofences
     * @return {array of geofence} contains only polygons
     */
    _filterPolygons(geofences) {
        return geofences.toArray().filter((geofence) => geofence.get('shape.type').toLowerCase() === shapeType);
    },

    /**
     *
     * @param {geofence} geofences
     * @return {array of objects}  polygons coordinates
     */
    polygons(geofences) {
        if (!geofences) { return null; }
        let polygons = this._filterPolygons(geofences);
        return polygons.map(p => this.inverseCoordinates(p));
    },

    /**
     *
     * @param {geofence} polygonGeofence
     * @return {object} object similar to geofence, but reverse coordinates
     */
    inverseCoordinates(polygonGeofence) {
        let polygon = Ember.copy(polygonGeofence.get('shape.coordinates'), true);

        return Ember.Object.create({
            name: polygonGeofence.get('name'),
            type: polygonGeofence.get('type'),
			color: polygonGeofence.get('color'),
            shape: {
                type: polygonGeofence.get('shape.type'),
                coordinates: polygon.map(p => p.map(coords => ({ lat: coords[1], lng: coords[0] }))).pop()
            }
        });
    },
});
