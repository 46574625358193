import Ember from 'ember'
/* global validator */

const { service } = Ember.inject
const twoSecconds = 2000

export default Ember.Controller.extend({
	i18n: service(),
	resolution: service(),
	api: service(),
	toast: service(),

	loading: false,

	company: Ember.computed('model', function () {
		let model = this.get('model')
		if (model && model.group) {
			if (!model.group.settings) {
				model.group['settings'] = { notifications: { emails: [], phones: [] } }
			}
			if (!model.group.settings.notifications) {
				model.group.settings['notifications'] = { emails: [], phones: [] }
			}
			if (!model.group.settings.notifications.emails) {
				model.group.settings.notifications['emails'] = []
			}
			if (!model.group.settings.notifications.phones) {
				model.group.settings.notifications['phones'] = []
			}
			return model.group
		}
		return {}
	}),

	emails: Ember.computed('company.settings.notifications.emails.@each', function () {
		let emails = this.get('company.settings.notifications.emails')
		return {
			email1: emails && emails.length ? emails[0] : '',
			email2: emails && emails.length > 1 ? emails[1] : '',
			email3: emails && emails.length > 2 ? emails[2] : '',
		}
	}),

	phones: Ember.computed('company.settings.notifications.phones.@each', function () {
		let phones = this.get('company.settings.notifications.phones')
		return {
			phone1: phones && phones.length ? phones[0] : '',
			phone2: phones && phones.length > 1 ? phones[1] : '',
			phone3: phones && phones.length > 2 ? phones[2] : '',
		}
	}),

	validInputs: Ember.computed('emails.email1', 'emails.email2', 'emails.email3', 'phones.phone1', 'phones.phone2', 'phones.phone3', function () {
		let email1 = this.get('emails.email1')
		let email2 = this.get('emails.email2')
		let email3 = this.get('emails.email3')

		let phone1 = this.get('phones.phone1').replace(/\s/g, "")
		let phone2 = this.get('phones.phone2').replace(/\s/g, "")
		let phone3 = this.get('phones.phone3').replace(/\s/g, "")

		return {
			email1: this.validateEmail(email1) && (email1 === '' || (email1 && email1 !== email2 && email1 !== email3)),
			email2: this.validateEmail(email2) && (email2 === '' || (email2 && email2 !== email1 && email2 !== email3)),
			email3: this.validateEmail(email3) && (email3 === '' || (email3 && email3 !== email2 && email3 !== email1)),

			phone1: this.validatePhone(phone1) && (phone1 === '' || (phone1 && phone1 !== phone2 && phone1 !== phone3)),
			phone2: this.validatePhone(phone2) && (phone2 === '' || (phone2 && phone2 !== phone1 && phone2 !== phone3)),
			phone3: this.validatePhone(phone3) && (phone3 === '' || (phone3 && phone3 !== phone2 && phone3 !== phone1)),
		}
	}),

	errors: Ember.computed('validInputs.@each', function () {
		var errors = false
		var validInputs = this.get('validInputs')
		for (var item in validInputs) {
			var valid = validInputs[item]
			if (!valid) {
				errors = true
				break
			}
		}
		return errors
	}),

	validateEmail(email) {
		return email === '' || validator.isEmail(email) ? true : false
	},

	validatePhone(phone) {
		let reg = /^[0-9 ]*$/
		return reg.test(phone)
	},

	actions: {
		save() {
			if (this.get('errors')) { return false } // validations

			let company = this.get('company')
			if (!company) { return false }

			let emailList = []
			let email1 = this.get('emails.email1')
			let email2 = this.get('emails.email2')
			let email3 = this.get('emails.email3')

			let phoneList = []
			let phone1 = this.get('phones.phone1')
			let phone2 = this.get('phones.phone2')
			let phone3 = this.get('phones.phone3')

			if (email1) { emailList.push(email1) }
			if (email2) { emailList.push(email2) }
			if (email3) { emailList.push(email3) }

			if (phone1) { phoneList.push(phone1) }
			if (phone2) { phoneList.push(phone2) }
			if (phone3) { phoneList.push(phone3) }

			let copiedCompany = Object.assign({}, company);
			Ember.set(copiedCompany, 'settings.notifications', {
				emails: emailList,
				phones: phoneList
			})

			this.set('loading', true)
			let api = this.get('api')
			let promise = api.put(`groups/${copiedCompany.id}`, {
				contentType: "application/json; charset=utf-8",
				dataType: "json",
				data: { group: copiedCompany }
			})
			promise
				.then((response) => {
					this.toast.success(this.get('i18n').t('messages.operationWithSuccess'), '', { hideDuration: 200, timeOut: 2000 })
					this.set('model', response)
					this.set('loading', false)
				})
				.catch(err => {
					this.toast.error(this.get('i18n').t('messages.operationWithError'))
				})
		},
	}
})
