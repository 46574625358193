import Ember from 'ember'

const { service } = Ember.inject

/**
 * Errors that originates from crate, most of them have code equals to 5000.
 * So we game them other code in order to disctinct them and set correct message.
 *
 * 1) no code e.g { t: translationKey }
 * 2) errors from crate { code: 5000, message: 'something in english' } - should be handled as exceptions
 * 3) regular (normal) errors { code: 402, message: 'some message' }
 */
const exceptionalErrors = [
	{ searchFor: 'Self-intersection', code: 5010 },
	{ searchFor: 'Already shared', code: 5020 },
]

export default Ember.Service.extend({
	i18n: service(),

	list: [],

	getGeneric() {
		let i18n = this.get('i18n')
		return {
			code: 0,
			message: i18n.t("errors.messages.errorsExistsCheckData")
		}
	},

	add(err) {
		let i18n = this.get('i18n')

		if (!err) {
			this.get('list').pushObject({
				code: 0,
				message: i18n.t("errors.messages.errorsExistsCheckData"),
			})
		}

		/**
		 * If is an exceptional error change it's code according to.
		 */
		this._checkForExceptions(err)


		let error = {
			code: err.code,
			message: (err.t ? i18n.t(err.t).string : i18n.t("errors." + err.code))
		}

		this.get('list').pushObject(error)
	},

	_checkForExceptions(err) {
		if (!err || !err.message) { return false }
		exceptionalErrors.forEach(exception => {
			if (err.message.indexOf(exception.searchFor) > -1) {
				err.code = exception.code
			}
		})

		return err
	},

	length() {
		return this.get('list').length
	},

	fetch() {
		Ember.deprecate('We need to renounce to this as it is not clear why is here.', false)
		return this.get('list')
	},

	clear() {
		this.get('list').clear()
	}
})
