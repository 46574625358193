import Ember from 'ember';
import config from '../../../config/environment';
import RSVP from 'rsvp'

const { service } = Ember.inject;

export default Ember.Controller.extend({
    session: service(),
    api: service(),
    i18n: service(),
    errors: service(),

    readonly: true,
    parentRoute: 'management.users',
    needs: 'management.users',
    parentController: Ember.computed.alias('controllers.management.users'),

    documentId: null,
    requestCompleted: false,
    documents: Ember.computed('model.documents.[]', function () {
        let _this = this;
        let user = this.get("model.user");
        if (!user.get("isNew")) {
            return _this.get('model.documents').filterBy('user.id', user.get("id"));
        }
        // if is a new user(no user ID), filter by documents isNew atrribute.
        else {
            return _this.get('model.documents').filterBy('isNew', true);
        }
    }),

    listOfErrors: Ember.computed('errors.list.@each', function () {
        return this.get("errors.list")
    }),

    queryParams: [
        { documentId: 'document' }
    ],

    onError(err, code) {
        /**
         * code ERRORS Handled onError
         *   1 : error on saving vehicle
         *   2 : error on saving user
         * 409 : user already exists
         * 410 : user email alrady exits
         */
        err.code = code;
        this.get('errors').add(err);
        this.set('requestCompleted', true)
    },

    saveFile(file, docID) {
        if (file && docID) {
            let fd = new FormData();
            fd.append('document', file);
            return this.get("api").request('/documents/' + docID + '/upload', {
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
            })
        }

        return window.Promise.resolve(null)
    },

    goToIndex() {
        this.set('readonly', true)
        this.transitionToRoute(this.get('parentRoute'))
    },

    rollBackAndTransition() {
        this.get('errors').clear();

        let user = this.get('model.user');
        let documents = this.get('documents');

        if (user.get("hasDirtyAttributes")) {
            user.rollbackAttributes();
        }

        if (documents) {
            documents.forEach((doc) => {
                doc.rollbackAttributes();
            });
        }
        this.set("readonly", true);
        this.set("requestCompleted", false);
        this.transitionToRoute(this.get('parentRoute'));
    },

    _saveVehicle(vehicle) {
        vehicle
            .save()
            .then(() => {
            })
            .catch(function (err) {
                this.onError(err, "1"); //error on saving vehicle
            });
    },

    /** 
    * oldVehicle = vehicle to which the user will be removed (set user to null)
    * selectedVehicle = vehicle to which the user will be assigned (set user to current userID)
    */
    assignVehicle(user) {
        let _this = this
        let vehicles = this.store.peekAll('vehicle');
        let selectedVehicle = vehicles.findBy('id', user.get("vehicle.id"));

        if (selectedVehicle) {
            selectedVehicle.set("user", user);

            vehicles = vehicles.filter(function (item) {    // exclude selectedVehicle, to find the vehicle with user
                return item.get('id') !== user.get("vehicle.id");
            });
            // oldVehicle, set user null
            let oldVehicle = vehicles.findBy('userID', user.get("id"));
            if (oldVehicle) {
                oldVehicle.set("user", null); //detach user
                oldVehicle
                    .save()
                    .then(() => {
                        // after detach user from old Vehicle, attach to selectedVehicle
                        _this._saveVehicle(selectedVehicle);
                    })
                    .catch(function (err) {
                        _this.onError(err, "1");
                    });
            }
            else {
                _this._saveVehicle(selectedVehicle);
            }
        }
        else {
            let attachedVehicle = vehicles.findBy('user.id', user.get("id"));
            if (attachedVehicle) {
                attachedVehicle.set("user", null);
                _this._saveVehicle(attachedVehicle);
            }
        }
    },

    save(ACLGroup, ACLVehicle) {
        let _this = this;
        this.get('errors').clear();
        // this.toast.clear();  BUG TOAST moved to last then chain
        this.set('requestCompleted', false)
        var user = this.get('model.user');

        if (Ember.isEmpty(ACLGroup)) {
            user.set("acl.groups", null);
        } else {
            user.set("acl.groups", ACLGroup.split(","));
        }
        if (Ember.isEmpty(ACLVehicle)) {
            user.set("acl.vehicles", null);
        } else {
            user.set("acl.vehicles", ACLVehicle.split(","));
        }
        if (!user) { throw { error: 'User not exist' }; }

        user
            .save()
            .then(savedUser => {
                let promises = {
                    savedDocuments: _this.saveDocuments(savedUser),
                    assignedVehicle: _this.assignVehicle(savedUser)
                }
                return RSVP.hash(promises)
            })
            .then(() => {
                _this.set('requestCompleted', true)
                _this.toast.clear();
                _this.get('errors').clear();
                _this.toast.success(_this.get('i18n').t('messages.operationWithSuccess'))
            })
            .catch(function (err) {
                // _this.toast.error(_this.get('i18n').t('messages.operationWithError'))
                _this.onError(err, "409");
            });
    },

    saveDocuments(user) {
        let newDocs = this.get('model.documents').filterBy("isNew");
        let promises = []

        // Add documents to vehicle
        newDocs.forEach((document) => {
            document.set("user", user)
            promises.push(document.save())
        })

        return RSVP.all(promises)
            .then(data => {
                let promises = []

                // Add file to document
                data.forEach(document => {
                    let file = document.get("document")
                    promises.push(this.saveFile(file, document.get('id')))
                })
                return RSVP.all(promises)
            })
    },

    actions: {
        disableReadonly() {
            this.set("readonly", false);
        },
        downloadFile(doc) {
            let url = config.api.host + '/' + config.api.namespace + '/documents/' + doc.get("id") + '/download';
            const idToken = this.get('session.data.authenticated.idToken');

            if (doc.get("id") && doc.get("downloading") === false) {
                doc.set("downloading", true); // download started
                let req = new XMLHttpRequest();
                req.open('GET', url, true);
                req.setRequestHeader('Authorization', `Bearer ${idToken}`);
                req.responseType = 'blob';

                req.onload = function (/*event*/) {
                    doc.set("downloading", false); // download finished
                    let blob = req.response;
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = doc.get("file.name");
                    link.click();
                }
                req.error = () => {
                    doc.set("downloading", false); // download finished
                }
                req.send();
            }
        },
        createDocument() {
            let startDate = new Date();
            let endDate = new Date();
            let user = this.get('model.user');
            endDate.setDate(endDate.getDate() + 1);

            this.store.createRecord('document', {
                groupID: null,
                companyID: null,
                type: "permis_conducere",
                category: null,
                number: null,
                user: user,
                valid: {
                    from: startDate,
                    until: endDate,
                },
                price: {
                    value: null,
                    currency: null
                },
                file: {
                    name: null,
                    type: null,
                    size: null,
                    hash: null
                },
                details: {
                    alertInterval: null
                }
            });
        },
        deleteDocument(doc) {
            if (doc) {
                doc.get("isNew") ? doc.rollbackAttributes() : doc.destroyRecord()
            }
        },
        cancel() {
            this.rollBackAndTransition();
        },
        save(ACLGroup, ACLVehicle) {
            this.save(ACLGroup, ACLVehicle)
        },
    }
});
