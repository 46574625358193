import Base from './base-grouped';
import PropsInit from '../../mixins/reports/controller-props-initialization';

export default Base.extend(PropsInit, {
    reportTitle: 'POI',
    sortProperties: ['startTime:asc'],
    groupByKey: 'vehicleID',

    limit: 10, // number of tables to show as default
    increment: 1,
    recordsLength: Ember.computed.alias('groupedRecords.length'),

    scrolledRecords: Ember.computed('groupedRecords', 'limit', function () {
        let limit = this.get('limit')
        let length = this.get('recordsLength')
        let groupedRecords = this.get('groupedRecords')
        return length > limit ? groupedRecords.slice(0, limit) : groupedRecords
    }),

    actions: {
        incrementRecords() {
            let limit = this.get('limit')
            let length = this.get('recordsLength')
            let increment = this.get('increment')
            if (length <= limit) { return false }

            this.incrementProperty('limit', increment)
        }
    },
});
