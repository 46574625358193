import Ember from 'ember';

export default Ember.Component.extend({
    myMessages: {
        noResults: "Nu sunt rezultate."
    },

    didRender() {
        var listItems = Ember.$('.single-select.ui.dropdown .menu .item');
        if (Ember.isEmpty(listItems)) {
            return;
        }

        var selected = this.get('selectedItem');
        listItems.each(function() {
            var item = Ember.$(this);
            if (item.attr('data-value') == selected) {
                item.toggleClass('active');
            }
        });
    },

    actions: {
        selectChanged(value, text, choice) {
            this.sendAction('selectChanged', value, text, choice);
        }
    }
});
