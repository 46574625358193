import Ember from 'ember';
import config from '../../../config/environment';

const { service } = Ember.inject;

export default Ember.Controller.extend({

	api: service(),
	session: service(),
	readonly: true,

	parentRoute: 'management.vehicles',
	needs: 'management.vehicles',
	parentController: Ember.computed.alias('controllers.management.vehicles'),
	// parentController: Ember.inject.controller('management.vehicles'),
	documents: Ember.computed('model.documents.[]', function () {
		let _this = this;
		let vehicle = this.get("model.vehicle");
		if (!vehicle.get("isNew")) {
			return _this.get('model.documents').filterBy('vehicle.id', vehicle.get("id"));
		}
		// if is a new vehicle(no vehicle ID), filter by documents isNew atrribute.
		else {
			return _this.get('model.documents').filterBy('isNew', true);
		}
	}),

	queryParams: [
		{ documentId: 'document' }
	],

	documentId: null,

	goToIndex() {
		this.set("readonly", true);
		this.transitionToRoute(this.get('parentRoute'));
	},

	checkAsignedUsers(selUserID, vehicleID) {
		// Remove current vehicle
		var vehicles = this.store.peekAll('vehicle').filter(function (item) {
			return item.get('id') !== vehicleID;
		})
		if (selUserID) {
			return vehicles.findBy('user.id', selUserID);
		} else {
			return null;
		}
	},
	saveDevice(deviceID, vehicleID) {
        /**
         * if vehicle & not device
         * set device to null
         * 1 - delete device (vehicleID, !deviceID) set device vehicleID to null
         *
         * 2 - set vehicle to device.
         *  2a - vehicle has not previous device
         *  2b - vehicle has previous device
         */

		if (!vehicleID) { return false }

		let devices = this.store.peekAll('device');

		if (!deviceID && vehicleID) { // delete vehicle from device
			let device = devices.findBy("vehicleID", vehicleID);
			if (device) {
				device.set("vehicleID", null);
				device.save();
			}
		}
		else if (deviceID && vehicleID) { // set device vehicle
			devices = devices.filter(function (item) {
				return item.get('id') !== deviceID; // exclude selectedDevice from devices
			});

			let device = this.store.peekAll('device').findBy("id", deviceID);
			if (device) { //device must exist
				device.set("vehicleID", vehicleID);
				let prevDevice = devices.findBy('vehicleID', vehicleID);
				if (prevDevice) { // 2b - vehicle has previous device
					prevDevice.set("vehicleID", null);
					prevDevice
						.save() // delete vehicle from prevDevice
						.then(() => {
							device.save(); // save vehicle to device
						});
				}
				else { // 2a - vehicle has not previous device
					device.save();
				}
			}
		}

		// devices = devices.filter(function (item) {
		//     return item.get('id') !== deviceID; // exclude selectedDevice from devices
		// });
		// if (vehicleID) {
		//     return devices.findBy('vehicleID', vehicleID);
		// } else {
		//     return null;
		// }
	},
	saveFile(file, docID) {
		if (file && docID) {
			let fd = new FormData();
			fd.append('document', file);
			return this.get("api").request('/documents/' + docID + '/upload', {
				data: fd,
				processData: false,
				contentType: false,
				type: 'POST',
			})
		}
	},

	rollBackAndTransition() {
		// Ember.Logger.log("controller cancel");
		let vehicle = this.get('model.vehicle');
		let documents = this.get('documents');

		if (vehicle.get("hasDirtyAttributes")) {
			vehicle.rollbackAttributes();
		}
		if (documents) {
			documents.forEach((doc) => {
				doc.rollbackAttributes();
			});
		}

		this.goToIndex();
	},

	actions: {
		disableReadonly() {
			this.set("readonly", false);
		},
		downloadFile(doc) {
			let url = config.api.host + '/' + config.api.namespace + '/documents/' + doc.get("id") + '/download';
			const idToken = this.get('session.data.authenticated.idToken');

			if (doc.get("id") && doc.get("downloading") === false) {
				doc.set("downloading", true); // download started
				let req = new XMLHttpRequest();
				req.open('GET', url, true);
				req.setRequestHeader('Authorization', `Bearer ${idToken}`);
				req.responseType = 'blob';

				req.onload = function (/*event*/) {
					doc.set("downloading", false); // download finished
					let blob = req.response;
					let link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);
					link.download = doc.get("file.name");
					link.click();
				}
				req.error = () => {
					doc.set("downloading", false); // download finished
				}
				req.send();
			}
		},

		createDocument() {
			let startDate = new Date();
			let endDate = new Date();
			let vehicle = this.get('model.vehicle');
			endDate.setDate(endDate.getDate() + 1);

			this.store.createRecord('document', {
				groupID: null,
				companyID: null,
				type: "RCA",
				category: null,
				number: null,
				vehicle: vehicle,
				valid: {
					from: startDate,
					until: endDate,
				},
				price: {
					value: null,
					currency: null
				},
				file: {
					name: null,
					type: null,
					size: null,
					hash: null
				},
				details: {
					alertInterval: null
				}
			});
		},

		saveDocuments(vehicle) {
			let _this = this;
			let newDocs = this.get('model.documents').filterBy("isNew");
			Ember.Logger.log('saveDocuments')
			this.store
				.find('vehicle', vehicle.get('id'))
				.then(data => {
					newDocs.forEach((doc) => {
						doc.set("vehicle", data.get('id'));
						Ember.Logger.log(doc.get('vehicle'))
						Ember.Logger.log(doc)
						let file = doc.get("document");
						doc
							.save()
							.then((data) => {
								return _this.saveFile(file, data.get('id'));
							})
							.then(() => {

							})
							.catch((err) => {
								Ember.Logger.error("ERROR SAVING DOC");
								Ember.Logger.debug(err);
							});
					});
				})


		},

		cancel() {
			this.rollBackAndTransition();
		},

		save() {
			// vehicle.save must be moved to a function
			var _this = this;
			var vehicle = this.get('model.vehicle');
			if (!vehicle) { throw { error: 'Vehicle not exist' }; }
			// Ember.Logger.log("controller save");
			let device = _this.store.peekAll('device').findBy("id", vehicle.get("device.id"))

			// if selected driver, was attached to another

			if (vehicle.get('isNew')) { // new. save to get vehicle id
				vehicle
					.save()
					.then(function (data) {
						_this.send("saveDocuments", data);
						if (device) {
							_this.saveDevice(device.get("id"), vehicle.get("id"));
						}
						else {
							_this.saveDevice(null, vehicle.get("id"));
						}
						let vehicleToDetachUser = _this.checkAsignedUsers(vehicle.get("user.id"), vehicle.get("id"));
						if (vehicleToDetachUser) {
							vehicleToDetachUser.set('user', null);
							vehicleToDetachUser.save()
								.then(() => {
									_this.goToIndex();
								});
						} else {
							_this.goToIndex();
						}
					})
					.catch(function (err) {
						Ember.Logger.debug("errorOnSave");
						Ember.Logger.debug(err);
					});
			}
			else { //edit
				_this.send("saveDocuments");
				let vehicleToDetachUser = _this.checkAsignedUsers(vehicle.get("user.id"), vehicle.get("id"));
				if (vehicleToDetachUser) {
					vehicleToDetachUser.set('user', null);
					vehicleToDetachUser
						.save()
						.then(() => {
							vehicle
								.save()
								.then(function (/*data*/) {
									if (device) {
										_this.saveDevice(device.get("id"), vehicle.get("id"));
									}
									else {
										_this.saveDevice(null, vehicle.get("id"));
									}
									_this.goToIndex();
								})
								.catch(function (err) {
									Ember.Logger.debug("errorOnSave");
									Ember.Logger.debug(err);
								});
						});
				}
				else {
					vehicle
						.save()
						.then(function (/*data*/) {
							if (device) {
								_this.saveDevice(device.get("id"), vehicle.get("id"));
							}
							else {
								_this.saveDevice(null, vehicle.get("id"));
							}
							_this.goToIndex();
						})
						.catch(function (err) {
							Ember.Logger.debug("errorOnSave");
							Ember.Logger.debug(err);
						});
				}
			}
		},
	}
});
