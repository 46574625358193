import Ember from 'ember'

export default Ember.Service.extend({

    $stickyTable: null,

    tableMaxWidth: 0,

    $biggestTable: null,

    $scrollContainer: null,

    lastScrollPosition: 0,

    /**
     * Preserve vertical scroll position on initialization.
     */
    preserveScrollTopPosition: true,

    alignColumns($tables, $relativeTo) {
        if (!this.checkJQueryParam($tables)) { return false }

        if ($relativeTo) {
            this.set('$biggestTable', $relativeTo)
            this.set('tableMaxWidth', $relativeTo.width())
        }

        if (!this.get('$biggestTable')) { return false }

        $tables.toArray().forEach((item) => {
            let $item = Ember.$(item)
            $item.width(this.get('tableMaxWidth'))
            $item.find('th').each((idx, col) => {
                Ember.$(col).width(Ember.$(this.get('$biggestTable').find('th').get(idx)).width())
            })
        })

    },

    checkJQueryParam($param) {
        return ($param && $param.length)
    },

    /**
     * All tables will have the width and columns width same as $biggestTable.
     * So we could initialize tableheader float pluing just for first table.
     */
    correctTablesWidth($tables) {
        if (!this.checkJQueryParam($tables)) { return false }
        /** Reset tableMaxWidth as in the next lines we search and set a new max. */
        this.set('tableMaxWidth', 0)

        this.setBiggestTable($tables)

        this.alignColumns($tables)
    },

    setBiggestTable($tables) {
        if (!this.checkJQueryParam($tables)) { return false }
        $tables.toArray().forEach((item) => {
            let $item = Ember.$(item)
            if ($item.width() > this.get('tableMaxWidth')) {
                this.set('tableMaxWidth', $item.width())
                this.set('$biggestTable', $item)
            }
        })
    },

    destroy() {
        if (!this.checkJQueryParam(this.get('$stickyTable'))) { return false }

        this.set('lastScrollPosition', this.get('$scrollContainer').scrollTop())

        let destroy = this.get('$stickyTable').floatThead('destroy')
        this.set('$stickyTable', null)
        return destroy
    },

    isVisible() {
        if (!this.checkJQueryParam(this.$stickyTable)) { return false }
        return this.$stickyTable.is(':visible')
    },

    reflow() {
        let $item = this.get('$stickyTable')
        if (!this.checkJQueryParam($item)) { return false }
        $item.floatThead('reflow')
    },

    scrollTo(animationTime = 100) {
        if (!this.get('preserveScrollTopPosition')) { return false }

        let _this = this

        this.get('$scrollContainer').animate({
            scrollTop: Number(_this.get('lastScrollPosition'))
        }, animationTime)
    },

    setMaxTableWidth($table) {
        if ($table.width() > this.get('tableMaxWidth')) {
            this.set('tableMaxWidth', $table.width())
            this.set('$biggestTable', $table)
        }
    },

    sticky($table, options) {

        if (!this.checkJQueryParam($table)) { return false }

        this.set('$scrollContainer', options.scrollContainer($table) || Ember.$('body'))

        $table.addClass('isTheStickyOne')

        this.set('$stickyTable', $table)

        $table.floatThead(Ember.$.extend({ zIndex: 10 }, options))

        this.reflow($table)

        this.scrollTo()

        return this
    }

})
