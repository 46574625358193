export default {
	"management": "Management",
	"documents": "Unterlagen",
	"moreDetails": "Fügen Sie mehr hinzu",
	"docStartDate": "Von",
	"docEndDate": "Bis",
	"price": "Preis",
	"load": "Belastung",
	"download": "Download",
	"accountInformation": "Account Informationen",
	"document": {
		"deleteTittle": "Dokument löschen",
		"sureToDelete": "Das Dokument {{fileName}} ({{type}}) wird gelöscht",
		"notifications": {
			"defaultText": "Benachrichtigungen",
			"day": "Ein Tag",
			"week": "Eine Woche",
			"month": "Ein Monat"
		}
	},
	"headerFields": {
		"licensePlate": "Kennzeichen",
		"driver": "Fahrer",
		"car": "Auto",
		"type": "Typ",
		"division": "Division",
		"status": "Status",
		"name": "Name",
		"email": "Email",
		"phoneNumber": "Telefon",
		"role": "Rolle",
		"width": "Breiter"
	},
	"implements": {
		"types": {
			"plow": "Pflug",
			"cultivator": "Kultivator",
			"disc-harrow": "Scheibenegge",
			"combinator": "Kombinator",
			"chisel-plow": "Meißelpflug",
			"seed-drill-weeding": "Unkrautjäte",
			"seed-drill-cereal": "Sämaschine",
			"weeder": "Weeder",
			"drill": "Drille",
			"sprayer": "Spritze",
			"fertilizer": "Düngemaschine",
			"leveler": "Richtmaschine",
			"planter": "Einzelkornsämaschine",
			"rotating-rake": "Rotierender Rechen",
			"baler": "Ballenpresse",
			"mower": "Mähmaschine",
			"harvesters": "Mähdrescher",
			"harrow": "Egge",
			"roller": "Roller",
			"trailer": "Anhänger",
			"vidange": "Vidange",
			"chopper": "Chopper",
			"front-loader": "Frontlader",
			"harvester-header-cereal": "Harvester header cereal",
			"harvester-header-weeding": "Harvester header weeding"
		},
		"newTitle": "Anbaugeärt hinzufügen",
		"noImplement": "Kein Anbaugerät",
		"name": "Name",
		"type": "Typ",
		"width": "Breite(cm)",
		"sureToDelete": "Sind Sie sicher, dass Sie dieses Gerät löschen möchten?"
	},
	"timetables": {
		"withoutTimetable": "Kein Zeitplan",
		"sureToDeleteTimetable": "Sind Sie sicher, dass Sie diesen Zeitplan löschen möchten?",
		"setCompanyDefaultTimetable": "Unternehmensstandard festlegen",
		"willBeChanged": "Der Zeitplan wird geändert",
		"defaultCompanyWillBeChanged": "Der Firmenplan wird geändert ab",
		"detachDefaultCompanyTimetable": "Das Unternehmen wird keine Zeitpläne haben",
		"defaultCompanyTimetableWillBeDeleted": "Das Unternehmen wird keinen Standardzeitplan haben!",
		"to": "An",
		"name": "Zeitplan Name",
		"isDefaultCompanyTimetable": "Wenn dieser Zeitplan gelöscht wird, hat das Unternehmen keinen Standardzeitplan",
		"browse": "Durchsuche...",
		"addCsv": "Upload CSV",
		"status": {
			"defaultText": "Status",
			"disabled": "Deaktiviert",
			"custom": "Benutzerdefiniert",
			"fullDay": "Ganztägig"
		},
		"new": {
			"title": "Zeitplan hinzufügen",
			"buttonText": "SPEICHERN"
		},
		"import": {
			"title": "Zeitplan importieren",
			"buttonText": "IMPORTIEREN",
			"name": "Zeitplanname",
			"licensePlate": "Kennzeichen",
			"affectedVehicles": "Fahrzeuge, die von bestehenden Fahrplänen abweichen",
			"timetablesForRemoval": "Zeitpläne zum Entfernung",
			"mondayStart": "Montag Start",
			"mondayStop": "Montag Stop",
			"tuesdayStart": "Dienstag Start",
			"tuesdayStop": "Dienstag Stop",
			"wednesdayStart": "Mittwoch Start",
			"wednesdayStop": "Mittwoch Stop",
			"thursdayStart": "Donnerstag Start",
			"thursdayStop": "Donnerstag Stop",
			"fridayStart": "Freitag Start",
			"fridayStop": "Freitag Stop",
			"saturdayStart": "Samstag Start",
			"saturdayStop": "Samstag Stop",
			"sundayStart": "Sonntag Start",
			"sundayStop": "Sonntag Stop"
		},
		"edit": {
			"title": "Zeitplan bearbeiten",
			"buttonText": "EDIT"
		},
		"view": {
			"title": "Zeitplan Informationen",
			"buttonText": "OK"
		}
	},
	"vehicles": {
		"division": "Einteillung",
		"driverIsAttached": "Der ausgewählte Fahrer wird einem anderen Fahrzeug zugeordnet!",
		"isAssignedToVehicle": "ist dem Fahrzeug zugeordnet",
		"willBeAssigned": "wird dem Fahrzeug zugeordnet",
		"sureToDeleteVehicle": "Sind Sie sicher, dass Sie das Fahrzeug löschen möchten?",
		"vehicleHasDeviceAssigned": "Gerät {{deviceID}} ist dem Fahrzeug angehängt",
		"selectDevice": "Gerät auswählen",
		"relayContact": {
			"blockedContact": "Zündung blockiert",
			"unblockedContact": "Zündung entsperren",
			"areYouSureToBlockThisContact": "Sind Sie sicher, dass Sie die Zündung blockieren möchten?",
			"areYouSureToUnblockThisContact": "Sind Sie sicher, dass Sie die Zündung entsperren möchten?",
			"blockContact": "Zündung blockieren",
			"unblockContact": "Zündung entsperren"
		},
		"speedLimit": {
			"max": "Standardwarnschwelle",
			"maxTooltip": "Warnungen werden gesendet, wenn der Schwellenwert unabhängig von der Straßenbegrenzung überschritten wird (beispiel: 90 km / h für LKWs).",
			"min": "Alarmschwelle",
			"minTooltip": "Geschwindigkeitswarnungen werden nicht gesendet, wenn sie unter diesem Schwellenwert liegen",
			"offset": "Zulässiger Schwellenwert",
			"offsetTooltip": "10 km / h müssen bei Ortschaften mit \ n50 + 10 km / h alarmiert werden, \ n90 + 10 km / h auf Nationalstraßen, \ n130 + 10 km / h auf Autobahnen",
			"errorMessage": "Überprüfen Sie die rot markierten Felder"
		},
		"doorAlert": {
			"noAlert": "Kein Alarm",
			"alertOpenDoor": "Warnung Tür offen",
			"doorOpenOutsidePOI": "Warnung Tür offen außerhalb des POI",
			"doorOpenOutsidePOIInTimetable": "Warnung Tür außerhalb des POI während des Zeitplans offen"
		},
		"removeDevice": "Gerät entfernen",
		"cannotChangeDeviceUntilRelayContactDisabled": "Wenn die Zündung blockiert ist, können die Geräte nicht gewechselt werden",
		"cannotChangeRelayContactUntilDeviceIsSaved": "Die Zündung kann erst blockiert werden, wenn das Gerät gespeichert ist",
		"deviceChange": "Gerät wechseln",
		"noDevices": "Es sind keine Geräte verfügbar",
		"vehicleDetails": "Fahrzeugangaben",
		"vehicleAlerts": "Fahrzeugwarnungen",
		"moreDetails": "Mehr Details",
		"autoPark": "Auto Park",
		"licensePlate": "Nummernschild",
		"vin": "VIN",
		"make": "Hersteller",
		"model": "Modell",
		"vehicle": "Fahrzeug",
		"fuel": {
			"typeText": "Treibstoffart",
			"type": {
				"petrol": "Benzin",
				"hybridPetrol": "Hybrid-Benzin",
				"diesel": "Diesel",
				"hybridDiesel": "Hybrid-Diesel",
				"lpg": "GPL",
				"electric": "Elektrisch"
			},
			"capacity": "Tankvolumen",
			"consumption": {
				"distanceWise": "Durchschnittlicher Kraftstoffverbrauch L/100KM",
				"timeWise": "Durchschnittlicher Kraftstoffverbrauch L/h"
			}
		},
		"category": "Kategorie",
		"type": {
			"utility": "Nützlichkeit",
			"car": "Auto",
			"minibus": "Minibus",
			"bus": "Bus",
			"truck": "LKW",
			"tractor": "Schlepper",
			"harvester": "Erntemaschine",
			"construction": "Baumaschine",
			"trailer": "Anhänger",
			"semi-trailer": "Sattelauflieger"
		},
		"seats": "Anzahl der Sitze",
		"selectDivision": "Division auswählen",
		"selectDriver": "Fahrer auswählen",
		"removeDriver": "Fahrer entfernen",
		"fabricationYear": "Herstellungsjhar",
		"registrationDate": "Registrierungsdatum",
		"engine": {
			"power": "Pferdestärke (HP)",
			"capacity": "Motor Leistung"
		},
		"engineLoadLimit": "Motorlastgrenze (%)",
		"engineLoadLimitText": "Motorlastgrenze (%) zur Berechnung der Arbeitsbereiche.\nNur ganzzahlige Zahlen zwischen 0 und 100 zulassen ",
		"payloadCapacity": "Nutzlast",
		"tireSize": "Reifengröße",
		"color": "Farbe",
		"new": {
			"title": "Fahrzeug hinzufügen"
		},
		"edit": {
			"title": "Fahrzeug bearbeiten"
		},
		"view": {
			"title": "Fahrzeug Details"
		}
	},
	"users": {
		"userDetails": "Benutzer Details",
		"vehicleHasDriver": "Das ausgewählte Fahrzeug hat bereits einen Fahrer!",
		"userHasVehicle": "Der Benutzer ist dem Fahrzeug zugeordnet",
		"ifDeleteDetatch": "Wenn Sie diesen Benutzer gelöscht haben, wird er diesem Fahrzeug nicht zugewiesen",
		"haveDriver": "gefahren von",
		"wichWillBeReplaced": "welches ersetzt wird durch ",
		"sureToDeleteThisUser": "Möchten Sie den Benutzer wirklich löschen?",
		"userName": "Benutzer",
		"firstName": "Vorname",
		"iButton": "iButton",
		"lastName": "Familienname",
		"email": "Email",
		"photoUpload": "Foto hochladen",
		"division": "Abteilung",
		"phoneNumber": "Telefon",
		"drivingLicense": "Führerschein",
		"assignedVehicle": "Zugewiesenes Fahrzeug",
		"detachVehicle": "Fahrzeug entfernen",
		"role": "Rolle",
		"driver": "Fahrer",
		"admin": "Administrator",
		"docStartDate": "Von",
		"docEndDate": "Bis",
		"docPrice": "Preis",
		"load": "Belastung",
		"new": {
			"title": "Benutzer hinzufügen",
			"buttonText": "SICHERN",
			"headerText": "Füllen Sie das Formular mit Benutzerdaten aus. Für Details klicken Sie auf 'Weitere Details hinzufügen'."
		},
		"edit": {
			"title": "Benuter bearbeiten",
			"buttonText": "BEARBEITEN",
			"headerText": "Füllen Sie das Formular mit Benutzerdaten aus. Für Details klicken Sie auf 'Weitere Details hinzufügen'."
		},
		"view": {
			"title": "Benutzerinformationen",
			"buttonText": "OK",
			"headerText": "Informationen zum ausgewählten Benutzer. Für weitere Informationen klicken Sie auf 'Mehr'."
		}
	},
	"groups": {
		"deleteGroup": "Sind Sie sicher, dass Sie die Gruppe löschen möchten?",
		"group": "Gruppe",
		"addGroup": "Gruppe hinzufügen",
		"timetable": "Zeitplan",
		"editGroup": "Gruppe bearbeiten",
		"groupName": "Gruppenname",
		"addSubGroup": "Untergruppe hinzufügen",
		"editSubGroup": "Untergruppe bearbeiten",
		"deleteSubGroup": "Untergruppe löschen",
		"share": "Partner",
		"add": "Hinzufügen"
	},
	"geofences": {
		"name": "Feld Bezeichnung",
		"radio": "Radius",
		"sureToDeleteGeofence": "Möchten Sie das Feld wirklich löschen?",
		"alertStationaryTimeHoverText": "Minuten stationär (5-60)",
		"showAll": "Alle Felder anzeigen",
		"selectAll": "Alle Felder",
		"selectNone": "Ohne Felder",
		"type": {
			"geofence": "Feld",
			"poi": "Farm"
		},
		"shape": {
			"type": {
				"Polygon": "Feld",
				"Point": "Farm"
			}
		},
		"new": {
			"title": "Feld hinzufügen",
			"buttonText": "SICHERN"
		},
		"import": {
			"title": "Feld importieren",
			"buttonText": "Importiere Feld",
			"importedGeofencesNumber": "{{value}} Felder wurden importiert",
			"updatedGeofencesNumber": "{{value}} Felder wurden geändert",
			"notImportedGeofencesNumber": "{{value}} Felder wurden nicht importiert",
			"allGeofencesImported": "Alle ({{value}}) Felder wurden importiert",
			"pleaseWait": "Bitte warten",
			"processingGeofences": "Daten aus Datei werden verarbeiten",
			"downloadTemplate": "Download Vorlage",
			"downloadExamples": "Download Beispiel"
		},
		"simulate": {
			"importedGeofencesNumber": "{{value}} Felder werden importiert.",
			"updatedGeofencesNumber": "{{value}} Felder werden geändert.",
			"notImportedGeofencesNumber": "{{value}} Felder werden nicht importiert",
			"allGeofencesImported": "Alle Felder werden importiert({{value}})",
			"validateFile": "Überprüfe CSV"
		},
		"edit": {
			"title": "BEARBEITE FELD",
			"buttonText": "BEARBEITEN"
		},
		"info": {
			"title": "Feld Informationen",
			"buttonText": "OK"
		}
	},
	"settings": {
		"speedLimit": "erlaubte Höchstgeschwindigkeit",
		"insertEmailAddress": "Geben Sie die E-Mail-Adresse ein (max 3):",
		"email": "Email",
		"insertPhoneNumber": "Telefonnummern einfügen (max 3) Beispiel: 0787 709 154",
		"phoneNumber": "Telefonnummer",
		"alerts": {
			"limit": "Begrenzung",
			"geofence-stationary": "Feld stationär",
			"document-expiry": "Ablauf des Dokuments",
			"fuel-consumption-limit": "maximaler Kraftstoffverbrauch / Stunde",
			"rpm-limit": "RPM > 1900 in 2 Minuten"
		},
		"emails": {
			"poi-stationary": "Stationär auf dem Hof",
			"high-rpm": "RPM > 2000 an jeweils 5 aufeinander folgenden Punkten",
			"stationary-fuel-consumption": "Stationärer Kraftstoffverbrauch",
			"document-expiry": "Ablauf des Dokuments",
			"poi-enter": "Hof betreten",
			"poi-exit": "Hof verlassen",
			"geofence-enter": "Feld betreten",
			"geofence-exit": "Feld verlassen",
			"geofence-stationary": "Stationär im Feld",
			"speeding": "Zu hohe Geschwindigkeit",
			"door-open-outside-poi": "Tür offen außerhalb des Feldes",
			"insertEmailsSeparatedByComma": "Fügen Sie mehrere durch Komma getrennte E-Mail-Adressen ein",
			"notifications": "Benachrichtigungen",
			"email": "Email"
		}
	}
}
