import Ember from 'ember'
import Base from './base'

const { service } = Ember.inject

export default Base.extend({
	i18n: service(),

	model() {
		return Ember.RSVP.hash({
			tHeaders: [
				{ key: 'date', value: this.get('i18n').t('reports.headerFields.date'), align: 'left' },
				{ key: 'distanceCompose', value: this.get('i18n').t('reports.headerFields.distanceCompose'), align: 'left' },
				{ key: 'duration', value: this.get('i18n').t('reports.headerFields.duration'), align: 'left' },
				{ key: 'poiCount', value: this.get('i18n').t('reports.headerFields.poiCount'), align: 'left' },
				{ key: 'poiStationaryDuration', value: this.get('i18n').t('reports.headerFields.poiStationaryDuration'), align: 'left' },
				{ key: 'poiStationaryDoorsOpenedDuration', value: this.get('i18n').t('reports.headerFields.poiStationaryDoorsOpenedDuration'), align: 'left' },
				{ key: 'freezerOnDuration', value: this.get('i18n').t('reports.headerFields.freezerOnDuration'), align: 'left' },
			],
			tData: []
		})
	}
})
