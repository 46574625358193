import Ember from 'ember';

export default Ember.Route.extend({
    model() {
        return this.store.createRecord('implement',{
            type: 'plow',
        });
    },
    actions: {
        willTransition() {
            Ember.$('.managementModal').modal("hide");
        },
    },
});
