import DS from 'ember-data';

export default DS.Model.extend({
    name: DS.attr('string'),
    timezone: DS.attr('string'),
    //default = true >> set as company timetable
    default: DS.attr('boolean', { defaultValue: false }),
    schedule: DS.attr(),
    createdAt: DS.attr("date")
});
