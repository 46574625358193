import Ember from 'ember'
/* globals L */

const mapPaddings = 0.10
const { Component } = Ember
const { service } = Ember.inject


export default Component.extend({
    countryBounds: [[48.517, 20.083], [43.485, 30.135]],
    linesList: [],
    mapBounds: [],
    sessionService: service("session"),
    targetMap: null,
    googleLayer: null,
    googleMapType: "roadmap",
    loadedMarkers: [],

    calculateBounds: function (points) {
        // Cardinal directions
        if (points.get("length") > 0) {
            var n = points.get("firstObject._latlng.lat")
            var s = n
            var e = points.get("firstObject._latlng.lng")
            var w = e

            points.forEach(function (point, index) {
                if (index > 0) {
                    n = n < point._latlng.lat ? point._latlng.lat : n
                    s = s > point._latlng.lat ? point._latlng.lat : s
                    e = e < point._latlng.lng ? point._latlng.lng : e
                    w = w > point._latlng.lng ? point._latlng.lng : w
                }
            })
            return L.latLngBounds(L.latLng(s - mapPaddings, w - mapPaddings), L.latLng(n + mapPaddings, e + mapPaddings))
        } else {
            return this.get("countryBounds")
        }
    },
    init: function () {
        this._super( ...arguments )
        this.set( 'mapBounds', this.calculateBounds( this.get( "markers" ) ) )
        if ( this.get( "sessionService.session.googleMapLayerType" ) ) {
            this.set( "googleMapType", this.get("sessionService.session.googleMapLayerType" ) );
        }
    },
    didUpdateAttrs: function () {
        this._super(...arguments)

        if (this.get("sessionService.session.resetMapBounds")) {
            this.set('mapBounds', this.calculateBounds(this.get("markers")))
            this.set("sessionService.session.resetMapBounds", false)
        }

        if ( ( this.get( "map" ) !== "google" ) && ( this.get( "googleLayer" ) != null ) ) {
            this.get( "targetMap" ).removeLayer( this.get( "googleLayer" ) );
            this.set( "googleLayer", null );
        }
    },

    actions: {
        onDragend() {
            this.sendAction("clearcAtiveMarker")
        },
        onViewreset(event) {
			this.set("targetMap", event.target)
            this.get('passMapInstance')(this.get('targetMap'))
        },
        onLoad: function ( event ) {
            var scale = L.control.scale( { metric: true } )
            scale.addTo( event.target )
			this.set( "targetMap", event.target )
			this.get( 'passMapInstance' )( this.get( 'targetMap' ) )
        },
        selectedGoogleMapType ( type ) {
            this.set( "googleMapType", type );
            this.get( "targetMap" ).removeLayer( this.get( "googleLayer" ) );
            this.set( "googleLayer", L.gridLayer.googleMutant( { opacity: 1, type: type } ) );
            this.get( "targetMap" ).addLayer( this.get( "googleLayer" ) );

            this.set( "sessionService.session.googleMapLayerType", type );
        },
        onGoogleLoad ( event ) {
            Ember.Logger.log("On load event");
            this.set( "googleLayer", event.target );
        }
    }
})
