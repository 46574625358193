import Ember from 'ember';
/* global L */
const defautlGeofenceZoom = 2000
const boundsPad = 0.1

export default Ember.Service.extend({
	// For details see: http://www.movable-type.co.uk/scripts/latlong.html
	bearing(p1, p2) {
		return Math.atan2(Math.sin(p2.lng - p1.lng) * Math.cos(p2.lat), Math.cos(p1.lat) * Math.sin(p2.lat) - Math.sin(p1.lat) * Math.cos(p2.lat) * Math.cos(p2.lng - p1.lng)) * 180 / Math.PI;
	},

	addLatLngProperties(object, lat, lng) {
		if (!object || !lat || !lng) { throw new Error('Invalid arguments.') }
		object.lat = lat;
		object.lng = lng;
		return object;
	},

	transformCoordinatesLatLng(coordinates) {
		if (coordinates.length === 1) {// Polygon
			let arr = []
			coordinates[0].forEach((coor) => {
				arr.push(Ember.Object.create({ lat: coor[1], lng: coor[0] }))
			})
			return arr
		}
		else if (coordinates.length === 2) {// Point
			return Ember.Object.create({ lat: coordinates[1], lng: coordinates[0] });
		}
	},

	calculateBoundsZoom(latlng, zoom) {
		/**
		 * - isPOI -
		 * 		if zoom
		 * 		if latlng is object (!latlng.length)
		 * - isGeofence -
		 * 		if latlng is an array
		 */

		if (!latlng || (Array.isArray(latlng) && latlng.length === 0)) { return false }

		if (zoom && !Array.isArray(latlng)) {	// POI
			return L.latLng(latlng).toBounds(zoom * 2);
		}
		else if (!zoom && !Array.isArray(latlng)) { //POI without zoom
			return L.latLng(latlng).toBounds(defautlGeofenceZoom);
		}
		else {	// geofence
			return L.latLngBounds(latlng).pad(boundsPad);
		}
	},

	calculateBounds(points, defaultBounds) {
		if (!points.length) {
			return defaultBounds ? defaultBounds : null;
		}

		let firstPoint = points[0];

		let n = firstPoint.lat;
		let s = n;
		let e = firstPoint.lng;
		let w = e;

		points.forEach(function (point, index) {
			if (index > 0) {
				n = Math.max(n, point.lat);
				s = Math.min(s, point.lat);
				e = Math.max(e, point.lng);
				w = Math.min(w, point.lng);
			}
		});

		let mapPadding = this.calculatePadding(s, w, n, e);

		return L.latLngBounds(L.latLng(s - mapPadding, w - mapPadding), L.latLng(n + mapPadding, e + mapPadding));
	},

    /**
	 * Calculates bounds for given markers created with L.divIcon function using map/shapes/marker service.
     * @param {array} marker [{_latlng: {lat: x, lng: y}}, {_latlng: {lat: z, lng: q}}]
     * @return {latLngBounds} or null
     */
	calculateMarkersBounds(markers, defaultBounds) {
		// Cardinal directions
		if (!markers.length) {
			return defaultBounds ? defaultBounds : null;
		}

		let firstMarker = markers[0];
		let points = markers;
		if (!this.hasLatLngProperties(firstMarker)) {
			points = markers.map(marker => this.addLatLngProperties(marker, marker._latlng.lat, marker._latlng.lng));
		}
		return this.calculateBounds(points, defaultBounds);
	},

	calculatePadding(s, w, n, e) {
		let dist = this.distance(s, w, n, e);
		return parseFloat(('0.000' + Math.ceil(dist)).slice(- dist.length));
	},

    /**
     * Takes to points coordinates and returns distance between them.
     * @return {number} meters
     */
	distance(lat1, lon1, lat2, lon2) {
		var pi = 0.017453292519943295;    // Math.PI / 180
		var cos = Math.cos;
		var a = 0.5 - cos((lat2 - lat1) * pi) / 2 + cos(lat1 * pi) * cos(lat2 * pi) * (1 - cos((lon2 - lon1) * pi)) / 2;

		return 12742000 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km   added the extra zeros for meters
	},

	getRouteHighlight(routeLine, markerService) {
		var markers = [];
		routeLine.options.markers.eachLayer(function (marker) {
			if (marker.options.entityType == "bearing") {
				markers.push(markerService._makeMarker("bearing", {
					location: marker._latlng,
					angle: marker._latlng.bearing,
					class: "selected",
					entityType: "bearing"
				}));
			}
		});

		return L.polyline(routeLine.getLatLngs(), {
			color: "#ffffff",
			weight: 6,
			markers: L.layerGroup(markers),
			bounds: routeLine.options.bounds,
			owner: "tracknamic",
			objectType: "route",
			entityID: routeLine.options.entityID,
			start: routeLine.start,
			end: routeLine.end
		});
	},

	hasLatLngProperties(object) {
		return (object.lat && object.lng);
	},

	/**
	 * Check if given route is a stationary one.
	 * @param {*} route
	 * @return {bolean}
	 */
	isStationary(route) {
		if (!route) { return false; }
		if (this.isInProcess(route)) { return false }
		return route.get('type') === 'stationary';
	},

	isInProcess(route) {
		return route.get('id') === 'unknown'
	},

	/**
	 * Replacement for L.latLng. We avoid using Leaflet directly. We try to use it throug ember-leaflet.
	 * @param {*} lat
	 * @param {*} lng
	 */
	latLng(lat, lng) {
		if (Array.isArray(lat)) {
			/**
			 * Basic validation
			 */
			if (lat.length < 2) { throw 'Invalid arguments' }
			return this.latLng(lat[0], lat[1])
		}
		/**
		 * Basic validation
		 */
		if (!lat || !lng) { throw 'Invalid arguments' }
		return {
			lat,
			lng
		}
	},

	/**
	 * Returns only points with distance between them, greater or equals to markerDistance.
	 * @param {*latlng, bearing} markers route marker directions
	 * @param {*} zoom map zoom
	 * @param {*} bounds map bounds
	 * @return {array}
	 */
	routeDirectionsMarkersDistance(points, zoom, bounds) {
		var markers = [];
		var prevPosition = null;
		if (!zoom) { return null }

		// I had to figure out a relation between the zoom value and the distance between the shown markers.
		// The lower the zoom, the further we are and we have to show less markers.
		// I thought that a second grade equation would do, but at high zoom values this specific one is still not the best solution

		var markerDistance = 50 * Math.pow(1.8, (18 - zoom));

		points.forEach((marker, index) => {
			if (index == 0) {
				prevPosition = { lat: marker.lat, lng: marker.lng };
			} else if (
				(this.distance(prevPosition.lat, prevPosition.lng, marker.lat, marker.lng) >= markerDistance)
				&& (bounds.contains({ lat: marker.lat, lng: marker.lng }))
			) {
				markers.push(marker);
				prevPosition = { lat: marker.lat, lng: marker.lng };
			}
		});
		return markers;
	},
	selectBearingMarkers(markersLayer, zoom, bounds) {
		var markers = [];
		var prevPosition = null;

		// I had to figure out a relation between the zoom value and the distance between the shown markers.
		// The lower the zoom, the further we are and we have to show less markers.
		// I thought that a second grade equation would do, but at high zoom values this specific one is still not the best solution

		var markerDistance = 50 * Math.pow(1.8, (18 - zoom));

		markersLayer.getLayers().forEach((marker, index) => {
			if (index == 0) {
				prevPosition = marker._latlng;
			} else if (
				(this.distance(prevPosition.lat, prevPosition.lng, marker._latlng.lat, marker._latlng.lng) >= markerDistance)
				&& (bounds.contains(marker._latlng))
			) {
				markers.push(marker);
				prevPosition = marker._latlng;
			}
		});
		return L.layerGroup(markers);
	},
});
