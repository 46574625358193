import Ember from 'ember';
/* global validator */

export default Ember.Component.extend({
    phone: null,
    invalidPhone: false,

    allowAdd: Ember.computed('maxValues', 'phonesList.[]', function () {
        let max = this.get('maxValues')
        let phonesLength = this.get('phonesList.length')
        if (!max || !phonesLength) { return true }
        return phonesLength < max
    }),

    addPhone(phone) {
        let reg = /^[0-9 ]*$/
        let phones = this.get('phonesList')
        let validPhone = reg.test(phone) && !this.get('phonesList').includes(phone)
        if (validPhone) {
            this.get('phonesList').pushObject(phone);
            this.get('saveGroup')();
            return true
        }
        return false
    },
    actions: {
        setPhone(phone, event) {
            let hasCommas = (phone.match(/,/g) || []).length
            let isEnterKey = event.keyCode == 13

            if (hasCommas !== 1 && !isEnterKey) { return false; }
            phone = isEnterKey ? phone : phone.substr(0, phone.indexOf(','));

            this.set('invalidPhone', false)
            if (this.addPhone(phone)) {
                this.set('phone', null)
            } else {
                this.set('invalidPhone', true)
            }
        },
        addPhoneToList() {
            let phone = this.get('phone')

            this.set('invalidPhone', false)
            if (this.addPhone(phone)) {
                this.set('phone', null)
            } else {
                this.set('invalidPhone', true)
            }
        },
        removePhone(phone) {
            if (!phone) { return false }
            this.get('phonesList').removeObject(phone);
            this.get('saveGroup')();
        },
    }
});
