import Ember from 'ember';
import RSVP from 'rsvp';

export default Ember.Route.extend({
    i18n: Ember.inject.service(),
    
    model() {
        let i18n = this.get('i18n')

        const tHeaders = [
            { key: 'name', value: i18n.t('management.headerFields.name'), align: 'left' },
            { key: 'type', value: i18n.t('management.headerFields.type'), align: 'left' }
        ]
        return RSVP.hash({
            tHeaders: tHeaders,
            geofences: this.store.findAll('geofence')
        });
    },
    actions: {
        importGeofence() {
            this.transitionTo('management.geofences.import');
        },
        newGeofence() {
            this.transitionTo('management.geofences.new');
        },
        viewGeofence(geofenceID) {
            if (geofenceID) {
                this.transitionTo('management.geofences.view', geofenceID);
            }
        },
        editGeofence(geofenceID) {
            if (geofenceID) {
                this.transitionTo('management.geofences.edit', geofenceID);
            }
        },
        deleteGeofence(geofenceID) {
            let geofence = this.store.peekRecord('geofence', geofenceID);
            if (geofence) {
                Ember.$(".deleteGeofence span#geofenceName").html(geofence.get("name"));

                Ember.$('.modal.deleteGeofence')
                    .modal({
                        onApprove: function () {
                            geofence.destroyRecord()
                                .then(() => {

                                })
                                .catch(function (err) {
                                    Ember.Logger.error(err);
                                });
                        },
                    })
                    .modal('show');
            }
        },
    }
});
