import Ember from 'ember';

export default Ember.Controller.extend({

    search(value, columns) {
        // Declare variables 
        var filter, table, tr;
        filter = value.toUpperCase();
        table = Ember.$('table#managementTable');
        tr = table.find('tr');

        if (!columns || !columns.length) { columns = [0, 1]; }

        if (!value) {
            tr.removeClass('hidden');
            return false;
        }

        // Loop through all table rows, and hide those who don't match the search query
        tr.each(function (idx, item) {
            let
                tds = Ember.$(item).find('td');

            if (tds.length) {
                let atLeastOneConditionIsTrue = false;
                columns.forEach(function (item) {
                    if (Ember.$(tds[item]).text().toUpperCase().indexOf(filter) > -1) {
                        atLeastOneConditionIsTrue = true;
                    }
                });

                if (atLeastOneConditionIsTrue) {
                    Ember.$(item).removeClass('hidden');
                } else {
                    Ember.$(item).addClass('hidden');
                }
            }
        });
    },

    actions: {
        typeChanged() {
            if (Ember.$('#management-type').dropdown('get value') === '') {
                Ember.$('#management-type').dropdown('clear');
                this.search(Ember.$('#management-type').dropdown('get value'), [4]);
            }
            else {
                this.search(Ember.$('#management-type').dropdown('get text'), [4]);
            }
        },

        divisionChanged() {
            if (Ember.$('#management-division').dropdown('get value') === '') {
                Ember.$('#management-division').dropdown('clear');
                this.search(Ember.$('#management-division').dropdown('get value'), [3]);
            }
            else {
                this.search(Ember.$('#management-division').dropdown('get text'), [3]);
            }
        },

        /**
         * @param   String 
         * @param   Array column indexes we want to search for
         */
        searchOnTable(value, columns) {
            this.search(value, columns);
        }
    }
});
