import Ember from 'ember';
import RSVP from 'rsvp';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

var menu = [
    Ember.Object.create({
        name: 'vehicles',
        route: 'management.vehicles',
        translationKey: 'vehicles',
    }),
    Ember.Object.create({
        name: 'users',
        route: 'management.users',
        translationKey: 'users',
    }),
    Ember.Object.create({
        name: 'groups',
        route: 'management.groups',
        translationKey: 'divisions',
    }),
    Ember.Object.create({
        name: 'poi',
        route: 'management.geofences',
        translationKey: 'poi',
    }),
    // Ember.Object.create({
    //     name: 'timetables',
    //     route: 'management.timetables',
    //     translationKey: 'timetables',
    // }),
    Ember.Object.create({
        name: 'account',
        route: 'management.account',
        translationKey: 'account',
    }),
    Ember.Object.create({
        name: 'settings',
        route: 'management.settings',
        translationKey: 'settings._',
    }),
    Ember.Object.create({
        name: 'implements',
        route: 'management.implements',
        translationKey: 'implements',
    }),
];

export default Ember.Route.extend(AuthenticatedRouteMixin, {
    model() {
        return RSVP.hash({
            menu: menu,
            groups: this.get('store').findAll('group'),
            vehicles: this.get('store').findAll('vehicle'),
            geofences: this.get('store').findAll('geofence'),
            users: this.get('store').findAll('user'),
            devices: this.get('store').findAll('device'),
            timetables: this.get('store').findAll('timetable'),
            implements: this.get('store').findAll('implement'),
        });
    }
});
