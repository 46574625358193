import Ember from 'ember'

//romania latlng and bounds
let romLatLng = {
    lat: 45.9432,
    lng: 24.9668
}
let romZoom = 230000
const { service } = Ember.inject
const allowedRadiusValues = [50, 100, 300, 500, 1000]

export default Ember.Component.extend({
    errors: service(),
    i18n: service(),
    mapUtil: service('map/util'),
    appSettings: service('application-settings'),
    session: service("session"),
    bounds: null,

    search: "",
    selectedVehicle: "",
    settedVehDD: false,
    showGeofences: false,

    vechicleCoordonates: null,

    obsCoorChanges: false,
    coordinates: null,  // lat lng (leaflet)
    operationIncomplete: false,

    zoom: Ember.computed("geofence.shape", function () {
        return (this.get("geofence.shape") && this.get("geofence.shape.type") === "Point") ? 14 : 7
    }),

    draggable: Ember.computed('readOnly', function () {
        return (this.get('readonly') ? false : true)
    }),

    radius: Ember.computed("geofence.boundary", function () {
        let radio = parseInt(this.get("geofence.boundary"))
        return radio
    }),

    dangerZone: Ember.computed('coordinates.@each.lat', 'coordinates.@each.lng', function () {
        return this.get('coordinates').map(r => ({ lat: r.lat, lng: r.lng }))
    }),

    setCoordinates() { // lng lat (geoJSON)
        if (this.get("geofence.shape.type") === "Point" && this.get("obsCoorChanges")) {
            if (this.get("geofence.shape")) {
                this.set("geofence.shape.coordinates.0", this.get("coordinates.lng"))
                this.set("geofence.shape.coordinates.1", this.get("coordinates.lat"))
            }
        }
        else if (this.get("geofence.shape.type") === "Polygon" && this.get("obsCoorChanges")) {
            if (this.get("geofence.shape")) {
                let arrLatLng = []
                this.get("coordinates").forEach(function (item) {
                    let coordinates = [item.get("lng"), item.get("lat")]
                    arrLatLng.push(coordinates)
                })
                this.set("geofence.shape.coordinates", [arrLatLng])
            }
        }
    },

    setBounds(coordinates, zoom) {
        this.set('bounds', this.get('mapUtil').calculateBoundsZoom(coordinates, zoom))
    },

    init() {
        this._super(...arguments)
        Ember.$('.ui.dimmer.modals').remove()
        if (this.get("geofence.shape.type") === "Point") {
            let latlng = Ember.Object.create({ lat: this.get("geofence.shape.coordinates.1"), lng: this.get("geofence.shape.coordinates.0") })
            this.set("coordinates", latlng)
        }
        else if (this.get("geofence.shape.type") === "Polygon") {
            let inversedCoordinates = Ember.A([])
            this.get("geofence.shape.coordinates")[0].forEach(function (item) {
                let latlng = Ember.Object.create({
                    lat: item[1],
                    lng: item[0]
                })
                inversedCoordinates.pushObject(latlng)
            })
            inversedCoordinates.popObject()
            this.set("coordinates", inversedCoordinates)
        }
        //check if poi radius exists in our dropdown. If not = set to first allowed radius value
        if (this.get("geofence") && !allowedRadiusValues.includes(parseInt(this.get("geofence.boundary")))) {
            this.set("geofence.boundary", allowedRadiusValues[0])
        }
        // set settings if they not exists, after refactoring
        if (this.get("geofence") && !this.get("geofence.settings")) {
            let settings = {
                event_type: {
                    enter: "notification",
                    exit: "notification",
                    stationary: "notification"
                }
            }
            this.set("geofence.settings", settings)
        }

        this.set("obsCoorChanges", true)

        if (this.get('edit') || !this.get('readonly')) {
            this.set('operationIncomplete', true)
        }

        if (this.get("geofence") && !this.get("geofence.isNew")) {
            if (this.get("geofence.shape.type") === "Point") {
                this.setBounds(this.get('coordinates'), this.get("radius"))
            }
            else if (this.get("geofence.shape.type") === "Polygon") {
                this.setBounds(this.get('coordinates'))
            }
        }
        else {
            //if no vehicles, Rom map will be centered on map
            this.setBounds(romLatLng, romZoom)

            // this.filterVehiclesByLicensePlate(this.get("vehicles"), "")
        }
    },

    isPolygon() {
        let geofence = this.get('geofence')
        return geofence.get("shape.type").toLowerCase() === "polygon"
    },

    closePolygon() {
        if (!this.isPolygon() || this.isPolygonClosed()) { return false }
        let geofence = this.get('geofence')
        geofence.get('shape.coordinates')[0].get('firstObject')
        geofence.get("shape.coordinates")[0].push(geofence.get("shape.coordinates")[0][0])
    },

    isPolygonClosed() {
        let first = this.get('geofence.shape.coordinates')[0].get('firstObject')
        let last = this.get('geofence.shape.coordinates')[0].get('lastObject')

        if (first[0] === last[0] &&
            first[1] === last[1]) {
            return true
        }

        return false
    },

    willDestroyElement() {
        this._super(...arguments)
        if (this.get('operationIncomplete')) {
            let geofence = this.get('geofence')
            if (this.get('edit')) {
                geofence.rollbackAttributes()
                this.closePolygon()
            } else if (this.get('geofence').get('isNew')) {
                geofence.rollbackAttributes()
                if (geofence.get('shape.type') === 'Polygon') {
                    geofence.deleteRecord()
                }
            }
        }
        this.get('errors').clear()
    },

    /**
     * Validates fields on save and populate errors array if errors found.
     * @param {geofence} geofence
     * @return {boolean} true if no errors false otherwise
     */
    validate(geofence) {
        this.get('errors').clear()

        if (!geofence.get('name')) {
            this.get('errors').add({ t: 'errors.messages.emptyName' })
        }

        let regexAlertStationaryTime = new RegExp("^([5-9]|[1-9][0-9]|1[0-1][0-9]|120)$")
        let alertStationaryTimeValue = geofence.get("settings.event_trigger.stationary")
        if (!regexAlertStationaryTime.test(alertStationaryTimeValue)) {
            this.get('errors').add({ t: 'errors.messages.invalidAlertStationaryTimeRange' })
        }
        if (alertStationaryTimeValue % 5 !== 0) {
            this.get('errors').add({ t: 'errors.messages.invalidAlertStationaryTimeMultiple' })
        }

        if (!geofence.get('shape.coordinates')) {
            this.get('errors').add({ t: 'errors.messages.missingCoordinates' })
        }

        if (geofence.get("shape.type") === "Polygon" && geofence.get('shape.coordinates.0').length < 3) {
            this.get('errors').add({ t: 'errors.messages.insufficientPolygonPoints' })
        }

        return !this.get('errors').length()
    },

    clearAttachedVechicles() {
        this.set('geofence.vehicles', [])
    },

    getSelectedVehicles() {
        let $selectedVehicles = this.$('.aclDropDown').find('[data-type="vehicle"].active')
        return $selectedVehicles
    },

	/**
	 * Check to see if given vehicle is in the list of vehicles passed to component and used in vehicles dropdown.
	 * Normally this should never return undefined as the selected vehicle can be only from the list we pass to component, but just to be sure.
	 * @param {*} vehicle
	 * @param {array of vehicles} vehiclesList
	 * @return {vehicle} if found undefined otherwise
	 */
    isInVehicleList($vehicle, vehicleList) {
        return vehicleList.findBy('id', $vehicle.attr('id'))
    },

    attachSelectedVehilces() {
        let vehicleList = this.get('vehicles')
        this.getSelectedVehicles().toArray().forEach((vehicle) => {
            let $vehicle = Ember.$(vehicle)
            let foundVehicle = this.isInVehicleList($vehicle, vehicleList)
            if (foundVehicle) {
                this.get('geofence.vehicles').pushObject(foundVehicle)
            }
        })
    },

    ddVehicles: Ember.computed("search", function () {
        return this.selectDDVehicles()
    }),

    filterVehiclesByLicensePlate(vehicles, string) {
        if (!vehicles) { return false }
        let filter = string.toLowerCase()
        let filtredVeh = []
        let selVehCoors = []
        let _this = this

        vehicles.forEach(function (item) {
            if (item.get("licensePlate").toLowerCase().indexOf(filter) > -1 && item.get("status.location")) {
                let latlng = Ember.Object.create({
                    lat: item.get("status.location")[1],
                    lng: item.get("status.location")[0]
                })
                filtredVeh.push(item)
                selVehCoors.pushObject(latlng)
            }
        })

        Ember.run.debounce(this, function () {
            if (_this.get('geofenceCoords')) {
                _this.actions.createMarker.call(this, { latlng: _this.get('geofenceCoords') })
            } else if (selVehCoors.length > 0) {
                this.setBounds(selVehCoors)
            }
            else {
                this.setBounds(romLatLng, romZoom)
            }
        }, 150)

        return filtredVeh
    },

    selectDDVehicles() {
        let search = this.get("search") ? this.get("search").toLowerCase() : ""
        let selectedVehicle = this.get("selectedVehicle") ? this.get("selectedVehicle").toLowerCase() : ""
        let settedVehDD = this.get("settedVehDD")
        let vehicles = this.get("vehicles")

        if (search === "" && settedVehDD) {     // to solve the behavior that restarts search field value
            search = selectedVehicle
        }

        return this.filterVehiclesByLicensePlate(vehicles, search)
    },

    actions: {
        selectGeofenceRadio() {
            if (this.get("geofence.shape")) {
                this.setBounds(this.get('coordinates'), this.get("radius"))
            }
        },
        selectVehicleDropdown(selectedVehicle) {
            this.set('selectedVehicle', selectedVehicle)
            this.set("search", selectedVehicle)
            if (!selectedVehicle) {
                this.selectDDVehicles()
            }
        },
        openVehDD() {
            this.set("settedVehDD", false)
        },
        closeVehDD() {
            this.set("settedVehDD", true)
        },
        vehicleClicked() {
            // alert("Vehicle Clicked")
        },
        changeType(selected) {
            this.set("obsCoorChanges", false)
            this.set("geofence.type", selected)
            this.set("geofence.shape", null)
            this.set("coordinates", null)
            this.set("obsCoorChanges", true)
        },
        updatePOI(e) {
            let coordinates = Ember.Object.create(e.target._latlng)
            this.set("coordinates", coordinates)
            this.setBounds(coordinates, this.get("radius"))
        },
        updateGeofence(r, e) {
            r.set("lat", e.target._latlng.lat)
            r.set("lng", e.target._latlng.lng)
            this.setCoordinates()
        },
        createMarker(e) {
            if (
                (!this.get('geofence.isNew'))
                ||
                Ember.$(e.originalEvent.target).parents('.notTriggerClick').length
                ||
                Ember.$(e.originalEvent.srcElement).hasClass("google-map-type")
                ||
                Ember.$(e.originalEvent.srcElement).hasClass("map-type-label")
            ) { return false }

            if (this.get("geofence.type") === "poi") {
                let coordinates = Ember.Object.create(e.latlng)

                let shape = {
                    type: "Point",
                    coordinates: []
                }
                this.set("geofence.shape", shape)
                this.set("coordinates", coordinates)
                this.setBounds(coordinates, this.get("radius"))
            }
            if (this.get("geofence.type") === "geofence") {
                let latlng = Ember.Object.create({
                    lat: e.latlng.lat,
                    lng: e.latlng.lng
                })
                let shape = {
                    type: "Polygon",
                    coordinates: []
                }
                this.set("geofence.shape", shape)
                if (!this.get("coordinates")) {
                    this.set("coordinates", [])
                }
                this.get("coordinates").pushObject(latlng)
                this.setCoordinates()
            }
        },
        /**
         * Saves POI / GEOFENCE. In the case of polygon we need to close it by adding at the end
         * of the array a new point with the same coordinates as the first one.
         */
        save() {
            if (!this.validate(this.get('geofence'))) { return false }
            this.setCoordinates()
            this.closePolygon()
            this.clearAttachedVechicles()
            this.attachSelectedVehilces()
            this.get('save')()
                .then(() => this.get('success')())
                .catch(err => {
                    if (!err) {
                        this.get('errors').add()
                    } else {
                        this.get('errors').add({ message: err.errors[0].detail })
                    }
                })
            this.set('operationIncomplete', false)
        },
        cancel() {
            this.sendAction('cancel')
        },

        resetErrors() {
            this.get('errors').clear()
        },

        onMapViewreset(event) {
            this.set("mapInstance", event.target)
        },

        onMapLoad(event) {
            var scale = L.control.scale({ metric: true })
            scale.addTo(event.target)
            this.set("mapInstance", event.target)

            if (this.get("appSettings.mapSettings.mapClient") === "google") {
                if (this.get("googleLayer")) {
                    this.get("mapInstance").removeLayer(this.get("googleLayer"));
                }
                this.set("googleLayer", L.gridLayer.googleMutant({ opacity: 1, type: this.get("appService.mapService.mapType") }));

                this.get("mapInstance").addLayer(this.get("googleLayer"));
            }
        },

        selectedGoogleMapType(type) {
            if (this.get("googleLayer")) {
                this.get("mapInstance").removeLayer(this.get("googleLayer"));
            }
            this.set("googleLayer", L.gridLayer.googleMutant({ opacity: 1, type: type }));
            this.get("mapInstance").addLayer(this.get("googleLayer"));

            this.set("session.session.googleMapLayerType", type);
        },

        setMapClient(clientType) {
            this.set("session.session.map", clientType);
            this.set("appSettings.map", clientType);
        }
    }
})
