import DS from 'ember-data'
import MF from 'ember-data-model-fragments'

export default DS.Model.extend({
    groupID: DS.attr('string'),
    companyID: DS.attr('string'),
    type: DS.attr('string'),
    category: DS.attr('string'),
    number: DS.attr('string'),
    valid: MF.fragment('doc-valid'),
    price: MF.fragment('doc-price'),
    file: MF.fragment('doc-file'),
    document: DS.attr('file'),

    details: MF.fragment('doc-details'),
	user: DS.belongsTo('user'),
    vehicle: DS.belongsTo("vehicle"),

    downloading: false
})
