import d3V3Lines from '../d3-v3-lines/component'

export default d3V3Lines.extend({
	actions: {
		setHoverdPoint(pointData) {
			if (this.get('getHoveredPointData')) {
				this.get('getHoveredPointData')(pointData)
			}
		}
	},
	init() {
		this._super(...arguments)
	}
})
