import Ember from 'ember';

const { service } = Ember.inject

export default Ember.Controller.extend({
	i18n: service(),

	parentRoute: 'management.groups',

    actions: {
        getModel() {
            return this.get('model.group');
        },

        save() {
			var group = this.get('model.group');
			let i18n = this.get('i18n')
			group
				.save()
				.then(() => {
					this.toast.success(i18n.t('messages.operationWithSuccess'))
					this.transitionToRoute(this.get('parentRoute'))
				})
				.catch(err => {
					Ember.Logger.log(err)
					this.toast.error(i18n.t('messages.operationWithError'))
				})
        },
        cancel() {
            var group = this.get('model.group');
            group.rollbackAttributes();
            this.transitionToRoute(this.get('parentRoute'));
        }
    }
});
