import Base from './base-grouped';
import PropsInit from '../../mixins/reports/controller-props-initialization';

export default Base.extend(PropsInit, {
    isFaz: true,
    reportTitle: 'FAZ',
    sortProperties: ['date:asc'],
    groupByKey: 'vehicleID',

    queryParams: ['vehicleID', 'start', 'end'],
    vehicleID: null,
    start: null,
    end: null
});
