import Ember from 'ember'
import { jwt_decode } from 'ember-cli-jwt-decode'

export default Ember.Controller.extend({
	session: Ember.inject.service(),

	invitationExpired: Ember.computed('session.invitationToken', function() {
		let invitationToken = this.get('session.invitationToken')
		try {
			const decoded = jwt_decode(invitationToken)
			if (decoded.exp < Date.now() / 1000) {
				return true
			}
		}
		catch (error) {
			return true
		}

		return false
	})
});
