import Ember from 'ember';

export default Ember.Component.extend({
    selected: [],
    initialized: false,
    i18n: Ember.inject.service(),

    select(value) {
        let selected = this.get('selected');
        selected.push(value);
        this.get('selected').setObjects(selected);
    },

    deselect(value) {
        let index = this.get('selected').indexOf(value);
        if (index === -1) { return false; }
        let selected = this.get('selected');
        selected.splice(index, 1)
        this.set('selected', selected);
    },

    deselectAll() {
        this.getOptions().forEach((item) => {
            let value = item.dataset.value;
            this.deselect(value);
        });
    },

    getDriver(driverID) {
        if (Ember.isEmpty(driverID)) { return null; }
        return this.get('drivers').find(function (item/*, index, self*/) {
            return item.get('id').toString() === driverID.toString();
        });
    },

    getOptions() {
        return this.$('.menu .item').toArray();
    },

    setSelected(value, $selectedItem) {
        if (value === 'null') { value = null; }
        if (this.get('selected').indexOf(value) === -1) {
            this.select(value);
            $selectedItem.addClass('active')
        } else {
            this.deselect(value);
            $selectedItem.removeClass('active')
        }
        this.$('.text').text(this.get('selected').length + ' ' + this.get("i18n").t("driverss"));

        if (this.get('selected').length) {
            this.$('.text').removeClass('default');
        } else {
            this.$('.text').addClass('default');
        }
    },

    /**
     * Select all options if optionsToSelect is not specified or empty.
     * @param   {array}     optionsToSelect menu items we want to select enumerated by comma.
     */
    selecteAll(optionsToSelect) {
        let doSelectAll = Ember.isEmpty(optionsToSelect);
        let menuOptions = this.getOptions();

        menuOptions.forEach((option) => {
            if (doSelectAll) {
                this.setSelected(option.dataset.value, Ember.$(option));
            } else {
                if (optionsToSelect.indexOf(option.dataset.value) > -1) {
                    this.setSelected(option.dataset.value, Ember.$(option));
                }
            }
        });
    },

    didInsertElement() {
        var _this = this;
        this.set('selected', []);
        this.$().find('.dropdown').dropdown({
            action: 'nothing',
            onHide: function () {
                _this.get('updateDriver')(_this.get('selected'), true);
            },
            useLabels: false
        });

        this.$('.menu').on('click', '.item', function () {
            _this.setSelected(Ember.$(this).data('value'), Ember.$(this));
        });

        this.selecteAll(this.get('selectedDrivers'));
    }
});