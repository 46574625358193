import Ember from 'ember';

const { inject: { service } } = Ember;

export default Ember.Service.extend({
    session: service('session'),

    user: Ember.computed('session.session.isAuthenticated', function () {
        return this.get('session.session.content.authenticated.profile')
    }),

	getCompanyID() {
		let user = this.get('user')
		if (user && user.companyID) { return user.companyID }
		return false
	},

    isAdmin: Ember.computed('user', function () {
        if (this.get('user')) {
            return this.get('user.role') === 'admin';
        }

        return false;
    })
});
