/**
 * This component is ment to extend addepar time-series-chart component and should be used as in
 * charts/d3-v3-lines-point-hovered (see template) and it was not ment to be used alone.
 */
import Ember from 'ember'
import timeSeriesChart from '../../time-series-chart'
/* global d3 */

const { service } = Ember.inject

/**
 * Get closest point relative to mouse x position or any other point as a matter of fact.
 * x is expressed as a date object
 * @param {object} p1
 * @param {object} p2
 * @param {date} givenXPosition
 * @returns {object} Closest point to given x position
 */
function getPoint(p1, p2, givenXPosition) {
	return Math.abs(givenXPosition - p1.time.getTime()) > Math.abs(p2.time.getTime() - givenXPosition) ? p2 : p1
}

export default timeSeriesChart.extend({
	i18n: service(),
	showDetails: Ember.computed('isInteractive', function () {
		if (!this.get('isInteractive')) {
			return Ember.K
		}

		return (data, i, element) => {
			d3.select(element).classed('hovered', true)
			var time = null
			if (Ember.isArray(data.values)) {
				time = data.values.shift().time
			} else {
				time = data.labelTime != null ? data.labelTime : data.time
			}
			time = this.adjustTimeForShowDetails(time)
			var content = this.$('<span>')
			content.append(this.$("<span class=\"tip-label\">").text(this.get('formatTime')(time)))
			this.showTooltip(content.html(), d3.event)
			var formatLabelFunction = this.get('formatLabelFunction')

			var addValueLine = (d) => {
				let name = this.$('<span class="name" />').text(d.group + ': ')
				let value = this.$('<span class="value" />').text(d.customValueText ? d.customValueText : formatLabelFunction(d.value))
				content.append(name)
				content.append(value)
				content.append('<br />')
			}

			if (Ember.isArray(data.values)) {
				data.values.forEach(addValueLine)
			} else {
				addValueLine(data)
			}
			return this.showTooltip(content.html(), d3.event)
		}
	}),
	_lineMarkerData: function () {
		let i18n = this.get('i18n')
		let _this = this
		var mousePosition = this._mousePosition()
		if (Ember.isEmpty(mousePosition)) {
			return []
		}

		var invXScale = this.get('xTimeScale').invert
		let xScale = this.get('xTimeScale')
		let yScale = this.get('yScale')

		var timeAtMousePosition = invXScale(mousePosition[0])

		var markerData = []
		let closestPoint = null
		let temperatureLine = null
		let doorsStatusLine = null
		let freezerStatusLine = null
		this.get('viewport').selectAll('path.line').each(function (d) {
			if (!(this instanceof SVGPathElement)) { return false }

			if (_this.$(this).hasClass('doors-status-line')) {
				doorsStatusLine = this
				return false
			}

			if (_this.$(this).hasClass('freezer-status-line')) {
				freezerStatusLine = this
				return false
			}

			if (_this.$(this).hasClass('temperature-line')) {
				temperatureLine = this
			}

			let point = getPoint(d[0][1], d[1][1], timeAtMousePosition.getTime())

			if (!closestPoint) {
				closestPoint = point
				return false
			}

			if (Math.abs(timeAtMousePosition.getTime() - closestPoint.time.getTime()) > Math.abs(timeAtMousePosition.getTime() - point.time.getTime())) {
				point.path = this
				closestPoint = point
			}

		})
		markerData = []
		if (temperatureLine) {
			markerData.push(Object.assign({}, closestPoint, {
				x: xScale(closestPoint.time),
				y: yScale(closestPoint.value),
				group: closestPoint.label,
				value: closestPoint.value,
				time: closestPoint.time,
				path: temperatureLine
			}))
		}

		if (freezerStatusLine) {
			markerData.push(Object.assign({}, closestPoint, {
				x: xScale(closestPoint.time),
				y: yScale(-50),
				group: i18n.t('freezerStatus').string,
				customValueText: closestPoint.freezerStatus,
				value: -50,
				time: closestPoint.time,
				path: freezerStatusLine
			}))
		}

		if (doorsStatusLine) {
			markerData.push(Object.assign({}, closestPoint, {
				x: xScale(closestPoint.time),
				y: yScale(50),
				group: i18n.t('openDoorsStatus').string,
				customValueText: closestPoint.doorStatusValue,
				value: 50,
				time: closestPoint.time,
				path: doorsStatusLine
			}))
		}

		let tooltipDetails = {
			values: [
				{ time: closestPoint.time }
			]
		}

		markerData.forEach(item => {
			tooltipDetails.values.push({ group: item.group, value: item.value, customValueText: item.customValueText })
		})

		this.get('showDetails')(tooltipDetails, null, temperatureLine)

		if (this.get('externalAction')) {

			this.get('externalAction')(closestPoint)
		}

		return markerData
	},
	updateLineGraphic: function () {
		var series = this.get('series')

		var line = d3.svg.line()
			.x((d) => this.get('xTimeScale')(d[1].time))
			.y((d) => this.get('yScale')(d[1].value))
			.interpolate(this.get('interpolate') ? 'basis' : 'linear')

		return series.selectAll("path")
			.data(this.segments)
			.enter()
			.append("path").attr('class', function (d) {
				if (d[1][1].isTemperature === true) {
					return 'line temperature-line'
				}
				if (d[1][1].isDoorStatus === true) {
					return 'line doors-status-line'
				}
				if (d[1][1].isFreezerStatus === true) {
					return 'line freezer-status-line'
				}
				return 'line'
			})
			.attr("d", line)
			.style("stroke", function (d) {
				if (d[1][1].doorStatus === 1) {
					return 'b94346'
				}
				if (d[1][1].freezerStatus === 1) {
					return '62c2ee'
				}
				return 'yellow'
			})
			.style("stroke-width", 2)
	},

	segments( data ) {
		Ember.Logger.log( " [ DATA ] " , data );
		var values = data.values//.map(o => o.value)
		var i = 0, n = values.length, segments = new Array(n - 1)
		while (++i < n) { segments[i - 1] = [[i - 1, values[i - 1]], [i, values[i]]] }
		return segments
	},

	didInsertElement: function () {
		this._super()
		
		d3.select(this.$('svg')[0]).on('mousemove', () => {
			if (!this.get('isInteractive')) {
				return
			}
			// # Check if we are within the domain/range of the data
			if (this._isEventWithinValidRange()) {
				Ember.run(this, this.get('updateLineMarkers'))
			}
		})

		d3.select(this.$('svg')[0]).on('mouseleave', () => {
			this.hideTooltip()
		})
	},

	updateLineMarkers: function () {
		var lineMarkers = this._getLineMarkers()

		lineMarkers.enter()
			.append('path')
			.attr({
				class: 'line-marker',
				fill: this.get('lineColorFn'),
				d: d3.svg.symbol().size(50).type('circle')
			})

		lineMarkers.exit().remove()

		// # Update the line marker icons with the latest position data
		lineMarkers.attr({
			transform: function (d) {
				return "translate(" + d.x + "," + d.y + ")"
			}
		})

		lineMarkers.style({
			'stroke-width': function (d) {
				return d3.select(d.path).attr('stroke-width')
			}
		})
	},
})
