import Ember from 'ember'
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin'
import RSVP from 'rsvp'

const { service } = Ember.inject

export default Ember.Route.extend(AuthenticatedRouteMixin, {
	markerService: service('map/map'),
	api: service(),
	promisesUtils: service('utils/promises'),
	currentUser: service('current-user'),

	model() {
		return RSVP.hashSettled({
			vehicles: //this.get('store').findAll('vehicle'),
			this.get('store').query('vehicle', {
				$sort: "license_plate"
			}),
			users: this.get("store").findAll("user"),
			dashboard: this._loadDashboard(),
			notifications: this.get('store').findAll('notification'),
			implements: this.get('store').findAll('implement'),
		})
	},

	setupController(controller, model) {
		this._super(...arguments)
		let vehicles = this.get('promisesUtils').isRejected(model.vehicles) ? [] : model.vehicles.value
		let users = this.get('promisesUtils').isRejected(model.users) ? [] : model.users.value
		let implementss = this.get('promisesUtils').isRejected(model.implements) ? [] : model.implements.value
		let notifications = this.get('promisesUtils').isRejected(model.notifications) ? [] : model.notifications.value
		let dashboard = this.get('promisesUtils').isRejected(model.dashboard) ? [] : model.dashboard.value
		let widgetData = this._prepareWidgetData(dashboard)

		model = Object.assign({}, widgetData, {
			vehicles,
			users,
			implements: implementss,
			alerts: notifications.toArray().filterBy('isAlert', true),
			notifications: notifications.toArray().filterBy('isAlert', false)
		})
		controller.set('model', model)
	},

	_prepareWidgetData(dashboardData) {
		if (!dashboardData.length) { return {} }
		let widgetData = {}
		dashboardData.forEach(widget => {
			switch (widget.id) {
				case "areaPerHour.24h":
					widgetData.dashboard = widget.data
					break
				case "areaToday":
					widgetData.areaToday = this._calcTotal(widget.data, false)
					break
				case "areaCurrentMonth":
					widgetData.areaCurrentMonth = this._calcTotal(widget.data, false)
					break
				case "fuelConsumptionToday":
					widgetData.fuelConsumptionToday = this._calcTotal(widget.data, true)
					break
				case "fuelConsumptionCurrentMonth":
					widgetData.fuelConsumptionCurrentMonth = this._calcTotal(widget.data, true)
					break
				case "vehicleStatus.byCountry":
					widgetData.vehicleStatusCountry = widget.data
					break
				default:
					break
			}
		})

		return widgetData
	},

	_loadDashboard() {
		return this.get("api").request('/dashboard/home', {
			dataType: "json",
		})
	},

	_calcTotal(data, isFuel) {
		var total = 0

		Object.keys(data).forEach(function (vehicleID) {
			if (data[vehicleID] !== null) {
				total += parseFloat(data[vehicleID])
			}
		})

		return isFuel ? parseFloat(total).toFixed(1) : parseFloat(total / 10000).toFixed(1)
	}
})
