export default {
	management: 'Zarządzanie',
	documents: 'Dokumenty',
	moreDetails: 'Dodaj więcej',
	docStartDate: 'Od',
	docEndDate: 'Do',
	price: 'Cena',
	load: 'Załaduj',
	download: 'Pobierz',
	accountInformation: 'Informacje konta',
	document: {
		deleteTittle: "Usuń dokument",
		sureToDelete: "Dokument {{fileName}} ({{type}}) zostanie usunięty",
		notifications: {
			defaultText: 'Powiadomienia',
			day: 'Jeden dzień',
			week: 'Jeden tydzień',
			month: 'Jeden miesiąc',
		}
	},
	headerFields: {
		licensePlate: "Numer Rejestracyjny",
		driver: "Kierowca",
		car: "Pojazd",
		type: "Typ",
		division: 'Dział',
		status: 'Status',
		name: 'Nazwa',
		email: 'Email',
		phoneNumber: 'Telefon',
		role: 'Typ',
		width: 'Width', // TODO translate this
	},
	implements: { // TODO translate all
		types:{
			plow: "Plow",
			cultivator: "Cultivator",
			// TODO: translate the rest
			"disc-harrow": "Disc",
			combinator: "Combinator",
			"chisel-plow": "Scarificator",
			"seed-drill-weeding": "Semanatoare plante prasitoare",
			"seed-drill-cereal": "Semanatoare plante paioase",
			weeder: "Prasitoare",
			drill: "Freza",
			sprayer: "Masina Erbicidat",
			fertilizer: "Masina Fertilizat",
			leveler: "Lama de nivelat",
			planter: "Plantator",
			"rotating-rake": "Grebla rotativa",
			baler: "Presa de balotat",
			mower: "Masina de cosit",
			harvesters: "Seceratoare",
			harrow: "Grapa",
			roller: "Tavalug",
			trailer: "Trailer",
			vidange: "Vidange",
			chopper: "Chopper",
			"front-loader": "Incarcator frontal",

			"harvester-header-cereal": "Header plante paioase",
			"harvester-header-weeding":"Header plante prasitoare"
		},
		newTitle: 'Add implement',
		noImplement: 'No implement',
		name: 'Name',
		type: "Type",
		width: 'Width (cm)',
		sureToDelete: 'Are you sure that you want to delete this implement',
	},
	timetables: {
		withoutTimetable: 'Brak harmonogramu',
		sureToDeleteTimetable: 'Na pewno usunąć ten harmonogram?',
		setCompanyDefaultTimetable: 'Ustaw jako domyślny dla firmy',
		willBeChanged: 'Harmonogram zostanie zmieniony',
		defaultCompanyWillBeChanged: 'Firmowy harmonogram zostanie zmieniony od',
		detachDefaultCompanyTimetable: 'Firma nie bedzie miała żadnych harmonogramów',
		defaultCompanyTimetableWillBeDeleted: 'Firma nie będzie miała domyślnego harmonogramu!',
		to: 'do',
		name: 'Nazwa harmonogramu',
		isDefaultCompanyTimetable: 'Jeśli ten harmonogram zostanie usunięty firma nie będzie miała domyślnego harmonogramu',
		browse: 'Przeglądaj...',
		addCsv: 'Prześlij CSV',
		status: {
			defaultText: 'Status',
			disabled: 'Nieaktywny',
			custom: 'Zmodyfikowany',
			fullDay: 'Cały dzień',
		},
		new: {
			title: 'Dodaj Harmonogram',
			buttonText: 'ZAPISZ',
		},
		import: {
			title: "Importuj harmonogram",
			buttonText: "IMPORT",
			name: "Nazwa Harmonogramu",
			licensePlate: "Numer rejestracjyjny",

			affectedVehicles: "Pojazdy przeniesione z istniejących harmogramów",
			timetablesForRemoval: "Harmonogramy do usunięcia",

			mondayStart: "Poniedziałek Start",
			mondayStop: "Poniedziałek Stop",

			tuesdayStart: "Wtorek Start",
			tuesdayStop: "Wtorek Stop",

			wednesdayStart: "Środa Start",
			wednesdayStop: "Środa Stop",

			thursdayStart: "Czwartek Start",
			thursdayStop: "Czwartek Stop",

			fridayStart: "Piątek Start",
			fridayStop: "Piątek Stop",

			saturdayStart: "Sobota Start",
			saturdayStop: "Sobota Stop",

			sundayStart: "Niedziela Start",
			sundayStop: "Niedziela Stop"
		},
		edit: {
			title: 'Edytuj Harmonogram',
			buttonText: 'EDYTUJ',
		},
		view: {
			title: 'Szczegóły harmonogramu',
			buttonText: 'OK',
		}
	},
	vehicles: {
		// small modal
		division: 'Dział',
		driverIsAttached: 'Wybrany kierowca zostanie przypisany do innego pojazdu!',
		isAssignedToVehicle: 'jest przypisany do pojazdu',
		willBeAssigned: 'zostanie przypisany do pojazdu',
		sureToDeleteVehicle: 'Na pewno usunąć pojazd?',
		vehicleHasDeviceAssigned: 'Urządzenie {{deviceID}} jest przypisane do pojazdu',
		selectDevice: 'Wybierz urządzenie',
		relayContact: {
			blockedContact: 'Zapłon zablokowany',
			unblockedContact: 'Odblokuj zapłon',
			areYouSureToBlockThisContact: 'Na pewno zablokować zapłon?',
			areYouSureToUnblockThisContact: 'Na pewno odblokować zapłon?',
			blockContact: 'Zablokuj zapłon',
			unblockContact: 'Odblokuj zapłon',
		},
		speedLimit: {
			max: 'Domyślny próg alarmowy',
			maxTooltip: 'alerty bedą wysłane jeśli próg jest przekroczony niezależnie od limitu prędkości na drodze (np 90 km/h dla pojazdów ciężarowych)',
			min: 'Próg alarmowy',
			minTooltip: 'alerty prędkości nie będą wysyłane jeśli będą poniżej prog',
			offset: 'Dozwolony próg',
			offsetTooltip: '10 km/h alert zostanie wysłany przy\n50 + 10 km/h w terenach zabudowanych, \n90 + 10 km/h na drogach poza terenem zabudowanym, \n130 + 10 km/h na autostradach',
			errorMessage: 'Sprawdź pola zaznaczone na czerwono'
		},
		doorAlert: {
			noAlert: "No alert",
			alertOpenDoor: "Alert otwarte drzwi",
			doorOpenOutsidePOI: "Alert otwarte drzwi poza POI",
			doorOpenOutsidePOIInTimetable: "Alert otwarte drzwi poza POI w harmonogramie",
		},
		removeDevice: 'Usuń urządzenie',
		cannotChangeDeviceUntilRelayContactDisabled: 'Jeśli zapłon jest zablokowany urządzenie nie może być zmienione',
		cannotChangeRelayContactUntilDeviceIsSaved: 'Zapłon nie może zostać zablokowany dopóki urządzenie nie zostanie zapisane',
		deviceChange: 'Zmień urządzenie',
		noDevices: 'Brak dostępnych urządzeń',
		vehicleDetails: 'Szczegóły Pojazdu',
		vehicleAlerts: 'Vehicle Alerts', // TODO translate
		moreDetails: 'More Details', // TODO Translate
		autoPark: 'Auto Park',
		licensePlate: 'Numer Rejestracyjny',
		vin: 'VIN',
		make: 'Marka',
		model: 'Model',
		vehicle: 'Pojazd',
		fuel: {
			typeText: 'Typ Paliwa',
			type: {
				petrol: 'Benzyna',
				hybridPetrol: 'Hybryda-benzyna',
				diesel: 'Diesel',
				hybridDiesel: 'Hybryda-diesel',
				lpg: 'LPG',
				electric: 'Elektryczny',
			},
			capacity: 'Pojemność zbiornika paliwa',
			consumption: {
				distanceWise: 'Średnie zużycie paliwa l/100km',
				timeWise: 'Średnie zużycie paliwa l/h'
			},
		},
		category: 'Kategoria',
		type: {
			utility: 'Użytkowy',
			car: 'Samochód',
			minibus: 'Minibus',
			bus: 'Bus',
			truck: 'Ciężarowy',
			tractor: 'Traktor',
			harvester: 'Kombajn',
			construction: 'Budowlany',
			trailer: 'Przyczepa',
			'semi-trailer': 'Naczepa'
		},
		seats: 'Ilość siedzeń',
		selectDivision: 'Wybierz dział',
		selectDriver: 'Wybierz kierowcę',
		removeDriver: 'Usuń kierowcę',
		fabricationYear: 'Rok produkcji',
		registrationDate: 'Data rejestracji',
		engine: {
			power: 'Moc (HP)',
			capacity: 'Pojemność silnika'
		},
		payloadCapacity: 'Ładowność',
		tireSize: 'Rozmiar opon',
		color: 'Kolor',
		new: {
			title: 'Dodaj Pojazd',
		},
		edit: {
			title: 'Edytuj Pojazd',
		},
		view: {
			title: 'Szczegóły pojazdu',
		}
	},
	users: {
		userDetails: 'Szczegóły użytkownika',
		// small modal
		vehicleHasDriver: 'Wybrany pojazd ma juz kierowcę!',
		userHasVehicle: 'Użytkownik jest przypisany do pojazdu',
		ifDeleteDetatch: 'Jeśli usuniesz użytkownika zostanie od usunięty z tego pojazdu',
		haveDriver: 'kierowany przez',
		wichWillBeReplaced: 'zostanie zastąpiony przez',
		sureToDeleteThisUser: 'Na pewno usunąć użytkownika',
		userName: 'Nazwa użytkownika',
		firstName: 'Imię',
		iButton: 'iButton',
		lastName: 'Nazwisko',
		email: 'Email',
		photoUpload: 'Prześlij zdjęcie',
		division: 'Dział',
		phoneNumber: 'Telefon',
		drivingLicense: 'Prawo Jazdy',
		assignedVehicle: 'Przypisany pojazd',
		detachVehicle: 'Usun pojazd',
		role: 'Rola',
		driver: 'Kierowca',
		admin: 'Administrator',
		docStartDate: 'Od',
		docEndDate: 'Do',
		docPrice: 'Cena',
		load: 'Ladunek',
		new: {
			title: 'Dodaj użytkownika',
			buttonText: 'ZAPISZ',
			headerText: 'Wypełnij formularz danymi użytkownika. Dla wiecej szczegółów \'Dodaj więcej szczegółów\'.'
		},
		edit: {
			title: 'Edytuj użytkownika',
			buttonText: 'EDYTUJ',
			headerText: 'Wypełnij formularz danymi użytkownika. Dla więcej szczegółów \'Dodaj więcej szczegółów\'.'
		},
		view: {
			title: 'Dane Użytkownika',
			buttonText: 'OK',
			headerText: 'Dane wybranego użytkownika. Dla wiecej szczegółów wciśnij \'Więcej\'.'
		}
	},
	groups: {
		deleteGroup: 'Na pewno usunąć dział',
		group: 'Dział',
		addGroup: 'Dodaj dział',
		timetable: 'Harmonogram',
		editGroup: 'Edytuj Dział',
		groupName: 'Nazwa działu',
		addSubGroup: 'Dodaj poddział',
		editSubGroup: 'Edytuj poddział',
		deleteSubGroup: 'Usuń poddział',
		share: "Partnerzy",
		add: "Dodaj"
	},
	geofences: {
		name: 'Nazwa POI',
		radio: 'Promień',
		sureToDeleteGeofence: 'Na pewno usunąć POI',
		alertStationaryTimeHoverText: 'Minuty postoju (5-60)',
		type: {
			geofence: 'Geofence',
			poi: 'POI'
		},
		shape: {
			type: {
				Polygon: 'Geofence',
				Point: 'POI'
			}
		},
		new: {
			title: 'dodaj POI',
			buttonText: 'ZAPISZ',
		},
		import: {
			title: 'Importuj POI',
			buttonText: 'IMPORTUJ POI',
			importedGeofencesNumber: '{{value}} POI zostały zaimportowane',
			notImportedGeofencesNumber: '{{value}} POI nie zostały zaimportowane',
			allGeofencesImported: 'Wszystkie ({{value}}) POI zostały importowane',
			pleaseWait: 'Proszę czekać',
			processingGeofences: 'Przetwarzanie danych z pliku',
		},
		edit: {
			title: 'EDYTUJ POI',
			buttonText: 'EDYTUJ',
		},
		info: {
			title: 'Dane POI',
			buttonText: 'OK',
		}
	},
	settings: {
		speedLimit: "Limit Prędkości",
		emails: {
			'poi-enter': 'Wjazd do POI',
			'poi-exit': 'Wyjazd z POI',
			'poi-stationary': 'Postój w POI',
			'geofence-enter': 'Wjazd do obszaru',
			'geofence-exit': 'Wyjazd z obszaru',
			'geofence-stationary': 'Postój w obszarze',
			'document-expiry': 'Data ważności dokumentu',
			'speeding': 'Przekroczenie prędkości',
			'door-open-outside-poi': 'Drzwi otwarte poza POI',
			insertEmailsSeparatedByComma: 'Podaj adresy email oddzielone przecinkiem',
			notifications: 'Powiadomienia',
			email: 'Email',
		}
	}
}
