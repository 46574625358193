import Ember from 'ember'

export default Ember.Service.extend({
    eventInterval: 10000,
    schedulePollEvent(event, interval) {
        var eventInterval = interval || this.get('eventInterval')
        return Ember.run.later(() => {
            event.apply(this)
            this.set('timer', this.schedulePollEvent(event))
        }, eventInterval)
    },
    start(interval) {
        this.set('timer', this.schedulePollEvent(this.get('onPollEvent'), interval))
    },
    stop() {
        Ember.run.cancel(this.get('timer'))
        this.set('timer', null)
    },
    onPollEvent() {
        //make request
        Ember.Logger.log("poll event service")
    }
})
