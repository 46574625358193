import Ember from 'ember'
import Base from './base'

const { service } = Ember.inject

export default Base.extend({
	i18n: service(),

	model() {
		return Ember.RSVP.hash({
			tHeaders: [
				{ key: 'date', value: this.get('i18n').t('reports.headerFields.date'), align: 'left' },
				{ key: 'distanceCompose', value: this.get('i18n').t('reports.headerFields.distanceCompose'), align: 'left' },
				{ key: 'duration', value: this.get('i18n').t('reports.headerFields.duration'), align: 'left' },
				{ key: 'temperature1', value: this.get('i18n').t('reports.headerFields.temperature1'), align: 'left' },
				{ key: 'temperature2', value: this.get('i18n').t('reports.headerFields.temperature2'), align: 'left' },
				{ key: 'temperature3', value: this.get('i18n').t('reports.headerFields.temperature3'), align: 'left' },
				{ key: 'temperature4', value: this.get('i18n').t('reports.headerFields.temperature4'), align: 'left' },
			],
			tData: []
		})
	}
})
