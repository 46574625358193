import Ember from 'ember'

var reports = [
    Ember.Object.create({
        active: true,
        name: 'faz',
        route: 'reports.faz',
        title: 'FAZ',
        translationKey: 'reports.menu.faz',
        type: 1,
        checkForCanData: true
    }),
    Ember.Object.create({
        active: true,
        checkForCanData: true,
        name: 'route',
        options: [
            {
                name: 'timetable',
                type: 'radio',
                items: [
                    { translationKey: 'reports.settings.specific.route.schedule', text: '', value: 'schedule' },
                    { translationKey: 'reports.settings.specific.route.noschedule', text: '', value: 'noschedule', selected: true }
                ]
            },
            {
                name: 'stationaryDuration',
                type: 'dropdown',
                items: [
                    { text: '1 min', value: 1 },
                    { text: '5 min', value: 5 },
                    { text: '10 min', value: 10 },
                    { text: '20 min', value: 20 },
                    { text: '30 min', value: 30 },
                ],
                translationKey: 'reports.settings.specific.route.stationaryDuration',
            }

        ],
        route: 'reports.route',
        title: 'ROUTE',
        translationKey: 'reports.menu.route',
        type: 2,
    }),
    Ember.Object.create({
        active: true,
        checkForCanData: false,
        multipleViews: true,
        name: 'stationary',
        route: 'reports.stationary',
        title: 'STATIONARY',
        translationKey: 'reports.menu.stationary',
        type: 3,
        options: [
            {
                name: 'duration',
                type: 'dropdown',
                items: [
                    { text: '1 min', value: 1 },
                    { text: '5 min', value: 5 },
                    { text: '10 min', value: 10 },
                    { text: '20 min', value: 20 },
                    { text: '30 min', value: 30 },
                ],
                translationKey: 'reports.settings.specific.route.duration',
            }
        ]
    }),
    Ember.Object.create({
        active: true,
        checkForCanData: true,
        allowImport: true,
        graph: true,
        name: 'fuel-level',
        route: 'reports.fuel-level',
        title: 'FUEL-LEVEL',
        translationKey: 'reports.menu.fuel-level',
    }),

    Ember.Object.create({
        active: true,
        name: 'poi',
        route: 'reports.poi',
        title: 'POI',
        translationKey: 'reports.menu.poi',
        type: 6,
    }),

    Ember.Object.create({
        active: false,
        name: 'notifications',
        route: 'reports.notifications',
        title: 'NOTIFICATIONS',
        translationKey: 'reports.menu.notifications',
        type: 7,
    }),

    Ember.Object.create({
        active: false,
        name: 'alerts',
        route: 'reports.alerts',
        title: 'ALERTS',
        translationKey: 'reports.menu.alerts',
        type: 8,
    })
]

const { service } = Ember.inject

export default Ember.Service.extend({
    i18n: service(),
    currentUser: service('current-user'),

    dateFields: ['date'],
    timestampFields: ['startTime', 'endTime', 'createdAt', 'updatedAt'],
    timeDurationFields: ['duration', 'time', 'temperature1', 'temperature2', 'temperature3', 'temperature4', 'poiStationaryDuration', 'poiStationaryDoorsOpenedDuration', 'freezerOnDuration'],
    floatFields: ['area', 'areaLoad50', 'areaPerHour', 'fuelConsumptionPerHour', 'consumptionCompose', 'canFuelLevelEndOfDay', 'distanceCompose', 'fuelConsumptionDistance', 'canFuelConsumptionDistance', 'fuelConsumption', 'distance', 'canDistance', 'averageSpeed', 'maxSpeed'],
    redundantFields: ['fuelConsumption', 'canFuelConsumption', 'distance', 'canDistance'],
    composedFields: ['consumptionCompose', 'distanceCompose'],
    numericWithoutTotal: ['canFuelConsumptionDistance', 'fuelConsumptionDistance', 'canFuelLevelEndOfDay'],
    numericFields: ['poiCount'],

    isComposeField(field) {
        return this.get('composedFields').indexOf(field) > -1
    },

    hasTotal(field) {
        let totalFields = this
            .get('timeDurationFields')
            .concat(this.get('floatFields'))
            .concat(this.get('numericFields'))
            .filter(item => this.get('numericWithoutTotal').indexOf(item) === -1)

        return totalFields.indexOf(field) > -1
    },

    findIndex(fields, fieldToSearch) {
        var i = 0
        while (i < fields.length) {
            if (fields[i].key === fieldToSearch) {
                return i
            }
            i++
        }
        return -1
    },

    hasLinkToMap(reportTitle) {
        if (['faz', 'route'].indexOf(reportTitle) > -1) { return true }
        return false
    },

    getReportFileNameByTitle(title) {
        var reportData = this.getReportDataByTitle(title)
        return reportData.get('filename')
    },
    getReportDataByTitle(title) {
        if (!title) { return null }
        return reports.findBy('title', title)
    },
    getReportBy(key, val) {
        return reports.findBy(key, val)
    },
    getMenuLinks() {
        let companyID = this.get('currentUser').getCompanyID()
        return reports.filterBy('active')
    }
})
