import Ember from 'ember'
import moment from 'moment'

export default Ember.Service.extend({
    // comaAfterFuelConsuption: false,
    // comaAfterDistance: false,
    i18n: Ember.inject.service(),

    getDriverName(driverID, drivers) {
        if (!driverID || !drivers) { return null }

        return drivers.find(function (item) {
            return item.get('id').toString() === driverID.toString()
        })
    },

    getDrivers(driversIDs, drivers) {
        var
            _this = this,
            i18n = this.get("i18n"),
            driver,
            lblDriver, driversNamesArray = []

        driversIDs.forEach(function (driverID) {
            if (driverID) {
                driver = _this.getDriverName(driverID, drivers)
                if (driver && driver.get('fullName')) {
                    driversNamesArray.push(' ' + driver.get('fullName'))
                }
            }
        })

        // Label Driver Content
        if (driversNamesArray.length === 1) {
            lblDriver = i18n.t('driver')
            return lblDriver + ':<b>' + driversNamesArray + '</b>'
        } else if (driversNamesArray.length < 1) {
            lblDriver = ""
            return lblDriver
        } else if (driversNamesArray.length > 5) {
            lblDriver = '<b>' + driversNamesArray.length + '</b> ' + i18n.t('selectedDrivers')
            return lblDriver
        } else {
            lblDriver = i18n.t('drivers')
            return lblDriver + ':<b>' + driversNamesArray + '</b>'
        }
    },

    isValid(value) {
        return value !== null && value !== undefined
    },

    calculateTotals(records) {
        var
            totals = {
                area: 0,
                areaLoad50: 0,
                areaPerHour: 0,
                averageSpeed: 0,
                maxSpeed: 0,
                canDistance: 0,
                sensorFuelConsumption: 0,
                canFuelConsumption: 0,
                fuelConsumption: 0,
                consumptionCompose: 0,
                distance: 0,
                distanceCompose: 0,
                duration: 0,
                temperature1: 0,
                temperature2: 0,
                temperature3: 0,
                temperature4: 0,
                poiCount: 0,
                poiStationaryDuration: 0,
                poiStationaryDoorsOpenedDuration: 0,
                freezerOnDuration: 0
            }

        records.forEach(item => {
			/**
			 * This is so wrong.
			 * I just wanted to loop again throug records, but is wrong.
			 */
            totals.hasProbe = item.get('hasProbe')
            totals.hasCan = item.get('hasCan')

            if (this.isValid(item.get('sensorFuelConsumption'))) {
                totals.sensorFuelConsumption = totals.sensorFuelConsumption + Number(item.get('sensorFuelConsumption'))
            }
            if (this.isValid(item.get('canFuelConsumption'))) {
                totals.canFuelConsumption = totals.canFuelConsumption + Number(item.get('canFuelConsumption'))
            }
            if (this.isValid(item.get('fuelConsumption'))) {
                totals.fuelConsumption = totals.fuelConsumption + Number(item.get('fuelConsumption'))
            }
            if (this.isValid(item.get('consumptionCompose'))) {
                totals.consumptionCompose = totals.consumptionCompose + Number(item.get('consumptionCompose'))
            }
            if (this.isValid(item.get('area'))) {
                totals.area = totals.area + Number(item.get('area'))
            }
            if (this.isValid(item.get('areaLoad50'))) {
                totals.areaLoad50 = totals.areaLoad50 + Number(item.get('areaLoad50'))
            }

            if (this.isValid(item.get('averageSpeed'))) {
                totals.averageSpeed = totals.averageSpeed + Number(item.get('averageSpeed'))
            }
            if (this.isValid(item.get('maxSpeed'))) {
                totals.maxSpeed = Math.max(item.get('maxSpeed'), totals.maxSpeed)
            }
            if (this.isValid(item.get('canDistance'))) {
                totals.canDistance = totals.canDistance + Number(item.get('canDistance'))
            }
            if (this.isValid(item.get('distance'))) {
                totals.distance = totals.distance + Number(item.get('distance'))
            }
            if (this.isValid(item.get('duration'))) {
                totals.duration = totals.duration + Number(item.get('duration'))
            }

            if (this.isValid(item.get('distanceCompose'))) {
                totals.distanceCompose = totals.distanceCompose + Number(item.get('distanceCompose'))
            }
            if (this.isValid(item.get('temperature1'))) {
                totals.temperature1 = totals.temperature1 + Number(item.get('temperature1'))
            }
            if (this.isValid(item.get('temperature2'))) {
                totals.temperature2 = totals.temperature2 + Number(item.get('temperature2'))
            }
            if (this.isValid(item.get('temperature3'))) {
                totals.temperature3 = totals.temperature3 + Number(item.get('temperature3'))
            }
            if (this.isValid(item.get('temperature4'))) {
                totals.temperature4 = totals.temperature4 + Number(item.get('temperature4'))
            }

            if (this.isValid(item.get('poiCount'))) {
                totals.poiCount = totals.poiCount + Number(item.get('poiCount'))
            }
            if (this.isValid(item.get('poiStationaryDuration'))) {
                totals.poiStationaryDuration = totals.poiStationaryDuration + Number(item.get('poiStationaryDuration'))
            }
            if (this.isValid(item.get('poiStationaryDoorsOpenedDuration'))) {
                totals.poiStationaryDoorsOpenedDuration = totals.poiStationaryDoorsOpenedDuration + Number(item.get('poiStationaryDoorsOpenedDuration'))
            }
            if (this.isValid(item.get('freezerOnDuration'))) {
                totals.freezerOnDuration = totals.freezerOnDuration + Number(item.get('freezerOnDuration'))
            }
            //console.log('key', key, 'totals: ', totals.hoursFunc)
        })

        if (totals.fuelConsumption) {
            totals.fuelConsumptionRaw = totals.fuelConsumption
        }

        if (totals.averageSpeed) {
            totals.averageSpeedRaw = totals.averageSpeed
            totals.averageSpeed = Number(totals.averageSpeed / records.length).toFixed(2)
        }
        else {
            totals.averageSpeed = 0
        }

        if (totals.area && totals.duration) {
            let totalHA = totals.area / 10000 // to HA
            let totalDuration = totals.duration / 3600 // to hours
            let areaPerHour = totalHA / totalDuration
            totals.areaPerHour = areaPerHour
        }
        else {
            totals.areaPerHour = 0
        }

        if (totals.maxSpeed) {
            totals.maxSpeed = totals.maxSpeed.toFixed(2)
        }
        else {
            totals.maxSpeed = 0
        }

        if (totals.consumptionCompose) {
            totals.consumptionCompose = totals.consumptionCompose.toFixed(2)
        }

        if (totals.canFuelConsumption) {
            totals.canFuelConsumption = totals.canFuelConsumption.toFixed(2)
        }

        if (totals.canDistance) {
            totals.canDistance = totals.canDistance.toFixed(2)
        }

        if (totals.distanceCompose) {
            totals.distanceCompose = totals.distanceCompose.toFixed(2)
        }

        if (totals.distance) {
            totals.distance = totals.distance.toFixed(2)
        }
        totals.durationRaw = totals.duration
        totals.duration = moment("1900-01-01 00:00:00").add(totals.duration, 'seconds').format("DD:HH:mm:ss")
        totals.temperature1Raw = totals.temperature1
        totals.temperature1 = moment("1900-01-01 00:00:00").add(totals.temperature1, 'seconds').format("DD:HH:mm:ss")
        totals.temperature2Raw = totals.temperature2
        totals.temperature2 = moment("1900-01-01 00:00:00").add(totals.temperature2, 'seconds').format("DD:HH:mm:ss")
        totals.temperature3Raw = totals.temperature3
        totals.temperature3 = moment("1900-01-01 00:00:00").add(totals.temperature3, 'seconds').format("DD:HH:mm:ss")
        totals.temperature4Raw = totals.temperature4
        totals.temperature4 = moment("1900-01-01 00:00:00").add(totals.temperature4, 'seconds').format("DD:HH:mm:ss")
        if (totals.poiCount) {
            totals.poiCount = totals.poiCount
        }
        totals.poiStationaryDurationRaw = totals.poiStationaryDuration
        totals.poiStationaryDuration = moment("1900-01-01 00:00:00").add(totals.poiStationaryDuration, 'seconds').format("DD:HH:mm:ss")
        totals.poiStationaryDoorsOpenedDurationRaw = totals.poiStationaryDoorsOpenedDuration
        totals.poiStationaryDoorsOpenedDuration = moment("1900-01-01 00:00:00").add(totals.poiStationaryDoorsOpenedDuration, 'seconds').format("DD:HH:mm:ss")
        totals.freezerOnDurationRaw = totals.freezerOnDuration
        totals.freezerOnDuration = moment("1900-01-01 00:00:00").add(totals.freezerOnDuration, 'seconds').format("DD:HH:mm:ss")

        return totals
    },

    getDriversList(driversIDs, drivers, records) {
        if (!driversIDs) {
            driversIDs = []
        }

        if (!driversIDs.length) {

            records.forEach(function (item) {
                if (driversIDs.indexOf(item.get('driverID')) === -1) {
                    driversIDs.push(item.get('driverID'))
                }
            })
        }

        return this.getDrivers(driversIDs, drivers)
    }
})