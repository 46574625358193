import Ember from 'ember';
/* global validator */

export default Ember.Component.extend({
    email: null,
    invalidEmail: false,

    allowAdd: Ember.computed('maxValues', 'emailsList.[]', function () {
        let max = this.get('maxValues')
        let emailsLength = this.get('emailsList.length')
        if (!max || !emailsLength) { return true }
        return emailsLength < max
    }),

    addEmail(email) {
        let validEmail = validator.isEmail(email) && !this.get('emailsList').includes(email)
        if (validEmail) {
            this.get('emailsList').pushObject(email);
            this.get('saveGroup')();
            return true
        }
        return false
    },
    actions: {
        setEmail(email, event) {
            let hasCommas = (email.match(/,/g) || []).length
            let isEnterKey = event.keyCode == 13

            if (hasCommas !== 1 && !isEnterKey) { return false; }
            email = isEnterKey ? email : email.substr(0, email.indexOf(','));

            this.set('invalidEmail', false)
            if (this.addEmail(email)) {
                this.set('email', null)
            } else {
                this.set('invalidEmail', true)
            }
        },
        addEmailToList() {
            let email = this.get('email')

            this.set('invalidEmail', false)
            if (this.addEmail(email)) {
                this.set('email', null)
            } else {
                this.set('invalidEmail', true)
            }
        },
        removeEmail(email) {
            if (!email) { return false }
            this.get('emailsList').removeObject(email);
            this.get('saveGroup')();
        },
    }
});
