import DS from 'ember-data';
import MF from 'ember-data-model-fragments';

export default DS.Model.extend({
    boundary: DS.attr('number'),
    name: DS.attr('string'),
    type: DS.attr('string'),
    shape: MF.fragment('fragments/geofence/shape'),
    global: DS.attr('boolean'),
    color: DS.attr('string'),
    settings: MF.fragment('fragments/geofence/settings'),

    group: DS.belongsTo('group'),
	vehicles: DS.hasMany('vehicle')
});
