import Ember from 'ember';
import Resolver from './resolver';
import loadInitializers from 'ember-load-initializers';
import config from './config/environment';

let App;

// Ember.MODEL_FACTORY_INJECTIONS = true;

Ember.$.fn.form.settings.rules.gte = function (value, min) {
	if (Ember.isNone(value)) { return true }
	let minValue = Number(min)
	let val = Number(value)
	return (val >= minValue)
}

Ember.$.fn.form.settings.rules.lte = function (value, max) {
	if (Ember.isNone(value)) { return true }
	let maxValue = Number(max)
	let val = Number(value)
	return (val <= maxValue)
}

Ember.$.fn.form.settings.rules.isMultipleOf = function (value, modulus) {
	if (Ember.isNone(value)) { return true }
	let modulusVal = Number(modulus)
	let val = Number(value)
	return (val % modulusVal === 0)
}

Ember.$.fn.form.settings.rules.isNumberWithoutComa = function (value) {
	if (Ember.isNone(value)) { return true }
	return (value.indexOf(',') === -1) && Ember.$.fn.form.settings.rules.number(value)
}

App = Ember.Application.extend({
	modulePrefix: config.modulePrefix,
	podModulePrefix: config.podModulePrefix,
	Resolver
});

loadInitializers(App, config.modulePrefix);

export default App;
