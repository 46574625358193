export default {
	"emptyName":"Name fehlt.",
	"missingCoordinates":"Koordinaten fehlen.",
	"invalidAlerStationaryTime":"Bitte eine Zahl zwischen 5 und 60 eingeben.",
	"insufficientPolygonPoints":"Unzureichende Punkte (minimum 3)",
	"errorsExists":"Es gibt Fehler.",
	"plaseTryAgainLater":"Versuchen Sie es später erneut.",
	"numberWithoutComma":"Bitte verwenden Sie \".\" als Dezimaltrennzeichen.",
	"errorsExistsCheckData":"Fehler gefunden. Bitte überprüfen Sie die Daten."
}
