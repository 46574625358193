import Ember from 'ember';

export function enabledDay(day/*, hash*/) {
  if (day[0] === 0 && day[1] === 0 && day[2] === 0 && day[3] === 0) {
    // disabled
    return "disabled";
  }
  else if (day[0] === 0 && day[1] === 0 && day[2] === 24 && day[3] === 0) {
    // disabled
    return "fullday";
  }
  else {
    // custom
    return "custom";
  }
}

export default Ember.Helper.helper(enabledDay);
