import Ember from 'ember'

const { service } = Ember.inject

export default Ember.Component.extend({
    classNames: ['actions'],

    appSettings: service('application-settings'),
    downloadReport: service('reports/download-report'),
    reportsFields: service('reports/report-fields'),
    i18n: service(),

    allowImport: Ember.computed("report", function () {
        return this.get("report.allowImport") ? true : false
    }),
    allowedFormats: ['csv', 'xlsx', 'pdf'],
    downloadDisabled: Ember.computed('report.map', 'activeChildController.empty', function () {
        return (this.get('report').get('map') || this.get('report').get('graph') || this.get('activeChildController.empty'))
    }),
    noOptions: Ember.computed.empty('report.options'),

    isOneVehicle: Ember.computed('selectedVehicles', function () {
        return this.get('selectedVehicles').length === 1
    }),

    selectedGroups: Ember.computed('selectedVehicles', function () {
        let vehicleData = []
        this.get('selectedVehicles').forEach((vehicle) => {
            let vdata = this.get('vehicles').findBy('id', vehicle)
            if (vdata) {
                vehicleData.push(vdata)
            }
        })

        let divisions = []
        vehicleData.forEach((item) => {
            if (divisions.indexOf(item.get('group').get('name')) === -1) {
                divisions.push(item.get('group').get('name'))
            }
        })

        return divisions
    }),

    isOneDivision: Ember.computed('selectedVehicles', function () {
        return this.get('selectedGroups.length') === 1
    }),

    donwloadOptions(format) {
        let _this = this
        let filenameParts = [this.get('i18n').t(this.get('report').translationKey)]

        if (this.get('isOneVehicle')) {
            filenameParts.push(this.get('vehicles').findBy('id', this.get('selectedVehicles')[0]).get('licensePlate'))
        } else if (this.get('isOneDivision')) {
            filenameParts.push(this.get('selectedGroups')[0])
        } else {
            filenameParts.push(this.get('selectedVehicles').length)
        }

        return {
            report: _this.get('report'),
            filter: _this.get('reportsFields.fields'),
            filename: filenameParts,
            extra: {
                locale: _this.get('appSettings.locale'),
                format
            }
        }
    },

    didInsertElement() {
        this.$('#download-report').dropdown()
        this.$('#report-settings').dropdown()
    },

    downloadLoading($download) {
        if (!$download || !$download.length) { return false }
        $download.addClass('loading')
        $download.attr('disabled', true)
    },

    donwloadFinish($download) {
        $download.removeClass('loading')
        $download.attr('disabled', false)
    },

    actions: {
        importFuelling() {
            // defined on reports/fuel-level.hbs EOF; calls the modals/fueling-import component
            Ember.$("#modalFuelingImport").modal('show')
        },
        download(format) {
            this.downloadLoading(this.$('#download-report'))
            this.get('downloadReport').download(this.donwloadOptions(format), () => {
                this.donwloadFinish(this.$('#download-report'))
            })
            this.$('.item.active').removeClass('active')
        },

        changeStationaryDuration(selected) {
            this.get('stationaryDurationChange')(selected)
        },

        openModal() {
            this.get('openModal')()
        },

        print() {
            this.get('print')(this.get('report').get('title'))
        },

        setOption(option, options) {
            /**
             * Check if option is selected already.
             */
            if (option.selected) { return false }

            options.items.forEach((n) => {
                Ember.set(n, 'selected', (n.value === option.value))
            })

            this.get('reportsFields').merge({ timetable: option.value === 'schedule' })
            /**
             * Reload data with the new timetable field state
             */
            let doNotReload = options.name === 'timetable' ? false : true
            this.get('activeChildController').reloadModel(doNotReload)
        }
    }
})
