import Ember from 'ember';
import moment from 'moment';

export default Ember.Service.extend({

    getLastDayInMonth(date, format) {
        var
            day;

        if (!date) { return null; }

        day = moment(new Date(date.getFullYear(), date.getMonth() + 1, 0));

        if (format) {
            return day.format(format);
        }

        return day;
    },

    getFirstDayInMonth(date, format) {
        var
            day;

        if (!date) { return null; }

        day = moment(new Date(date.getFullYear(), date.getMonth(), 1));

        if (format) {
            return day.format(format);
        }

        return day;
    },

    getCurentDate(format) {
        var
            day = moment();

        if (format) {
            return day.format(format);
        }

        return day;
    },

    momentToTimestamp(momentIstance) {
        if (!moment.isMoment(momentIstance)) { return null; }
        return momentIstance.toDate().getTime();
    },

    timestampToDate(timestamp, format) {
        var
            date;

        if (!timestamp || !Number(timestamp)) { return null; }

        date = moment(timestamp);

        if (format) {
            return date.format(format)
        }

        return date;
    },

	/**
	 * Return difference between two dates.
	 * @param {Date object or timestamp} t1
	 * @param {Date object or timestamp} t2
	 * @return {Number} difference betwee t1 and t2 absolut value
	 */
    diffTwoDates(t1, t2) {
        let diff = moment(t1).diff(t2);
        return Math.abs(diff);
    },

    asDHMS(diff) {
        let days = Math.trunc(moment.duration(diff).asDays());
        let hours = Math.trunc(moment.duration(diff).asHours());
        let minutes = Math.trunc(moment.duration(diff).asMinutes());
        // let seconds = Math.trunc(moment.duration(diff).asSeconds());

        if (days > 0) {
            return days + "z " + (hours - (24 * days)) + "h " + moment.utc(diff).format("mm[m] ss[s]");
        }
        else if (hours > 0) {
            return moment.utc(diff).format("H[h] mm[m] ss[s]");
        }
        else if (minutes > 0) {
            return moment.utc(diff).format("mm[m] ss[s]");
        }
        else {
            return moment.utc(diff).format("ss[s]");
        }
    },

    duration(t1, t2) {
        let diff = this.diffTwoDates(t1, t2);
        return this.asDHMS(diff);
	},

	areSameYear(firstDate, secondDate) {
		if (!firstDate || !secondDate) { return false }
		return firstDate.getFullYear() === secondDate.getFullYear()
	},

	areSameMonth(firstDate, secondDate) {
		if (!firstDate || !secondDate) { return false }
		return firstDate.getMonth() === secondDate.getMonth()
	},

	areSameDay(firstDate, secondDate) {
		if (!firstDate || !secondDate) { return false }
		return firstDate.getDate() === secondDate.getDate()
	}
});
