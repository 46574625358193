import Ember from 'ember';
import RSVP from 'rsvp';

export default Ember.Route.extend({
    i18n: Ember.inject.service(),
    model() {
        let implementations = this.store.peekAll('implement')
        let i18n = this.get('i18n')

        const tHeaders = [
            { key: 'name', value: i18n.t('management.headerFields.name'), align: 'left' },
            { key: 'type', value: i18n.t('management.headerFields.type'), align: 'left' },
            { key: 'width', value: i18n.t('management.headerFields.width'), align: 'left' },
        ]

        return RSVP.hash({
            tHeaders: tHeaders,
            implements: implementations,
        })
    },

    actions: {
        viewImplement(implementID) {
            if (!implementID) { return false }
            this.transitionTo('management.implements.view', implementID)
        },
        newImplement() {
            this.transitionTo('management.implements.new')
        },
        editImplement(implementID) {
            if (!implementID) { return false }
            this.transitionTo('management.implements.edit', implementID)
        },
        deleteImplement(implementID) {
            if (!implementID) { return false }
            //TO DO try remove implement directly and not store.peekRecord (first pass it as object to action)
            let implementToRemove = this.store.peekRecord('implement', implementID);
            if (implementToRemove) {
                Ember.$("span#deleteImplementName").html(implementToRemove.get("name"));
                Ember.$("span#deleteImplementType").html(implementToRemove.get("type"));
                Ember.$('.deleteImplementID')
                    .modal({
                        onApprove: () => {
                            implementToRemove.destroyRecord()
                                .then(() => {
                                    this.refresh();
                                })
                                .catch((err) => {
                                    Ember.Logger.error(err);
                                });
                        }
                    })
                    .modal('show');
            }
        },
    }
});
