import Ember from 'ember';
import config from '../../../config/environment';

/* global validator */

const { service } = Ember.inject

export default Ember.Controller.extend({
	i18n: service(),
	api: service(),
    session: service(),
	selectedType: 0,
	
	actions: {
		downloadGeofence ( format ) {
			if (format != "0") {
				let 	url		= config.api.host + '/' + config.api.namespace + '/geofences/download?format=' + format
				const 	idToken	= this.get( 'session.data.authenticated.idToken' )
	
				Ember.Logger.log("URL: ", url)
	
				let req = new XMLHttpRequest()
				req.open( 'GET', url, true )
				req.setRequestHeader( 'Authorization', `Bearer ${idToken}` )
				req.setRequestHeader( 'Content-type', 'application/json; charset=utf-8' )
				req.responseType = 'blob'
	
				req.onload = function (event) {
					let blob = req.response
					Ember.Logger.debug( 'event: ', event )
					Ember.Logger.debug( 'file size: ', blob.size )
					let link = document.createElement( 'a' )
					link.href = window.URL.createObjectURL( blob )
					link.download = "geofences." + format
					link.className = 'hidden'
					document.body.appendChild( link )
					link.click( )
				}
	
				req.send()
			}
		}
	}
});