import Ember from 'ember';

export default Ember.Controller.extend({
    currentUser: Ember.inject.service('current-user'),

    company: Ember.computed('currentUser.user.companyID', function () {
        var companyID = this.get('currentUser.user.companyID')
        var company = this.store.peekRecord('group', companyID)
        if (company) {
            return company
        }
        else {
            return null
        }
    }),
});
