import Ember from 'ember';

export default Ember.Route.extend({
    model: function() {
        return Ember.RSVP.hash({
            // groups:         this.get('store').findAll('group'),
            integrations:   this.get("store").findAll("integration"),
        });
    }
});
