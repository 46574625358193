import Ember from 'ember';
import RSVP from 'rsvp';

export default Ember.Route.extend({
    model(params) {
        var timetables = this.store.peekAll('timetable');
        var group = this.store.peekRecord('group', params.id);
        return RSVP.hash({
            group: group,
            timetables: timetables
        });
    },
});
