/**
 * 1) Iterate each route
 * 2) MOVING: one detect and save direaction markers
 * 3) Construct the polyline
 * 4) STATIONARY: save stationary markers
 * 5) MERGE same type consecutive routes
 * We observe that here are two parts: a) logic part
 * 									   b) visual part (map)
 * Being so, we can apart this two parts. As polyline is a map layer we will place a file line in map/shapes that will handle this part.
 */

import Ember from 'ember'
import moment from 'moment'
/* globals L */

/**
 * TODO: transform this serivce into shape route.
 */

const { service } = Ember.inject
const altitudeTolerance = 20
const distanceTolerance = 50
const tolerableDistanceBetweenStationaryRoutes = 100
const tolerableDistanceBetweenMovingRoutes = 500

export default Ember.Service.extend({
	dates: service(),
	markerService: service('map/shapes/marker'),
	util: service('map/util'),
	defaultBounds: {
		n: 48.517,
		s: 43.485,
		w: 20.083,
		e: 30.135
	},

	/**
	 * Set the bounds comparing current bounds with a new location coordintes.
	 * @param {*} location
	 * @param {*} currentBounds
	 * @return {object} new bounds
	 */
	setBounds(location, currentBounds) {
		let mapBounds = {}

		mapBounds.n = currentBounds.n < location.lat ? location.lat : currentBounds.n
		mapBounds.s = currentBounds.s > location.lat ? location.lat : currentBounds.s
		mapBounds.e = currentBounds.e < location.lng ? location.lng : currentBounds.e
		mapBounds.w = currentBounds.w > location.lng ? location.lng : currentBounds.w

		return mapBounds
	},

	_getCanFuelLevel(point, selectedVehicle) {
		if (!point) { return null }

		if (!point.can) { return 0 }

		if (!Ember.isNone(point.can.fuel_level) && point.can.fuel_level) {
			return Number(point.can.fuel_level)
		}

		if (!Ember.isNone(point.can.fuel_level_percent && point.can.fuel_level_percent)) {
			let fuelCapacity = Number(selectedVehicle.get('fuel.capacity'))
			return fuelCapacity * Number(point.can.fuel_level_percent)
		}

		return 0
	},

	_getLocationGeneralDetails(point, selectedVehicle) {
		if (!point) { return null }
		let fuelCanLevel = this._getCanFuelLevel(point, selectedVehicle)
		return {
			lat: point.gps.coordinates[1],
			lng: point.gps.coordinates[0],
			altitude: Ember.isEmpty(point.gps.altitude) ? 0 : point.gps.altitude,
			speed: Ember.isEmpty(point.gps.speed) ? 0 : point.gps.speed,
			bearing: point.gps.bearing,
			timestamp: point.timestamp,
			fuelCanLevel: fuelCanLevel / 100,
			fuelAverageLevel: Ember.isEmpty(point.fuel_average_level) ? null : point.fuel_average_level,
			fuelLevel: Ember.isEmpty(point.fuel_level) ? 0 : point.fuel_level,
			acceleration: Ember.isEmpty(point.can) ? 0 : Ember.isEmpty(point.can.acceleration) ? 0 : point.can.acceleration,
			rpm: Ember.isEmpty(point.can) ? 0 : Ember.isEmpty(point.can.rpm) ? 0 : point.can.rpm,
			engineLoad: Ember.isEmpty(point.can) ? 0 : Ember.isEmpty(point.can.engine_load) ? 0 : point.can.engine_load,
			engineTemperature: Ember.isEmpty(point.can) ? 0 : Ember.isEmpty(point.can.engine_temperature) ? 0 : Number((point.can.engine_temperature / 10).toFixed(1)),
			engineState: point.engine_state
		}
	},

	// Refactor this when time
	getRouteEndLocation(route, lineLocations) {
		if (Ember.isEmpty(route.get("end.location"))) {
			if (Ember.isEmpty(lineLocations[lineLocations.length - 1])) {
				// route.set("end.location", "")
				return ''
			} else {
				return lineLocations[lineLocations.length - 1].lat + ", " + lineLocations[lineLocations.length - 1].lng
			}
		} else {
			return route.get("end.location")
		}
	},

	/**
	 * Take location from first point in route if route misses start object.
	 * @param {*} route
	 * @param {*} lineLocations
	 */
	getRouteStartLocation(route, lineLocations) {
		if (Ember.isEmpty(route.get("start.location"))) {
			return lineLocations[0].lat + ", " + lineLocations[0].lng
		} else {
			return route.get("start.location")
		}
	},

    /**
     * @param {*} routes A collection of route objects described by the "route" model
     * @param {*} maxStopDuration Maximum duration for a stop before making a new route
     * @param {boolean} processUnknownRoute Handle route with id unknown or not
	 * @param {boolean}
     */
	makeRoutes(routes, maxStopDuration, processUnknownRoute, selectedVehicle, onlyContinuousData = false) {
		var _this = this
		var leafletRoutes = []
		let mapUtil = this.get('util')

		var haltedMarkers = []
		var lineLocations = []
		var continuousData = []
		// var routeGlueIndex  = 0
		var max = {
			speed: 0,
			altitude: 0
		}
		var lastAltitude = 0

		var boundsInit = routes.get("firstObject.points.simplified.firstObject.gps.coordinates")
		var mapBounds = boundsInit ? {
			n: boundsInit[1],
			s: boundsInit[1],
			e: boundsInit[0],
			w: boundsInit[0]
		} : this.get("defaultBounds")

		routes.forEach(function (route, routeIndex) {
			let isStationary = _this.get('util').isStationary(route)
			let startTime = route.get("start.time")
			let endTime = route.get("end.time")
			let duration = _this.get('dates').duration(startTime, endTime)
			let rawDuration = _this.get('dates').diffTwoDates(endTime, startTime)

			if (!route.get("points") || (route.get("points") && !route.get("points.mapMatched") && !route.get("points.simplified")) /*|| (isStationary && !leafletRoutes.length)*/) {
				return
			}

			let previousLocation = null

			route.get("points.simplified").forEach(function (point, pointIndex) {

				let locationDetails = _this._getLocationGeneralDetails(point, selectedVehicle)

				lineLocations.push(locationDetails)
				/**
				 * If altitude is equal 0 and last altitude is smaller than 20 (altitude tolerance)
				 * then let it to 0, else set it to last altitude.
				 */
				if ((locationDetails.altitude == 0) && ((Math.abs(locationDetails.altitude - lastAltitude) > altitudeTolerance))) {
					locationDetails.altitude = lastAltitude
				} else {
					lastAltitude = locationDetails.altitude
				}

				continuousData.push(locationDetails)

				max.speed = Math.max(locationDetails.speed, max.speed)
				max.altitude = Math.max(locationDetails.altitude, max.altitude)

				/** Add direction arrows on route */
				if (pointIndex > 0) {
					if (_this.get('util').distance(previousLocation.lat, previousLocation.lng, locationDetails.lat, locationDetails.lng) > distanceTolerance) {
						mapBounds = _this.setBounds(locationDetails, mapBounds)
						previousLocation = locationDetails
					}
				} else {
					previousLocation = locationDetails
				}
			})

			let startLocation = _this.getRouteStartLocation(route, lineLocations)
			let endLocation = _this.getRouteEndLocation(route, lineLocations)


			if (routeIndex === routes.get("length") - 1) {
				let location = lineLocations[lineLocations.length - 1]
				if (processUnknownRoute) {
					haltedMarkers.push(_this.get('markerService')._makeMarker("direction", {
						location: mapUtil.latLng([location.lat, location.lng]),
						angle: location.bearing,
						entityType: "route-unfinished"
					}))
				}
			}

			let polyline = {
				distance: (route.get('details.distance') / 1000).toFixed(2),
				duration,
				isSelected: false,
				rawDuration,
				stationary: isStationary,
				isUnknown: _this.get('util').isInProcess(route),
				_latlngs: lineLocations,
				options: {
					color: "#000000",
					weight: 10,
					owner: "tracknamic",
					objectType: "route",
					entityID: route.get("id"),
					start: {
						location: startLocation,
						time: startTime,
						timestamp: startTime.getTime()
					},
					end: {
						location: endLocation,
						time: endTime,
						timestamp: endTime.getTime()
					}
				}
			}

			leafletRoutes.push(polyline)
			lineLocations = []
		})
		if (onlyContinuousData) {
			return {
				continuousData
			}
		}

		leafletRoutes = _this.filterStationaryRoutesByDuration(leafletRoutes, maxStopDuration)
		leafletRoutes = _this.mergeAndGetPolylines(leafletRoutes)
		haltedMarkers = _this.getHaltedMarkers(leafletRoutes, haltedMarkers, processUnknownRoute)
		var stationaryMarkers = L.layerGroup(haltedMarkers)
		stationaryMarkers.options.owner = "tracknamic"
		// var dist = this.get('util').distance(mapBounds.s, mapBounds.w, mapBounds.n, mapBounds.e)
		var mapPadding = 0.01// parseFloat(('0.000' + Math.ceil(dist)).slice(- dist.length))

		return {
			routeLines: leafletRoutes,
			continuousData: continuousData,
			mapBounds: L.latLngBounds(mapUtil.latLng(mapBounds.s - mapPadding, mapBounds.w - mapPadding), mapUtil.latLng(mapBounds.n + mapPadding, mapBounds.e + mapPadding)),
			stationaryMarkers: stationaryMarkers,
			maxValues: max
		}
	},

	filterStationaryRoutesByDuration(leafletRoutes, maxStopDuration) {
		return leafletRoutes.filter(leafletRoute => {
			return !leafletRoute.stationary || (leafletRoute.stationary && leafletRoute.rawDuration > maxStopDuration)
		})
	},

	getHaltedMarkers(leafletRoutes, haltedMarkers, processUnknownRoute) {
		if (!leafletRoutes || !leafletRoutes.length) { return haltedMarkers }
		// if last leafletRoutes type is moving, need to create a stationaryPoint if we don't have to process unknowRoute
		let createNewStationary = !leafletRoutes.get("lastObject.stationary") && !processUnknownRoute
		let stationaryRoutes = leafletRoutes.filterBy('stationary', true)
		if (createNewStationary) { stationaryRoutes.push(leafletRoutes.get("lastObject")) }

		stationaryRoutes.forEach((route, idx) => {
			let markerType = 'route-stationary'
			let coordinates = route._latlngs[0]
			let duration = route.duration
			let endLocation = route.options.end.location
			let startLocation = route.options.start.location
			let startTime = moment(route.options.start.time)
			let endTime = moment(route.options.end.time)

			if (idx === 0) { markerType = 'route-start' }
			else if (idx === stationaryRoutes.length - 1 && !processUnknownRoute) {
				if (createNewStationary) { // this is the last moving route. we need (last coordinate, endTime, endLocation) will transform to stationary
					coordinates = route._latlngs.get("lastObject")
					startTime = endTime
					startLocation = endLocation
				}
				markerType = 'route-end'
				duration = duration = this.get('dates').duration(moment().valueOf(), endTime)
			}

			haltedMarkers.push(this.get('markerService')._makeMarker("stop", {
				location: { lat: coordinates.lat, lng: coordinates.lng },
				coordinates,
				duration,
				endLocation,
				startLocation,
				startTime,
				endTime,
				entityType: markerType
			}))
		})

		return haltedMarkers
	},

	/**
	 * Check if object has property toDelete set to true.
	 * @param {*} object
	 * @return {boolean}
	 */
	_isMarkedForDeletion(object) {
		return object && object.toDelete
	},

	_areRoutesStationaries(currentPolyline, nextPolyline) {
		return currentPolyline.stationary === true && nextPolyline.stationary === true
	},

	_areRoutesMoving(currentPolyline, polyline) {
		return currentPolyline.stationary === false && polyline.stationary === false
	},

	_markForDeletetion(object) {
		object.toDelete = true
		return object
	},

	/**
	 * Return parent merged route with new end property and new calculated duration.
	 * @param {object} parentMeredePolyline
	 * @param {string} duration as string
	 */
	_setDurationOfMergedPolylines(parentMeredePolyline) {
		let startTime = parentMeredePolyline.options.start.time
		let endTime = parentMeredePolyline.options.end.time

		/**
		 * Calculate new duration of parent merged route.
		 */
		return this.get('dates').duration(startTime, endTime)
	},

	/**
	 * Return added distances of the given polylines.
	 * @param {object} parentMeredePolyline
	 * @param {object} polyline
	 * @return {int} toatal distance
	 */
	_setDistanceOfMergedPolyliness(parentMeredePolyline, polyline) {
		return (Number(parentMeredePolyline.distance) + Number(polyline.distance)).toFixed(2)
	},

	mergeStationaries(currentPolyline, polyline) {
		if (!currentPolyline.mergedRoutes) {
			currentPolyline.mergedRoutes = []
		}

		let copied = Ember.copy(polyline)

		currentPolyline.mergedRoutes.push(copied)
		currentPolyline.options.end = polyline.options.end
		currentPolyline.duration = this._setDurationOfMergedPolylines(currentPolyline)
		return currentPolyline
	},

	mergeMoving(currentPolyline, polyline) {
		if (!currentPolyline.mergedRoutes) {
			currentPolyline.mergedRoutes = [Ember.copy(currentPolyline)]
		}

		currentPolyline.mergedRoutes.push(polyline)

		currentPolyline.options.end = polyline.options.end
		currentPolyline.duration = this._setDurationOfMergedPolylines(currentPolyline)
		currentPolyline.distance = this._setDistanceOfMergedPolyliness(currentPolyline, polyline)
		currentPolyline._latlngs = this.mergeCoordinates(currentPolyline, polyline)

		return currentPolyline
	},

	_arePolylinesDifferentType(currentPolyline, polyline) {
		return currentPolyline.stationary !== polyline.stationary
	},

	_distanceIsTolerable(currentPolyline, polyline) {
		let currentPolylineCoords = currentPolyline._latlngs[currentPolyline._latlngs.length - 1]
		let polylineCoords = polyline._latlngs[0]

		let distance = Math.abs(this.get('util').distance(currentPolylineCoords.lat, currentPolylineCoords.lng, polylineCoords.lat, polylineCoords.lng))

		if (currentPolyline.stationary) {
			return distance < tolerableDistanceBetweenStationaryRoutes
		} else {
			return distance < tolerableDistanceBetweenMovingRoutes
		}
	},

	mergeMarkers(currentPolyline, polyline) {
		return Object.assign(currentPolyline.options.markers._layers, polyline.options.markers._layers)
	},

	mergeCoordinates(currentPolyline, polyline) {
		return currentPolyline._latlngs.concat(polyline._latlngs)
	},

	/**
	 * Iterate routes starting with provided index and see if next routes are the same type (stationary / moving).
	 * If there are then merge them and mark for deletion merged routes
	 * @param {array of polylines objects} polylines
	 * @param {polyline} currentPolyline
	 * @param {int} nextPolylineIndex
	 * @return {array of polylinesc objects} array of objects with merged routes and routes marked for deletions
	 */
	mergeConsecutiveSameTypePolylines(polylines, currentPolyline, nextPolylineIndex) {
		let comparisonRoute = currentPolyline

		for (let i = nextPolylineIndex; i < polylines.length; i++) {
			if (this._arePolylinesDifferentType(comparisonRoute, polylines[i]) || !this._distanceIsTolerable(comparisonRoute, polylines[i])) {
				return polylines
			}

			if (this._areRoutesStationaries(currentPolyline, polylines[i])) {
				currentPolyline = this.mergeStationaries(currentPolyline, polylines[i])
				polylines[i] = this._markForDeletetion(polylines[i])
				comparisonRoute = polylines[i]
			} else if (this._areRoutesMoving(currentPolyline, polylines[i])) {
				currentPolyline = this.mergeMoving(currentPolyline, polylines[i])
				polylines[i] = this._markForDeletetion(polylines[i])
				comparisonRoute = polylines[i]
			}
		}
		return polylines
	},

	/**
	 * Iterates over routes and merge consecutive routes that have same type.
	 * Distance between two consectutive stationaries routes should be less then 100 m in order to be merged.
	 * Distance between two consectutive not stationaries routes should be less then 200 m in order to be merged.
	 * @param {array of polyline objects} polylines
	 * @return {array of polyline objects} routes array without merged routes
	 */
	mergeAndGetPolylines(polylines) {
		if (!polylines) { return null }

		polylines.forEach((polyline, idx) => {
			if (this._isMarkedForDeletion(polyline)) { return false }
			let nextPolylineIndex = idx + 1
			polylines = this.mergeConsecutiveSameTypePolylines(polylines, polyline, nextPolylineIndex)
		})

		return polylines.filterBy('toDelete', undefined)
	},
})
