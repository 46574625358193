import Ember from 'ember'
import moment from 'moment'
import config from '../../config/environment'

const hoveredRouteOpacity = 0.75
const hoveredRouteWeight = 12
const routeOpacity = 1
const routeWeight = 10
// const routeColor = "#606062"
const selectedRouteColor = "#FFDC36"
const selectedRouteWeight = 6
const RomaniaBounds = [[48.517, 20.083], [43.485, 30.135]]

const { service } = Ember.inject
const { computed } = Ember

export default Ember.Controller.extend({
	i18n: service(),
	resolution: service(),
	appSettings: service('application-settings'),
	mapService: service("map/map"),
	mapUtil: service('map/util'),
	markerService: service('map/shapes/marker'),
	poi: service('map/shapes/poi'),
	polygon: service('map/shapes/polygon'),
	session: service(),
	vehiclesUtils: service('utils/vehicles'),
	// showRightCharts: false,
	activeMarkerKey: null,
	currentlyLoading: false,
	config,
	mapZoom: null,
	stopLimit: 600000,
	stationaryMarkersZIndex: 10000,
	// Important property for mobile, because on mobile the map looses its bounds when hidden
	mapInFocus: false,
	selectedRouteID: null,
	selectedRouteColor,
	selectedRouteWeight,
	completeMapBounds: RomaniaBounds,	// the leaflet-map bounds
	resetBounds: false,
	hoverRouteID: null,
	hoveredLocation: null,

	postReadyRoutes: computed('mapReadyRoutes', 'selectedRouteID', function () {
		return this.get('mapReadyRoutes')
	}),

	routeBounds: computed('postReadyRoutes.[]', function () {
		let routes = this.get('postReadyRoutes')
		let _latlngs = []
		if (!routes.length) { return RomaniaBounds }
		routes.forEach(route => {
			_latlngs = _latlngs.concat(route._latlngs)
		})
		return this.get('mapUtil').calculateBounds(_latlngs)
	}),


	mapBounds: computed('routeBounds', 'selectedRouteBounds', function () {
		let selectedRouteBounds = this.get('selectedRouteBounds')
		let routeBounds = this.get('routeBounds')
		if (selectedRouteBounds) { return selectedRouteBounds }
		return routeBounds
	}),

	selectedRouteBounds: computed('selectedRouteID', function () {
		let selectedRouteID = this.get('selectedRouteID')
		if (!selectedRouteID) { return null }
		let selectedRoute = this.get('postReadyRoutes').findBy('options.entityID', selectedRouteID)
		if (!selectedRoute) { return null }
		return this.get('mapUtil').calculateBoundsZoom(selectedRoute._latlngs)
	}),

	selectedRoute: computed('selectedRouteID', 'postReadyRoutes', function () {
		let selectedRouteID = this.get('selectedRouteID')
		if (!selectedRouteID) { return null }
		let selectedRoute = this.get('postReadyRoutes').findBy('options.entityID', selectedRouteID)
		if (!selectedRoute) { return null }
		return selectedRoute
	}),

	selectedRoutePoints: computed('selectedRoute', function () {
		let selectedRoute = this.get('selectedRoute')
		let selectedVehicle = this.get('model.selectedVehicle')
		let routes = null
		if (!selectedRoute) { return [] }
		if (selectedRoute.mergedRoutes && selectedRoute.mergedRoutes.length) {
			let mergedRoutesIDs = selectedRoute.mergedRoutes.map(item => item.options.entityID)
			routes = this.get('sortedRoutes').filter(item => {
				return mergedRoutesIDs.indexOf(item.get('id')) > -1
			})
		} else {
			routes = this.get('sortedRoutes').filter(item => item.get('id') === selectedRoute.options.entityID)
		}

		let mapData = this.get("mapService").makeRoutes(routes, undefined, undefined, selectedVehicle, true)
		return mapData.continuousData
	}),

	selectedRouteDirectionsMarkers: computed('routesDirectionMarkers', 'selectedRouteID', function () {
		let selectedMarkers = this.get('routesDirectionMarkers') ? this.get('routesDirectionMarkers').filterBy('isSelected') : null
		return this.get('selectedRouteID') ? selectedMarkers : null
	}),

	routesDirectionMarkers: computed('postReadyRoutes', 'mapZoom', 'completeMapBounds', 'selectedRouteID', function () {
		if (!this.get("postReadyRoutes") || !this.get("mapZoom")) { return null }
		let arr = []

		let bounds = this.get("completeMapBounds") ? this.get("completeMapBounds") : this.get("mapBounds")	//TODO What about to set direction markers witohut bounds ?
		let zoom = this.get("mapZoom")
		let selectedRouteID = this.get('selectedRouteID')

		this.get('postReadyRoutes').filterBy("stationary", false).forEach(function (item) {
			arr = arr.concat(item._latlngs.map(function (e) {
				let copied = Object.assign({}, e, {
					routeID: item.options.entityID,
					isSelected: selectedRouteID === item.options.entityID ? true : false
				})
				return copied
			}))
		})

		return this.get("mapUtil").routeDirectionsMarkersDistance(arr, zoom, bounds)
	}),

	polygons: computed('model', function () {
		return this.get('polygon').polygons(this.get('model.geofences'))
	}),

	pois: computed('model', function () {
		return this.get('poi').pois(this.get('model.geofences'))
	}),

	resetSelectedRouteID() {
		this.set("selectedRouteID", null)
	},

	// paramsObserver: Ember.observer("routeStartDate", "routeEndDate", "model.selectedVehicle", "stopLimit", function () {
	// 	let startDate = this.get('routeStartDate')
	// 	let endDate = this.get('routeEndDate')
	// 	this.fixDates(startDate, endDate)
	// 	this.set("selectedRouteID", null)
	// }),

	queryParams: [
		{ routeStartDate: 'start' },
		{ routeEndDate: 'end' }
	],

	sortedRoutes: computed.sort("model.routes", "sortCriteria"),
	sortCriteria: ["startTime:asc"],

	routeStartDate: moment(moment().format("YYYY-MM-DD")).valueOf(),
	routeEndDate: moment(moment().format("YYYY-MM-DD")).endOf('day').valueOf(),
	maxDate: moment(moment().format("YYYY-MM-DD")).endOf('day').toDate(),

	chart: 0,

	showCharts: false,

	processedMapData: computed('mapData', 'selectedRoutePoints.[]', function () {
		let mapData = this.get('mapData')
		let i18n = this.get('i18n')
		let selectedRoutePoints = this.get('selectedRoutePoints')
		let selectedVehicle = this.get('model.selectedVehicle')
		if (selectedRoutePoints && selectedRoutePoints.length) {
			mapData = selectedRoutePoints
		}
		let processed = {
			speed: [],
			altitude: [],
			fuelCanLevel: [],
			fuelLevel: [],
			fuelAverageLevel: [],
			fuel: [],
			acceleration: [],
			rpm: [],
			engineLoad: [],
			engineTemperature: [],
		}

		mapData.forEach(item => {
			processed.speed.pushObject({
				location: {
					altitude: item.altitude,
					bearing: item.bearing,
					lat: item.lat,
					lng: item.lng,
				},
				time: new Date(item.timestamp),
				value: item.speed,
				label: i18n.t('speed').string
			})

			processed.fuelCanLevel.pushObject({
				location: {
					altitude: item.altitude,
					bearing: item.bearing,
					lat: item.lat,
					lng: item.lng,
				},
				time: new Date(item.timestamp),
				value: item.fuelCanLevel,
				label: i18n.t('fuelCanLevel').string
			})

			if (item.fuelAverageLevel) {
				processed.fuelAverageLevel.pushObject({
					location: {
						altitude: item.altitude,
						bearing: item.bearing,
						lat: item.lat,
						lng: item.lng,
					},
					time: new Date(item.timestamp),
					value: item.fuelAverageLevel,
					label: i18n.t('fuelAverageLevel').string
				})
			}

			processed.fuelLevel.pushObject({
				location: {
					altitude: item.altitude,
					bearing: item.bearing,
					lat: item.lat,
					lng: item.lng,
				},
				time: new Date(item.timestamp),
				value: item.fuelLevel,
				label: i18n.t('fuelLevel').string
			})

			processed.rpm.pushObject({
				location: {
					altitude: item.altitude,
					bearing: item.bearing,
					lat: item.lat,
					lng: item.lng,
				},
				time: new Date(item.timestamp),
				value: item.rpm,
				label: i18n.t('rpm').string
			})

			processed.engineLoad.pushObject({
				location: {
					altitude: item.altitude,
					bearing: item.bearing,
					lat: item.lat,
					lng: item.lng,
				},
				time: new Date(item.timestamp),
				value: item.engineLoad,
				label: i18n.t('engineLoad').string
			})

			processed.engineTemperature.pushObject({
				location: {
					altitude: item.altitude,
					bearing: item.bearing,
					lat: item.lat,
					lng: item.lng,
				},
				time: new Date(item.timestamp),
				value: item.engineTemperature,
				customValueText: item.engineTemperature + ' °C',
				label: i18n.t('engineTemperature').string
			})
		})

		processed.fuel.setObjects(processed.fuelAverageLevel.concat(processed.fuelCanLevel.concat(processed.fuelLevel)))

		return processed
	}),

	doNotShowSpeed: computed('processedMapData', function () {
		return this.get('processedMapData.speed').isEvery('value', 0)
	}),

	doNotShowFuelLevel: computed('processedMapData', function () {
		return this.get('processedMapData.fuel').isEvery('value', 0)
	}),

	doNotShowRpm: computed('processedMapData', function () {
		return this.get('processedMapData.rpm').isEvery('value', 0)
	}),

	doNotShowEngineLoad: computed('processedMapData', function () {
		return this.get('processedMapData.engineLoad').isEvery('value', 0)
	}),

	doNotShowEngineTemperature: computed('processedMapData', function () {
		return this.get('processedMapData.engineTemperature').isEvery('value', 0)
	}),

	noCharts: computed('doNotShowSpeed', 'doNotShowFuelLevel', 'doNotShowEngineLoad', 'doNotShowEngineTemperature', 'doNotShowRpm', function () {
		if (this.get("doNotShowSpeed") &&
			this.get("doNotShowFuelLevel") &&
			this.get("doNotShowEngineLoad") &&
			this.get("doNotShowEngineTemperature") &&
			this.get("doNotShowRpm")) {
			return true
		}
		else {
			return false
		}
	}),

	routesAreReady: computed("sortedRoutes", "stopLimit", function () {
		let routes = this.get("sortedRoutes")
		let stopLimit = this.get("stopLimit")
		let selectedVehicle = this.get('model.selectedVehicle')
		let processUnknownRoute = this.processUnknownRoute(routes, selectedVehicle.get('isMoving'))

		let mapData = this.get("mapService").makeRoutes(routes, stopLimit, processUnknownRoute, selectedVehicle)
		return mapData
	}),

	mapData: computed('routesAreReady', function () {
		return this.get('routesAreReady').continuousData
	}),

	routesEndsTime: computed('mapData', function () {
		let mapData = this.get('mapData')
		if (!mapData.length) { return [] }
		return [moment(mapData[0].timestamp), moment(mapData[mapData.continuousData.length - 1].timestamp)]
	}),

	stationaryMarkers: computed('routesAreReady', function () {
		return this.get('routesAreReady').stationaryMarkers
	}),

	mapReadyRoutes: computed('routesAreReady', function () {
		return this.get('routesAreReady').routeLines
	}),

	groupedByDateRoutes: computed('mapReadyRoutes', function () {
		let groupedRoutes = {}
		let routesCount = this.get('mapReadyRoutes').get('length')
		this.get('mapReadyRoutes').toArray().forEach((route, idx) => {

			let startTime = moment(route.options.start.time).format('DD/MM/YYYY')
			if (!groupedRoutes[startTime]) {
				groupedRoutes[startTime] = []
			}

			if (idx === 0 && !route.stationary) {
				groupedRoutes[startTime].pushObject(this.addStartRoute(route))
			}

			groupedRoutes[startTime].pushObject(route)

			if (idx === routesCount - 1 && !route.stationary && !route.isUnknown) {
				groupedRoutes[startTime].pushObject(this.addStopRoute(route))
			}
		})

		return groupedRoutes
	}),

	addStopRoute(route) {
		return Ember.Object.create({
			options: {
				start: {
					location: route.options.end.location,
					time: route.options.end.time
				}
			},
			stationary: true
		})
	},

	addStartRoute(route) {
		return Ember.Object.create({
			options: {
				end: {
					time: route.options.start.time
				},
				start: {
					location: route.options.start.location
				}
			},
			stationary: true
		})
	},

	fixDates(startDate, endDate) {
		if (startDate > endDate) {
			this.set('routeEndDate', moment(startDate).endOf('day').valueOf())
		}
	},

	processUnknownRoute(routes, isMoving) {
		if (!routes || routes.get("length") === 0) { return false }
		let lastRoute = routes[routes.get("length") - 1]
		if (lastRoute.get("id") !== 'unknown') { return false }
		if (isMoving) { return true }
		// unknow route id with > 2 different coordinates. Probably it is moving
		let movingRoute = lastRoute.get('points.simplified').mapBy('gps.coordinates').uniqBy('0', '1').length > 2
		if (movingRoute) { return true }
		routes.pop()
		return false
	},

	resetParameters() {
		let startDate = this.get('routeStartDate')
		let endDate = this.get('routeEndDate')
		this.fixDates(startDate, endDate)
		this.set("selectedRouteID", null)
	},

	setGoogleMapLayer(type) {
		if (type) {
			this.get("appSettings").set("mapType", type);
			this.set("session.session.googleMapLayerType", type)
		} else {
			if (this.get("session.session.googleMapLayerType")) {
				this.get("appSettings").set("mapType", this.get("session.session.googleMapLayerType"));
			}
		}

		if (this.get("session.session.map")) {
			this.get("appSettings").set("map", this.get("session.session.map"));
		}
	},

	actions: {
		addFueling(date, location) {
			if (!date || !location) { return false }
			let jsDate = date.toDate(); //parse moment date to jsDate
			this.set("fuelingValues", { timestamp: jsDate, location })
			Ember.$("#modalAddFueling").modal('show')
		},
		/**
		 * @param {number} chartNumber
		 * @param {boolean} notShow - if true the graph is disabled because its values are 0
		 */
		showChart(chartNumber, notShow) {
			if (notShow) { return false }

			if (!this.get('showCharts')) {
				this.set('showCharts', true)
			}
			this.set('chart', chartNumber)
		},

		stopLimitChange(stopLimit) {
			this.set('stopLimit', stopLimit)
			this.resetParameters()
		},

		toggleCharts() {
			if (this.get("noCharts")) { return false }
			if (!this.get('chart')) { this.set('chart', 1) }
			this.set('showCharts', !this.get('showCharts'))
		},

		vehicleChanged: function (vehicleId) {
			this.resetParameters()
			let startDate = this.get('routeStartDate')
			let endDate = this.get('routeEndDate')
			this.transitionToRoute('map.routes', vehicleId, {
				queryParams: {
					start: startDate,
					end: endDate
				}
			})
			this.set("session.session.resetMapBounds", true)
		},

		polylineMouseover(route) {
			if (!route || !route.options.entityID) { return false }
			this.set('hoverRouteID', route.options.entityID)
			Ember.set(route, 'options.weight', hoveredRouteWeight)
			Ember.set(route, 'options.opacity', hoveredRouteOpacity)
		},
		polylineMouseOut(route) {
			if (!route || !route.options.entityID) { return false }
			this.set('hoverRouteID', null)
			Ember.set(route, 'options.weight', routeWeight)
			Ember.set(route, 'options.opacity', routeOpacity)
		},
		polylineClick(route) {
			if (!route || !route.options.entityID) { return false }
			this.set('hoverRouteID', null)
			if (route.options.entityID === this.get('selectedRouteID')) {
				Ember.set(route, 'options.weight', routeWeight)
				Ember.set(route, 'options.opacity', routeOpacity)
				Ember.set(route, 'isSelected', false)
				this.set('selectedRouteID', null)
			}
			else {
				Ember.set(route, 'isSelected', true)
				this.set('selectedRouteID', route.options.entityID)
			}


			// for mobile -> mapInFocus will close the menu and show map
			this.set("mapInFocus", true)
		},
		directionMarkerClick(routeID) {
			if (!routeID) { return false }
			let selectedRoute = this.get("postReadyRoutes").findBy("options.entityID", routeID)
			this.send("polylineClick", selectedRoute)
		},
		directionMarkerHover(routeID) {
			if (!routeID) { return false }
			let selectedRoute = this.get("postReadyRoutes").findBy("options.entityID", routeID)
			this.send("polylineMouseover", selectedRoute)
		},
		directionMarkerHoverOut(routeID) {
			if (!routeID) { return false }
			let selectedRoute = this.get("postReadyRoutes").findBy("options.entityID", routeID)
			this.send("polylineMouseOut", selectedRoute)
		},

		/**
		 * This function triggers when datepicker change. It should not trigger on other changes.
		 */
		startDateChanged: function (/*formattedDate, dateObject, datepickerInstance*/) {
			this.resetParameters()
			this.set("session.session.resetMapBounds", true)
		},

		endDateChanged: function (/*formattedDate, dateObject, datepickerInstance*/) {
			this.resetSelectedRouteID()
			this.set("session.session.resetMapBounds", true)
		},

		prevClicked: function () {
			this.resetSelectedRouteID()

			this.set('routeStartDate', moment(this.get('routeStartDate')).add(-1, 'days').valueOf())
			this.set('routeEndDate', moment(this.get('routeEndDate')).add(-1, 'days').endOf('day').valueOf())
			this.set("session.session.resetMapBounds", true)
		},
		nextClicked: function () {
			this.resetSelectedRouteID()

			this.set('routeStartDate', moment(this.get('routeStartDate')).add(1, 'days').valueOf())
			this.set('routeEndDate', moment(this.get('routeEndDate')).add(1, 'days').endOf('day').valueOf())
			this.set("session.session.resetMapBounds", true)
		},

		openPopup(key) {
			this.set('activeMarkerKey', key)
		},
		routeReport: function (route) {
			var vehicleID = this.get("model.selectedVehicle.id")
			this.transitionToRoute("reports.route", {
				queryParams: {
					vehicleID: vehicleID,
					start: route.options.start.time.getTime(),
					end: route.options.end.time.getTime()
				}
			})
		},
		onZoomEnd: function (e) {
			let zoom = e.target.getZoom()
			this.set("mapZoom", zoom)
		},
		onMoveend: function (e) {
			let bounds = e.target.getBounds()
			// OPTIONAL bounds.pad(1))
			this.set("completeMapBounds", bounds)
		},

		resetMapBounds: function () {
			this.set("mapBounds", this.get("_mapBounds"))
		},
		getHoveredPointData(point) {
			if (point && point.location) {
				this.set("hoveredLocation", point.location)
			}
		},
		disableHoverMarker() {
			this.set("hoveredLocation", null)
		},


		onMapViewreset(event) {
			this.set("mapInstance", event.target)
			this.setGoogleMapLayer();
		},
		onMapLoad: function (event) {
			var scale = L.control.scale({ metric: true });
			scale.addTo(event.target);
			this.set("mapInstance", event.target);
			this.setGoogleMapLayer();
		},
		selectedGoogleMapType(type) {
			this.setGoogleMapLayer(type);
			this.get("mapInstance").removeLayer(this.get("googleLayer"));
			this.set("googleLayer", L.gridLayer.googleMutant({ opacity: 1, type: type }));
			this.get("mapInstance").addLayer(this.get("googleLayer"));
		},
		onGoogleMapLoad(event) {
			this.set("googleLayer", event.target);
		}
	},
})
