import Ember from 'ember'

const { inject: { service } } = Ember

export default Ember.Component.extend({
	overlayLayer: service('overlay-layer'),
	vehicleValidationSchema: service('vehicle-validation-schema'),
	formValidate: service('form-validate'),
	i18n: service(),

	moreDetails: false,
	aDevices: null,
	nullDevices: false,
	requestSent: false,
	relayRequestSent: false,
	deviceHasChanged: false,
	defaultDeviceID: null,

	observerVehicleType: Ember.observer('vehicle.type', function () {
		let type = this.get('vehicle.type')
		let alertType = this.get('vehicle.settings.alerts.rpm-limit.type')
		if (type === 'harvester' && alertType == 'alert') {
			this.set('vehicle.settings.alerts.rpm-limit.type', 'disabled')
		}
	}),

	enabledAlerts: Ember.computed('vehicle.settings.alerts.geofence-stationary.type', 'vehicle.settings.alerts.fuel-consumption-limit.type', 'vehicle.settings.alerts.rpm-limit.type', function () {
		let vehicle = this.get('vehicle')
		return {
			'geofence-stationary': vehicle.get('settings.alerts.geofence-stationary.type') && (vehicle.get('settings.alerts.geofence-stationary.type') !== 'disabled'),
			'fuel-consumption-limit': vehicle.get('settings.alerts.fuel-consumption-limit.type') && (vehicle.get('settings.alerts.fuel-consumption-limit.type') !== 'disabled'),
			'rpm-limit': vehicle.get('settings.alerts.rpm-limit.type') && (vehicle.get('settings.alerts.rpm-limit.type') !== 'disabled'),
		}
	}),

	sortedGroups: Ember.computed('groups', function () {
		return this.get('groups').sortBy('name')
	}),

	sortedUsers: Ember.computed('users', function () {
		return this.get('users').sortBy('firstName', 'lastName')
	}),
	unusedImplements: Ember.computed("implements", "vehicles", function () {
		let vehicles = this.get("vehicles")
		let usedIDs = vehicles.uniqBy("implementID").rejectBy("implementID", null)
		let vehicleImplement = this.get("vehicle.implementID")

		return this.get("implements").reject((implement) => {
			return (vehicleImplement && vehicleImplement === implement.get("id")) ? false : usedIDs.isAny("implementID", implement.get("id"))
		})
	}),

	checkAsignedUser(selUserID, vehicleID) {
		var vehicles = this.get('vehicles').filter(function (item) {
			return item.get('id') !== vehicleID
		})
		if (selUserID) {
			return vehicles.findBy('user.id', selUserID)
		} else {
			return null
		}
	},

	setActiveDevices(vehicleID) {
		var devices = []
		this.get('devices').forEach(function (item) {
			if (!item.get("vehicleID") || item.get("vehicleID") === vehicleID) {
				devices.push(item)
			}
		})
		if (devices.length < 1) {
			this.set("nullDevices", true)
		}

		this.set("aDevices", devices)
	},

	init() {
		this._super(...arguments)
		Ember.$('.ui.dimmer.modals').remove()
		Ember.$.fn.dropdown.settings.message = { // translation number of for selecions for dropdown(geofences)
			addResult: 'Add <b>{term}</b>',
			count: '{count} ' + this.get("i18n").t("selectedGeofences"),
			maxSelections: 'Max {maxCount} selections',
			noResults: 'No results found.'
		}
		var _this = this,
			vehicle = this.get('vehicle')

		if (!vehicle) {
			return false
		}

		if (vehicle.get('id')) {
			_this.setActiveDevices(vehicle.get('id'))
			let device = _this.get('devices').findBy("vehicleID", vehicle.get('id'))
			if (device) {
				this.set('defaultDeviceID', device.get('id'))
				vehicle.set("device", device)
			}
			else {
				this.set('deviceHasChanged', true)
			}
		}
		else {
			_this.setActiveDevices()
		}

		if (this.get('documentId')) {
			this.set('moreDetails', true)
		}
	},

	didReceiveAttrs() {
		this._super(...arguments)
		if (this.get('requestCompleted')) {
			this.set('requestSent', false)
		}

		if (this.get('relayRequestCompleted')) {
			this.set('relayRequestSent', false)
		}

		if (this.get('relayRequestCompleted') && this.get("listOfErrors").length === 0) {
			// alert("requestFinished")
			this.toggleProperty('vehicle.status.relayState')
			this.hideRelayModal();
		}

		if (this.get("listOfErrors").length > 0) {
			// alert("we have errors")
			this.get("listOfErrors").forEach(error => {
				this.get('toast').error(error.message, '', { timeOut: 0, extendedTimeOut: 0 })
			})
		}
	},

	didInsertElement() {
		let _this = this
		Ember.$("#dduserID").dropdown("save defaults")

		Ember.$('.confirmContactRelay')
			.modal({
				allowMultiple: true,
				onApprove: function () {
					_this.set('relayRequestSent', true)
					_this.get('updateRelay')(_this.get('vehicle.id'), !_this.get('vehicle.status.relayState'))
					return false
				},
				onDeny: function () {
					_this.get('toast').clear()
				},
			})
	},

	didRender() {
		let _this = this
		Ember.$('form.formVehicle')
			.form({
				on: 'blur',
				fields: _this.get('vehicleValidationSchema').schema.fields,
				onInvalid() {
					let $ele = this

					function isVisible(element) {
						var elementTop = $(element).offset().top;
						var elementBottom = elementTop + $(element).outerHeight();
						var viewportTop = $(window).scrollTop();
						var viewportBottom = viewportTop + $(window).height();
						return elementBottom > viewportTop && elementTop < viewportBottom;
					};

					let errorIsVisible = isVisible($ele)
					if (!errorIsVisible) {
						Ember.$('#formContent').animate({
							scrollTop: $ele.offset().top - (Ember.$(window).height() - $ele.outerHeight(true)) / 2
						}, 200);
					}
					return false;
				}
			})
	},

	hideRelayModal() {
		if (this.get('relayRequestCompleted') && this.get("listOfErrors").length === 0) {
			Ember.$('.confirmContactRelay').modal('hide');
		}
	},

	actions: {
		deleteDoc(doc) {
			Ember.$(".confirmationModal").modal('show')
			this.set("docToDelete", doc)
		},
		confirmDelete(response) {
			let doc = this.get("docToDelete")
			if (response && doc) {
				this.get("deleteDocument")(doc)
			}
		},
		beforeModifyChecked() {
			Ember.$('.confirmContactRelay').modal('show')
			return false
		},
		edit() {
			this.sendAction('disableReadonly')
		},

		save() {
			if (!Ember.$('form.formVehicle').form("validate form")) {
				Ember.Logger.debug("Validare")
				Ember.Logger.debug("FormERROR")
				return false
			}
			this.set('requestSent', true)
			this.get('save')()
		},

		createDocument() {
			this.sendAction('createDocument')
		},
		cancel() {
			this.get('cancel')()
		},
		selectGroup(groupID) {
			var editingVehicle = this.get('vehicle')
			editingVehicle.set("group", this.get("groups").findBy("id", groupID))
		},
		selectDevice(deviceID) {
			let isDefaultDevice = (this.get('defaultDeviceID') === deviceID && deviceID)
			this.set('deviceHasChanged', !isDefaultDevice)
			let vehicle = this.get('vehicle')
			this.set('previousDeviceID', deviceID)
			vehicle.set("device", this.get("devices").findBy("id", deviceID))
		},
		selectDriver(object) {
			var selUserID = Ember.$("#" + object.id).dropdown("get value")

			var _this = this
			var editingVehicle = this.get('vehicle')
			if (!selUserID) {
				editingVehicle.set("user", null)
				return true
			}
			var vehicleWithDriverAttached = null
			if (editingVehicle.get('isNew')) {
				vehicleWithDriverAttached = _this.checkAsignedUser(selUserID, null)
			}
			else {
				vehicleWithDriverAttached = _this.checkAsignedUser(selUserID, editingVehicle.get('id'))
			}

			var overlayerAttached = null
			if (_this.get("isModal")) {
				overlayerAttached = ".vehicleModal"
			}
			if (vehicleWithDriverAttached) {
				_this.get('overlayLayer').add(Ember.$(overlayerAttached))
				Ember.$(".lblDriverName").html(Ember.$("#dduserID").dropdown("get text"))
				Ember.$(".lblVehicleName").html(vehicleWithDriverAttached.get("carName"))
				Ember.$(".lblVehiclePlate").html(vehicleWithDriverAttached.get("licensePlate"))
				Ember.$(".lblCurrentVehicleName").html(editingVehicle.get("make") + " " + editingVehicle.get("model"))
				Ember.$(".lblCurrentVehiclePlate").html(editingVehicle.get("licensePlate"))

				Ember.$('.confirmDriverModal')
					.modal({
						allowMultiple: true,
						onApprove: function () {
							editingVehicle.set("user", _this.get("sortedUsers").findBy("id", selUserID))
							_this.get('overlayLayer').remove(Ember.$(overlayerAttached))
						},
						onDeny: function () {
							Ember.$("#dduserID").dropdown("restore defaults")
							_this.get('overlayLayer').remove(Ember.$(overlayerAttached))
						}
					})
					.modal('show')
			} else {
				editingVehicle.set("user", _this.get("sortedUsers").findBy("id", selUserID))
				this.get('overlayLayer').remove(Ember.$(overlayerAttached))
			}
		},
		addMoreDetails() {
			this.toggleProperty("moreDetails")
		}
	}
})
