import MF from 'ember-data-model-fragments';

export default MF.Fragment.extend({
	car: MF.fragment('fragments/vehicleType'),
	utility: MF.fragment('fragments/vehicleType'),
	minibus: MF.fragment('fragments/vehicleType'),
	bus: MF.fragment('fragments/vehicleType'),
	truck: MF.fragment('fragments/vehicleType'),
	tractor: MF.fragment('fragments/vehicleType'),
	harvester: MF.fragment('fragments/vehicleType'),
	construction: MF.fragment('fragments/vehicleType'),
	trailer: MF.fragment('fragments/vehicleType'),
	'semi-trailer': MF.fragment('fragments/vehicleType')
});
