import Ember from 'ember'

export default Ember.Component.extend({
    classNames: ['notificationListContainer'],
    classNameBindings: ["showAll:showAll"],

    limit: 4,
    enableShowAll: false,

    filteredNotifications: Ember.computed.filter('notifications', function (notification) {
        return  notification.get('isPoi') || notification.get('isGeofence') || notification.get('isDoorOpen') ||
                notification.get('isFuelConsumption') || notification.get('isRpmLimit')
    }),

    showAll: Ember.computed("filteredNotifications.@each", "enableShowAll", function () {
        let enableShowAll = this.get("enableShowAll")
        let length = this.get("filteredNotifications").length
        return (length > this.get("limit") && !enableShowAll) ? false : true
    }),

    hasData: Ember.computed("filteredNotifications.@each", function () {
        let length = this.get("filteredNotifications").length
        return (length > 0) ? true : false
    }),

    didInsertElement() {
        var height = parseInt(this.$().parent().height() * 0.88, 10);

        this.$().height(height);
    },

    actions: {
        showAll() {
            this.set("enableShowAll", true)
        }
    }
})
