/**
 * http://mkoryak.github.io/floatThead/
 */
import Ember from 'ember'
import moment from 'moment'

const { service } = Ember.inject
const oneMinute = 60000
// const fiveMinutes = 300000
// const tenMinutes = 600000
// const twentyMinutes = 1200000
// const thirtyMinutes = 1800000
const sixtySeconds = 60
const ROUTE = 'ROUTE'
const STATIONARY = 'STATIONARY'
const POI = 'POI'
const TEMPERATURERANGE = 'TEMPERATURE-RANGE'
const FAZ = 'FAZ'
const POITEMPERATURE = 'POI-TEMPERATURE'

export default Ember.Component.extend({
	classNames: ['content'],

	i18n: service(),
	reportHeader: service('reports/report-header'),
	reportsInfo: service('reports/reports-info'),
	dates: service('dates'),
	resolution: service(),
	mapUtil: service('map/util'),
	reportFields: service('reports/report-fields'),
	reportTableState: service('reports/table-layout-state'),
	showDriverInTableHeader: Ember.computed('reportTitle', function () {
		return [TEMPERATURERANGE, POITEMPERATURE, FAZ].indexOf(this.get('reportTitle')) === -1
	}),

	hasMultipleViews: false,
	showMap: false,
	markers: [],
	loadingSwitch: false,

	bounds: null,
	routes: [],
	selectedTitmetable: Ember.computed("reportsInfo", function () {
		let report = this.get("reportsInfo").getReportDataByTitle(this.get("reportTitle"))
		if (!report.options) { return false }
		let timeTable = report.options.findBy('name', 'timetable')
		if (!timeTable) { return false }
		let translationKey = timeTable.items.findBy("selected", true).translationKey
		if (!translationKey) { return false }
		return this.get('i18n').t(translationKey)
	}),

	sortedRecords: Ember.computed.sort('records', 'sortProperties'),

	doWeConcatenate(end, start) {
		let difference = Math.abs(end - start)
		return difference < (this.get('stationaryDuration') * oneMinute)
	},

	concatenateNextRecords(nextIndex, records) {
		let record = records[nextIndex - 1]
		let copyOfRecord = Ember.Object.create(record)
		copyOfRecord.set('isConcatenated', true)
		if (!record.get('childs')) {
			record.set('childs', [copyOfRecord])
		}
		for (let i = nextIndex; i < records.length; i++) {
			let nextRecord = records[i]

			if (!this.doWeConcatenate(record.get('endTime'), nextRecord.get('startTime'))) { return record }

			record.set('endTime', nextRecord.get('endTime'))
			record.set('goToMap', '<a href=/map/routes/' + record.get('vehicleID') + '?end=' + nextRecord.get('endTime') + '&start=' + record.get('startTime') + ' target="_blank"><i class="map outline icon"></i></a>')
			record.set('endLocation', nextRecord.get('endLocation'))
			record.set('duration', Number(record.get('duration')) + Number(nextRecord.get('duration')))
			record.set('fuelConsumption', Number(record.get('fuelConsumption')) + Number(nextRecord.get('fuelConsumption')))
			record.set('canFuelConsumption', Number(record.get('canFuelConsumption')) + Number(nextRecord.get('canFuelConsumption')))
			record.set('canDistance', Number(record.get('canDistance')) + Number(nextRecord.get('canDistance')))
			record.set('distance', Number(record.get('distance')) + Number(nextRecord.get('distance')))
			record.set('consumptionCompose', Number(record.get('consumptionCompose')) + Number(nextRecord.get('consumptionCompose')))
			record.set('distanceCompose', Number(record.get('distanceCompose')) + Number(nextRecord.get('distanceCompose')))
			record.set('maxSpeed', Math.max(record.get('maxSpeed'), nextRecord.get('maxSpeed')))
			record.set('canOdometer', nextRecord.get('canOdometer'))
			record.set('averageSpeed', Number(record.get('averageSpeed')) + Number(nextRecord.get('averageSpeed') / 2))
			record.get("childs").pushObject(nextRecord)
			nextRecord.set('isConcatenated', true)
		}
		return record
	},

	sliceArray(arr) {
		let newArr = []

		arr.map(record => {
			let newRecord = {}
			for (let i in record) {
				if (record.hasOwnProperty(i)) {
					newRecord[i] = record[i]
				}
			}
			newArr.push(Ember.Object.create(newRecord))
		})

		return newArr
	},

	concatenatedRecords(sortedRecords) {
		let records = this.sliceArray(sortedRecords)
		let concatenatedRecords = []

		records.forEach((record, idx) => {
			record.set('showChilds', false)
			if (idx === records.get('length') - 1 || record.get('isConcatenated')) { return false }
			concatenatedRecords.push(this.concatenateNextRecords(idx + 1, records))
		})

		let lastRecord = records.pop()
		if (!lastRecord.get('isConcatenated')) {
			concatenatedRecords.push(lastRecord)
		}

		return concatenatedRecords
	},

	filterRecords(sortedRecords) {
		let records = this.sliceArray(sortedRecords)
		return records.filter(record => record.get('duration') > (this.get('stationaryDuration') * sixtySeconds))
	},

	/**
	 * Exceptions
	 */
	preparedRecords: Ember.computed('sortedRecords', function () {
		if (!this.get('sortedRecords').length) { return [] }
		let report = this.get('reportsInfo').getReportDataByTitle(this.get('reportTitle'))
		if (report.get('title') === ROUTE) {
			return this.concatenatedRecords(this.get('sortedRecords'))
		} else if ([STATIONARY, POI].indexOf(report.get('title')) > -1) {
			return this.filterRecords(this.get('sortedRecords'))
		} else {
			return this.get('sortedRecords')
		}
	}),

	totalsRow: Ember.computed('columns', function () {
		var
			totals = this.get('reportHeader').calculateTotals(this.get('preparedRecords'))

		/**
		 * Exceptions
		 */
		if (totals.duration) {
			totals.duration = totals.durationRaw
		}
		if (totals.temperature1) {
			totals.temperature1 = totals.temperature1Raw
		}
		if (totals.temperature2) {
			totals.temperature2 = totals.temperature2Raw
		}
		if (totals.temperature3) {
			totals.temperature3 = totals.temperature3Raw
		}
		if (totals.temperature4) {
			totals.temperature4 = totals.temperature4Raw
		}
		if (totals.poiStationaryDuration) {
			totals.poiStationaryDuration = totals.poiStationaryDurationRaw
		}
		if (totals.poiStationaryDoorsOpenedDuration) {
			totals.poiStationaryDoorsOpenedDuration = totals.poiStationaryDoorsOpenedDurationRaw
		}
		if (totals.freezerOnDuration) {
			totals.freezerOnDuration = totals.freezerOnDurationRaw
		}

		let row = Ember.Object.create(totals)
		row.set(this.get('columns.firstObject.key'), 'Total')

		return row
	}),

	excludeColumnFromHeader() {
		let columns = this.get('columns')
		let excluded = this.get('excluded')
		return columns.filter(column => excluded.indexOf(column.key) === -1)
	},

	preparedColumns: Ember.computed('columns', function () {
		return this.excludeColumnFromHeader()
	}),

	can: Ember.computed('vehicle', function () {
		let vehicle = this.get('vehicle')
		if (!vehicle) { return false }
		let report = this.get('reportsInfo').getReportDataByTitle(this.get('reportTitle'))
		/**
		 * If report does not care about can data set can to false.
		 * We do not want cand data in header in this case.
		 */
		if (!report.get('checkForCanData')) { return false }
		return this.get('vehicle.hasValidCan')
	}),

	init() {
		this._super(...arguments)
		let reportTitle = this.get('reportTitle')
		let reportData = this.get('reportsInfo').getReportDataByTitle(reportTitle)
		let datesOps = this.get('dates')

		if (reportData.get('multipleViews')) {
			this.set('hasMultipleViews', reportData.get('multipleViews'))
			if (this.get("preparedRecords")) {
				let arrMakers = this.get("preparedRecords").map(record => {
					return {
						duration: datesOps.duration(moment("1900-01-01 00:00:00").add(record.duration, 'seconds').valueOf(), moment("1900-01-01 00:00:00").valueOf()),
						lat: record.get("coordinates.1"),
						lng: record.get("coordinates.0")
					}
				})

				this.set("markers", arrMakers)
				this.set('bounds', this.get('mapUtil').calculateBoundsZoom(arrMakers))
			}
		}

		if (this.get('hasMultipleViews')) {
			let secondaryLayout = this.get('reportTableState').showSecondaryLayout(this.get('reportTitle'), this.get('recordsGroup.groupIdentifier'))
			if (secondaryLayout) {
				this.set(secondaryLayout.whatToShow, true)
				if (secondaryLayout.whatToShow === 'showMap') {
					this.showMapLayout()
				}
			}
		}
	},

	didInsertElement() {
		this.set('headerDrivers', this.get('reportHeader').getDriversList(this.get('driversIDs'), this.get('allDrivers'), this.get('records')))
		this.set('today', moment().format('DD.MM.YYYY HH:mm:ss'))
		this.set('calculateTotals', this.get('reportHeader').calculateTotals(this.get('records')))

		if (this.get('vehicle') && this.get('vehicle').get('group')) {
			this.set('groupName', this.get('vehicle').get('group').get('name'))
		}

		if (this.get('isFaz')) {
			this.set("startTime", Ember.$("#rangestartFAZ input").val())
			this.set("endTime", Ember.$("#rangeendFAZ input").val())
		} else {
			this.set("startTime", Ember.$("#rangestart input").val())
			this.set("endTime", Ember.$("#rangeend  input").val())
		}
	},

	setTableState(whatToShow) {
		if (this.get(whatToShow)) {
			this.get('reportTableState').add(this.get('reportTitle'), this.get('recordsGroup.groupIdentifier'), whatToShow)
		} else {
			this.get('reportTableState').remove(this.get('reportTitle'), this.get('recordsGroup.groupIdentifier'))
		}
	},

	showMapLayout() {
		this.set('loadingSwitch', true)

		if (!this.get('showMap')) {
			this.set('loadingSwitch', false)
			this.set('routes', [])
			return false
		}

		let _this = this

		this.get('getRoutesInTimeInterval')(this.get('vehicle'), this.get('reportFields.fields.startDate'), this.get('reportFields.fields.endDate'))
			.then(data => data.map(function (r) {
				return {
					locations: r.get('points.simplified').map(function (p) {
						return { lat: p.gps.coordinates[1], lng: p.gps.coordinates[0] }
					})
				}
			}))
			.then(data => {
				_this.set('loadingSwitch', false)
				_this.set('routes', data)
			})
			.catch(err => {
				Ember.Logger.error(err)
				_this.set('loadingSwitch', false)
				_this.set('routes', [])
			})
	},

// PAGINATION START
	pageIndex: 1,
	showAll: false,
	resultsPerPage: 10, // obvious
	recordsCount: Ember.computed("preparedRecords.length", function () {
		return this.get("preparedRecords.length")
	}),
	showPagination: Ember.computed("recordsCount", "resultsPerPage", function () {
		return this.get("recordsCount") > this.get("resultsPerPage")
	}),

	visibleRecords: Ember.computed("preparedRecords", "showAll", "pageIndex", "resultsPerPage", function () {
		let resultsPerPage = this.get("resultsPerPage")
		let page = this.get("pageIndex")

		if (this.get("showAll") || !this.get("showPagination")) {
			return this.get('preparedRecords');
		} else {
			return this.get('preparedRecords').slice((page - 1) * resultsPerPage, page * resultsPerPage);
		}
	}),
// PAGINATION END

	actions: {
		setResultsPerPage(results) {
			if (!results) { return false }
			this.set("resultsPerPage", results)
			this.set("pageIndex", 1)
		},
		showAll() {
			this.toggleProperty("showAll")
		},
		turnPage(pageNumber) {
			if (!pageNumber) { return false }
			this.set("pageIndex", pageNumber)
		},

		switchTableContent() {
			this.toggleProperty('showMap')
			this.setTableState('showMap')
			this.showMapLayout()
		},
	}
})
