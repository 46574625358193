import DS from 'ember-data';

export default DS.RESTSerializer.extend({
	keyForRelationship(key, relationship) {
		if (relationship === 'belongsTo') {
			return `${key}ID`;
		}
	},

	normalizeArrayResponse(store, primaryModelClass, payload/*, id, requestType*/) {
		let vehicles = payload.vehicle
		vehicles.forEach(vehicle => {
			let geolocation = vehicle.geolocation
			/**
			 * Reports case for example
			 */
			if (!geolocation) { return false }

			let geofences = geolocation.geofences || null
			let geofencesCount = 0
			let location = geolocation.location || null

			if (geofences) {
				geofencesCount = geofences.length
				geolocation.popupText = geofences.join('\n')
				geolocation.geofencesCount = geofencesCount
			} else if (location) {
				geolocation.popupText = location
			}
		})
		return this._super(...arguments)
	},

	normalize(typeClass, hash) {
		if (!hash.settings.alerts['geofence-stationary']) {
			hash.settings.alerts['geofence-stationary'] = {
				type: 'disabled',
			}
		}
		if (!hash.settings.alerts['fuel-consumption-limit']) {
			hash.settings.alerts['fuel-consumption-limit'] = {
				type: 'disabled',
				limit: 1
			}
		}
		if (!hash.settings.alerts['rpm-limit']) {
			hash.settings.alerts['rpm-limit'] = {
				type: 'disabled',
			}
		}

		return this._super(...arguments);
	},

	serialize(/*snapshot, options*/) {
		let json = this._super(...arguments);
		if (!json.settings.alerts['geofence-stationary']) {
			json.settings.alerts['geofence-stationary'] = {
				type: 'disabled',
			}
		}
		if (!json.settings.alerts['fuel-consumption-limit']) {
			json.settings.alerts['fuel-consumption-limit'] = {
				type: 'disabled',
				limit: 1
			}
		}
		if (!json.settings.alerts['rpm-limit']) {
			json.settings.alerts['rpm-limit'] = {
				type: 'disabled',
			}
		}

		delete json.deviceID;
		return json;
	}
});
