export default {
	'poi-stationary': '{{licensePlate}} stopped in farm {{geofenceName}}',
	'geofence-stationary': '{{licensePlate}} stopped in field {{geofenceName}}',
	'rpm-limit': 'Engine overload for {{licensePlate}}',
	'fuel-consumption-limit': 'Fuel level drop for {{licensePlate}}',
	'document-expiry': '{{type}} for {{driver}} {{vehicle}} will expire on {{until}}',
	'document-expired': '{{type}} for {{driver}} {{vehicle}} expired on {{until}}',

	// not titan alerts
	'poi-enter': '{{licensePlate}} entered farm {{geofenceName}}',
	'poi-exit': '{{licensePlate}} left farm {{geofenceName}}',

	'geofence-enter': '{{licensePlate}} entered field {{geofenceName}}',
	'geofence-exit': '{{licensePlate}} left field {{geofenceName}}',

	'door-open-outside-poi': 'Door open outside field for {{licensePlate}}',
}
