import Ember from 'ember';

// 1- didReceiveAttrs will hide the modal when requestComplete and noErrors. ifErrors show them in the form
// 2- onModalHidden completed, cancel action(Controller) will make the transition
export default Ember.Component.extend({
	init() {
		this._super(...arguments);
		Ember.$('.ui.dimmer.modals').remove();
	},

	didReceiveAttrs() {
		if (this.get('requestCompleted') && !this.get("listOfErrors").length > 0) {
			this.hideModal()
		}
	},

	didInsertElement() {
		let _this = this;
		Ember.$('.managementModal')
			.modal({
				duration: 200,
				onHidden: function () {
					_this.get('cancel')();
				},
			})
			.modal('show');

		if (Ember.$("[data-id='" + this.get('documentId') + "']").length) {
			Ember.$("[data-id='" + this.get('documentId') + "']").addClass('highlight-bordered-yellow');
			Ember.$('#formContent').animate({
				scrollTop: Ember.$("[data-id='" + this.get('documentId') + "']").offset().top - Ember.$('#formContent').offset().top + Ember.$('#formContent').scrollTop()
			}, 200);
		}

	},

	hideModal() {
		Ember.$('.managementModal').modal('hide');
	},

	actions: {
		save() {
			this.get('save')(Ember.$('#aclGroups').val(), Ember.$('#aclVehicles').val());
		},
		cancel() {
			this.hideModal();
		},
	}
});
