import Ember from 'ember';
import RSVP from 'rsvp';

export default Ember.Route.extend({
    aUsers: [],

    i18n: Ember.inject.service(),

    translateRole(role) {
        //translate role from driver to Sofer in our case
        if (role === "driver") {
            return this.get('i18n').t('management.users.driver');
        }
        else if (role === "admin") {
            return this.get('i18n').t('management.users.admin');
        }
        else {
            return role;
        }
    },
    getGroupName(groupID, groups) {
        var group;

        if (Ember.isEmpty(groupID) || Ember.isEmpty(groups)) { return null; }

        group = groups.findBy("id", groupID);

        if (group) {
            return group.get("name");
        } else {
            return null;
        }
    },

    model() {
        var _this = this;
        var arrUsers = [];
        var users = this.store.peekAll('user');
        var groups = this.store.peekAll('group');
        let i18n = this.get('i18n')

        const tHeaders = [
            { key: 'fullName', value: i18n.t('management.headerFields.name'), align: 'left' },
            { key: 'email', value: i18n.t('management.headerFields.email'), align: 'center' },
            { key: 'phoneNumber', value: i18n.t('management.headerFields.phoneNumber'), align: 'right' },
            { key: 'groupName', value: i18n.t('management.headerFields.division'), align: 'left' },
            { key: 'role', value: i18n.t('management.headerFields.role'), align: 'left' },
        ]

        users.forEach(function (user) {
            let newUser = Ember.Object.create();
            tHeaders.forEach(function (item) {
                if (item.key === "role") {
                    newUser.set("role", user.get("role"));
                    newUser.set("roleName", _this.translateRole(user.get("role")));
                } else if (item.key === "groupName") {
                    newUser.set("groupName", _this.getGroupName(user.get("groupID"), groups));
                }
                else {
                    newUser.set(item.key, user.get(item.key));
                }
            });
            newUser.set("id", user.get("id"));
            arrUsers.pushObject(newUser);
        });

        arrUsers = arrUsers.sortBy("fullName");

        return RSVP.hash({
            tHeaders: tHeaders,
            aUsers: arrUsers,
            groups: groups,
        });
    },

    actions: {
        viewUser(userID) {
            if (userID) {
                this.transitionTo('management.users.view', userID);
            }
        },
        viewDocuments(userID) {
            if (userID) {
                this.transitionTo('management.users.docs', userID);
            }
        },
        newUser() {
            this.transitionTo('management.users.new');
        },
        editUser(userID) {
            if (userID) {
                this.transitionTo('management.users.edit', userID);
            }
        },
        deleteUser(userID) {
            var _this = this;

            if (userID) {
                var userObj = this.store.peekRecord('user', userID);

                Ember.$("#UserNameModal").html(userObj.get("fullName"));
                Ember.$("#emailModal").html(userObj.get("email"));

                // has any vehicle this userID assigned ?
                var vehicle = this.store.peekAll('vehicle').findBy("userID", userID);
                if (vehicle) {
                    Ember.$("#userAssignedToVehicle").html('<h3><i class="warning sign icon"></i> ' + _this.get('i18n').t('management.users.userHasVehicle') + ' "' + vehicle.get("licensePlate") + '"; ' + _this.get('i18n').t('management.users.ifDeleteDetatch') + '.</h3>');
                }
                else {
                    Ember.$("#userAssignedToVehicle").html('');
                }
                Ember.$('.modal.deleteUserID')
                    .modal({
                        onApprove: function () {
                            // delete user
                            _this.get('store')
                                .findRecord('user', userObj.get("id"), { backgroundReload: false })
                                .then(function (user) {
                                    user.destroyRecord().then(function () {
                                        // detach user from vehicle
                                        if (vehicle) {
                                            _this.get('store')
                                                .findRecord('vehicle', vehicle.get("id"), { backgroundReload: false })
                                                .then(function (veh) {
                                                    veh.set("userID", null);
                                                    veh.save()
                                                        .catch(function (err) {
                                                            Ember.Logger.error(err);
                                                        });
                                                })
                                                .catch(function (err) {
                                                    Ember.Logger.error(err);
                                                });
                                        }
                                        _this.refresh();
                                    });
                                })
                                .catch(function (err) {
                                    Ember.Logger.error(err);
                                });
                        },
                    })
                    .modal('show');
            }
        }
    }

});
