import Ember from 'ember'
import Base from 'ember-simple-auth-auth0/authenticators/auth0-lock'
import createSessionDataObject from 'ember-simple-auth-auth0/utils/create-session-data-object'
import ENV from '../config/environment'
import {/*isAjaxError,*/ isNotFoundError} from 'ember-ajax/errors'

const Promise = window.Promise

export default Base.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    raven: Ember.inject.service(),

    authenticate (options) {
        var _this = this;
        // The Auth0 service is available because this is a child class of Auth0Lock, in which it is available ... so we can do the workaround for the 2nd attempt issue
        var auth0 = this.get("auth0");

        const lock = auth0.getAuth0LockInstance(options);
        return new Ember.RSVP.Promise( function (resolve, reject) {
            lock.on('unrecoverable_error', reject);
            lock.on('authenticated', (authenticatedData) => {
                if (Ember.isEmpty(authenticatedData)) {
                    return reject(new Error('The authenticated data did not come back from the request'));
                }

                Object.assign(authenticatedData.idTokenPayload, authenticatedData.idTokenPayload['https://titanfarmis.com/appMetadata'])
                authenticatedData.idTokenPayload.email = authenticatedData.idTokenPayload.name

                resolve(createSessionDataObject(authenticatedData.idTokenPayload, authenticatedData));
            });

            lock.show();
        }).then(sessionData => {
            Ember.Logger.log("Here is the profile", sessionData);
            return _this.onAuth(sessionData, options)
        });
    },
    restore (/*data*/) {
        return this._super(...arguments)
        .then(restoredData => {
            return this.onRestore(restoredData)
        })
    },

    onAuth (sessionData/*, options*/) {
        Ember.Logger.debug("On auth:", sessionData)

        this.setRavenUser(sessionData.idTokenPayload);

        let apiHost = ENV.api.host + "/"+ ENV.api.namespace
        let userData = {
            invitationToken: this.get("session.invitationToken")
        }

        return this.get("ajax")
        .request(`${apiHost}/users/me`, {
            headers: {
                Authorization: `Bearer ${sessionData.idToken}`
            },
            dataType: "json",
        })
        .then(data => {
            Ember.Logger.debug("Got user profile:", data)
            if (data.user.enabled) {
                sessionData.profile.details = data.user.details;
                return Promise.resolve(sessionData)
            } else {
                // Reject if the company account is disabled and pass the user data instead of the session so that the failure on the login route could be redirected to the account-disabled route
                return Promise.reject(data);
            }
        })
        .catch(error => {
            if (!isNotFoundError(error)) {
                return Promise.reject(error);
                // throw error
            }

            Ember.Logger.debug("Could not get profile, creating user:", userData)

            return this.get("ajax")
            .post(`${apiHost}/users`, {
                headers: {
                    Authorization: `Bearer ${sessionData.idToken}`
                },
                data: userData,
                dataType: "json",
            })
            .then(data => {
                Ember.Logger.debug("Created user:", data)

                return Promise.resolve(sessionData)
            })
        })
    },
    onRestore (restoredData) {
        Ember.Logger.debug("On restore:", restoredData)

        this.setRavenUser(restoredData.idTokenPayload)

        return Promise.resolve(restoredData)
    },

    setRavenUser (profile) {
        this.get('raven').callRaven('setUserContext', {
            authID: profile.user_id,
            groupID: profile.groupID,
            companyID: profile.companyID,
            email: profile.email
        });
    },
    refreshProfile (authenticatedData, options) {
        Ember.Logger.debug("Refreshing profile:", options)

        return new Promise((resolve, reject) => {
            if (authenticatedData.accessToken == null) {
                return reject(new Error('Missing access token'))
            }

            const lock = this.get('auth0').getAuth0LockInstance(options)

            lock.getUserInfo(authenticatedData.accessToken, (error, profile) => {
                if (error) {
                    return reject(error)
                }

                resolve(createSessionDataObject(profile, authenticatedData))
            })
        })
    }
});
