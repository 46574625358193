import Ember from 'ember'

const { service } = Ember.inject

export default Ember.Component.extend({
	speedLimitValidatonSchemma: service('speed-limit-validtion-schema'),

	didRender() {
		Ember.$('.form.settings.formSettings')
			.form({
				fields: this.get('speedLimitValidatonSchemma.schema.fields'),
				onInvalid() {
					let $firstFieldWithError = Ember.$('.field.error').first()
					let scrollContainer = Ember.$('#trk-content')

					scrollContainer.animate({
						scrollTop: $firstFieldWithError.position().top - 30
					}, 200)

					return false
				}
			})
	}
})
