/**
 * This component is not ment to be reusable with other kind of data.
 * It can be reused in other places exaclty how it is used in homepage.
 * Functions that are not property of component should be grouped in an externall class. As they are now are not testable.
 */
import Ember from 'ember'
import moment from 'moment'

const { service } = Ember.inject
const tenSeconds = 10000
const sevenMinutes = 420000
const closedDoors = 'closed'
const openDoors = 'open'
const directionSeparator = ':'
/**
 * Set this constant to true if you want to use test data.
 */
const asc = "asc"
const desc = "desc"

/**
 * Remove any spaces from license plate as we want to fit in one column td size.
 * Change this function for any changes to licensePlates.
 * @param {string} licensePlate
 * @return {string}
 */
function processLicensePlate(licensePlate) {
	if (!licensePlate) { return null }
	return licensePlate.replace(/ /g, '').trim()
}

/**
 * Creates doors array with translated text.
 * @param {array} doors - array of strings
 * @param {object} translationObject - must have t method
 * @return {object} - doors array
 * 					- doors popup
 * 					- doors status
 */
function processDoors(doors, translationObject) {
	if (doors == null) {
		return {
			array: [],
			popup: translationObject.t('openDoor.na'),
			status: 'na'
		}
	}

	if (Ember.isEmpty(doors)) {
		return {
			array: [],
			popup: translationObject.t('openDoor.allClosed'),
			status: closedDoors
		}
	}

	let translatedDoorsArray = doors.map(door => translationObject.t('openDoor.' + door))

	return {
		array: translatedDoorsArray,
		popup: translatedDoorsArray.join('\n'),
		status: openDoors
	}
}

function processState(missingSignal, state) {
	if (missingSignal) { return 3 }
	if (state === 'moving') { return 2 }
	return 1
}

/**
 * Set text to be displayed. If vehicle is in multiple geofences only first will be displayed and on hover the user can see all.
 * @param {object} geolocation
 * @return {object}
 */
function parseLocation(geolocation) {
	if (!geolocation) { return null }
	if (geolocation.location) {
		return {
			text: geolocation.location,
			popup: geolocation.location
		}
	}
	if (geolocation.geofences) {
		return {
			text: geolocation.geofences[0],
			popup: geolocation.geofences.join('\n')
		}
	}

	return null
}

function parseFuelLevel(status, fuelCapacity) {
	if (!status) { return null }
	if (status.fuelLevel) { return status.fuelLevel }
	if (status.can && status.can.fuel && status.can.fuel.level && status.can.fuel.level[0]) { return status.can.fuel.level[0] / 100 }
	if (fuelCapacity && status.can && status.can.fuel && status.can.fuel.level && status.can.fuel.level[1]) {
		let fuelLevelPercent = status.can.fuel.level[1]
		return Math.trunc((fuelLevelPercent / 100.0) * fuelCapacity)
	}
	return null
}

function missingSignal(updatedAt) {
	if (updatedAt == null) { return true }
	let diff = moment(moment()).diff(moment(updatedAt))
	if (diff > sevenMinutes) {
		return moment.duration(diff).humanize()
	}
	return false
}

function getRandomIntInclusive(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive 
}

export default Ember.Component.extend({
	api: service(),
	i18n: service(),
	liveUpdate: service('live-update'),

	liveData: null,
	limit: 7,
	sortValue: 'licensePlate:asc',
	sortValue2: 'missingSignal:asc',

	unusedImplements: Ember.computed("implements", "unmodifiedData", function () {
		let data = this.get("unmodifiedData")
		if (data) {
			let usedIDs = data.uniqBy("implementID").rejectBy("implementID", null)

			return this.get("implements").reject((implement) => {
				return usedIDs.isAny("implementID", implement.get("id"))
			}).sortBy('name')
		} else {
			return null;
		}
	}),

	/**
	 * Creates data for template from data that arrives from api.
	 * @param {array} data
	 * @return {array}
	 */
	transformData(data) {
		let implementations = this.get("implements")
		if (Ember.isEmpty(data)) { return [] }
		return data.map((item) => {
			let i18n = this.get('i18n')
			let doors = item.status.doors
			let missingSignalValue = missingSignal(item.status.updatedAt)
			let obj = {
				doors: processDoors(doors, i18n),
				engineState: item.status.engineState,
				id: item.id,
				licensePlate: processLicensePlate(item.licensePlate),
				freezer: item.status.freezer,
				temperature: item.status.temperature ? item.status.temperature.toFixed(1) : item.status.temperature,
				fuelLevel: parseFuelLevel(item.status, item.fuelCapacity),
				location: parseLocation(item.geolocation),
				speed: item.status.can.speed ? item.status.can.speed : item.status.speed,
				missingSignal: typeof (missingSignalValue) === 'boolean' && missingSignalValue,
				missingSignalSince: missingSignalValue,
				state: item.state,
				type: item.type,
				group: item.groupID,
				lastPOI: item.status.lastGeofences,
				lastPOITimestamp: item.status.lastGeofencesTimestamp,
				lastPOITitle: item.status.lastGeofences ? `${item.status.lastGeofences} - ${moment(item.status.lastGeofencesTimestamp).format('DD.MM.YYYY HH:mm:ss')}` : '',
				lastPOITitlex: item.status.lastGeofences ? `\n${i18n.t('dashboard.liveStatus.lastPOI')}: ${item.status.lastGeofences} - ${moment(item.status.lastGeofencesTimestamp).format('DD.MM.YYYY HH:mm:ss')}` : '',
				stateTooltip: '',
				stateNumber: processState(missingSignalValue, item.state),
				implement: implementations.findBy('id', item.implementID),
			}

			if (obj.missingSignal) {
				obj.stateTooltip = i18n.t('vehicleStatus.unreachable')
			} else if (obj.missingSignalSince) {
				obj.stateTooltip = i18n.t('vehicleStatus.unreachableSince', { status: obj.missingSignalSince })
			} else if (obj.state === "moving") {
				obj.stateTooltip = i18n.t('vehicle.engineOn')
			} else {
				obj.stateTooltip = i18n.t('vehicle.engineOff')
			}

			return obj
		})
	},

	/**
	 * Get data from api or from testData f of useTestData constant.
	 */
	refreshData() {
		this.get('api')
			.request('/dashboard/home/vehicle-status')
			.then((data) => {
				if (this.isDestroying || this.isDestroyed) { return false }
				this.set('unmodifiedData', data.data)
				this.set('liveData', this.transformData(data.data))
			})
	},

	init() {
		this._super(...arguments)
		this.refreshData()
		Ember.$('.ui.dimmer.modals .confirmationModal').remove()
		/**
		 * Set the timer.
		 */
		let liveUpdate = this.get('liveUpdate')
		liveUpdate.onPollEvent = () => {
			this.refreshData()
		}
		/**
		 * Start the timer.
		 */
		liveUpdate.start(tenSeconds)
	},

	filtredLiveData: Ember.computed("liveData", "searchValue", function () {
		if (!this.get("searchValue")) { return this.get("liveData") }
		let searchValue = this.get("searchValue").toLowerCase();

		return this.get("liveData").filter((item) => {
			let hasLicensePlateAndMatch = item.licensePlate && item.licensePlate.toLowerCase().indexOf(searchValue) > -1
			let hasLocationAndMatch = item.location && item.location.text && item.location.text.toLowerCase().indexOf(searchValue) > -1
			let hasLastPoiAndMatch = item.lastPoi && item.lastPoi.toLowerCase().indexOf(searchValue) > -1
			if (hasLicensePlateAndMatch || hasLocationAndMatch || hasLastPoiAndMatch) {
				return true
			}
			return false
		})
	}),

	/**
	 * Stop the timer if component will be destroyed.
	 */
	willDestroyElement() {
		this.get('liveUpdate').stop()
	},

	/**
	 * Change sort direction.
	 * @param {string} sort by value
	 * @param {string} sort by order
	 * @return {string}
	 */
	changeSortOrder(sortOrder) {
		if ([asc, desc].indexOf(sortOrder) === -1) { throw 'Invalid arguments' }

		if (!sortOrder) {
			return asc
		}

		if (sortOrder && sortOrder === asc) { return desc }
		return asc
	},

	/**
	 * E.g  1) fuelLevel -> fuelLevel
	 * 		2) fuelLevel:asc -> fuelLevel
	 * @param {string} sortBy
	 * @return {string}
	 */
	getSortValue(sortBy) {
		if (!sortBy) { throw 'Invalid arguments' }
		let sortByElements = sortBy.split(directionSeparator)
		if (sortByElements.length > 2) { throw 'Invalid arguments ' }
		return sortByElements.shift()
	},

	/**
	 * E.g  1) fuelLevel -> fuelLevel
	 * 		2) fuelLevel:asc -> asc
	 * @param {string} sortBy
	 * @return {string}
	 */
	getSortDirection(sortBy) {
		if (!sortBy) { throw 'Invalid arguments' }
		let sortByElements = sortBy.split(directionSeparator)
		if (sortByElements.length > 2) { throw 'Invalid arguments ' }
		if (sortByElements.length < 2) { return false }
		let sortOrder = sortByElements.pop()
		return sortOrder
	},

	actions: {
		changeImplement(item, value) {
			this.toast.clear()
			let vehicle = this.store.peekRecord("vehicle", item.id)

			if (vehicle) {
				let oldVehicleImplement = vehicle.get("implementID")
				let selectedImplement = value != null ? value.get("id") : null
				vehicle.set("implementID", selectedImplement)
				vehicle.save()
					.then(() => {
						if (oldVehicleImplement) { // this implement must be included in unusedImplements
							let toAddImplement = this.get("implements").findBy("id", oldVehicleImplement)
							this.get("unusedImplements").addObject(toAddImplement)
						}
						if (selectedImplement) {  //this implement must be removed from unusedImplements
							let toRemoveImplement = this.get("unusedImplements").findBy("id", selectedImplement)
							this.get("unusedImplements").removeObject(toRemoveImplement)
						}
						this.toast.success(this.get('i18n').t('messages.operationWithSuccess'), '', { timeOut: 3000 })
						Ember.set(item, "implement", value)
					})
					.catch(() => {
						this.toast.error(this.get('i18n').t('errors.4'), '', { timeOut: 3000 })
					})
			}
		},
		headerClick(value, value2) {
			if (!value) { return false }

			if (value2) {
				/**
				 * Previous sort text e.g fuelLevel:asc
				 */
				let prevSort = this.get('sortValue2')
				/**
				 * Previous sort text order e.g asc
				 */
				let prevSortOrder = this.getSortDirection(prevSort)
				/**
				 * Previous sort text e.g fuelLevel
				 */
				let prevSortValue = this.getSortValue(prevSort)
				/**
				 * New sort order
				 */
				let sortOrder = desc
				if (prevSortValue === value2) {
					sortOrder = this.changeSortOrder(prevSortOrder)
				}

				this.set('sortValue2', value2.concat(directionSeparator, sortOrder))
			}

			let prevSortValue = this.getSortValue(this.get('sortValue'))
			if (prevSortValue !== value) {
				this.set("sortValue2", this.get('sortValue'))
			}

			let sortOrder = asc
			if (prevSortValue === value) {	//change asc to desc or viceversa
				let prevSort = this.get('sortValue')
				let prevSortOrder = this.getSortDirection(prevSort)
				sortOrder = this.changeSortOrder(prevSortOrder)
			}

			this.set("sortValue", value.concat(directionSeparator, sortOrder))
		}
	}
})
