import Ember from 'ember'
import RSVP from 'rsvp'

export default Ember.Route.extend({
    classNames: ["statusFeed"],

    model() {
        var vehicles = this.store.findAll('vehicle')
        var groups = this.store.findAll('group')
        var implementations = this.store.findAll('implement')

        return RSVP.hash({
            groups,
            vehicles,
            implements: implementations,
        })
    },

    activate() {
        let controller = this.controllerFor('widgets/status-feed')
        controller.resetValues()
    }
})
