import Ember from 'ember';

export default Ember.Component.extend({

    aGroups: [],
    companyID: null,
    i18n: Ember.inject.service(),

    inserVehiclesOnGroups(groups, vehicles) {
        var _mainThis = this;
        /*
            0. order by groupID length to get the level
            1. get parent
            2. insert in parent the parent Vehicles
            3. insert in parent childrens the groupID
        */

        var arrangedGroup = [];

        //new structure of array
        // DONE
        groups.toArray().forEach(function (item) {
            var idSplited = item.id.split('-');
            idSplited.pop();
            var parentID = idSplited.join('-');
            arrangedGroup.push({
                "groupID": item.get('id'),
                "level": item.get('id').split("-").length,
                "parentID": parentID,
                "groupName": item.get('name'),
                "childrens": [],
                "vehicles": []
            });
        });

        //insert Vehicles on each Group
        vehicles.toArray().forEach(function (veh) {
            arrangedGroup.findBy('groupID', veh.get('groupID')).vehicles.push(veh);
        });

        // sortArray ASC
        arrangedGroup.sort(function (a, b) {
            return a.groupID.length - b.groupID.length;
        });

        var arrSubGroups = [];

        arrangedGroup.toArray().forEach(function (grp) {
            var groupLevel = grp.level;

            //get all childs of parent
            function getChilds(group) {
                var childrens = [];

                arrangedGroup.forEach(function (n) {
                    var idSplited = n.groupID.split('-');
                    idSplited.pop();
                    var partId = idSplited.join('-');
                    if (partId === group.groupID) {
                        n.childrens = getChilds(n);
                        childrens.push(n);
                    }
                });
                return childrens;
            }

            //groupLevel == 2: main childrens of companyID
            if (groupLevel === 2) {// get the parents and push on them
                var splitedGroupID = grp.groupID.split("-");
                splitedGroupID.pop();
                var parentiID = splitedGroupID.join('-');
                //get all childrens for this parent
                //find parentID and push in him
                arrSubGroups.findBy('groupID', parentiID).childrens.push({
                    //this will insert parent on each level
                    "groupID": grp.groupID,
                    "parentID": grp.parentID,
                    "level": grp.level,
                    "groupName": grp.groupName,
                    "childrens": getChilds(grp),
                    "vehicles": grp.vehicles
                });

            } else if (groupLevel === 1) {
                // this must be executed first because the arrangedGroup Array is sorted ASC.
                // we have not parent. this is the companyID grp
                arrSubGroups.push({
                    "groupID": grp.groupID,
                    "groupName": grp.groupName,
                    "level": grp.level,
                    "parentID": "",
                    "childrens": [],
                    "vehicles": grp.vehicles
                });
                _mainThis.set("companyID", grp.groupID);
            }
        });

        return arrSubGroups;

    },

    didInsertElement() {
        var _this = this;
        var groups = this.get("groups").toArray();
        var vehicles = this.get("vehicles").toArray();

        var arrangedGroupsVehicles = this.inserVehiclesOnGroups(groups, vehicles);
        this.set("aGroups", arrangedGroupsVehicles);


        Ember.$('.aclDropDown')
            .dropdown({
                useLabels: false,
                action: 'nothing'
            })
            .on('click', '.item', function (e) {
                e.preventDefault();
                var item = Ember.$(e.target);
                var itemType = item.attr("data-type");
                var itemValue = item.attr("data-value");
                var parentID = item.attr("data-parent");
                //if parentID is clear this is a CompanyID
                if (parentID === "") {
                    parentID = itemValue;
                }

                item.toggleClass('active');

                function desParents(parID) {
                    Ember.$("#" + parID).removeClass("active");
                    var idSplited = parID.split('-');
                    idSplited.pop();
                    var partId = idSplited.join('-');

                    if (idSplited.length > 0 && idSplited[0] !== "") {
                        desParents(partId);
                    }
                }

                function selParents(parID) {
                    // get all childs count if all of them are active
                    var allActive = true;
                    Ember.$(".aclDropDown .item[data-parent='" + parID + "']").each(function (index, element) {
                        if (!Ember.$(element).hasClass("active")) {
                            allActive = false;
                        }
                    });
                    //if all childrens active. We will remove each one from its inputHidden and add only the parentGroup
                    if (allActive) {
                        Ember.$("#" + parID).addClass("active");
                    }

                    var idSplited = parID.split('-');
                    idSplited.pop();
                    var partId = idSplited.join('-');

                    if (idSplited.length > 0 && idSplited[0] !== "") {
                        selParents(partId);
                    }
                }


                // addAll Active to input,
                function allCheckedToInput() {

                    var aGroupsObj = [];
                    var aVehiclesObj = [];
                    var aVehicles = [];
                    var aGroups = [];
                    //select all active items ant push it to array.
                    Ember.$(".aclDropDown .item").each(function (index, element) {
                        if (Ember.$(element).hasClass("active")) {
                            if (Ember.$(element).attr("data-type") === "vehicle") {
                                aVehiclesObj.push({
                                    "vehicleID": Ember.$(element).attr("data-value"),
                                    "parentID": Ember.$(element).attr("data-parent")
                                });
                                aVehicles.push(Ember.$(element).attr("data-value"));
                            }
                            else if (Ember.$(element).attr("data-type") === "group") {
                                aGroupsObj.push({
                                    "groupID": Ember.$(element).attr("data-value"),
                                    "parentID": Ember.$(element).attr("data-parent")
                                });
                                aGroups.push(Ember.$(element).attr("data-value"));
                            }
                        }
                    });

                    aGroupsObj.forEach(function (element) {
                        // check if parentGroup is on Groups Array
                        var parentChecked = false;
                        aGroups.forEach(function (item) {
                            if (element.parentID.indexOf(item) > -1) {
                                parentChecked = true;
                            }
                        });
                        // if parentGroup is on Groups, we will not need this group, so we delete it
                        if (parentChecked) {
                            aGroups.splice(aGroups.indexOf(element.groupID), 1);
                        }
                    });

                    aVehiclesObj.forEach(function (element) {
                        // check if parentGroup is on Groups Array
                        var parentChecked = false;
                        aGroups.forEach(function (item) {
                            if (element.parentID.indexOf(item) > -1) {
                                parentChecked = true;
                            }
                        });
                        // if parentGroup is on Groups, we will not need this vehicle, so we delete it
                        if (parentChecked) {
                            aVehicles.splice(aVehicles.indexOf(element.vehicleID), 1);
                        }
                    });

                    if (aGroups.length > 1) {
                        Ember.$('#aclGroups').val(aGroups.join(","));
                    }
                    else {
                        Ember.$('#aclGroups').val(aGroups[0]);
                    }

                    if (aVehicles.length > 1) {
                        Ember.$('#aclVehicles').val(aVehicles.join(","));
                    }
                    else {
                        Ember.$('#aclVehicles').val(aVehicles[0]);
                    }


                    // set dropdown selected Text
                    var text = "", gtext = "",
                        vtext = "";
                    if (aGroups.length === 1) {
                        gtext = aGroups.length + " " + _this.get('i18n').t('aclGroup');
                    }
                    else if (aGroups.length > 1) {
                        gtext = aGroups.length + " " + _this.get('i18n').t('aclGroups');
                    }
                    if (aVehicles.length === 1) {
                        vtext = aVehicles.length + " " + _this.get('i18n').t('aclVehicle');
                    }
                    else if (aVehicles.length > 1) {
                        vtext = aVehicles.length + " " + _this.get('i18n').t('aclVehicles');
                    }

                    if (aGroups.length !== 0 && aVehicles.length !== 0) {
                        text = gtext + ", " + vtext;
                        Ember.$(".aclDropDown").dropdown("set text", text);
                    }
                    else if (aGroups.length !== 0 || aVehicles.length !== 0) {
                        text = gtext + vtext;
                        Ember.$(".aclDropDown").dropdown("set text", text);
                    }
                    else {
                        Ember.$(".aclDropDown").dropdown("restore placeholder text");
                    }
                }

                if (item.hasClass("active") && itemType === "group") {          //selecting group
                    //add active class to all children
                    Ember.$(".aclDropDown .item[data-parent^='" + itemValue + "']").each(function (index, element) {
                        Ember.$(element).addClass("active");
                    });
                    selParents(parentID);
                }

                else if (item.hasClass("active") && itemType === "vehicle") {   //selecting vehicle
                    selParents(parentID);
                }

                else if (!item.hasClass("active") && itemType === "group") {    //deselecting group
                    //remove active class from all children
                    Ember.$(".aclDropDown .item[data-parent^='" + itemValue + "']").each(function (index, element) {
                        Ember.$(element).removeClass("active");
                    });
                    desParents(parentID);
                }

                else if (!item.hasClass("active") && itemType === "vehicle") {  //deselecting vehicle
                    desParents(parentID);
                }

                allCheckedToInput();
                // console.log("GROUPS: " + Ember.$('#aclGroups').val());
                // console.log("VEHICL: " + Ember.$('#aclVehicles').val());
            });


    },
    didRender() {
        var _this = this;
        if (_this.get("preselected")) {
            var user = _this.get("user");
            var aclGroups = user.get("acl.groups");
            var aclVehicles = user.get("acl.vehicles");
            if (aclGroups && aclGroups.length > 0) {
                aclGroups.forEach(function (element) {
                    Ember.$('.aclDropDown .item[data-value="' + element + '"]').trigger("click");
                });
            }
            if (aclVehicles && aclVehicles.length > 0) {
                aclVehicles.forEach(function (element) {
                    Ember.$('.aclDropDown .item[data-value="' + element + '"]').trigger("click");
                });
            }
        }

        if (this.get('selectedVehicles')) {
            if (this.get('isGlobalGeofence')) {
                Ember.$('.aclDropDown').dropdown("restore defaults")
            }
            else {
                this.get('selectedVehicles').forEach((vehicle) => {
                    Ember.$('.aclDropDown .item[data-value="' + vehicle.get('id') + '"]').trigger("click");
                });
            }
        }
    }
});
