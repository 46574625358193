import Ember from 'ember';
import ApplicationRouteMixin from 'ember-simple-auth-auth0/mixins/application-route-mixin';

const { service } = Ember.inject;

export default Ember.Route.extend(ApplicationRouteMixin, {
    i18n: service(),
    moment: service(),
    session: service(),
    api: service(),
    appSettings: service('application-settings'),
    currentUser: service('current-user'),

    setLocale() {
        let defaultLocale = 'ro';
        let user = this.get("currentUser.user");
        let locale = (user && user.details && user.details.locale) ? user.details.locale : defaultLocale

        this.get('moment').setLocale(locale);
        this.set("i18n.locale", locale);
        this.get('appSettings').set('locale', locale);
    },

    afterModel(/*resolvedModel, transition*/) {
        this.setLocale()
    },

    sessionAuthenticated() {
        this._super(...arguments)
        this.setLocale()
    },

    actions: {
        loading(transition, originRoute) {
            Ember.Logger.log('LOADING ', originRoute.get('fullRouteName'), transition)

            transition.promise.finally(function (/*result*/) {
                Ember.Logger.log('LOADING DONE', originRoute.get('fullRouteName'))
            });

            return true
        },
    }
});
