import DS from 'ember-data';

export default DS.Model.extend({
    vehicleID: DS.attr("string"),
    amount: DS.attr("number"),
    timestamp: DS.attr(),
    price: DS.attr("number"),
    groupID: DS.attr("string"),
    location: DS.attr("string"),
    invoice: DS.attr("string"),
    createdAt: DS.attr("string"),
    updatedAt: DS.attr("string"),
});
