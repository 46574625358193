import Ember from 'ember';
import Base from './base';

export default Base.extend({
    i18n: Ember.inject.service(),

    model() {
        return Ember.RSVP.hash({
            tHeaders: [
                { key: 'date', value: this.get('i18n').t('reports.headerFields.date'), align: 'left' },
                { key: 'consumptionCompose', value: this.get('i18n').t('reports.headerFields.consumptionCompose'), align: 'left' },
                { key: 'duration', value: this.get('i18n').t('reports.headerFields.duration'), align: 'left' },
                { key: 'fuelConsumptionPerHour', value: this.get('i18n').t('reports.headerFields.fuelConsumptionPerHour'), align: 'left' },
                { key: 'area', value: this.get('i18n').t('reports.headerFields.areaTotal'), align: 'left' },
                { key: 'areaLoad50', value: this.get('i18n').t('reports.headerFields.areaTotalLoad50'), hoverText: this.get('i18n').t('reports.headerFields.areaTotalLoad50Popup'), align: 'left' },
                { key: 'areaPerHour', value: this.get('i18n').t('reports.headerFields.areaPerHour'), align: 'left' },
                { key: 'canFuelLevelEndOfDay', value: this.get('i18n').t('reports.headerFields.canFuelLevelEndOfDay'), align: 'left' },
                { key: 'goToMap', value: this.get('i18n').t('reports.headerFields.map') }
            ],
            tData: []
        });
    }
});
