import Ember from 'ember'

const { service } = Ember.inject

export default Ember.Component.extend({
	i18n: service(),

	minutes: null,
	selected: null,
	step: 1,

	getMinutes(step = 1) {
		let minutes = []
		for (let i = 0; i < 60; i = i + step) {
			minutes.push(i)
		}
		return minutes
	},

	init() {
		this._super(...arguments)
		this.set('minutes', this.getMinutes(this.get('step')))
	}
})
