import Ember from 'ember'
import moment from 'moment'
import { mouse as d3mouse, select, selectAll } from 'd3-selection'
import { scaleQuantile } from 'd3-scale'
import { transition } from 'd3-transition'
import { max as d3max } from 'd3-array'
import { translationMacro as t } from 'ember-i18n'

const minutes59 = 3540000
const { service } = Ember.inject

export default Ember.Component.extend({

	i18n: service(),
	dashboard: service('dashboard'),

	startHour: null,
	endHour: null,

	isMobile: Ember.computed('resolution.isMobile', 'resolution.isTablet', function () {
		return (this.get('resolution.isMobile') || this.get('resolution.isTablet')) ? true : false
	}),
	heatMapData: [],
	heatMapMargin: { top: 25, right: 10, bottom: 10, left: 10 },
	yAxisTextWidth: Ember.computed('resolution', function () {
		return this.get('resolution.isMobile') ? 90 : 105
	}),
	mHourStep: Ember.computed('resolution', function () {
		if (this.get('resolution.isMobile')) {
			return 3
		} else if (this.get('resolution.isTablet')) {
			return 2
		} else return 1
	}),
	hourTotal: Ember.computed('resolution', 'mHourStep', function () { // nr of hours shown in desktop mode - x Axis
		return (this.get('resolution.isMobile') || this.get('resolution.isTablet')) ? Math.floor(24 / this.get('mHourStep')) : 24
	}),
	gridSizeY: 20,
	distanceTxt: t("dashboard.area"),
	noData: false,
	shownDefaultNr: 13,
	shownSmallNr: 11,
	/**
	 * Show / hide show more link
	 */
	showMore: false,
	/**
	 * Show / hide show less link
	 */
	showLess: false,
	showChkHeight: 50,
	showDriverNames: false,
	padding: 15,

	getVehicleIDByLicensePlate(licPlate) {
		let vehicle = this.get("model.vehicles").findBy("licensePlate", licPlate)
		if (!vehicle) { return null }
		if (!vehicle.get('id')) { return null }
		return vehicle.get('id')
	},

	loadData: function () {
		var vehicles = this.get('model.vehicles').toArray()
		var dashboardData = this.get('model.dashboard')
		var isMobile = this.get('isMobile')
		var mHStep = this.get('mHourStep')

		if (!dashboardData) { return [] }

		var hours = [],
			vehicleNrs = [],
			driverNames = [],
			row = 0,
			column = this.get('hourTotal')
		/**
		 * So, what is this?
		 * 1) Let's assume that arrayLength is 20.
		 * 2) This instuction creates an array with 20 elements each of them having undefined as a value.
		 * 3) However, It is strange how arrayLength is set after it is used.
		 * 4) This means that arrayLength is undefined as it is hoisted.
		 * 5) This results however in Array.apply(null, Array(undefined)).
		 * 6) In the end we have this beautiful array [undefined].
		 */
		var arr = Array.apply(null, Array(arrayLength)),
			arrayLength = vehicles.length * column

		if (Object.keys(dashboardData).length > 0) {
			Object.keys(dashboardData).forEach(function (hour, hindex) {
				let formattedHour = moment(parseInt(hour)).get('hour')
				formattedHour = (formattedHour === 0 ? 24 : formattedHour)

				if ((isMobile) && (hindex % mHStep === 0)) {
					hours.push(formattedHour)
				} else if (!isMobile) {
					hours.push(formattedHour)
				}
				Object.keys(dashboardData[hour]).forEach(vehicleID => {
					let vehicle = vehicles.findBy('id', vehicleID)
					/**
					 * If somehow the vehicle is not present return from function and go to next item.
					 */
					if (!vehicle) { return false }

					let licensePlate = vehicle.get('licensePlate')

					if (vehicleNrs.indexOf(licensePlate) === -1) {
						vehicleNrs.push(licensePlate)
					}

					let driverName = (vehicle !== undefined && vehicle.get("user.firstName") !== undefined) ? vehicle.get("user.fullName") : licensePlate
					if (driverNames.indexOf(driverName) === -1) {
						driverNames.push(driverName)
					}

					if (isMobile) {
						let index = Math.floor(hindex / mHStep)

						if (hours.indexOf(formattedHour) < 0) {
							arr[row * column + index].distance = (parseFloat(arr[row * column + index].distance) + (parseFloat(dashboardData[hour][vehicleID]) / 10000)).toFixed(1)
						} else {
							arr[row * column + index] = {
								vehicleNr: licensePlate,
								hour: formattedHour,
								distance: parseFloat(parseFloat(dashboardData[hour][vehicleID]).toFixed(3) / 10000).toFixed(1)
							}
						}
					} else {
						arr[row * column + hindex] = {
							vehicleNr: licensePlate,
							hour: formattedHour,
							distance: parseFloat(parseFloat(dashboardData[hour][vehicleID]).toFixed(3) / 10000).toFixed(1)
						}
					}
					row++
				})
				row = 0
			})
			this.set('heatMapData', arr)
			this.set('hours', hours)
			this.set('vehicleNrs', vehicleNrs)
			this.set('drivers', driverNames)
		}
	},

	/* Build heatmap */
	buildHeatMap: function (vehicleNrCount) {
		var txtClass = this.get('resolution.isMobile') ? " isMobile" : "" + this.get('resolution.isTablet') ? " isTablet" : ""
		var _this = this
		var startHour = this.get('startHour')
		var endHour = this.get('endHour')


		var hours = this.get('hours'),
			vehicleNrs = this.get("showDriverNames") ? this.get("drivers") : this.get("vehicleNrs"),
			heatMapData = this.get('heatMapData'),
			margin = this.get('heatMapMargin'),
			gridSizeY = this.get("gridSizeY"),
			width = parseInt(select('#heatmapContainer').style('width'), 10),
			height = Math.round(gridSizeY * (vehicleNrs.length > vehicleNrCount ? vehicleNrCount : vehicleNrs.length) + gridSizeY / 1.5 + margin.top + margin.bottom),
			buckets = 9,
			colors = ['#ffd8d8', '#ffcbc9', '#ffbdbb', '#ffb0ac', '#fda19e', '#fc9490', '#f98582', '#f67774', '#f36767'],
			nrTotalHours = this.get('hourTotal'),
			nrLbWidth = this.get('yAxisTextWidth')

		if (heatMapData && heatMapData.length > 0) {

			heatMapData = heatMapData.slice(0, vehicleNrCount * nrTotalHours)

			if ((vehicleNrs.length > vehicleNrCount) && (!this.get("showMore"))) {
				this.set("showMore", true)
			}

			// Set parent height
			// let headerHeight = this.$().parentsUntil(".heatmap").parent(".card").find(".card-title").height() + this.get("padding")
			// let parentHeight = this.$().parentsUntil(".heatmap").parent(".mItem").height()
			// if (parentHeight < headerHeight + height + this.get("showChkHeight")) {
			//     this.$().parentsUntil(".heatmap").parent(".mItem").height(height + headerHeight + this.get("padding") + this.get("showChkHeight"))
			// }

			var gridSizeX = Math.floor(((width * 98 / 100) - margin.left - margin.right - nrLbWidth) / nrTotalHours)
			//legendElementWidth = gridSizeX*2,
			var innerXPos = (width - gridSizeX * nrTotalHours - nrLbWidth) / 2

			var svg = select("#heatmapContainer").append("svg")
				.attr("height", height - this.get("padding"))
				.attr("class", "content")
				.append("svg")
				.attr("x", innerXPos)
				.attr("class", "inner")
				.append("g")
				.attr("transform", "translate(" + nrLbWidth + "," + margin.top + ")")

			// nrLabels
			svg.selectAll(".nrLabel")
				.data(vehicleNrs.slice(0, vehicleNrCount))
				.enter()
				.append("a")
				.attr("class", "heatmap-label")
				.attr("xlink:href", (d) => "map/routes/" + _this.getVehicleIDByLicensePlate(d) + "?end=" + endHour + "&start=" + startHour)
				.append("text")
				.text((d) => d)
				.attr("x", -3)
				.attr("y", function (d, i) { return i * gridSizeY })
				.style("text-anchor", "end")
				.attr("transform", "translate(-6," + gridSizeY / 1.5 + ")")
				.attr("class", function () {
					return "nrLabel mono axis" + txtClass
				})

			// timeLabels
			svg.selectAll(".timeLabel")
				.data(hours)
				.enter().append("text")
				.text((d) => d)
				.attr("x", function (d, i) { return i * gridSizeX })
				.attr("y", 0)
				.style("text-anchor", "middle")
				.attr("transform", "translate(" + gridSizeX / 2 + ", -6)")
				.attr("class", function (d) {
					return ((d >= 8 && d <= 16) ? "timeLabel mono axis axis-worktime" : "timeLabel mono axis") + txtClass
				})

			var colorScale = scaleQuantile()
				.domain([0, buckets - 1, d3max(heatMapData, (d) => d.distance)])
				.range(colors)
			var nr = "",
				ypoz = -1
			var cards = svg.selectAll(".hour")
				.data(heatMapData, (d) => d.vehicleNr + ':' + d.hour)
				.enter().append("rect")
				.attr("x", (d, i) => (i >= nrTotalHours) ? (i % nrTotalHours) * gridSizeX : i * gridSizeX)
				.attr("y", function (d) {
					if (nr !== d.vehicleNr) {
						nr = d.vehicleNr
						ypoz++
					}
					return ypoz * gridSizeY
				})
				.attr("class", "hour")
				.attr("width", gridSizeX - 1)
				.attr("height", gridSizeY - 1)
				.style("fill", colors[0])
				.on("mouseover", function ( d, index ) {
					var rectBox = this.getBBox()
					var parentBox = this.ownerSVGElement.getBBox()
					var positionInGrid = index % nrTotalHours;
					var boxWidth = rectBox.width + 1;

					// Because of the variable widths of the labels on the left side we need to take the right border for reference.
					var xPosition = parentBox.x + parentBox.width - boxWidth * nrTotalHours + boxWidth * positionInGrid;
					var yPosition = rectBox.y + 2;

					tooltip.html(_this.get("distanceTxt") + ": " + d.distance + " ha")
						.style("left", (xPosition) + "px")
						.style("top", (yPosition) + "px")
						.style("display", "block")
				})
				.on("mouseout", function () {
					tooltip.style("display", "none")
				})
				.on("click", function () {
					tooltip.style("display", "none")

				})
			cards.transition()
				.duration(1000)
				.style("fill", (d) => colorScale(d.distance)) // (d) => (d.distance === 0 ? "#BBB" : colorScale(d.distance)))

			cards.exit().remove()

            /*var legend = svg.selectAll(".legend")
                .data([0].concat(colorScale.quantiles()), function(d) { return d })
            legend.enter().append("g")
                .attr("class", "legend")
            legend.append("rect")
            .attr("x", function(d, i) { return legendElementWidth * i })
            .attr("y", height)
            .attr("width", legendElementWidth)
            .attr("height", gridSizeX / 2)
            .style("fill", function(d, i) { return colors[i] })
            legend.append("text")
            .attr("class", "mono")
            .text(function(d) { return "≥ " + Math.round(d) })
            .attr("x", function(d, i) { return legendElementWidth * i })
            .attr("y", height + gridSizeY)
            legend.exit().remove()*/

			// Prep the tooltip, initial display is hidden
			var tooltip = select("#heatmapContainer")
				.append("div")
				.attr("class", "toolTip")
				.style("display", "none")

			// Set setting icon right position
			let r = width - parseInt(select(".inner").node().getBoundingClientRect().width, 10) - innerXPos - margin.right - 30
			Ember.$(".heatmap-settings").css({ 'right': r + 'px' })
		} else {
			// No data
			this.set("noData", true)
			this.$("").height(height)
		}
	},

	changeYLabel: function (lbData) {
		var _this = this

		if (lbData !== undefined) {
			selectAll("#heatmapContainer .nrLabel")
				.data(lbData.slice(0, lbData.length))
				.text((d) => d)
				.each(function () {
					let maxLbWidth = _this.get("yAxisTextWidth")
					let self = select(this),
						textLength = self.node().getComputedTextLength(),
						text = self.text()

					if (textLength > (maxLbWidth - 9) && text.length > 0) {
						text = text.split(" ")
						self.text(text[0])
						textLength = self.node().getComputedTextLength()
					}
				})
		}
	},

	_doResize: function () {
		var svg = select("#heatmapContainer")

		if (!svg.empty()) {
			let mHourStep = 1 // -->
			if (this.get('resolution.isTablet')) {
				mHourStep = 2
			} else if (this.get('resolution.isMobile')) {
				mHourStep = 3
			}

			let margin = { top: 25, right: 0, bottom: 10, left: 0 } // --> margin = this.get("heatMapMargin"),
			if (this.get('resolution.isMobile') || this.get('resolution.isTablet')) {
				margin = { top: 25, right: 30, bottom: 10, left: 30 }
			}

			let width = parseInt(select('#heatmapContainer').style('width'), 10),
				nrTotalHours = (this.get('resolution.isMobile') || this.get('resolution.isTablet')) ? Math.floor(24 / mHourStep) : 24, //this.get('hourTotal'),
				nrLbWidth = this.get('yAxisTextWidth'),
				gridSizeX = Math.floor(((width * 98 / 100) - margin.left - margin.right - nrLbWidth) / nrTotalHours),
				gridSizeY = this.get("gridSizeY"),
				innerXPos = (width - gridSizeX * nrTotalHours - nrLbWidth) / 2,
				nr = "",
				ypoz = -1,
				vehicleNrCount = svg.selectAll("rect").size() / nrTotalHours,
				heatmapHeight = Math.round(gridSizeY * vehicleNrCount + gridSizeY / 1.5 + margin.top + margin.bottom)

			// Set parent height
			let headerHeight = Ember.$('#heatmapContainer').parentsUntil(".heatmap").parent(".card").find(".card-title").height()
			Ember.$('#heatmapContainer').parentsUntil(".heatmap").parent(".mItem").height(heatmapHeight + headerHeight + this.get("padding") + this.get("showChkHeight"))

			svg.select("svg.inner")
				.attr("x", innerXPos)

			svg.selectAll("rect")
				.attr("x", (d, i) => (i >= nrTotalHours) ? (i % nrTotalHours) * gridSizeX : i * gridSizeX)
				.attr("y", function (d) {
					if (nr !== d.vehicleNr) {
						nr = d.vehicleNr
						ypoz++
					}
					return ypoz * gridSizeY
				})
				.attr("width", gridSizeX - 1)

			// timeLabels
			svg.selectAll(".timeLabel")
				.attr("x", function (d, i) { return i * gridSizeX })
				.attr("transform", "translate(" + gridSizeX / 2 + ", -6)")
		}
	},

	init() {
		this._super(...arguments)

		this.set('vehicleNrs', [])

		var isSubscribed = this.get('resizeService').has('debouncedDidResize')
		let modelDashboard = this.get('model.dashboard')
		/**
		 * 0 means hour 00:00
		 * 23 means 23:59
		 */
		if (modelDashboard) {
			let keys = Object.keys(modelDashboard)
			if (keys[0] && keys[23]) {
				this.set("startHour", keys[0]);
				let endHour = Number(keys[23]) + minutes59
				this.set("endHour", endHour);
			}
		}

		if (!isSubscribed) {
			var self = this

			this.get('resizeService').on('debouncedDidResize', () => {
				self._doResize()
			})
		}
	},

	didInsertElement() {
		Ember.run.scheduleOnce('afterRender', this, this.afterRenderEvent)
	},

	afterRenderEvent: function () {
		// Load the data
		this.loadData()

		let defaultNr = this.get("shownDefaultNr")
		let smallNr = this.get("shownSmallNr")
		let vehiclesLength = this.get('vehicleNrs').length
		let showNr = vehiclesLength > defaultNr ? smallNr : vehiclesLength

		this.buildHeatMap(showNr)
	},

	removeHeatmapContainerElements() {
		select("#heatmapContainer").selectAll("*").remove()
	},

	setHeights(vehicleNrCount) {
		vehicleNrCount = vehicleNrCount || this.get('vehicleNrs').length
		let margin = this.get('heatMapMargin')
		let gridSizeY = this.get("gridSizeY")
		let heatmapHeight = Math.round(gridSizeY * vehicleNrCount + gridSizeY / 1.5 + margin.top + margin.bottom)
		// Set parent height
		let headerHeight = this.$().parentsUntil(".heatmap").parent(".card").find(".card-title").height()
		this.$().parentsUntil(".heatmap").parent(".mItem").height(heatmapHeight + headerHeight + this.get("padding") + this.get("showChkHeight"))

		// Reset card-title height
		this.$().parentsUntil(".heatmap").parent(".card").find(".card-title").height(headerHeight)
	},

	actions: {
		fadeToggle() {
			Ember.$('.heatmap-settings-container').fadeToggle("fast")
		},

		toggleYLabel() {
			this.toggleProperty('showDriverNames')
			this.changeYLabel(this.get("showDriverNames") ? this.get("drivers") : this.get("vehicleNrs"))
			this.send("fadeToggle")
		},

		showLess() {
			let defaultNr = this.get("shownDefaultNr")
			let smallNr = this.get("shownSmallNr")
			let vehiclesLength = this.get('vehicleNrs').length
			let showNr = vehiclesLength > defaultNr ? smallNr : vehiclesLength

			this.removeHeatmapContainerElements()
			this.setHeights(showNr)
			this.buildHeatMap(showNr)
			this.get('dashboard').showMore()

			this.$().parents('.card-content').removeClass('show-more')
			this.set('showMore', true)
			this.set('showLess', false)
		},

		showMore() {
			let vehicleNrCount = this.get('vehicleNrs').length
			this.removeHeatmapContainerElements()
			this.setHeights(vehicleNrCount)
			this.buildHeatMap(vehicleNrCount)
			Ember.$("a.more").remove()

			this.get('dashboard').showMore()

			this.$().parents('.card-content').addClass('show-more')
			this.set('showMore', false)
			this.set('showLess', true)
		}
	}
})
