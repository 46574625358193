import Ember from 'ember'
import RSVP from 'rsvp'

export default Ember.Route.extend({
	model() {
		return RSVP.hash({
			vehicles: this.store.peekAll("vehicle"),
			groups: this.store.peekAll("group"),
			geofences: this.store.peekAll("geofence"),
			geofence: this.store.createRecord('geofence', {
				groupID: null,
				name: null,
				color: "#FFDC36",
				global: false,
				type: "poi",
				boundary: 500,
				settings: {
					event_trigger: {
						stationary: 5
					},
					event_type: {
						enter: "disabled",
						exit: "disabled",
						stationary: "disabled",
					}
				},
				vehicles: []
			})
		})
	},
	resetController(controller, isExiting) {
		if (isExiting) {
			// isExiting would be false if only the route's model was changing
			controller.setProperties({
				lat: null,
				lng: null
			});
		}
	}
})
