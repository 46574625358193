import Ember from 'ember';
import config from '../../config/environment';

const { inject: { service } } = Ember;

export default Ember.Component.extend({
    currentUser: service('current-user'),
    session: service('session'),
    resolution: service('resolution'),
    router: Ember.inject.service("-routing"),
    rootURL: config.rootURL,
    sidebar: service('sidebar'),
    sticky: service('sticky-table'),

    init: function () {
        this._super(...arguments);
        this.setSidebar();

        Ember.Logger.debug('currentUser', this.get('currentUser.user'));
        Ember.Logger.debug("HandlerInfo", this.get("history"));
    },

    /** Sticky params */
    defautlStickyOptions: {
        scrollContainer: function ($table) {
            return $table.parents('.report-tables');
        }
    },

    fixSticky() {
        if (this.get('resolution').isMobile) { return false; }

        this.get('sticky').destroy();
        if (!this.get('resolution').isMobileResolutions()) {
            Ember.$('.content table').css('width', '100%');
            Ember.$('.content .header').css('width', 'auto');
        } else {
            Ember.$('.content table').css({
                'min-width': 'auto',
                width: 'auto'
            });
        }

        this.get('sticky').correctTablesWidth(Ember.$('.content table'));
        this.get('sticky')
            .sticky(Ember.$('.content table').first(), this.get('defautlStickyOptions'));

        Ember.$('.content .header').css('width', Ember.$('.content table').first().width() + 'px');
    },

    didInsertElement() {
        this.$('.ui.inline.dropdown').dropdown();
        /**
         * All side-menu should have id ending in '-menu' if we want to use hamberuger icon.
         */
        this.get('sidebar').initMenu();
    },

    // TODO: chech if this is not controlled in sidebar service (we have the resolution service injected there)
    setSidebar() {
        if (!this.get('resolution').isMobile) {
            this.get('sidebar').hide();
        } else {
            if (this.get('sidebar').getMenu() !== null) {
                this.get('sidebar').getMenu().removeClass('icon-only');
            }
        }
    },

    toggleMenu() {
        if (this.get('sidebar').isActive()) {
            this.get('sidebar').hide(this.fixSticky, this);
        } else {
            this.get('sidebar').show(this.fixSticky, this)
        }
    },

    actions: {
        logout() {
            this.get('session').invalidate();
        },

        hamburgerClick() {
            this.toggleMenu();
        },

        back() {
            var routeName = Ember.getOwner(this).lookup('controller:application').currentRouteName;
            if (Ember.isEmpty(routeName)) {
                return;
            }
            var previousRoute = "";
            var splittedRouteNames = routeName.split('.');
            var filteredRoutes = splittedRouteNames.filter(function (name) {
                return name !== 'index';
            });
            if (filteredRoutes.length == 1) {
                //hack: from mapdetails should redirect to map
                if (filteredRoutes[0] == "mapdetails") {
                    this.get("router").transitionTo("map");
                    return;
                }
                this.get("router").transitionTo("index");
                return;
            }
            filteredRoutes.forEach(function (routeName, ind) {
                var isLastPart = ind == filteredRoutes.length - 1;
                if (isLastPart) {
                    return;
                }
                previousRoute += ((ind == 0) ? "" : ".") + routeName;
            });
            this.get("router").transitionTo(previousRoute);
        }
    }
});
