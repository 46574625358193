import Ember from 'ember'
import { select, selectAll } from 'd3-selection'
import { scaleLinear, scaleTime } from 'd3-scale'
import { line as d3line } from 'd3-shape'
import { extent, max as d3max } from 'd3-array'
import { axisBottom, axisRight } from 'd3-axis'

export default Ember.Component.extend({
    dataDomain: [],
    header: "",
    unit: "",
    class: "",
    xParam: "",
    yParam: "",

    didRender() {
        var data = this.get('dataDomain')
        var header = this.get("header")
        var unit = this.get("unit")
        var chartContainerName = '.' + this.get('class') + ' .svg-container.chart'
        var initWidth = Ember.$(chartContainerName).width()
        var initHeight = 200
        var xParam = this.get("xParam")
        var yParam = this.get("yParam")
		/**
		 * Remove existing svg component.
		 */
        select(chartContainerName + ' svg').remove()

        if (Ember.isEmpty(data)) {
            return
        }

        var svg = select(chartContainerName)
            .append("svg")
            .attr("preserveAspectRatio", "xMinYMin meet")
            .attr("viewBox", "0 0 " + initWidth + " " + initHeight)
            .attr("width", initWidth)
            .attr("height", initHeight)
            .classed("svg-content", true),
            margin = {
                right: 20,
                left: 20,
                bottom: 5,
                top: 20
            },
            width = +svg.attr("width") - margin.left - margin.right,
            height = +svg.attr("height") - margin.top - margin.bottom,
            g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")")

        var x = scaleTime().rangeRound([0, width]).clamp(true)
        var y = scaleLinear().rangeRound([height, 0]).clamp(true)

        var maxYDataValue = Math.max.apply(Math, data.map(function (d) {
            return d[yParam]
        }))

        var line = d3line()
            .x(function (d) {
                return x(d[xParam])
            })
            .y(function (d) {
                return y(d[yParam])
            })

        data.forEach(function (d) {
            d[xParam] = d[xParam]
            d[yParam] = +d[yParam]
        })

        x.domain(extent(data, function (d) {
            return d[xParam]
        }))
        y.domain(extent(data, function (d) {
            return d[yParam]
        }))

        // add left axis, max value
        g.append("g")
            .attr("class", "chart-y")
            .call(axisRight(y).tickFormat(""))
            .append("text")
            .attr("fill", "#BDBFC1")
            .attr("x", 10)
            .attr("y", y(maxYDataValue) - 10)
            .attr("dy", 3)
            .text(maxYDataValue)

        // add bottom axis without values and ticks
        g.append("g")
            .attr("transform", "translate(0," + height + ")")
            .call(axisBottom(x).tickSizeOuter(0))
            .selectAll(".tick")
            .remove()

        //add line for graph
        g.append("path")
            .datum(data)
            .attr("class", "line-graph")
            .attr("fill", "none") //"#727376")
            .attr("stroke", "#FFDC36")
            .attr("stroke-linejoin", "round")
            .attr("stroke-linecap", "round")
            .attr("stroke-width", 1.5)
            .attr("d", line)

        var maxYDomainValue = d3max(y.domain())
        var maxXDomainValue = d3max(x.domain())
        if (maxYDomainValue > height) {
            height = maxYDomainValue
        }
        // add chart header
        g.append("text")
            .attr("class", "chart-header")
            .attr("fill", "#BDBFC1")
            .attr("x", x(maxXDomainValue))
            .attr("y", y(height))
            .attr("dy", 3)
            .attr("dy", "15")
            .attr("text-anchor", "end")
            .text(header)
        g.append("text")
            .attr("class", "chart-header-unit")
            .attr("fill", "#BDBFC1")
            .attr("x", x(maxXDomainValue))
            .attr("y", y(height))
            .attr("dy", 3)
            .attr("dy", "25")
            .attr("text-anchor", "end")
            .text(unit)
    }
})
