import Ember from 'ember'
const { service } = Ember.inject

export default Ember.Component.extend({
    i18n:       service(),
    message:    Ember.computed( "error", "namespace", function ( ) {
        let namespace   = this.get("namespace")
        let error       = this.get("error")

        let path = "errors.messages.validation." + ( namespace ? (namespace + ".") : "") + error

        return this.get("i18n").t( path )
    } )
});
