import Ember from 'ember';
import Base from './base';

export default Base.extend({
    i18n: Ember.inject.service(),

    model() {
        return Ember.RSVP.hash({
            tHeaders: [
                { key: 'licensePlate', value: this.get('i18n').t('reports.headerFields.licensePlate'), align: 'left' },
                { key: 'driver', value: this.get('i18n').t('reports.headerFields.driver'), align: 'left' },
                // { key: 'distance', value: this.get('i18n').t('reports.headerFields.distance'), align: 'left' },
                { key: 'distanceCompose', value: this.get('i18n').t('reports.headerFields.distanceCompose'), align: 'left' },
				{ key: 'duration', value: this.get('i18n').t('reports.headerFields.duration'), align: 'left' },
				{ key: 'consumptionCompose', value: this.get('i18n').t('reports.headerFields.consumptionCompose'), align: 'left' },
                // { key: 'fuelConsumption', value: this.get('i18n').t('reports.headerFields.fuelConsumption'), align: 'left' },
                { key: 'fuelConsumptionDistance', value: this.get('i18n').t('reports.headerFields.fuelConsumptionDistance'), align: 'left' },
                // { key: 'canDistance', value: this.get('i18n').t('reports.headerFields.canDistance'), align: 'left' },
                // { key: 'canFuelConsumption', value: this.get('i18n').t('reports.headerFields.canFuelConsumption'), align: 'left' },
                { key: 'canFuelConsumptionDistance', value: this.get('i18n').t('reports.headerFields.canFuelConsumptionDistance'), align: 'left' }
            ],
            tData: []
        });
    }
});
