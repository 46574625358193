import Ember from 'ember';

export default Ember.Controller.extend({
    actions: {
        searchGroup(value) {
            if (value === '') {
                Ember.$('.ui.segment').removeClass('hidden');
            }
            
            Ember.$('.ui.segment').each(function (idx, item) {
                if (Ember.$(item).find('span').text().toLowerCase().indexOf(value.toLowerCase()) > - 1) {
                    Ember.$(item).removeClass('hidden');
                    Ember.$(item).parent().parent().show();
                } else {
                    Ember.$(item).addClass('hidden');
                }
            });
        }
    }
});
