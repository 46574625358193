import Ember from 'ember'

export default Ember.Service.extend({
	schema: {
		on: 'blur',
		inline: true,
		fields: {
			engineLoadLimit:{
				identifier: 'engineLoadLimit',
				rules: [
					{
						type: 'gte[0]',
						prompt: "Sarcina motor % admisa minima este 10."
					},
					{
						type: 'integer',
						prompt: "Sarcina motor % trebuie sa fie un nr. intreg."
					},
					{
						type: 'lte[100]',
						prompt: "Sarcina motor % admisa maxima este 90."
					}
				]
			},
			groupID: {
				identifier: "groupID",
				rules: [{
					type: "empty",
					prompt: "Divizia este un camp obligatoriu."
				}]
			},
			make: {
				identifier: "make",
				rules: [{
					type: "empty",
					prompt: "Marca vehicolului este un camp obligatoriu."
				}]
			},
			licensePlate: {
				identifier: "licensePlate",
				rules: [{
					type: "empty",
					prompt: "Numarul de inmatriculare este un camp obligatoriu."
				}]
			},
			type: {
				identifier: "type",
				rules: [{
					type: "empty",
					prompt: "Tipul vehiculului este un camp obligatoriu."
				}]
			},
			offset: {
				identifier: 'offset',
				rules: [
					{
						type: 'gte[-50]',
						prompt: "Incalcarea admisa minima este -50."
					},
					{
						type: 'lte[50]',
						prompt: "Incalcarea admisa maxima este 50."
					}
				]
			},

			fuel_consumption_distanceWise: {
				identifier: "fuel_consumption_distanceWise",
				rules: [{
					type: "regExp",
					value: /^(?:\d+\.\d+|\d+|)$/g, // No commas allowed | Can't start with "." | Can be empty
					prompt: "Folositi \".\" pentru a separa zecimalele."
				}]
			},
			fuel_consumption_timeWise: {
				identifier: "fuel_consumption_timeWise",
				rules: [{
					type: "regExp",
					value: /^(?:\d+\.\d+|\d+|)$/g, // No commas allowed | // Can't start with "."
					prompt: "Folositi \".\" pentru a separa zecimalele."
				}]
			},
			geofenceStationaryDuration: {
				identifier: 'geofenceStationaryDuration',
				rules: [
					{
						type: 'gte[5]',
						prompt: "Acceptate doar numere mai mari sau egale cu 5"
					},
					{
						type: 'isMultipleOf[5]',
						prompt: "Se accepta doar multipli de 5"
					},
					{
						type: 'lte[120]',
						prompt: "Acceptate doar numere mai mici sau egale cu 120"
					}
				]
			},
			fuelConsumptionLimitValue: {
				identifier: 'fuelConsumptionLimitValue',
				rules: [
					{
						type: 'gte[1]',
						prompt: "Acceptate doar numere mai mari ca 0"
					},
				]
			},
			rpmLimit: {
				identifier: 'rpmLimit',
				rules: [
					{
						type: 'gte[1]',
						prompt: "Acceptate doar numere mai mari ca 0"
					},
				]
			},

			minSpeed: {
				rules: [
					{
						type: 'gte[0]',
						prompt: 'Valoarea minima pentru "Viteza minima" este 0.'
					},
					{
						type: 'lte[300]',
						prompt: 'Values less then 300 are accepted only'
					}
				]
			},

			maxSpeed: {
				rules: [
					{
						type: 'gte[0]',
						prompt: 'Values greater then 20 are accepted only.'
					},
					{
						type: 'lte[300]',
						prompt: 'Values less then 200 are accepted only'
					}
				]
			}
		}
	}
})
