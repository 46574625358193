import Ember from 'ember'
import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin'
// import ENV from '../config/environment'

export default Ember.Route.extend(UnauthenticatedRouteMixin, {
    session: Ember.inject.service(),

    lockOptions: {
        autoclose:          true,
        closable:           true,
        allowSignup:        false,
        rememberLastLogin:  false,
        avatar:             null,
        auth: {
            redirect: false,
            params:{
                scope: 'openid profile'
            }
        },
        configurationBaseUrl: 'https://cdn.eu.auth0.com'
    },

    showLock () {
        var _this = this;
        Ember.Logger.debug("Invitation token:", this.get("session.invitationToken"))

        this.get('session')
        .authenticate('authenticator:tracknamic', this.get("lockOptions"))
        .catch(error => {
            if (error.user != null && error.user.enabled === false) {
                Ember.Logger.error("Login failed! Account is disabled");
                _this.transitionTo("account-disabled");
            } else {
                Ember.Logger.error("Login failed !", error)
                // _this.transitionTo("login");
                // window.location.replace(ENV['ember-simple-auth'].baseURL)
            }
		})
    },

    activate () {
        this.showLock();
    }
})
