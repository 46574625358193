import Alerts from './alerts'
import Errors from './errors'
import Management from './management'
import Messages from './messages'
import Reports from './reports'
import Markers from './markers'

export default {
	pagination: {
		first: 'First page',
		last: 'Last page',
		next: 'Next page',
		previous: 'Previous page',
		of: 'of',
		resultsPerPage: 'Results per page',
		goToPage: 'Go to page',
		pageOfTotal: 'Page {{page}} of {{total}}',
	},
	pleaseWait: 'Please wait',
	processingFileData: 'Processing file',
	expand: 'Expand',
	menu: {
		dispatch: {
			index: 'Dispatch',
			routes: {
				add: 'Add route'
			}
		}
	},
	loading: {
		text: 'Loading'
	},
	acl: 'Access control',
	alerts: {
		messages: Alerts
	},
	alertsAndNotifications: 'Alerts and Notifications',
	openDoorsStatus: "Doors status",
	doorsStatus: {
		open: "Open doors",
		closed: "Closed doors",
	},
	closed: "Closed",
	open: "Open",
	openDoor: {
		'front-left': "Front right door is open",
		'front-right': "Front left door is open",
		'back-left': "Back right door is open",
		'back-right': "Back left door is open",
		'trunk': "Back door is open",
		'allClosed': "All the doors are closed",
		'na': 'No sensors',
		generic: 'Open door'
	},
	freezerStatus: "Refrigeration unit status",
	freezer: {
		on: "Refrigeration unit is on",
		off: "Refrigeration unit is off",
		na: "No refrigeration unit"
	},
	vehicle: {
		engineOn: 'Engine On',
		engineOff: 'Engine Off',
		unreachable: 'No signal',
	},
	vehicleStatus: {
		engineOn: 'Moving',
		engineOff: 'Stopped',
		unreachable: 'No signal',
		unreachableSince: 'No signal since {{status}}',
		noLocation: 'No device associated',
		generic: 'Vehicle status',
	},
	licensePlate: 'License Plate',
	markers: Markers,
	previous: 'Previous',
	next: 'Next',
	noResults: 'No results',
	fromDate: 'From date',
	until: 'Until',
	temperatureIntervals: "Every",
	interval: 'Interval',
	routeReport: 'Routes',
	anotherValue: 'Other value',
	liters: 'Liters',
	litersPerHour: 'Liters / hour',
	consume: 'Consumption',
	consumption: 'Consumption',
	speedPerHour: 'Speed (km/h)',
	speed: 'Speed',
	altitude: 'Altitude',
	altitudeMeters: 'Altitude (m)',
	fuelLevelLitres: 'Fuel Level (l)',
	engineLoad: "Engine Load (%)",
	engineTemperature: "Engine Temperature (°C)",
	refuel: 'Refuel',
	averageSpeed: 'Average Speed',
	maxSpeed: 'Maximum Speed',
	distance: 'Distance',
	fuelLevelForPeriod: 'Fuel level for period',
	canFuelLevel: 'CAN Fuel Level',
	fuelLevel: 'Fuel Level',
	fuelCanLevel: 'CAN Fuel Level',
	fuelAverageLevel: 'Average Fuel Level',
	fuelConsumption: 'Consumption',
	details: 'Details',
	noDetails: 'No details',
	event_enter: 'Enter event',
	event_exit: 'Exit event',
	event_stationary: 'Stop event',
	alert_in: 'Enter alert',
	alert_out: 'Exit alert',
	alert_transit: 'Transit alert',
	back: 'Back',
	minValue: 'Minimum value',
	maxValue: 'Maximum value',
	averageValue: 'Average value',
	settings: {
		markers: 'Marker settings',
		map: 'Map settings',
		_: 'Settings'
	},
	statusFeed: 'Fleet status',
	vehicles: 'Vehicles',
	vehicless: 'vehicle(s)',
	geofences: 'Fields',
	pois: 'Fields',
	users: 'Users',
	default: 'Default',
	divisions: 'Divisions',
	duration: 'Duration',
	account: 'Account',
	poi: 'Fields',
	timetables: 'Schedules',
	integrations: 'Integrations',
	implements: "Implements",
	errors: {
		'1': 'Error saving vehicle',
		'2': 'Error saving user',
		'3': 'Error blocking/unblocking ignition',
		'4': 'Error saving implement',
		'409': 'User already exists',
		'410': 'Email already used',
		'5010': 'Only simple polygons are allowed.',
		'5020': 'The division is already shared.',
		messages: Errors,
		accountDisabled: 'Sorry. Your company account was deactivated.'
	},
	dayHours: 'Hours',
	theMinutes: 'Minutes',
	monthDays: 'Days',
	theWeekDays: 'Days',
	messages: Messages,
	day: 'Day',
	week: 'Week',
	month: 'Month',
	every: 'Every',
	map: 'Map',
	type: 'Type',
	search: 'Search',
	ALL: 'ALL',
	ok: 'OK',
	add: 'ADD',
	save: 'SAVE',
	saveSuccess: 'Data saved.',
	edit: 'EDIT',
	cancel: 'CANCEL',
	send: 'SEND',
	timezone: 'Timezone',
	delete: 'DELETE',
	addFueling: 'Add fueling',
	aclGroup: 'Group',
	aclGroups: 'Groups',
	aclVehicle: 'vehicle',
	aclVehicles: 'vehicles',
	allVehicles: 'All Vehicles',
	selected: 'selected',
	subscribe: 'Subscribe',
	acceleration: 'Acceleration',
	rpm: 'RPM',
	temperature: 'Temperature',
	celsiusTemperature: 'Temperature (°C)',
	sensors: 'Sensors',
	accelerationG: 'Acceleration',
	alert: 'Alert',
	notification: 'Notification',
	disabled: 'Disabled',
	driver: 'Driver',
	drivers: 'Drivers',
	driverss: 'driver(s)',
	noDriver: 'Without driver',
	selectedDrivers: 'selected drivers',
	selectFile: 'Select File',
	selectVehicles: 'Select vehicles',
	selectFilters: 'Select filters',
	selectedFilters: 'filters selected',
	selectedGeofences: 'fields selected',
	unknownLocation: 'Unknown location',
	time: {
		period: 'Period',
		today: 'Today',
		currentWeek: 'Current week',
		currentMonth: 'Current month',
		yesterday: 'Yesterday',
		lastWeek: 'Last week',
		lastMonth: 'Last month'
	},
	weekDays: {
		sunday: 'Sunday',
		monday: 'Monday',
		tuesday: 'Tuesday',
		wednesday: 'Wednesday',
		thursday: 'Thursday',
		friday: 'Friday',
		saturday: 'Saturday'
	},
	dashboard: {
		heatmapTitle: 'Activity in the last 24h',
		distance: 'Distance',
		area: 'Area',
		heatmapShowMore: 'Show more',
		heatmapShowLess: 'Show less',
		dailyKm: 'Total km in the last 24h',
		dailyHa: 'Total ha in the last 24h',
		dailyConsumption: 'Consumption in the last 24h',
		monthlyKm: 'Km in the current month',
		monthlyHa: 'Ha in the current month',
		monthlyConsumption: 'Total consumption in the current month',
		cars: 'cars',
		alerts: 'Alerts',
		notifications: 'Notifications',
		showAlerts: 'Show Alerts',
		showNotifications: 'Show Notifications',
		showAllVehicles: 'Show all vehicles',
		stoped: 'Stopped',
		moving: 'Moving',
		contact: 'Do you have a suggestion or complaint?',
		contactLink: 'Contact Us',
		contactTitle: 'Contact',
		contactSendBtn: 'Send',
		contactMsgHint: 'Add message',
		errorOnSendingMessage: 'An error occured while sending your message.',
		plaseTryAgainLater: 'Please try again later',
		emptyMessage: 'Without message',
		pleaseInputMessage: 'Please enter your message',
		noAlertData: 'No Alerts',
		noNotificationData: 'No notifications',
		comingSoon: 'Coming soon',
		noData: 'No Data',
		showDriver: 'Driver Name',
		showLicensePlate: 'License Plate',
		liveStatus: {
			vehicle: 'Vehicle',
			address: 'Address / Field',
			lastPOI: 'Last field',
			status: 'Status',
			speed: 'Speed',
			fuelLevel: 'Fuel level',
			temperature: 'Agregation',
			doors: 'Doors',
			implements: "Implements",
		}
	},

	management: Management,
	reports: Reports,
	routes: {
		reports: 'Reports',
		'reports.stationary': 'Stops'
	},
	signup: {
		// terms: 'I agree to the <a href="/tos" target="_new">terms of service</a>.',
		terms: 'Pentru acceptarea serviciilor FarmIS va rugam confirmati <a href="/tos" target="_new">Termenii si Conditiile pentru Acces la Platforma</a>.',
		invitation: {
			expired: 'The invitation has expired. Please contact the administrator.'
		},
		termsText: "<h1>Termeni si Conditii pentru Acces la Platforma</h1>"
			+ "<h4>Obligațiile Furnizorului</h4><ul>"
			+ "<li>să furnizeze Beneficiarului accesul la Sistemul Titan FarmIS, în măsura îndeplinirii de către Beneficiar a tuturor obligațiilor contractuale asumate;</li>"
			+ "<li>să ofere Beneficiarului instruirea inițială de bază cu privire la utilizarea Sistemului Titan FarmIS;</li>"
			+ "<li>să acorde garanție pentru dispozitivele GPS conform termenilor și condițiilor din Anexa nr. 1 la Contract;</li>"
			+ "<li>să furnizeze actualizări ale Sistemului Titan FarmIS, corespunzătoare pachetului achiziționat de către Beneficiar;</li>"
			+ "<li>să stocheze gratuit datele înregistrate în Sistemul Titan FarmIS pentru o perioadă de 12 luni, perioadă în care Beneficiarul le poate accesa oricând.</li></ul>"

			+ "<h4>Obligațiile Beneficiarului</h4><ul>"
			+ "<li>să utilizeze Sistemul Titan FarmIS (inclusiv echipamentul GPS și/sau cartela SIM) conform scopului stabilit prin prezentul Contract, destinației sale, în conformitate cu instrucțiunile de utilizare, normelor de protecția muncii și a reglementărilor specifice determinate de condițiile efective existente la locația la care este utilizat utilajul pe care este instalat Sistemul Titan FarmIS. Clientul are obligația de a solicita toate informațiile referitoare la utilizarea în condiții optime a Sistemului Titan FarmIS;</li>"
			+ "<li>să nu transfere unor terțe părți sau să permită utilizarea de către terțe părți a Sistemului Titan FarmIS;</li>"
			+ "<li>să nu intervină în vreun fel asupra fișierelor de date și componentelor Sistemului Titan FarmIS, inclusiv prin utilizarea de alte programe, în scopul dezasamblării și decompilării acestuia;</li>"
			+ "<li>să nu efectueze vreo intervenție, ajustare, reglare sau schimbare de orice fel în legătură cu Sistemul Titan FarmIS; orice intervenție asupra Sistemului Titan FarmIS se va putea efectua doar de către Furnizor;</li>"
			+ "<li>să asigure reprezentanților desemnați de Furnizor disponibilitatea utilajelor pentru realizarea instalării/intervențiilor;</li>"
			+ "<li>să nu altereze autocolante cu sigla Titan FarmIS aplicate pe utilajele monitorizate;</li>"
			+ "<li>să permită utilizarea de către Furnizor a informațiilor înregistrate de Sistemul FarmIS pentru monitorizarea parametrilor de funcționare a utilajului pe care este montat în scopuri științifice, statistice și de marketing.</li></ul>"

			+ "<h3>Modul Hartă</h3>"
			+ "Acest modul este accesibil tuturor utilizatorilor din cadrul companiei. Lista de divizii și utilaje vizibile și accesibile în acest modul sunt administrabile la nivel de utilizator. Acest modul cuprinde două secțiuni:<ul>"
			+ "<li>Secțiunea hartă generală: în această secțiune sunt vizibile pozițiile cele mai recente ale tuturor vehiculelor accesibile utilizatorului logat. Această secțiune se reactualizează automat la fiecare 10 secunde.</li>"
			+ "<li>Secțiunea hartă detaliată: în această secțiune un utilaj este deja preselectat (poate fi schimbat) și utilizatorul poate vizualiza rutele parcurse de acest utilaj pe intervalul de timp selectat.</li></ul>"

			+ "<h3>Modul Rapoarte</h3>"
			+ "Acest modul este accesibil tuturor utilizatorilor din cadrul companiei. Lista de divizii și utilaje vizibile și accesibile în acest modul sunt administrabile la nivel de utilizator. Acest modul cuprinde următoarele secțiuni:<ul>"

			+ "<li><b>FAZ</b> - Acest raport poate fi generat pentru multiple utilaje simultan pentru o perioadă dintre prima și ultima zi a lunii pentru lunile trecute și dintre prima zi și ziua curentă pentru luna în curs. Raportul poate fi descărcat sub format PDF, Excel și CSV. Pentru fiecare vehicul se va genera un fișier separat cu numele ‘TITLURAPORT_denumireutilaj_luna’. Raportul poate fi printat direct din browser. Acest raport suportă abonare la trimiteri automate cu frecvență configurabilă."
			+ "<br>Raportul FAZ conține informații generale despre utilaj, operator, perioada raportului, momentul generării și conține un tabel, pentru fiecare utilaj selectat în parte, cu următoarele coloane: Dată, Distanță, Consum, Ore Funcționare, Viteză medie.</li><br>"

			+ "<li><b>Foi Parcurs</b> - Acest raport poate fi generat pentru multiple utilaje simultan. Raportul poate fi descărcat sub format PDF, Excel și CSV. Pentru fiecare utilaj se va genera un fișier separat cu numele ‘TITLURAPORT_ denumireutilaj_luna’. Raportul poate fi printat direct din browser. Acest raport suportă abonare la trimiteri automate cu frecvență configurabilă."
			+ "<br>Raportul Foi Parcurs conține informații despre utilaj, operator, perioada raportului, momentul generării și conține un tabel, pentru fiecare vehicul selectat în parte, cu următoarele coloane: Vehicul, Operator, Moment Pornire, Locație Pornire, Moment Sosire, Locație Sosire, Distanță, Ore Funcționare, Consum Calculat</li><br>"

			+ "<li><b>Staționări</b> - Acest raport poate fi generat pentru multiple utilaje simultan. Raportul poate fi descărcat sub format PDF, Excel și CSV. Numele fișierului este de forma ‘TITLURAPORT_datainceput_datasfarsit’. Raportul poate fi printat direct din browser. Acest raport suportă abonare la trimiteri automate cu frecvență configurabilă."
			+ "<br>Raportul Staționări conține un tabel cu următoarele coloane: utilaj, Operator, Adresă, Început staționare, Sfârșit staționare, Durată.</li><br>"

			+ "<li><b>Consum</b> - Acest raport poate fi generat pentru multiple vehicule simultan. Raportul poate fi descărcat sub format PDF, Excel și CSV. Numele fișierului este de forma ‘TITLURAPORT_datainceput_datasfarsit’. Raportul poate fi printat direct din browser. Acest raport suportă abonare la trimiteri automate cu frecvență configurabilă. Raportul Consum conține un tabel cu următoarele coloane: Vehicul, Operator, Distanță, Ore Funcționare, Consum, Consum / 100.</li><br>"

			+ "<li><b>Activitate peste orar</b> - Acest raport poate fi generat pentru multiple utilaje simultan pentru o perioadă de maximum 30 de zile. Raportul poate fi descărcat sub format PDF, Excel și CSV. Numele fișierului este de forma ‘TITLURAPORT_datainceput_datasfarsit’. Raportul poate fi printat direct din browser. Acest raport suportă abonare la trimiteri automate cu frecvență configurabilă. Raportul conține un tabel cu următoarele coloane: Operator Dată, Distanță, Durată, Consum.</li><br>"

			+ "<li><b>Încălcări</b> - Acest raport poate fi generat pentru multiple utilaje simultan pentru o perioadă de maximum 30 de zile. Raportul poate fi descărcat sub format PDF, Excel și CSV. Numele fișierului este de forma ‘TITLURAPORT_datainceput_datasfarsit’. Raportul poate fi printat direct din browser. Acest raport suportă abonare la trimiteri automate cu frecvență configurabilă. Raportul conține un tabel cu următoarele coloane: Operator, Adresă, Viteză, Limită Perimetru, Durată.</li></ul>"

			+ "NOTE: Fiecare tabel suportă filtrarea după operator, unde acest câmp este activ."

			+ "<h3>Modul Management</h3>"
			+ "Acest modul este accesibil utilizatorilor cu rol de administratori. Lista de divizii, utilaje și utilizatori vizibile și accesibile în acest modul sunt administrabile la nivel de companie."
			+ "<br>Acest modul cuprinde următoarele secțiuni:<ul>"

			+ "<li><b>Utilaje</b> - În această secțiune, utilizatorul poate efectua următoarele operații:<ul>"
			+ "<li>vizualizare sumară a utilajelor;</li>"
			+ "<li>vizualizare detaliată a fiecărui utilaj în parte;</li>"
			+ "<li>adăugare / editare / ștergere utilaj;</li>"
			+ "<li>atașare utilaje la divizie în cadrul operațiilor de adăugare și editare.</li></ul>"
			+ "Pagina de start a acestei secțiuni cuprinde un tabel cu utilajele companiei."
			+ "<br>De asemenea, utilizatorul poate filtra lista de vehicule după tipul utilajului și divizia din care acesta face parte. Este accesibil și un câmp de căutare, utilizatorul putând căuta, fie după numărul de înmatriculare al utilajului, fie după marcă și model."
			+ "<br>Pentru fiecare utilaj din listă, utilizatorul poate efectua operațiile de vizualizare detalii, editare și ștergere."
			+ "<br>Ultima operație este de adăugare, utilizatorul având la dispoziție o mulțime de câmpuri pentru o bună identificare a vehiculului în toate secțiunile aplicației."

			+ "</li><br><li><b>Utilizatori</b> - În secțiunea Utilizatori, utilizatorul cu rol de administrator va avea acces la toți utilizatorii din cadrul companiei, putând efectua operațiile de adăugare, editare, vizualizare detalii și ștergere pentru fiecare utilizator."
			+ "<br>Pagina inițială a acestei secțiuni cuprinde un tabel cu toți utilizatorii din companie. Administratorul va putea vizualiza detalii despre fiecare utilizator în parte, va putea edita și șterge utilizatori."
			+ "<br>În cadrul operațiilor de adăugare / editare, utilizatorii pot fi încadrați într-o divizie, le pot fi asignate vehicule, drepturi de acces etc."
			+ "<br>De asemenea, utilizatorii pot încărca poze de profil pentru o mai bună identificare în cadrul aplicației."

			+ "</li><br><li><b>Divizii</b> - În această secțiune, utilizatorul cu rol de administrator poate vizualiza diviziile companiei din care face parte, într-o structură arborescentă."
			+ "<br>Utilizatorul va putea adăuga divizii noi, în cadrul oricărei subdivizii. Compania este divizia ‘root’ și este unică. Toate diviziile vor fi create în cadrul acestei divizii ‘root’. Utilizatorul are posibilitatea sa restrângă sau să extindă lista. În primă fază se va afișa lista extinsă."
			+ "<br>De asemenea, există posibilitatea de căutare după nume, a diviziilor. Dacă diviziile găsite au fost restrânse, vor rămâne extinse.</ul>"
			+ "</li>Observație: Toate rapoartele sunt printabile și se pot exporta în format MS Excel."
	},

	dispatch: {
		route: {
			title: 'Route',
			distance: 'Distance (km)',
			name: 'Name',
			vehicles: 'Vehicles',
			duration: 'Duration',
			waypoints: {
				title: 'Points',
				type: 'Type',
				types: {
					load: 'Load',
					unload: 'Unload',
					stop: 'Stop'
				},
				location: 'Location',
				duration: 'Duration (minutes)'
			},
			actions: {
				route: 'Generate route',
				optimize: 'Optimize route',
				next: 'Next',
				save: 'Save',
				add: 'Add',
				search: 'Find route...'
			},
			state: {
				generating: 'Generating route...'
			},
			optimized: {
				title: 'Optimized route',
				use: 'Use optimized route',
				message: 'The route can be optimized',
				diff: 'The optimized route is shorter by <b>{{distance}}</b> and faster by <b>{{time}}</b>.'
			},
			schedule: {
				title: 'Schedules'
			},
			details: {
				title: 'Details'
			}
		}
	}
};
