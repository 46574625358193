import Ember from 'ember'
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin'
import moment from 'moment'

var stops = [
	Ember.Object.create({
		id: '1',
		text: '1 min',
		value: 60000
	}),
	Ember.Object.create({
		id: '2',
		text: '5 min',
		value: 300000
	}),
	Ember.Object.create({
		id: '3',
		text: '10 min',
		value: 600000
	}),
	Ember.Object.create({
		id: '4',
		text: '20 min',
		value: 1200000
	}),
	Ember.Object.create({
		id: '5',
		text: '30 min',
		value: 1800000
	}),
]

export default Ember.Route.extend(AuthenticatedRouteMixin, {
	queryParams: {
		routeStartDate: {
			refreshModel: true
		},
		routeEndDate: {
			refreshModel: true
		}
	},

	beforeModel() {
		let controller = this.controllerFor('map.routes')
		controller.set('currentlyLoading', true)
	},
	model: function (params) {
		var _this = this
		return Ember.RSVP.hash({
			groups: this.store.findAll("group"),
			vehicles: this.store.findAll("vehicle"),
			selectedVehicle: Ember.computed("vehicles", function () {
				return _this.store.peekRecord("vehicle", params.vehicle_id)
			}),
			routes: this.store.query("route", {
				$where: {
					vehicle_id: params.vehicle_id,
					'>=': {
						start_time: params.routeStartDate
					},
					'<=': {
						start_time: params.routeEndDate
					}
				}
			}),
			stops: stops,
			geofences: this.store.findAll('geofence')
		})
	},
	afterModel() {
		let controller = this.controllerFor('map.routes')
		controller.set('currentlyLoading', false)
	},
	resetController: function (controller, isExiting) {
		if (!isExiting) { return false }
		// isExiting would be false if only the route's model was changing
		if (!controller.get("routeStartDate")) {
			controller.set("routeStartDate", moment(moment().format("YYYY-MM-DD")).valueOf())
		}
		if (!controller.get("routeEndDate")) {
			controller.set("routeEndDate", moment(moment().format("YYYY-MM-DD")).endOf('day').valueOf())
		}
		/**
		 * When user leaves the page set selected route to null.
		 */
		controller.set('selectedRouteID', null)
	}
})
