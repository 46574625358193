import Ember from 'ember';

export default Ember.Service.extend({
    fields: null,
    setFields(value) {
        this.set('fields', value);
    },

    merge(fields) {
        this.set('fields', Object.assign(this.get('fields'), fields));
    }
});
