import Ember from 'ember';
import RSVP from 'rsvp';

export default Ember.Route.extend({
    model(params) {
        let vehicle = this.store.peekRecord('vehicle', params.id);
        let filtredDocs = this.store.query('document', {
            $where: {
                vehicle_id: vehicle.get("id")
            }
        });
        let documents = this.store.peekAll('document');

        return RSVP.hash({
            vehicle: vehicle,
            documents: documents,
            filtredDocs: filtredDocs
        });
    },

    setupController(controller) {
        this._super(...arguments);
        controller.set('vehicles', this.store.peekAll('vehicle'));
        controller.set('users', this.store.peekAll('user'));
        controller.set('groups', this.store.peekAll('group'));
        controller.set('timetables', this.store.peekAll('timetable'));
        controller.set('devices', this.store.peekAll('device'));
        controller.set('implements', this.store.peekAll('implement'));
    },
    actions: {
        willTransition() {
            Ember.$('.managementModal').modal("hide");
        },
    }
});