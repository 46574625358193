import Ember from 'ember'
import RSVP from 'rsvp'

const { service } = Ember.inject

export default Ember.Route.extend({
	currentUser: service('current-user'),

	i18n: Ember.inject.service(),

	getType(vehicleType) {
		var _this = this
		var type = ""
		switch (vehicleType) {
			case "utility":
				type = _this.get('i18n').t('management.vehicles.type.utility')
				break
			case "car":
				type = _this.get('i18n').t('management.vehicles.type.car')
				break
			case "minibus":
				type = _this.get('i18n').t('management.vehicles.type.minibus')
				break
			case "bus":
				type = _this.get('i18n').t('management.vehicles.type.bus')
				break
			case "truck":
				type = _this.get('i18n').t('management.vehicles.type.truck')
				break
			case "tractor":
				type = _this.get('i18n').t('management.vehicles.type.tractor')
				break
			case "harvester":
				type = _this.get('i18n').t('management.vehicles.type.harvester')
				break
			case "construction":
				type = _this.get('i18n').t('management.vehicles.type.construction')
				break
			case "trailer":
				type = _this.get('i18n').t('management.vehicles.type.trailer')
				break
			case "semi-trailer":
				type = _this.get('i18n').t('management.vehicles.type.semi-trailer')
		}
		return type
	},

	getDriverName(userID, users) {
		var driver

		if (Ember.isEmpty(userID) || Ember.isEmpty(users)) { return null }

		driver = users.findBy("id", userID)

		if (driver) {
			return driver.get("fullName")
		} else {
			// The user is deleted, but is not detached from vehicle.
			// In this case we should set userID to null.
			return null
		}
	},

	getGroupName(groupID, groups) {
		var group

		if (Ember.isEmpty(groupID) || Ember.isEmpty(groups)) { return null }

		group = groups.findBy("id", groupID)

		if (group) {
			return group.get("name")
		} else {
			return null
		}
	},

	getEngineState(engineState) {
		if (Ember.isEmpty(engineState)) { return null }
		return (engineState ? this.get('i18n').t('vehicle.engineOn') : this.get('i18n').t('vehicle.engineOff'))
	},

	model() {
		var _this = this
		var vehicles = this.store.peekAll('vehicle')
		var users = this.store.peekAll('user')
		var groups = this.store.peekAll('group')
		let i18n = this.get('i18n')

		const tHeaders = [
			{ key: 'licensePlate', value: i18n.t('management.headerFields.licensePlate'), align: 'left' },
			{ key: 'driverName', value: i18n.t('management.headerFields.driver'), align: 'left' },
			{ key: 'carName', value: i18n.t('management.headerFields.car'), align: 'left' },
			{ key: 'type', value: i18n.t('management.headerFields.type'), align: 'left' },
			{ key: 'groupName', value: i18n.t('management.headerFields.division'), align: 'left' },
			{ key: 'status', value: i18n.t('management.headerFields.status'), align: 'left' },
		]

		let loggedUserCompanyID = this.get('currentUser').getCompanyID()
		let arrVehicles = vehicles
			.filterBy('group.companyID', loggedUserCompanyID)
			.map(vehicle => {
				let newVehicle = Ember.Object.create()
				tHeaders.forEach(function (item) {
					if (item.key === "type") {
						newVehicle.set("type", _this.getType(vehicle.get("type")))
					} else if (item.key === "driverName") {
						newVehicle.set("driverName", _this.getDriverName(vehicle.get("userID"), users))
					} else if (item.key === "groupName") {
						newVehicle.set("groupName", _this.getGroupName(vehicle.get("groupID"), groups))
					} else if (item.key === "status") {
						newVehicle.set("status", _this.getEngineState(vehicle.get("status.engineState")))
					}
					else {
						newVehicle.set(item.key, vehicle.get(item.key))
					}
				})
				newVehicle.set("id", vehicle.get("id"))
				return newVehicle
			})
			.sortBy('carName', 'driverName')

		return RSVP.hash({
			tHeaders: tHeaders,
			aVehicles: arrVehicles,
			groups: groups,
		})
	},

	actions: {
		viewVehicle(vehicleID) {
			if (vehicleID) {
				this.transitionTo('management.vehicles.view', vehicleID)
			}
		},
		newVehicle() {
			this.transitionTo('management.vehicles.new')
		},
		viewDocuments(vehicleID) {
			if (vehicleID) {
				this.transitionTo('management.vehicles.docs', vehicleID)
			}
		},
		editVehicle(vehicleID) {
			if (vehicleID) {
				this.transitionTo('management.vehicles.edit', vehicleID)
			}
		},
		deleteVehicle(vehicleID) {
			var _this = this
			if (vehicleID) {
				var vehicleObj = this.store.peekRecord('vehicle', vehicleID)
				Ember.$("#makeModelModal").html(vehicleObj.get("carName"))
				Ember.$("#vinModal").html(vehicleObj.get("vin"))
				Ember.$("#licensePlate").html(vehicleObj.get("licensePlate"))
				// has any devices this vehicle assigned ?
				var device = this.store.peekAll('device').findBy("vehicleID", vehicleID)
				if (device) {
					Ember.$("#deviceAssignedToVehicle").html('<h3><i class="warning sign icon"></i> ' + _this.get('i18n').t('management.vehicles.vehicleHasDeviceAssigned', { deviceID: device.get('id') }) + '.</h3>')
				}
				else {
					Ember.$("#deviceAssignedToVehicle").html('')
				}
				Ember.$('.modal.deleteVehicleID')
					.modal({
						onApprove: function () {
							_this.get('store')
								.findRecord('vehicle', vehicleObj.get("id"), { backgroundReload: false })
								.then(function (vehicle) {
									vehicle.destroyRecord()
										.then(function () {
											if (device) {
												_this.store.findRecord('device', device.get("id"), { backgroundReload: false })
													.then(function (dev) {
														dev.set("vehicleID", null)
														dev.save()
															.catch(function (err) {
																Ember.Logger.error(err)
															})
													})
													.catch(function (err) {
														Ember.Logger.error(err)
													})
											}
											_this.refresh()
											_this.toast.success(_this.get('i18n').t('messages.deletedWithSuccess'))
										})
								})
								.catch(function (err) {
									_this.toast.error(_this.get('i18n').t('messages.operationWithError'))
									Ember.Logger.error(err)
								})
						},
					})
					.modal('show')
			}
		},
	}
})
