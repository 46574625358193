import Base from './base';

export default Base.extend({
    model() {
        return {
            tHeaders: [
                { key: 'driver', value: 'Sofer', align: 'left' },
                { key: 'type', value: 'Tip', align: 'left' },
                { key: 'ts', value: 'Data', align: 'left' },
                { key: 'address', value: 'Adresa', align: 'left' },
                { key: 'speed', value: 'Speed', align: 'left' },
                { key: 'perimeter', value: 'Limita perimetru', align: 'left' },
                { key: 'duration', value: 'Durata', align: 'left' }
            ],
            tData: []
        };
    }
});
