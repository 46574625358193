export default {
	'poi-stationary': 'Staționare ferma {{geofenceName}} de {{licensePlate}}',
	'geofence-stationary': 'Staționare field {{geofenceName}} de {{licensePlate}}',
	'rpm-limit': 'Depășire limită turație motor de {{licensePlate}}',
	'fuel-consumption-limit': 'Scădere combustibil de {{licensePlate}}',
	'document-expiry': '{{type}} pentru {{driver}} {{vehicle}} expirã în data de {{until}}',
	'document-expired': '{{type}} pentru {{driver}} {{vehicle}} a expirat în data de {{until}}',
	
	// not titan alerts
	'poi-enter': 'Intrare ferma {{geofenceName}} de {{licensePlate}}',
	'poi-exit': 'Ieșire ferma {{geofenceName}} de {{licensePlate}}',

	'geofence-enter': 'Intrare field {{geofenceName}} de {{licensePlate}}',
	'geofence-exit': 'Ieșire field {{geofenceName}} de {{licensePlate}}',

	'door-open-outside-poi': 'Deschidere ușă în afara ferma de către {{licensePlate}}',
}
