// https://github.com/miguelcobain/ember-leaflet/issues/156
import MarkerLayer from 'ember-leaflet/components/marker-layer'

export default MarkerLayer.extend({
	createLayer() {
		let layer = this._super(...arguments)
		layer._openPopup = () => { }
		return layer
	}
})
