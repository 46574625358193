import Ember from 'ember';

export default Ember.Route.extend({
    resetController(controller, isExiting) {
        if (isExiting) {
            controller.setProperties({
                response: null,
                progressOne: null,
                completedRequest: false,
            });
        }
    }
});
