import Alerts from './alerts'
import Errors from './errors'
import Management from './management'
import Messages from './messages'
import Reports from './reports'
import Markers from './markers'

export default {
	"pagination": {
		"first": "Erste Seite",
		"last": "Letzte Seite",
		"next": "Nächste Seite",
		"previous": "Vorherige Seite",
		"of": "von",
		"resultsPerPage": "Ergebnisse pro Seite",
		"goToPage": "Gehe zu Seite",
		"pageOfTotal": "Seite {{page}} von {{total}}"
	},
	"pleaseWait": "Bitte warten",
	"processingFileData": "Datei wird verarbeitet",
	"expand": "Erweitern",
	"menu": {
		"dispatch": {
			"index": "Versende",
			"Routen": {
				"add": "Route hinzufügen"
			}
		}
	},
	"loading": {
		"text": "Lade"
	},
	"acl": "Zugangskontrolle",
	"alerts": {
		"messages": Alerts
	},
	"alertsAndNotifications": "Warnungen und Benachrichtigungen",
	"openDoorsStatus": "Status Türen",
	"doorsStatus": {
		"Offen": "Offene Türen",
		"Geschlossen": "Geschlossene Türen"
	},
	"closed": "Geschlossen",
	"open": "Offen",
	"openDoor": {
		"front-left": "Die vordere rechte Tür ist offen",
		"front-right": "Die vordere linke Tür ist offen",
		"back-left": "Die hintere rechte Tür ist offen",
		"back-right": "Die linke hintere Tür ist offen",
		"trunk": "Hintertür ist offen",
		"allClosed": "Alle Türen sind geschlossen",
		"na": "Keine Sensoren",
		"generic": "Offene Tür"
	},
	"freezerStatus": "Status der Kühleinheit",
	"freezer": {
		"on": "Kühleinheit ist eingeschaltet",
		"off": "Kühleinheit ist ausgeschaltet",
		"na": "Keine Kühleinheit"
	},
	"vehicle": {
		"engineOn": "Motor an",
		"engineOff": "Motor aus",
		"unreachable": "Kein Signal"
	},
	"vehicleStatus": {
		"engineOn": "In Bewegung",
		"engineOff": "Gestoppt",
		"unreachable": "Kein Signal",
		"unreachableSince": "Kein Signal seit {{status}}",
		"noLocation": "Kein Gerät zugeordnet",
		"generic": "Gerätestatus"
	},
	"licensePlate": "Nummernschild",
	"markers": Markers,
	"previous": "Vorherige",
	"next": "Nächste",
	"noResults": "Keine Ergebnisse",
	"fromDate": "Ab Datum",
	"until": "Bis",
	"temperatureIntervals": "Jeden",
	"interval": "Intervall",
	"routeReport": "Routen",
	"anotherValue": "Andere Werte",
	"liters": "Liter",
	"litersPerHour": "Liter / Stunde",
	"consume": "Verbrauch",
	"consumption": "Verbrauch",
	"speedPerHour": "Geschwindigkeit (km/h)",
	"speed": "Geschwindigkeit",
	"altitude": "Höhe",
	"altitudeMeters": "Höhe (m)",
	"fuelLevelLitres": "Tankfüllstand (l)",
	"engineLoad": "Motorlast (%)",
	"engineTemperature": "Motortemperatur (°C)",
	"refuel": "Auftanken",
	"averageSpeed": "Durchschnittsgeschwindigkeit",
	"maxSpeed": "Maximale Geschwindigkeit",
	"distance": "Distanz",
	"fuelLevelForPeriod": "Kraftstoffstand für Zeitraum",
	"canFuelLevel": "CAN Kraftstoffstand",
	"fuelLevel": "Kraftstoffstand",
	"fuelCanLevel": "CAN Kraftstoffstand",
	"fuelAverageLevel": "Durchschnittlicher Kraftstoffstand",
	"fuelConsumption": "Verbrauch",
	"details": "Details",
	"noDetails": "Keine Details",
	"event_enter": "Ereignis eingeben",
	"event_exit": "Ereignis beenden",
	"event_stationary": "Stoppe Ereignis",
	"alert_in": "Warnung eingeben",
	"alert_out": "Warnung beenden",
	"alert_transit": "Transitwarnung",
	"back": "Zurück",
	"minValue": "Mindestwert",
	"maxValue": "Maximalwert",
	"averageValue": "Durchschnittswert",
	"settings": {
		"markers": "Markereinstellungen",
		"map": "Karteneinstellungen",
		"_": "Einstellungen"
	},
	"statusFeed": "Flottenstatus",
	"vehicles": "Geräte",
	"vehicless": "Geräte",
	"geofences": "Felder",
	"pois": "Felder",
	"users": "Benutzer",
	"default": "Standard",
	"divisions": "Gruppen",
	"duration": "Dauer",
	"account": "Konto",
	"poi": "Felder",
	"timetables": "Zeitpläne",
	"integrations": "Einbindungen",
	"implements": "Anbaugeräte",
	"errors": {
		"1": "Fehler beim Speichern des Fahrzeugs",
		"2": "Fehler beim Speichern des Benutzers",
		"3": "Fehler beim Blockieren / Entsperren der Zündung",
		"4": "Fehler beim Speichern des Geräts",
		"409": "Benutzer existiert bereits",
		"410": "E-Mail bereits verwendet",
		"5010": "Es sind nur einfache Polygone zulässig.",
		"5020": "Die Aufteilung ist bereits geteilt.",
		"messages": Errors,
		"accountDisabled": "Es tut uns leid. Ihr Firmenkonto wurde deaktiviert."
	},
	"dayHours": "Stunden",
	"theMinutes": "Minuten",
	"monthDays": "Tage",
	"theWeekDays": "Tage",
	"messages": Messages,
	"day": "Tag",
	"week": "Woche",
	"month": "Monat",
	"every": "Jeden",
	"map": "Karte",
	"type": "Typ",
	"search": "Suche",
	"ALL": "ALLE",
	"ok": "OK",
	"add": "HINZUFÜGEN",
	"save": "SICHERN",
	"saveSuccess": "Daten gesichert.",
	"edit": "BEARBEITEN",
	"cancel": "ABBRUCH",
	"send": "SENDEN",
	"timezone": "Zeitzone",
	"delete": "LÖSCHEN",
	"addFueling": "Tanken hinzufügen",
	"aclGroup": "Gruppe",
	"aclGroups": "Groupen",
	"aclVehicle": "Gerät",
	"aclVehicles": "Geräte",
	"allVehicles": "Alle Geräte",
	"selected": "ausgewählt",
	"subscribe": "Abonnieren",
	"acceleration": "Beschleunigung",
	"rpm": "RPM",
	"temperature": "Temperatur",
	"celsiusTemperature": "Temperatur (°C)",
	"sensors": "Sensoren",
	"accelerationG": "Beschleunigung",
	"alert": "Warnungen",
	"notification": "Benachrichtigungen",
	"disabled": "Deaktiviert",
	"driver": "Fahrer",
	"drivers": "Fahrer",
	"driverss": "Fahrer",
	"noDriver": "Ohne Fahrer",
	"selectedDrivers": "Ausgewählte Fahrer",
	"selectFile": "Datei auswählen",
	"selectVehicles": "Gerät auswählen",
	"selectFilters": "Filter auswählen",
	"selectedFilters": "Ausgewählte Filter",
	"selectedGeofences": "Felder ausgewählt",
	"unknownLocation": "Unbekannter Standort",
	"time": {
		"period": "Periode",
		"today": "Heute",
		"currentWeek": "Diese Woche",
		"currentMonth": "Diesen Monat",
		"yesterday": "Gestern",
		"lastWeek": "Letzte Woche",
		"lastMonth": "Letzten Monat"
	},
	"weekDays": {
		"sunday": "Sonntag",
		"monday": "Montag",
		"tuesday": "Dienstag",
		"wednesday": "Mittwoch",
		"thursday": "Donnerstag",
		"friday": "Freitag",
		"saturday": "Samstag"
	},
	"dashboard": {
		"heatmapTitle": "Aktivität in den letzten 24 Stunden",
		"distance": "Distanz",
		"area": "Bereich",
		"heatmapShowMore": "Zeige mehr",
		"heatmapShowLess": "Zeige weniger",
		"dailyKm": "Gesamtkilometer in den letzten 24h",
		"dailyHa": "Gesamt ha in den letzten 24h",
		"dailyConsumption": "Verbrauch in den letzten 24h",
		"monthlyKm": "Km im laufenden Monat",
		"monthlyHa": "Ha im laufenden Monat",
		"monthlyConsumption": "Gesamtverbrauch im laufenden Monat",
		"cars": "Autos",
		"alerts": "Warnungen",
		"notifications": "Benachrichtigungen",
		"showAlerts": "Zeige Warnungen",
		"showNotifications": "Zeige Benachrichtigungen",
		"showAllVehicles": "Zeige alle Fahrzeuge",
		"stoped": "Gestoppt",
		"moving": "In Bewegung",
		"contact": "Haben Sie einen Vorschlag oder eine Beschwerde??",
		"contactLink": "Kontaktieren Sie uns",
		"contactTitle": "Kontakt",
		"contactSendBtn": "Senden",
		"contactMsgHint": "Nachricht hinzufügen",
		"errorOnSendingMessage": "Beim Senden Ihrer Nachricht ist ein Fehler aufgetreten.",
		"plaseTryAgainLater": "Bitte versuchen Sie es später noch einmal",
		"emptyMessage": "Ohne Nachricht",
		"pleaseInputMessage": "Bitte geben Sie Ihre Nachricht ein",
		"noAlertData": "Keine Warnungen",
		"noNotificationData": "Keine Benachrichtigungen",
		"comingSoon": "Coming soon",
		"noData": "Keine Daten",
		"showDriver": "Fahrername",
		"showLicensePlate": "Nummernschild",
		"liveStatus": {
			"vehicle": "Fahrzeug",
			"address": "Adresse / Feld",
			"lastPOI": "Letztes Feld",
			"status": "Status",
			"speed": "Geschwindigkeit",
			"fuelLevel": "Tankfüllstand",
			"temperature": "Agregation",
			"doors": "Türen",
			"implements": "Anbaugeräte"
		}
	},
	"management": Management,
	"reports": Reports,
	"routes": {
		"reports": "Berichte",
		"reports.stationary": "Stopps"
	},
	"signup": {
		"terms": "Pentru acceptarea serviciilor FarmIS va rugam confirmati <a href=\"/tos\" target=\"_new\">Termenii si Conditiile pentru Acces la Platforma</a>.",
		"invitation": {
			"expired": "Die Einladung ist abgelaufen. Bitte wenden Sie sich an den Administrator."
		}
	},
	"dispatch": {
		"route": {
			"title": "Route",
			"distance": "Distanz (km)",
			"name": "Name",
			"vehicles": "Geräte",
			"duration": "Dauer",
			"waypoints": {
				"title": "Punkte",
				"type": "Typ",
				"types": {
					"load": "Ladung",
					"unload": "Ausladen",
					"stop": "Stop"
				},
				"location": "Standort",
				"duration": "Dauer (Minuten)"
			},
			"actions": {
				"route": "Route generieren",
				"optimize": "Route optimieren",
				"next": "Nächste",
				"save": "Sichern",
				"add": "Hinzufügen",
				"search": "Suche Route..."
			},
			"state": {
				"generating": "Route generieren..."
			},
			"optimized": {
				"title": "Optimierte Route",
				"use": "Verwenden Sie eine optimierte Route",
				"message": "Die Route kann optimiert werden",
				"diff": "Die optimierte Route ist um kürzer <b>{{distance}}</b> und schneller um <b>{{time}}</b>."
			},
			"schedule": {
				"title": "Zeitpläne"
			},
			"details": {
				"title": "Details"
			}
		}
	}
}
