import Ember from 'ember'
import moment from 'moment'
import config from '../../config/environment'

function check(options) {
	if (!options) { return false }
	if (!options.report.get('type')) { return false }
	if (!options.extra) { return false }
	if (!options.extra.format || ['pdf', 'csv', 'xlsx'].indexOf(options.extra.format) === -1) { return false }
	if (!options.extra.locale) { return false }
	if (!options.filter) { return false }
	if (Ember.isEmpty(options.filter.vehicles)) { return false }
	if (!options.filter.startDate) { return false }
	if (!options.filter.endDate) { return false }

	return true
}

const { service } = Ember.inject

export default Ember.Service.extend({
	i18n: service(),
	session: service(),

	filenameSeparator: '_',

	download(options, callback) {
		const idToken = this.get('session.data.authenticated.idToken')
		let _this = this

		if (!idToken) { return false }

		if (!check(options)) { return false }

		let url = config.api.host + '/' + config.api.namespace + '/reports/' + options.report.type + '/download'
		let data = JSON.stringify({
			id: options.report.type,
			format: options.extra.format,
			locale: options.extra.locale,
			$filter: options.filter
		})

		let req = new XMLHttpRequest()
		req.open('POST', url, true)
		req.setRequestHeader('Authorization', `Bearer ${idToken}`)
		req.setRequestHeader('Content-type', 'application/json; charset=utf-8');
		req.responseType = 'blob'

		req.onload = function (event) {
			let blob = req.response
			Ember.Logger.debug('event: ', event)
			Ember.Logger.info(options.filter)
			Ember.Logger.debug('file size: ', blob.size)
			let link = document.createElement('a')
			link.href = window.URL.createObjectURL(blob)
			link.download = options.filename.join(_this.get('filenameSeparator')) + _this.get('filenameSeparator') + moment(options.filter.startDate).format('DD/MM/YYYY') + _this.get('filenameSeparator') + moment(options.filter.endDate).format('DD/MM/YYYY') + "." + options.extra.format
			link.className = 'hidden'
			document.body.appendChild(link)
			link.click()
			if (callback && typeof callback === 'function') {
				callback()
			}
		}

		req.send(data)
	}
})
