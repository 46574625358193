export default {
    emptyName: 'Numele este obligatoriu.',
    missingCoordinates: 'Lipsesc coordonatele.',
    invalidAlertStationaryTimeRange: 'Timpul de stationare este incorect. Introduceti un numar intre 5 si 120.',
    invalidAlertStationaryTimeMultiple: 'Timpul de stationare este incorect. Introduceti un numar multiplu de 5.',
    insufficientPolygonPoints: 'Puncte insuficiente (minim 3)',
    errorsExists: 'Există erori.',
    plaseTryAgainLater: 'Vă rugăm să încercați din nou mai târziu',
    numberWithoutComma: 'Folositi "." pentru a separa zecimalele.',
    errorsExistsCheckData: 'Există erori. Verificați datele.',
    onlyValuesBiggerThan0: 'Acceptate doar valori mai mari ca 0.',
    onlyValuesBetween5And120AndMultpleOf5: 'Introduceti o valoare intre 5 - 120. \nAcceptat doar multiplu de 5.',

    validation: {
        poi: {
            invalidPoiShape:        "O forma de tip \"point\" poate sa aiba numai o pereche de coordonate, iar valoarea minima pentru boundary este 50.",
            poiRequiresPoint:       "Cand valoarea pe coloana \"type\" este \"poi\", singura valoare acceptata pentru coloana \"shape\" este \"point\".",
            poiOrGeofenceExpected:  "Valorile acceptate pentru cloloana \"type\" sunt: \"poi\" sau \"geofence\".",
            invalidPolygon:         "Pentru formele de tip \"polygon\" proprietatea \"coordinates\" trebuie sa fie un array de numere reale(de ex. float), cu lungimea de cel putin 8 elemente.",
            boundaryTooSmall:       "Valoarea \"boundary\" a unui POI trebuie sa fie un numar mai mare decat 50.",
            invalidCoordinates:     "\"coordinates\" trebuie sa fie sub forma de array."
        }
    }
}
