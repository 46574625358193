import Ember from 'ember';
import RSVP from 'rsvp';

export default Ember.Route.extend({
    model() {
        let vehicle = this.store.createRecord('vehicle', {
            groupID: null,
            userID: null,
            timetableID: null,
            make: null,
            model: null,
            vin: null,
            year: null,
            color: null,
            seats: null,
            type: null,
            hasRelay: null,
            licensePlate: null,
            registrationDate: null,
            tireSize: null,
            fuel: {
                type: null,
                capacity: null,
                consumption: {
                    distanceWise: null,
                    timeWise: null,
                    weightWise: null
                }
            },
            engine: {
                power: null,
                capacity: null
            },
            speedLimit: {
                max: null,
                min: null,
                offset: null
            },
            settings: {
                route: {
                    purpose: null
                },
                alerts: {
                    'geofence-stationary': {
                        type: 'disabled',
                    },
                    'fuel-consumption-limit': {
                        type: 'disabled',
                        limit: 1
                    },
                    'rpm-limit': {
                        type: 'disabled',
                    },
                },
            },
            status: {
                location: [
                    null
                ],
                speed: null,
                fuelLevel: null,
                engineState: null,
                relayState: null,
                odometer: null,
                updatedAt: null
            },
            geofence: {
                geofence_id: null,
                timetable_id: null
            }
        });

        let documents = this.store.peekAll('document');

        return RSVP.hash({
            vehicle: vehicle,
            documents: documents
        });
    },

    setupController(controller) {
        this._super(...arguments);
        controller.set('vehicles', this.store.peekAll('vehicle'));
        controller.set('users', this.store.peekAll('user'));
        controller.set('groups', this.store.peekAll('group'));
        controller.set('timetables', this.store.peekAll('timetable'));
        controller.set('devices', this.store.peekAll('device'));
        controller.set('implements', this.store.peekAll('implement'));
    },
    actions: {
        willTransition() {
            Ember.$('.managementModal').modal("hide");
            this.controller.rollBackAndTransition();
        },
    }
});
