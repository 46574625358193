// import Ember from 'ember';

// export default Ember.Route.extend({
//     model(newGroupID) {
//         var companyID = newGroupID.split('-')[0];
//         return this.store.createRecord('group', {
//             id: newGroupID,
//             companyID: companyID,
//             timetableID: null,
//             name: null
//         });
//     }
// });
