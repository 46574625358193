import DS from 'ember-data'
import MF from 'ember-data-model-fragments'
import Ember from 'ember'

export default MF.Fragment.extend({
    used: DS.attr('number'),
    level: DS.attr(),
    usedLiters: Ember.computed("used", function () {
        return this.get("used") / 10
    })
})
