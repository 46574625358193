import Ember from 'ember';

export default Ember.Service.extend({
    Resolution: Ember.inject.service('resolution'),

    jQueryMenu: null,

    initialized: false,

    intern: false,

    _setMenu(jQueryMenu) {
        if (!this.get('intern')) { return false; }

        if (!jQueryMenu || !jQueryMenu.length) { return false; }
        this.set('jQueryMenu', jQueryMenu);
    },


    getMenu() {
        return this.get('jQueryMenu');
    },

    show(callback, callbackContext) {
        if (this.get('jQueryMenu') === null) { return false; }
        this.get('jQueryMenu').addClass('active').promise().done(() => {
            if (callback && typeof callback === 'function') {
                callback.call(callbackContext);
            }
        });    
    },

    hide(callback, callbackContext) { 
        if (this.get('jQueryMenu') === null) { return false; }
        this.get('jQueryMenu').removeClass('active').promise().done(() => {
            if (callback && typeof callback === 'function') {
                callback.call(callbackContext);
            }
        });
    },

    isActive() {
        return this.get('jQueryMenu').hasClass('active');
    },

    _events() {
        var _this = this;
        
        if (!this.get('intern') && this.get('reset')) { return false; }

        Ember.$('body').on('click', '.tracknamic-side-bar a', function () {
            if (_this.get('Resolution').isMobile) {
                _this.get('jQueryMenu').removeClass('active');
            }
        });  
    },

    setTo(jQueryMenu) {
        if (!jQueryMenu) { return null; }
        this.set('jQueryMenu', jQueryMenu); 
        this.set('initialized', false);
        this.set('reset', true);
    },

    initMenu() {
        if (this.get('initialized')) { return false; }

        this.set('intern', true);
        this._setMenu(Ember.$('#trk-aside'));

        if (!this.getMenu()) { return false; }
        
        this._events();
        this.set('initialized', true); 
        this.set('intern', false);
    }

});
