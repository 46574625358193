import Ember from 'ember';

export default Ember.Service.extend({
    largeDesktopBreakpoint: 1450,
    desktopBreakpoint: 1200,
    computerBreakpoint: 991,
    tabletBreakpoint: 768,

    isMobile: true,
    isTablet: false,
    isComputer: false,
    isDesktop: false,
    isLargeDesktop: false,

    isPortrait: false,
    isMobileChange: false,
    resolutionChanged: false,

    init: function () {
        var self = this;
        self.environmentCheck(); //Call to set environment variables at application start
        Ember.$(window).resize(function () {
            Ember.run.debounce(self, self.environmentCheck, 200);
        });
    },
    /**
     * Detects if the change si from mobile resolution to nonmobile and vice versa.
     * @param {string} prev previous resolution
     * @param {string} active active resolution
     */
    determineChangeSize(prev, active) {
        if (!prev || !active) { return false; }
        let mobiles = ['isMobile'];
        if (mobiles.indexOf(prev) > -1 && mobiles.indexOf(active) > -1 || (mobiles.indexOf(prev) === -1 && mobiles.indexOf(active) === -1)) {
            this.set('isMobileChange', false);
        } else {
            this.set('isMobileChange', true);
        }
    },

    isMobileResolutions() {
        return (this.get('isMobile') || this.get('isTablet'));
    },

    environmentCheck: function () {
        var self = this;

        var largeDesktopSize = self.get('largeDesktopBreakpoint');
        var desktopSize = self.get('desktopBreakpoint');
        var computerSize = self.get('computerBreakpoint');
        var tabletSize = self.get('tabletBreakpoint');

        if (window.matchMedia("(min-width: " + largeDesktopSize + "px").matches) {
            self.set('isLargeDesktop', true);
            self.set('isDesktop', false);
            self.set('isComputer', false);
            self.set('isTablet', false);
            self.set('isMobile', false);
            self.determineChangeSize(this.get('nowActive'), 'isLargeDesktop');
            self.set('nowActive', 'isLargeDesktop');
        }
        else if (window.matchMedia("(min-width: " + desktopSize + "px) and (max-width: " + largeDesktopSize + "px)").matches) {
            self.set('isLargeDesktop', false);
            self.set('isDesktop', true);
            self.set('isComputer', false);
            self.set('isTablet', false);
            self.set('isMobile', false);
            self.determineChangeSize(this.get('nowActive'), 'isDesktop');
            self.set('nowActive', 'isDesktop');
        }
        else if (window.matchMedia("(min-width: " + computerSize + "px) and (max-width: " + desktopSize + "px)").matches) {
            self.set('isLargeDesktop', false);
            self.set('isDesktop', false);
            self.set('isComputer', true);
            self.set('isTablet', false);
            self.set('isMobile', false);
            self.determineChangeSize(this.get('nowActive'), 'isComputer');
            self.set('nowActive', 'isComputer');
        }
        else if (window.matchMedia("(min-width: " + tabletSize + "px) and (max-width: " + computerSize + "px)").matches) {
            self.set('isLargeDesktop', false);
            self.set('isDesktop', false);
            self.set('isComputer', false);
            self.set('isTablet', true);
            self.set('isMobile', false);
            self.determineChangeSize(this.get('nowActive'), 'isTablet');
            self.set('nowActive', 'isTablet');
        }
        else {
            self.set('isLargeDesktop', false);
            self.set('isDesktop', false);
            self.set('isComputer', false);
            self.set('isTablet', false);
            self.set('isMobile', true);
            self.determineChangeSize(this.get('nowActive'), 'isMobile');
            self.set('nowActive', 'isMobile');
        }

        self.set('isPortrait', window.matchMedia("(orientation: portrait)").matches);

        self.toggleProperty('resolutionChanged');
    },

    onResize: function (callback) {
        if (callback) {
            Ember.$(window).on("resize", function (event) {
                callback(event);
            });
        }
    },

    releaseResizeHandlers: function () {
        Ember.$(window).off("resize");
    }
});
