import Ember from 'ember';

export default Ember.Controller.extend({
    filterBy: null,
    filterValue: null,
    searchValue: null,

    resetValues() {
        this.set('filterBy', null)
        this.set('filterValue', null)
        this.set('searchValue', null)
    },

    actions: {
        typeChanged(/*value*/) {
            if (Ember.$('#management-type').dropdown('get value') === '') {
                this.set("filterBy", null);
                Ember.$('#management-type').dropdown('clear');
            }
            else {
                this.set("filterBy", "type");
                this.set("filterValue", Ember.$('#management-type').dropdown('get value'));
            }
        },
        engineStateChanged() {
            if (Ember.$('#management-engine').dropdown('get value') === '') {
                this.set("filterBy", null);
                Ember.$('#management-engine').dropdown('clear');
            }
            else {
                this.set("filterBy", "stateNumber");
                this.set("filterValue", Number(Ember.$('#management-engine').dropdown('get value')));
            }
        },
        divisionChanged() {
            if (Ember.$('#management-division').dropdown('get value') === '') {
                this.set("filterBy", null);
                Ember.$('#management-division').dropdown('clear');
            }
            else {
                this.set("filterBy", "group");
                this.set("filterValue", Ember.$('#management-division').dropdown('get value'));
            }
        },

        search(value) {
            this.set("searchValue", value)
        }
    }
});
