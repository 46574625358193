import Alerts from './alerts'
import Errors from './errors'
import Management from './management'
import Messages from './messages'
import Reports from './reports'
import Markers from './markers'

export default {
	pagination: {
		first: 'Prima pagina',
		last: 'Ultima pagina',
		next: 'Pagina urmatoare',
		previous: 'Pagina precedenta',
		of: 'din',
		resultsPerPage: 'Rezultate pe pagina',
		goToPage: 'Mergi la pagina',
		pageOfTotal: 'Pagina {{page}} din {{total}}',
	},
	pleaseWait: 'Vă rugăm să așteptați',
	processingFileData: 'Se procesează datele din fișier',
	expand: 'Mărește',
	menu: {
		dispatch: {
			index: 'Dispecerat',
			routes: {
				add: 'Adauga ruta'
			}
		}
	},
	loading: {
		text: 'Se incarca'
	},
	acl: 'Control access',
	alerts: {
		messages: Alerts
	},
	alertsAndNotifications: 'Alerte si Notificari',
	openDoorsStatus: "Stare uși",
	doorsStatus: {
		open: "Uși deschise",
		closed: "Uși închise",
	},
	closed: "Închis",
	open: "Deschis",
	openDoor: {
		'front-left': "Ușa din dreapta față deschisă",
		'front-right': "Ușa din stânga față deschisă",
		'back-left': "Ușa din dreapta spate deschisă",
		'back-right': "Ușa din stânga spate deschisă",
		'trunk': "Ușa din spate deschisă",
		'allClosed': "Toate ușile sunt închise",
		'na': 'Fara senzori',
		generic: 'Ușă deschisă'
	},
	freezerStatus: "Stare agregat frigorific",
	freezer: {
		on: "Agregat frigorific pornit",
		off: "Agregat frigorific oprit",
		na: "Fara agregat frigorific"
	},
	vehicle: {
		engineOn: 'Pornit',
		engineOff: 'Oprit',
		unreachable: 'Fără semnal',
	},
	vehicleStatus: {
		engineOn: 'În mișcare',
		engineOff: 'Oprit',
		unreachable: 'Fără semnal',
		unreachableSince: 'Fără semnal de {{status}}',
		noLocation: 'Fără dispozitiv atașat',
		generic: 'Stare utilaj',
	},
	licensePlate: 'Număr înmatriculare',
	markers: Markers,
	previous: 'Precedent',
	next: 'Următor',
	noResults: 'Fără rezultate',
	fromDate: 'Din data',
	until: 'Până în',
	temperatureIntervals: 'La fiecare',
	interval: 'Interval',
	routeReport: 'Foaie parcurs',
	anotherValue: 'Altă valoare',
	liters: 'Litri',
	litersPerHour: 'Litri / ora',
	consume: 'Consum',
	consumption: 'Consum',
	speedPerHour: 'Viteza (km/h)',
	speed: 'Viteza',
	altitude: 'Altitudinea',
	altitudeMeters: 'Altitudinea (m)',
	fuelLevelLitres: 'Nivel rezervor (l)',
	engineLoad: "Sarcină Motor (%)",
	engineTemperature: "Temperatură Motor (°C)",
	refuel: 'Alimentare',
	averageSpeed: 'Viteza medie',
	maxSpeed: 'Viteza maxima',
	distance: 'Distanta',
	fuelLevelForPeriod: 'Nivel rezervor pentru perioada',
	canFuelLevel: 'Nivel Rezervor CAN',
	fuelLevel: 'Nivel Rezervor',
	fuelCanLevel: 'Nivel Rezervor CAN',
	fuelAverageLevel: 'Nivel Rezervor Medie',
	fuelConsumption: 'Consum',
	details: 'Detalii',
	noDetails: 'Fără detalii',
	event_enter: 'Eveniment intrare',
	event_exit: 'Eveniment iesire',
	event_stationary: 'Eveniment stationare',
	alert_in: 'Alerta intrare',
	alert_out: 'Alerta iesire',
	alert_transit: 'Alerta tranzit',
	back: 'Inapoi',
	minValue: 'Valoare minimă',
	maxValue: 'Valoare maximă',
	averageValue: 'Valoare medie',
	settings: {
		markers: 'Setari markeri',
		map: 'Setari harta',
		_: 'Setări'
	},
	statusFeed: 'Stare flotă',
	vehicles: 'Utilaje',
	vehicless: 'utilaj(e)',
	geofences: 'Fielduri',
	pois: 'Ferme',
	users: 'Utilizatori',
	default: 'Implicit',
	divisions: 'Divizii',
	duration: 'Durata',
	account: 'Cont',
	poi: 'Terenuri',
	timetables: 'Orare',
	integrations: 'Integrări',
	implements: "Implementuri",
	errors: {
		'1': 'Eroare la salvare utilaj',
		'2': 'Eroare la salvare utilizator',
		'3': 'Eroare la blocare/deblocare contact',
		'4': 'Eroare la salvare implement',
		'409': 'Utilizatorul exista deja',
		'410': 'Emailul exita deja in sistem',
		'5010': 'Nu sunt permise decât poligoane simple.',
		'5020': 'Divizia este deja partajată.',
		messages: Errors,
		accountDisabled: 'Ne pare rău. Contul companiei dumneavoastră a fost dezactivat.'
	},
	dayHours: 'Ore',
	theMinutes: 'Minute',
	monthDays: 'Zile',
	theWeekDays: 'Zile',
	messages: Messages,
	day: 'Zi',
	week: 'Saptamana',
	month: 'Luna',
	every: 'In fiecare',
	map: 'Harta',
	type: 'Tip',
	search: 'Caută',
	ALL: 'TOATE',
	ok: 'OK',
	add: 'ADAUGA',
	save: 'SALVEAZA',
	saveSuccess: 'Datele au fost salvate.',
	edit: 'MODIFICA',
	cancel: 'ANULARE',
	send: 'TRIMITE',
	timezone: 'Fus Orar',
	delete: 'STERGE',
	addFueling: 'Adauga alimentare',
	aclGroup: 'Grup',
	aclGroups: 'Grupuri',
	aclVehicle: 'utilaj',
	aclVehicles: 'utilaje',
	allVehicles: 'Toate utilajele',
	selected: 'selectate',
	subscribe: 'Subscribe',
	acceleration: 'Accelerația',
	rpm: 'RPM',
	temperature: 'Temperatură',
	celsiusTemperature: 'Temperatură (°C)',
	sensors: 'Senzori',
	accelerationG: 'Accelerație',
	alert: 'Alertă',
	notification: 'Notificare',
	disabled: 'Dezactivat',
	driver: 'Operator',
	drivers: 'Operatori',
	driverss: 'operator(i)',
	noDriver: 'Fara operator',
	selectedDrivers: 'operatori selectati',
	selectFile: 'Selectează fișier',
	selectVehicles: 'Selectează utilaje',
	selectFilters: 'Selectează filtre',
	selectedFilters: 'filtre selectate',
	selectedGeofences: 'fielduri selectate',
	unknownLocation: 'Locatie necunoscuta',
	time: {
		period: 'Perioada',
		today: 'Astazi',
		currentWeek: 'Saptamana curenta',
		currentMonth: 'Luna curenta',
		yesterday: 'Ieri',
		lastWeek: 'Saptamana trecuta',
		lastMonth: 'Luna trecuta'
	},
	weekDays: {
		sunday: 'duminica',
		monday: 'luni',
		tuesday: 'marti',
		wednesday: 'miercuri',
		thursday: 'joi',
		friday: 'vineri',
		saturday: 'sambata'
	},
	dashboard: {
		heatmapTitle: 'Activitate în ultimele 24 h',
		distance: 'Distanța',
		area: 'Suprafață',
		heatmapShowMore: 'Arată mai multe',
		heatmapShowLess: 'Arată mai puține',
		dailyKm: 'Total km ultimele 24h',
		dailyHa: 'Total ha ultimele 24h',
		dailyConsumption: 'Consum ultimele 24h',
		monthlyKm: 'Km parcurși în luna curentă',
		monthlyHa: 'Ha lucrate în luna curentă',
		monthlyConsumption: 'Consum total în luna curentă',
		cars: 'mașini',
		alerts: 'Alerte',
		notifications: 'Notificări',
		showAlerts: 'Toate alertele',
		showNotifications: 'Toate notificările',
		showAllVehicles: 'Arată toate utilajele',
		stoped: 'oprit',
		moving: 'în mișcare',
		contact: 'Ai o idee, propunere sau reclamație?',
		contactLink: 'Contactează-ne',
		contactTitle: 'Contact',
		contactSendBtn: 'Trimite',
		contactMsgHint: 'Adaugă mesaj',
		errorOnSendingMessage: 'A apărut o eroare în încercarea de a trimite mesajul dvs.',
		plaseTryAgainLater: 'Vă rugăm să încercați din nou mai târziu',
		emptyMessage: 'Fără mesaj',
		pleaseInputMessage: 'Vă rugăm să indroduceți mesajul dvs.',
		noAlertData: 'Nu sunt alerte',
		noNotificationData: 'Nu sunt notificări',
		comingSoon: 'În curând',
		noData: 'Nu sunt date',
		showDriver: 'Nume operator',
		showLicensePlate: 'Utilaj',
		liveStatus: {
			vehicle: 'Utilaj',
			address: 'Adresa / field',
			lastPOI: 'Ultimul field',
			status: 'Stare',
			speed: 'Viteza',
			fuelLevel: 'Combustibil',
			temperature: 'Agregat',
			doors: 'Usi',
			implements: "Implementuri",
		}
	},

	management: Management,
	reports: Reports,
	routes: {
		reports: 'Rapoarte',
		'reports.stationary': 'Stationari'
	},
	signup: {
		// terms: 'Sunt de acord cu <a href="/tos" target="_new">Termenii si Conditiile</a>.',
		terms: 'Pentru acceptarea serviciilor FarmIS va rugam confirmati <a href="/tos" target="_new">Termenii si Conditiile pentru Acces la Platforma</a>.',
		invitation: {
			expired: 'Invitatia a expirat. Va rugam contactati administratorul.'
		},
		termsText: "<h1>Termeni si Conditii pentru Acces la Platforma</h1>"
			+ "<h4>Obligațiile Furnizorului</h4><ul>"
			+ "<li>să furnizeze Beneficiarului accesul la Sistemul Titan FarmIS, în măsura îndeplinirii de către Beneficiar a tuturor obligațiilor contractuale asumate;</li>"
			+ "<li>să ofere Beneficiarului instruirea inițială de bază cu privire la utilizarea Sistemului Titan FarmIS;</li>"
			+ "<li>să acorde garanție pentru dispozitivele GPS conform termenilor și condițiilor din Anexa nr. 1 la Contract;</li>"
			+ "<li>să furnizeze actualizări ale Sistemului Titan FarmIS, corespunzătoare pachetului achiziționat de către Beneficiar;</li>"
			+ "<li>să stocheze gratuit datele înregistrate în Sistemul Titan FarmIS pentru o perioadă de 12 luni, perioadă în care Beneficiarul le poate accesa oricând.</li></ul>"

			+ "<h4>Obligațiile Beneficiarului</h4><ul>"
			+ "<li>să utilizeze Sistemul Titan FarmIS (inclusiv echipamentul GPS și/sau cartela SIM) conform scopului stabilit prin prezentul Contract, destinației sale, în conformitate cu instrucțiunile de utilizare, normelor de protecția muncii și a reglementărilor specifice determinate de condițiile efective existente la locația la care este utilizat utilajul pe care este instalat Sistemul Titan FarmIS. Clientul are obligația de a solicita toate informațiile referitoare la utilizarea în condiții optime a Sistemului Titan FarmIS;</li>"
			+ "<li>să nu transfere unor terțe părți sau să permită utilizarea de către terțe părți a Sistemului Titan FarmIS;</li>"
			+ "<li>să nu intervină în vreun fel asupra fișierelor de date și componentelor Sistemului Titan FarmIS, inclusiv prin utilizarea de alte programe, în scopul dezasamblării și decompilării acestuia;</li>"
			+ "<li>să nu efectueze vreo intervenție, ajustare, reglare sau schimbare de orice fel în legătură cu Sistemul Titan FarmIS; orice intervenție asupra Sistemului Titan FarmIS se va putea efectua doar de către Furnizor;</li>"
			+ "<li>să asigure reprezentanților desemnați de Furnizor disponibilitatea utilajelor pentru realizarea instalării/intervențiilor;</li>"
			+ "<li>să nu altereze autocolante cu sigla Titan FarmIS aplicate pe utilajele monitorizate;</li>"
			+ "<li>să permită utilizarea de către Furnizor a informațiilor înregistrate de Sistemul FarmIS pentru monitorizarea parametrilor de funcționare a utilajului pe care este montat în scopuri științifice, statistice și de marketing.</li></ul>"

			+ "<h3>Modul Hartă</h3>"
			+ "Acest modul este accesibil tuturor utilizatorilor din cadrul companiei. Lista de divizii și utilaje vizibile și accesibile în acest modul sunt administrabile la nivel de utilizator. Acest modul cuprinde două secțiuni:<ul>"
			+ "<li>Secțiunea hartă generală: în această secțiune sunt vizibile pozițiile cele mai recente ale tuturor vehiculelor accesibile utilizatorului logat. Această secțiune se reactualizează automat la fiecare 10 secunde.</li>"
			+ "<li>Secțiunea hartă detaliată: în această secțiune un utilaj este deja preselectat (poate fi schimbat) și utilizatorul poate vizualiza rutele parcurse de acest utilaj pe intervalul de timp selectat.</li></ul>"

			+ "<h3>Modul Rapoarte</h3>"
			+ "Acest modul este accesibil tuturor utilizatorilor din cadrul companiei. Lista de divizii și utilaje vizibile și accesibile în acest modul sunt administrabile la nivel de utilizator. Acest modul cuprinde următoarele secțiuni:<ul>"

			+ "<li><b>FAZ</b> - Acest raport poate fi generat pentru multiple utilaje simultan pentru o perioadă dintre prima și ultima zi a lunii pentru lunile trecute și dintre prima zi și ziua curentă pentru luna în curs. Raportul poate fi descărcat sub format PDF, Excel și CSV. Pentru fiecare vehicul se va genera un fișier separat cu numele ‘TITLURAPORT_denumireutilaj_luna’. Raportul poate fi printat direct din browser. Acest raport suportă abonare la trimiteri automate cu frecvență configurabilă."
			+ "<br>Raportul FAZ conține informații generale despre utilaj, operator, perioada raportului, momentul generării și conține un tabel, pentru fiecare utilaj selectat în parte, cu următoarele coloane: Dată, Distanță, Consum, Ore Funcționare, Viteză medie.</li><br>"

			+ "<li><b>Foi Parcurs</b> - Acest raport poate fi generat pentru multiple utilaje simultan. Raportul poate fi descărcat sub format PDF, Excel și CSV. Pentru fiecare utilaj se va genera un fișier separat cu numele ‘TITLURAPORT_ denumireutilaj_luna’. Raportul poate fi printat direct din browser. Acest raport suportă abonare la trimiteri automate cu frecvență configurabilă."
			+ "<br>Raportul Foi Parcurs conține informații despre utilaj, operator, perioada raportului, momentul generării și conține un tabel, pentru fiecare vehicul selectat în parte, cu următoarele coloane: Vehicul, Operator, Moment Pornire, Locație Pornire, Moment Sosire, Locație Sosire, Distanță, Ore Funcționare, Consum Calculat</li><br>"

			+ "<li><b>Staționări</b> - Acest raport poate fi generat pentru multiple utilaje simultan. Raportul poate fi descărcat sub format PDF, Excel și CSV. Numele fișierului este de forma ‘TITLURAPORT_datainceput_datasfarsit’. Raportul poate fi printat direct din browser. Acest raport suportă abonare la trimiteri automate cu frecvență configurabilă."
			+ "<br>Raportul Staționări conține un tabel cu următoarele coloane: utilaj, Operator, Adresă, Început staționare, Sfârșit staționare, Durată.</li><br>"

			+ "<li><b>Consum</b> - Acest raport poate fi generat pentru multiple vehicule simultan. Raportul poate fi descărcat sub format PDF, Excel și CSV. Numele fișierului este de forma ‘TITLURAPORT_datainceput_datasfarsit’. Raportul poate fi printat direct din browser. Acest raport suportă abonare la trimiteri automate cu frecvență configurabilă. Raportul Consum conține un tabel cu următoarele coloane: Vehicul, Operator, Distanță, Ore Funcționare, Consum, Consum / 100.</li><br>"

			+ "<li><b>Activitate peste orar</b> - Acest raport poate fi generat pentru multiple utilaje simultan pentru o perioadă de maximum 30 de zile. Raportul poate fi descărcat sub format PDF, Excel și CSV. Numele fișierului este de forma ‘TITLURAPORT_datainceput_datasfarsit’. Raportul poate fi printat direct din browser. Acest raport suportă abonare la trimiteri automate cu frecvență configurabilă. Raportul conține un tabel cu următoarele coloane: Operator Dată, Distanță, Durată, Consum.</li><br>"

			+ "<li><b>Încălcări</b> - Acest raport poate fi generat pentru multiple utilaje simultan pentru o perioadă de maximum 30 de zile. Raportul poate fi descărcat sub format PDF, Excel și CSV. Numele fișierului este de forma ‘TITLURAPORT_datainceput_datasfarsit’. Raportul poate fi printat direct din browser. Acest raport suportă abonare la trimiteri automate cu frecvență configurabilă. Raportul conține un tabel cu următoarele coloane: Operator, Adresă, Viteză, Limită Perimetru, Durată.</li></ul>"

			+ "NOTE: Fiecare tabel suportă filtrarea după operator, unde acest câmp este activ."

			+ "<h3>Modul Management</h3>"
			+ "Acest modul este accesibil utilizatorilor cu rol de administratori. Lista de divizii, utilaje și utilizatori vizibile și accesibile în acest modul sunt administrabile la nivel de companie."
			+ "<br>Acest modul cuprinde următoarele secțiuni:<ul>"

			+ "<li><b>Utilaje</b> - În această secțiune, utilizatorul poate efectua următoarele operații:<ul>"
			+ "<li>vizualizare sumară a utilajelor;</li>"
			+ "<li>vizualizare detaliată a fiecărui utilaj în parte;</li>"
			+ "<li>adăugare / editare / ștergere utilaj;</li>"
			+ "<li>atașare utilaje la divizie în cadrul operațiilor de adăugare și editare.</li></ul>"
			+ "Pagina de start a acestei secțiuni cuprinde un tabel cu utilajele companiei."
			+ "<br>De asemenea, utilizatorul poate filtra lista de vehicule după tipul utilajului și divizia din care acesta face parte. Este accesibil și un câmp de căutare, utilizatorul putând căuta, fie după numărul de înmatriculare al utilajului, fie după marcă și model."
			+ "<br>Pentru fiecare utilaj din listă, utilizatorul poate efectua operațiile de vizualizare detalii, editare și ștergere."
			+ "<br>Ultima operație este de adăugare, utilizatorul având la dispoziție o mulțime de câmpuri pentru o bună identificare a vehiculului în toate secțiunile aplicației."

			+ "</li><br><li><b>Utilizatori</b> - În secțiunea Utilizatori, utilizatorul cu rol de administrator va avea acces la toți utilizatorii din cadrul companiei, putând efectua operațiile de adăugare, editare, vizualizare detalii și ștergere pentru fiecare utilizator."
			+ "<br>Pagina inițială a acestei secțiuni cuprinde un tabel cu toți utilizatorii din companie. Administratorul va putea vizualiza detalii despre fiecare utilizator în parte, va putea edita și șterge utilizatori."
			+ "<br>În cadrul operațiilor de adăugare / editare, utilizatorii pot fi încadrați într-o divizie, le pot fi asignate vehicule, drepturi de acces etc."
			+ "<br>De asemenea, utilizatorii pot încărca poze de profil pentru o mai bună identificare în cadrul aplicației."

			+ "</li><br><li><b>Divizii</b> - În această secțiune, utilizatorul cu rol de administrator poate vizualiza diviziile companiei din care face parte, într-o structură arborescentă."
			+ "<br>Utilizatorul va putea adăuga divizii noi, în cadrul oricărei subdivizii. Compania este divizia ‘root’ și este unică. Toate diviziile vor fi create în cadrul acestei divizii ‘root’. Utilizatorul are posibilitatea sa restrângă sau să extindă lista. În primă fază se va afișa lista extinsă."
			+ "<br>De asemenea, există posibilitatea de căutare după nume, a diviziilor. Dacă diviziile găsite au fost restrânse, vor rămâne extinse.</ul>"
			+ "</li>Observație: Toate rapoartele sunt printabile și se pot exporta în format MS Excel."
	},

	dispatch: {
		route: {
			title: 'Ruta',
			distance: 'Distanta (km)',
			name: 'Nume',
			vehicles: 'Utilaje',
			duration: 'Durata',
			waypoints: {
				title: 'Puncte',
				type: 'Tip',
				types: {
					load: 'Incarcare',
					unload: 'Descarcare',
					stop: 'Stationare'
				},
				location: 'Locatie',
				duration: 'Durata (minute)'
			},
			actions: {
				route: 'Genereaza ruta',
				optimize: 'Optimizeaza ruta',
				next: 'Continua',
				save: 'Salveaza',
				add: 'Adauga',
				search: 'Cautare ruta...'
			},
			state: {
				generating: 'Se genereaza ruta...'
			},
			optimized: {
				title: 'Ruta optimizata',
				use: 'Foloseste ruta optimizata',
				message: 'Ruta poate fi optimizata',
				diff: 'Ruta optimizata este cu <b>{{distance}}</b> km mai scurta si cu <b>{{time}}</b> mai rapida.'
			},
			schedule: {
				title: 'Program'
			},
			details: {
				title: 'Detalii'
			}
		}
	}
};
