import Ember from 'ember'
import fuzzy from "npm:fuzzy"
import polyline from "npm:@mapbox/polyline"
/* global L */

export default Ember.Controller.extend({
	ajax: Ember.inject.service(),
	map: null,

	waypointTypes: ['load', 'unload', 'stop'],
	waypoints: Ember.computed.map('model.route.waypoints', function (waypoint) {
		return {
			location: {
				name: waypoint.get('name'),
				coordinates: waypoint.get('location') != null ? waypoint.get('location').reverse() : null,
			},
			duration: waypoint.get('duration'),
			type: waypoint.get('type')
		}
	}),
	validWaypoints: Ember.computed.filter('waypoints.@each.location', function(waypoint) {
		return waypoint.location != null && waypoint.location.coordinates != null
	}),
	distinctWaypoints: Ember.computed.uniqBy('validWaypoints', 'location'),

	locations: Ember.computed.mapBy('distinctWaypoints', 'location'),
	bounds: Ember.computed('locations.@each', function() {
		let coordinates = this.get('locations').map(function(location) {
			return location.coordinates
		})

		// If no locations set, show Romania
		if (coordinates.length < 2) {
			coordinates.push([43.517, 19])
			coordinates.push([48.25, 29.967])
		}

		return L.latLngBounds(coordinates)
	}),
	canRoute: Ember.computed.gte('locations.length', 2),
	hasRoute: Ember.computed.notEmpty('route'),
	isRouting: false,
	routePolyline: Ember.computed.alias('model.route.decodedPolyline'),
	routeWaypoints: [],
	route: null,
	routeTotalDuration: Ember.computed('route', 'waypoints.@each.duration', function() {
		let routeDuration = this.get('route.duration')
		let waypointsTotalDuration = this.get('waypoints').reduce(function(previousValue, item) {
			if (item.duration != null) {
				return previousValue + ((parseInt(item.duration) || 0) * 60)
			}
			return previousValue
		}, 0)

		return routeDuration + waypointsTotalDuration
	}),

	isOptimizing: false,
	optimizedRoute: null,
	hasOptimizedRoute: Ember.computed.notEmpty('optimizedRoute'),
	onOptimizedRouteChanged: Ember.observer('hasOptimizedRoute', function() { /* eslint-disable-line ember-best-practices/no-observers */
		const map = this.get('map')
		const bounds = this.get('bounds')

		if (map) {
			// Because of the container resize, we need to fit the map to our bounds again
			Ember.run.later(map, function() {
				this.stop()
				this.invalidateSize(false)
				this.fitBounds(bounds)
			})
		}
	}),
	optimizedWaypoints: [],
	optimizedLocations: Ember.computed.mapBy('optimizedWaypoints', 'location'),
	optimizedRouteTotalDuration: Ember.computed('optimizedRoute', 'optimizedWaypoints.@each.duration', function() {
		let routeDuration = this.get('optimizedRoute.duration')
		let waypointsTotalDuration = this.get('optimizedWaypoints').reduce(function(previousValue, item) {
			if (item.duration != null) {
				return previousValue + ((parseInt(item.duration) || 0) * 60)
			}
			return previousValue
		}, 0)

		return routeDuration + waypointsTotalDuration
	}),
	optimizedRouteLayer: null,
	optimizedRouteVisible: false,
	optimizedRouteViable: Ember.computed('route', 'optimizedRoute', function () {
		let route = this.get('route')
		let optimizedRoute = this.get('optimizedRoute')

		// The optimized route's distance should be smaller by at least 5% of the original route's distance
		return  (route != null && route.distance > 0) &&
		(optimizedRoute != null && optimizedRoute.distance > 0) &&
		(optimizedRoute.distance <= (route.distance - 5 / 100 * route.distance))
	}),

	name: Ember.computed.alias('model.route.name'),
	vehicles: Ember.computed.alias('model.route.vehicles'),
	startDate: Ember.computed.alias('model.route.startDate'),
	schedule: Ember.computed.alias('model.route.schedule'),
	isSaving: false,

	actions: {
		mapLoaded (map){ 
			this.set('map', map.target)
		},
		debouncedSearchLocation (term) {
			// Ember.Logger.debug('Debounced search:', term)
			
			return new Ember.RSVP.Promise((resolve, reject) => {
				Ember.run.debounce(this, this.get('actions.searchLocation'), term, resolve, reject, 600)
			})
		},
		searchLocation (term, resolve, reject) {
			Ember.Logger.debug('Search location:', term)
			
			let geofences = this.get('model.geofences').toArray()

			return this.get('ajax').request('https://map.tracknamic.com/search/search', {
				data: {
					q: term,
					limit: 5,
					format: 'json',
					addressdetails: 1
				}
			})
			.then(function (locations) {
				let filteredGeofences = fuzzy.filter(term, geofences, {
					extract: function(geofence) {
						return geofence.get('name')
					}
				}).slice(0, 5).map(function(filtered) {
					return filtered.original
				})

				Ember.Logger.debug('Search results:', locations, filteredGeofences)

				return [
					{ groupName: "Geofences", options: filteredGeofences.map(function (geofence) {
						return {
							name: geofence.get('name'),
							shape: geofence.get('shape'),
							coordinates: geofence.get('shape.coordinates').toArray().reverse()
						}
					}) },
					{ groupName: "Locations", options: locations.map(function (location) {
						return {
							name: location.display_name,
							coordinates: [location.lat, location.lon]
						}
					}) },
				]
			})
			.then(function(result){
				resolve(result)
			},function(error){
				reject(error)
			})
		},
		route () {
			let waypoints = this.get('validWaypoints')
			let coordinates = []
			waypoints.forEach(function(waypoint) {
				coordinates.push(`${waypoint.location.coordinates[1]},${waypoint.location.coordinates[0]}`)
			})

			Ember.Logger.debug('Route:', waypoints)

			this.set('isRouting', true)

			this.get('ajax').request(`https://map.tracknamic.com/route/v1/car/${coordinates.join(';')}`, {
				data: {
					overview: 'full'
				}
			})
			.then(response => {
				this.set('isRouting', false)

				Ember.Logger.debug('Route response:', response)

				if (response.routes != null && response.routes.length > 0) {
					this.set('route', response.routes[0])
					this.set('routeWaypoints', this.get('distinctWaypoints').copy())
					
					this.send('setChosenRoute', response.routes[0], this.get('routeTotalDuration'), this.get('distinctWaypoints'))
				}
			})
		},
		addWaypoint () {
			this.get('waypoints').addObject({location: null})
		},
		removeWaypoint (waypoint) {
			this.get('waypoints').removeObject(waypoint)
		},
		reorderWaypoints(itemModels) {
			this.get('waypoints').setObjects(itemModels)
		},
		optimize () {
			const originalWaypoints = this.get('distinctWaypoints')
			const waypoints = this.get('distinctWaypoints').copy()

			let coordinates = []
			waypoints.forEach(function(waypoint) {
				coordinates.push(`${waypoint.location.coordinates[1]},${waypoint.location.coordinates[0]}`)
			})

			Ember.Logger.debug('Optimize:', coordinates)
			
			this.set('isOptimizing', true)

			this.get('ajax').request(`https://map.tracknamic.com/trip/v1/car/${coordinates.join(';')}`, {
				data: {
					source: 'first',
					destination: 'last',
					roundtrip: 'false',
					overview: 'full'
				}
			})
			.then(response => {
				this.set('isOptimizing', false)

				Ember.Logger.debug('Optimize response:', response)

				this.set('optimizedRoute', response.trips[0])

				response.waypoints.forEach((waypoint, index) => {
					if (index === waypoint.waypoint_index) {
						return
					}

					waypoints.replace(waypoint.waypoint_index, 1, [originalWaypoints.objectAt(index)])					
				})
				this.set('optimizedWaypoints', waypoints)
			})
		},
		toggleOptimizeLayer (checked) {
			Ember.Logger.debug('toggleOptimizeLayer:', checked)

			this.set('optimizedRouteVisible', checked)

			if (checked) {
				this.send('setChosenRoute', this.get('optimizedRoute'), this.get('optimizedRouteTotalDuration'), this.get('optimizedWaypoints'))				
			} else {
				this.send('setChosenRoute', this.get('route'), this.get('routeTotalDuration'), this.get('routeWaypoints'))				
			}
		},
		setSchedule (schedule) {
			Ember.Logger.debug('setSchedule:', schedule)
		},
		setStartDate (formattedDate, date) {
			Ember.Logger.debug('setStartDate:', formattedDate, date)

			this.set('startDate', date)
		},
		setChosenRoute (newRoute, totalDuration, waypoints) {
			let route = this.get('model.route')
			route.set('distance', newRoute.distance)
			route.set('duration', totalDuration)
			route.set('polyline', newRoute.geometry)
			route.set('waypoints', waypoints.map(waypoint => {
				return {
					name: waypoint.location.name,
					location: waypoint.location.coordinates.map(coordinate => parseFloat(coordinate)).reverse(),
					type: waypoint.type,
					duration: waypoint.duration
				}
			}))
		},
		save () {
			this.set('isSaving', true)

			this.get('model.route').save()
			.then(result => {
				Ember.Logger.debug('Saved planned route:', result)

				this.set('isSaving', false)

				this.transitionToRoute('dispatch.routes')
			}, error => {
				Ember.Logger.error('Failed to save planned route !', error)

				this.set('isSaving', false)
			})
		},

		willChangeStep (options) {
			Ember.Logger.debug('willChangeStep:', options)

			if (options.from === 'points' && options.to === 'route' && !this.get('canRoute')) {
				return false
			} else if (options.from === 'details') {
				// Only allow saving if date is set
				if (this.get('startDate') != null) {
					this.send('save')
				}

				return false
			}
		},
		didChangeStep (options) {
			Ember.Logger.debug('didChangeStep:', options)

			if (options.from === 'points' && options.to === 'route') {
				this.send('route')
				this.send('optimize')
			}
		}
	},
})
