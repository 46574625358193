/**
 * This component is ment to extend addepar time-series-chart component and should be used as in
 * charts/d3-v3-lines-point-hovered (see template) and it was not ment to be used alone.
 */
import Ember from 'ember'
import timeSeriesChart from '../../time-series-chart'
/* global d3 */

/**
 * Get the potin that is closest to mouse x coordinates.
 * @param {array} line
 * @param {date} timeAtMousePosition
 * @returns {object}
 */
function getPoint(line, timeAtMousePosition) {
	let bisectDate = d3.bisector(function (d) { return d.time }).left
	let closestElement = bisectDate(line.values, timeAtMousePosition, 1)
	let t0 = line.values[closestElement - 1]
	let t1 = line.values[closestElement]

	if (!t0 || !t1) { return null }
	return timeAtMousePosition.getTime() - t0.time.getTime() > t1.time.getTime() - timeAtMousePosition.getTime() ? t1 : t0
}

export default timeSeriesChart.extend({

	_lineMarkerData: function () {
		var mousePosition = this._mousePosition()
		if (Ember.isEmpty(mousePosition)) {
			return []
		}

		var invXScale = this.get('xTimeScale').invert
		let xScale = this.get('xTimeScale')
		let yScale = this.get('yScale')

		var timeAtMousePosition = invXScale(mousePosition[0])

		var markerData = []
		let pointData = null
		this.get('viewport').selectAll('path.line').each(function (d) {
			let point = getPoint(d, timeAtMousePosition)
			if (!point) { return false }

			/**
			 * In this very moment does not matter which line we use to take the point as all have same x coordinates.
			 */
			if (!pointData) {
				pointData = point
			}

			return markerData.push(Object.assign({}, point, {
				x: xScale(point.time),
				y: yScale(point.value),
				group: d.group,
				value: point.value,
				time: point.time,
				path: this
			}))
		})

		if (this.get('externalAction')) {
			this.get('externalAction')(pointData)
		}

		let tooltipDetails = { values: [] }
		tooltipDetails.values.push({ time: markerData[0].time })
		markerData.forEach(item => {
			tooltipDetails.values.push({ group: item.label, value: item.value, customValueText: item.customValueText })
		})

		this.get('showDetails')(tooltipDetails, null, null)

		return markerData
	},

	showDetails: Ember.computed('isInteractive', function () {
		if (!this.get('isInteractive')) {
			return Ember.K;
		}

		return (data, i, element) => {
			d3.select(element).classed('hovered', true);

			var time = null
			if (Ember.isArray(data.values)) {
				time = data.values.shift().time
			} else {
				time = data.labelTime != null ? data.labelTime : data.time
			}
			time = this.adjustTimeForShowDetails(time);
			var content = this.$('<span>');
			content.append(this.$("<span class=\"tip-label\">").text(this.get('formatTime')(time)));
			this.showTooltip(content.html(), d3.event);
			var formatLabelFunction = this.get('formatLabelFunction');

			var addValueLine = (d) => {
				var name = this.$('<span class="name" />').text(d.group + ': ');
				var value = this.$('<span class="value" />').text(d.customValueText ? d.customValueText : formatLabelFunction(d.value));
				content.append(name);
				content.append(value);
				content.append('<br />');
			};

			if (Ember.isArray(data.values)) {
				data.values.forEach(addValueLine);
			} else {
				addValueLine(data);
			}

			return this.showTooltip(content.html(), d3.event);
		};
	}),
})
