import Ember from 'ember';

export default Ember.Component.extend({
    cancelled: false,

    init() {
        this._super(...arguments);
        Ember.$('.ui.dimmer.modals').remove();
    },

    didInsertElement() {
        var _this = this;
        Ember.$('.managementModal')
            .modal({
                duration: 200,
                onHide: function () {
                    if (!_this.get("cancelled")) {
                        _this.set("cancelled", true);
                        _this.send('cancel');
                    }
                },
            })
            .modal('show');
    },
    actions: {
        save() {
            this.set("cancelled", true);
            Ember.$('.managementModal').modal("hide");
            this.get('save')();
        },
        cancel() {
            if (this.get("cancelled") === false) {
                this.set("cancelled", true);
                Ember.$('.managementModal').modal("hide");
            }
            this.get('cancel')();
        },
    }
});
