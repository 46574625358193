import Ember from 'ember';

export default Ember.Service.extend({
    fullHeatMap: false,
    showMore() {
        this.set('fullHeatMap', true);
    },

    partialHeatMap() {
        this.set('fullHeatMap', false);
    }
});
