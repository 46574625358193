import Ember from 'ember';
import DS from 'ember-data';
import MF from 'ember-data-model-fragments'

export default DS.Model.extend({
    groupID: DS.attr('string'),
    companyID: DS.attr('string'),
    email: DS.attr('string'),
    phoneNumber: DS.attr('string'),
    firstName: DS.attr('string'),
    lastName: DS.attr('string'),
    fullName: Ember.computed('firstName', 'lastName', function () {
        return this.get('firstName') + ' ' + this.get('lastName');
    }),
    role: DS.attr('string'),
    avatar: DS.attr('string'),
    details: MF.fragment('fragments/user/details'),
    acl: MF.fragment('fragments/user/acl'),
    isAdmin: Ember.computed('role', function () {
        return (this.get('role') === 'admin');
    }),

    vehicle: DS.belongsTo("vehicle"),
    group: DS.belongsTo("group"),
    vehicles: DS.hasMany("vehicle")
});
