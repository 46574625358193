import Ember from 'ember'
import RSVP from 'rsvp'
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin'

const { service } = Ember.inject
const tenSeconds = 10000

export default Ember.Route.extend(AuthenticatedRouteMixin, {
	tail: service('map/tail'),
    parseDivisions: service('parse-divisions'),
    polling:        service('live-update'),
	model: function () {
        return RSVP.hash({
            groups:         this.store.findAll("group"),
            vehicles:       this.store.query('vehicle', {geolocation: true}),
            users:          this.store.findAll("user"),
            geofences:      this.store.findAll('geofence')
        })
	},

	visibility: true,

	activatePolling(polling) {
		if (polling && !polling.get('timer')) {
			this.get('polling').start(tenSeconds)
		}
	},

	adaptToVisibility() {
		Ember.Logger.log('%c document.hidden ' + document.hidden, 'color: blue')
		let polling = this.get('polling')
		if (document.hidden) {
			this.deativatePolling(polling)
			Ember.Logger.log('polling disabeld')
			Ember.Logger.log('%c DO NOT REFRESH MAP', 'color: red')
		} else {
			this.refreshData()
			this.activatePolling(polling)
			Ember.Logger.log('polling reactivated')
		}
		Ember.Logger.log('=====================')
	},

	deativatePolling(polling) {
		if (polling && polling.get('timer')) {
			this.get('polling').stop()
		}
	},

	refreshData() {
		Ember.Logger.log('%c REFRESH MAP', 'color: #bada55')
		this.store.query('vehicle', {geolocation: true}).then(vehicles => {
			this.set("model.vehicles", vehicles)
			// _this.controllerFor("map.index").set("isRefresh", moment().valueOf())
		})
	},

    // Route hooks
    activate() {
		var controller = this.controllerFor('map.index')
		/**
		 * When we go from /map/routes to /map  the following happens:
		 * 1) old map is removed from map
		 * 2) vehicleMarkers computed is triggered but with old map
		 * 3) tails points are added on old map
		 * 4) new map is set
		 * 5) errors happens
		 */
		controller.set('mapInstance', null)
		/**
		 * Reset tail positions for all vehicles when we entering this route.
		 */
		controller.get('tail').resetAllVehicleData()
		/**
		 * Set map to null as this must be set when new map is ready
		 */
		controller.get('tail').setMapToNull()

        this.get('polling').onPollEvent = () => {
            this.refreshData()
		}

		document.addEventListener("visibilitychange", this.adaptToVisibility.bind(this))

		this.get('polling').start(tenSeconds)
    },

    deactivate () {
		this.get('polling').stop()
		document.removeEventListener("visibilitychange", this.adaptToVisibility)
		let controller = this.controllerFor('map.index')
		controller.set('activeMarker', null)
		controller.set('selectedVehicle', null)
		Ember.Logger.log('%c LEAVE MAP PAGE', 'color: blue')
    },

    didInsertElement() {
        this.get('sidebar').setTo(Ember.$("#map-menu-col"))
        this.activeOrNot()
	},

    actions: {
		willTransition() {
            this.controller.resetValues();
        },
    }
})
