import Ember from 'ember';

const rejected = 'rejected'
const fulfilled = 'fulfilled'

export default Ember.Service.extend({
	isRejected(promise) {
		if (!promise) { return null }
		return promise.state === rejected
	},

	ifFulfilled(promise) {
		if (!promise) { return null }
		return promise.state = fulfilled
	}
});
