/**
 * http://t1m0n.name/air-datepicker/docs/
 */
import Ember from 'ember';
import moment from 'moment';
/**
 * dateFormat moment.js supported format See: https://momentjs.com/docs/#/displaying/
 */
export default Ember.Component.extend({
	datepicker: null,
	appSettings: Ember.inject.service('application-settings'),
    appLanguage: Ember.computed.alias("appSettings.locale"),

	dateFormat: "DD/MM/YYYY HH:mm",
	classNames: ["has-air-datepicker"],
	iconType: "calendar",
	iconPosition: "left",
	position: "bottom left",
	autoClose: true,
	pickerType: "datetime",
	inline: false,

	// To distinguish between user interactions and controller callbacks
	_internalUpdate: false,
	_startDate: null,
	_minDate: null,
	_maxDate: null,
	_selectedDate: null,

	init: function () {
		this._super(...arguments);
		this.validateDateProperty("maxDate");
		this.validateDateProperty("minDate");
		this.validateDateProperty("startDate");
		this.validateDateProperty("selectedDate");
	},

	validateDateProperty: function (property) {
		var value = this.get(property);
		if (value) {
			if (typeof value != "object") {
				value = moment(value).toDate();
			}
			if (value != this.get("_" + property)) {
				this.set("_" + property, value);
			}
		}
	},

	didInsertElement() {
		var
			_this = this,
			datepickerInstance = null,
			timepicker = false,
			classes = null;

		switch (this.get("pickerType")) {
			case "datetime":
				timepicker = true;
				break;
			case "time":
				timepicker = true;
				classes = "only-timepicker";
				break;
		}

		datepickerInstance = this.$('input').datepicker({
			classes: classes,
			timepicker: timepicker,
			autoClose: _this.get('autoClose'),
			minDate: _this.get('_minDate'),
			maxDate: _this.get("_maxDate"),
			startDate: _this.get('_startDate'),
			date: _this.get("_selectedDate"),
			inline: _this.get("inline"),
			language: _this.get("appLanguage"),
			view: _this.get("view"),
			position: _this.get("position"),
			minView: _this.get("view"),


			toggleSelected: false,

			onShow: function (dp, animationCompleted) {
				_this.sendAction('onShow', dp, animationCompleted);
			},
			onHide: function (dp, animationCompleted) {
				if (!_this.get("_internalUpdate")) {
					let dateObject = dp.selectedDates[0]
					let formattedDate = dp.formatDate(dateObject)
					let datepickerInstance = dp
					let publicValue = _this.get("selectedDate");

					if (typeof publicValue == "number") {
						_this.set("selectedDate", dateObject.getTime());
					} else {
						_this.set("selectedDate", dateObject);
					}

					_this.sendAction('onSelect', formattedDate, _this.get("selectedDate"), datepickerInstance);
					_this.sendAction('onHide', dp, animationCompleted);
				}
				_this.set("_internalUpdate", false);
			},
		}).data('datepicker');

		datepickerInstance.formatDate = function (string, date) {
			return moment(date).format(_this.get("dateFormat"));
		};
		this.set("_internalUpdate", true);
		datepickerInstance.selectDate(this.get('_selectedDate'));

		this.set('datepicker', datepickerInstance);
	},

	// Setting up observers and private properties in order to prevent infinite loops
	selectedDateObserver: Ember.observer("selectedDate", function () {
		this.validateDateProperty("selectedDate");
		if (this.get("datepicker")) {
			// Only execute this once when the selectedDate was modified by a controller action
			this.set("_internalUpdate", true);
			this.get("datepicker").selectDate(this.get("_selectedDate"));
		}
	}),

	maxDateObserver: Ember.observer("maxDate", function () {
		this.validateDateProperty("maxDate");
		this.get("datepicker").update("maxDate", this.get("_maxDate"));
	}),
	minDateObserver: Ember.observer("minDate", function () {
		this.validateDateProperty("minDate");
		this.get("datepicker").update("minDate", this.get("_minDate"));
	})
});
